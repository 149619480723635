
import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADDRESS_CREATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADDRESS_CREATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.ADDRESS_CREATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.ADDRESS_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADDRESS_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.ADDRESS_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.ADDRESS_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADDRESS_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.ADDRESS_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.SET_DEFAULT_ADDRESS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SET_DEFAULT_ADDRESS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.SET_DEFAULT_ADDRESS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        } 
        default:
            return state;
    }

};