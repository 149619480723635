import axios from 'axios';
import { BASE_URL } from '../config/api_endpoint'

const headers = {
  'X-Shopify-Storefront-Access-Token': `3da7733ea17e0010bcc22d4100d72bbc`,
  'Content-Type': 'application/json'
}

const axiosInstance = axios.create({});

const hubSpotHeaders = {
  'Content-Type': 'application/json'
}


export const signUpQuery = (queries, input) => {
  return axios.post(BASE_URL, {
    query: queries,
    // variables: {
    input
    // }
  }, {
    headers: headers
  }).then((response) => {
    return response?.data?.data
  }).catch((error) => {
    throw error
  })
}

export const loginQuery = (queries, input) => {
  return axios.post(BASE_URL, {
    query: queries,
    variables: {
      input
    }
  }, {
    headers: headers
  }).then((response) => {
    return response.data && response.data.data
  }).catch((error) => {
    throw error
  })
}


export const postQuery = (queries, input) => {
  return axios.post(BASE_URL, {
    query: queries,
    variables: input
  }, {
    headers: headers
  }).then((response) => {
    return response.data && response.data.data
  }).catch((error) => {
    throw error
  })
}


export const commonPostQuery = (queries, input) => {
  return axios.post(BASE_URL, {
    query: queries,
    variables: input
  }, {
    headers: headers
  }).then((response) => {
    return response?.data
  }).catch((error) => {
    throw error
  })
}



export const getQuery = (queries) => {
  return axios.post(BASE_URL, {
    query: queries,
  }, {
    headers: headers
  }).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}

export const saveMeasurement = (queries, input) => {
  let body = {
    queries: queries,
    variables: input
  }
  return axios.post('https://api.worldofakal.com/add/measurement', body).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}


export const getMeasurementQuery = (queries) => {
  let body = {
    queries
  }
  return axios.post('https://api.worldofakal.com/get/measurement', body).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}



export const getOrderId = (data) => {
  const params = {
    orderId: data,
  };
  return axios.get('https://api.worldofakal.com/get/orderId', { params }).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}

export const updateOrderUrl = (input, queries) => {
  let body = {
    queries: queries,
    variables: input
  }
  return axios.post('https://api.worldofakal.com/order/update/url', body).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}

export const getAdminOrders = (queries) => {
  let body = {
    queries: queries,
  }
  return axios.post('https://api.worldofakal.com/admin/orders', body).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}


export const doPost = (url, data) => {
  const config = {
    method: 'POST',
    url,
    data,
    headers: hubSpotHeaders
  };
  return axiosInstance(config)
    .then(function (response) {
      return response
    }).catch((error) => {
      console.log('error', error)
    })

}

export const getProductsDetails = (data) => {
  const params = {
    productId: data,
  };
  return axios.get('https://api.worldofakal.com/get/products/details', { params }).then((response) => {
    return response.data
  }).catch((error) => {
    throw error
  })
}