export const LINE_ITEMS_UPDATE = `mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
  checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      id
      lineItems(first :10){
          edges {
              node{
                  id
                  quantity
              }
          }
      }
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}

  `