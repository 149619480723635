export const LAST_INCOMPLETE_CHECK_OUT = (accesstoken) => {
  return `{
    customer(customerAccessToken: "${accesstoken}") {
      email
      addresses(first: 4, reverse: true) {
        edges {
          node {
            address1
            address2
            city
            country
            countryCode
            firstName
            id
            lastName
            name
            phone
            province
            zip
          }
        }
      }
      defaultAddress {
        address1
        city
        company
        country
        countryCode
        firstName
        lastName
        name
        phone
        id
        province
        zip
      }
      lastIncompleteCheckout {
         id
        customAttributes {
          key
          value
        }
        appliedGiftCards {
          amountUsed {
            amount
            currencyCode
          }
          balance {
            amount
            currencyCode
          }
          id
          lastCharacters
          presentmentAmountUsed {
            amount
            currencyCode
          }
        }
        totalPrice {
          amount
          currencyCode
        }
        shippingLine {
          price {
            amount
            currencyCode
          }
          title
          handle
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        lineItems(first: 10) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              quantity
              title
              unitPrice {
                amount
              }
              variant {
                id
                price {
                  amount
                  currencyCode
                }
                image {
                  id
                  src
                  altText
                }
              }
            }
          }
        }
      }
    }
  }`
}
