import React from 'react'
import { withRouter } from 'react-router-dom'
import AdminCanvasShirt from '../../component/Admin_Canvas/shirt'
import AdminCanvasPant from '../../component/Admin_Canvas/pant'
import AdminCanvasSuit from '../../component/Admin_Canvas/suit'
import AdminCanvasBlazer from '../../component/Admin_Canvas/blazer'
import AdminCanvasVests from '../../component/Admin_Canvas/vest'
import { connect } from 'react-redux'
import Actions from '../../redux/action'

class AdminCanvas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            noJacket: true
        }
    }
    changeSuitTypes() {
        this.setState({ noJacket: !this.state.noJacket })
    }
    _renderStyles() {
        let { type } = this.props.match.params
        const { adminOrderList } = this.props.adminOrderList || {}
        const { order } = adminOrderList || {}
        const { noJacket } = this.state
        switch (type) {
            case 'shirt':
                return <AdminCanvasShirt
                    order={order}
                />
            case 'pant':
                return <AdminCanvasPant
                    order={order}
                />
            case 'suit':
                return <AdminCanvasSuit
                    order={order}
                />
            case 'blazer':
                return <AdminCanvasBlazer
                    order={order}
                />
            case 'vest':
                if (noJacket) {
                    return <AdminCanvasVests
                        order={order}
                        showJacket={() => this.changeSuitTypes()}
                    />
                } else {
                    return <AdminCanvasSuit
                        order={order}
                        suitVest={true}
                        showJacket={() => this.changeSuitTypes()}
                    />
                }

            default:
                return <AdminCanvasShirt
                    order={order}
                />
        }
    }

    render() {
        return (
            <React.Fragment>
                {this._renderStyles()}
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        adminOrderList: Actions.getAdminOrders(state)
    }
}

export default withRouter(connect(mapStateToProp, {
})(AdminCanvas));