const BASE_URL = 'https://paulsfashion.myshopify.com/api/2020-10/graphql.json'
const ADMIN_BASE_URL = 'https://4b970555fd034ea80639c954738d4a27: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@paulsfashion.myshopify.com/admin/api/2021-01/graphql.json'
const S3BASE_URL = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/shirt/'
const S3BASE_URL_PANT = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/pant/'
const S3BASE_URL_OLD = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/'
const S3BASE_URL_SUIT = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/suit/'
const S3BASE_URL_VEST = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/vest/'
const S3BASE_URL_BLAZER = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/blazer/'
export {
    BASE_URL,
    ADMIN_BASE_URL,
    S3BASE_URL,
    S3BASE_URL_OLD,
    S3BASE_URL_PANT,
    S3BASE_URL_SUIT,
    S3BASE_URL_VEST,
    S3BASE_URL_BLAZER
}
