import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    token: {},
    customerList: {},
    currentUrl: {},
    customerId: '',
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.USER_LOGIN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.USER_LOGIN_SUCCESS: {
            let { customerAccessToken } = action.data && action.data.customerAccessTokenCreate
            return {
                ...state,
                isLoading: false,
                token: customerAccessToken,
                error: false
            };
        }
        case Actions.USER_LOGIN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.GET_CUSTOMER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_CUSTOMER_LIST_SUCCESS: {
            let { data } = action.data
            let { id } = data?.customer || {}
            return {
                ...state,
                isLoading: false,
                customerList: action.data,
                customerId: id,
                error: false
            };
        }
        case Actions.GET_CUSTOMER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CUSTOMER_PROFILE_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CUSTOMER_PROFILE_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.CUSTOMER_PROFILE_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CUSTOMER_RESET_PASSWORD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CUSTOMER_RESET_PASSWORD_SUCCESS: {
            let { customerAccessToken } = action.data && action.data.customerUpdate
            return {
                ...state,
                isLoading: false,
                token: customerAccessToken,
                error: false
            };
        }
        case Actions.CUSTOMER_RESET_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            }
        }
        case Actions.RESET_PASSWORD_BY_URL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.RESET_PASSWORD_BY_URL_SUCCESS: {
            let { customerAccessToken } = action.data && action.data.customerReset
            return {
                ...state,
                isLoading: false,
                token: customerAccessToken,
                error: false
            };
        }
        case Actions.RESET_PASSWORD_BY_URL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CURRENT_LOCATION: {
            return {
                ...state,
                isLoading: false,
                currentLocation: action.data
            };
        }
        default:
            return state;
    }

};