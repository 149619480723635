import React from "react";

const ButtonDesign = ({ buttonImgPath, ButtonValue, className, NoImg, btnWidth, onClick, arrowImg, ButtonarrowDesign, Noarrow, disabled, dismiss }) => {
    return (
        <React.Fragment>
            <button className={`btn align-items-center gap-10 fs-14 text-lowercase button-design-focus rounded-0 ${btnWidth}`} type="button" onClick={onClick} disabled={disabled} data-bs-dismiss={dismiss}>
                <img className={` ${NoImg ? 'd-none' : 'd-block'} ${ButtonarrowDesign}`} src={buttonImgPath} alt="button-img" />
                <p className={`text-nowrap ${className}`}>{ButtonValue}</p>
                <img className={`${Noarrow ? 'd-none' : 'd-block'}`} src={arrowImg} at="button-img" />
            </button>
        </React.Fragment>
    )
}

export default ButtonDesign;