const NAME ='SELECTED_CHECK_OUT'

export const RESET_CHECK_OUT = `${NAME}/RESET_CHECK_OUT`;

export const SELECTED_CHECK_OUT_SUCCESS = `${NAME}/SELECTED_CHECK_OUT`;


export const selectedCheckoutSuccess = (data) => {
    return {
        type: SELECTED_CHECK_OUT_SUCCESS,
        data
    }
}

export const resetCheckOut = (data) => {
    return {
        type: RESET_CHECK_OUT,
        data
    }
}