const NAME = 'SELECTED_FABRICS'

export const UPDATE_BLAZER_FABRIC = `${NAME}/UPDATE_BLAZER_FABRIC`;
export const UPDATE_BLAZER_AMOUNT = `${NAME}/UPDATE_BLAZER_AMOUNT`;
export const SET_DEFAULT_BLAZER_FABRIC = `${NAME}/SET_DEFAULT_BLAZER_FABRIC`

export const getSelectedBlazerFabric = store => store[NAME].blazer
export const updateSelectedBlazerFabric = (value) => {
    return {
        type: UPDATE_BLAZER_FABRIC,
        value
    }
}
export const updatedBlazerProductAmount = (data) => {
    return {
        type: UPDATE_BLAZER_AMOUNT,
        data
    }
}
export const setDefaultBlazerFabrics = (data, key) => {
    return {
        type: SET_DEFAULT_BLAZER_FABRIC,
        data,
        key
    }
}