import React from 'react'
import { withRouter } from 'react-router-dom'
import { CUSTOMER_LIST, LAST_INCOMPLETE_CHECK_OUT } from '../../Queries'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import moment from 'moment'
import { ORDER_URL_UPDATE_QUERY } from '../../Queries/updateOrderUrl'
import { Constants } from '../../util/Constant'
class OrderSuccess extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      receivedOrder: undefined,
      customer: undefined,
    }
  }
  componentDidMount() {
    this._getCustomerDetails()
    this.props.resetCheckOut()
    this.props.resetCanvas()
  }
  _getCustomerDetails() {
    let { token = {} } = this.props.token
    let { accessToken } = token || {}

    let query = CUSTOMER_LIST(accessToken)
    this.props.getCustomerList(query)
    this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
  }


  _updateOrderAdminUrl() {
    let { id } = this.props.match.params
    let input = {
      id: `${Constants.SHOPIFY_TYPE_URL.adminOrderId}${id}`,
      note: `https://www.thepaulsfashion.com/admin/order/${id}/details`,
    }
    const callback = (res) => {
      if (res) {
        this.props.resetCanvas()
      }
    }
    this.props.updateAdminOrderUrl(input, ORDER_URL_UPDATE_QUERY, callback)
  }
  orderDate(date) {
    return moment(date).format('L')
  }
  navOrderSucess(obj) {
    this.props.history.push(`/order/status/${obj?.id}`)
  }
  
  render() {
    let { number } = this.props.match.params
    let { customerList } = this.props.customerList || {}
    let { customer = {} } = customerList?.data || {}
    let receivedOrder = customer?.orders?.edges?.find((o) => o?.node?.orderNumber === parseInt(number))
    let { processedAt } = receivedOrder?.node || {}
    return (
      // <div className="col-lg-12 shopping_crt">
      //   {this._renderOrderInformation()}
      <div className='order_status px-lg-0 px-3 py-lg-4 py-3'>
        <div className='order_status_content'>
          <div className='order_status_line pt-2'>
            <p className='f-sb mb-2'>Hi <span>{customer?.displayName}</span>,</p>
            <p className='fs-16 f-sb mb-2'>Congratulations! Your order has been confirmed and is now being processed.</p>
          </div>
          <div className='order_status_line'>
            <div className='row'>
              <div className='row col-lg-5'>
                <div className='col-6'>
                  <h6 className='f-m fs-14'>Order</h6>
                  <p className='f-l'>#{number}</p>
                </div>
                <div className='col-6'>
                  <h6 className='f-m fs-14'>Order Date</h6>
                  <p className='f-l'> {this.orderDate(processedAt)}</p>
                </div>
              </div>
            </div>
            <div className='order_status_line px-lg-0 px-2 py-4'>
              <div className='d-flex flex-column row-gap-2'>
                <p className='f-l'>Thank you for your purchasing! Your order is currently being processed. </p>
                <p className='f-l'>Once reviewed, our team of experienced tailors will begin crafting your order from scratch - following along to each and every one of the specifications you outlined in the previous steps.

                  Look out for a confirmation email with your tracking number in the next few weeks! Please reach out if you have any questions or concerns.

                  In the meantime, feel free to continue browsing. Make sure to have a look a the New Arrivals page - there are some real hidden gems in there :)

                  In fact, as way to thank you for your ongoing support, please use the discount code below at checkout to recieve a 10% discount on your next purchase!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProp = (state) => {
  return {
    customerList: Actions.getCustomer(state),
    token: Actions.getTokenSelector(store.getState()) || {},
  }
}

export default withRouter(connect(mapStateToProp, {
  getCustomerList: Actions.getCustomerList,
  resetCheckOut: Actions.resetCheckOut,
  getAdminOrderId: Actions.getAdminOrderId,
  updateAdminOrderUrl: Actions.updateAdminOrderUrl,
  resetCanvas: Actions.resetCanvas,
  getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
})(OrderSuccess));