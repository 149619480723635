import React from 'react'
import { withRouter } from 'react-router-dom'
import { PATH } from '../../globals/app-constraints'

class SuitMonogramInitials extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedAccents, closeMonogramModal } = this.props
        return (
            <React.Fragment>
                {
                    selectedAccents && (
                        <div className={`offcanvas-backdrop fade show`}></div>
                    )
                }
                <div className="offcanvas offcanvas-end canvas_offcanvas_fabric show"
                    tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
                    data-bs-backdrop=" static">
                    <div className="offcanvas-header canvas_offcanvas_header">
                        <button
                            type="button"
                            className="canvas_close_btn bg-transparent border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => closeMonogramModal()}>
                            <img src={PATH?.img?.ExitPopupIcon} className='PDP_exit_popup_img cursor-pointer' alt="exit-popup" data-bs-dismiss="offcanvas" />
                        </button>
                    </div>
                    <div className="offcanvas-body p-0">
                        <div className='canvas_fabric_detail'>
                            <svg className={'suit_front_svg'} viewBox="0 0 400 465" width="326" height={'477'}>
                                <text x={"350"} y={'350'} transform="rotate(1,170,155)"
                                    fontSize={'8px'} fill="#ffffff">{selectedAccents && selectedAccents.initials}</text>
                            </svg>
                            <img src={require('../../asset/images/suit_monogram.jpg')} alt="images" className='canvas_fabric_img' />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(SuitMonogramInitials)