import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class AccentsCuff extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { selectedCuffFabric, openCuffCategories } = this.props
        return (
            <React.Fragment>
                <div className='mt-2'>
                    <p className="f-m H-l fs-13 text-lowercase pb-2">Customized Cuffs</p>
                    {/* <ul className="row mx-auto p-0 w-90 my-4 custom-collar cuff_desg">
                    <li className={selectedCuffFabric === 'default' ? 'active_type' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/by_default_cuff.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openCuffCategories('default')} />
                        <p>BY DEFAULT</p>
                    </li>
                    <li className={selectedCuffFabric === 'all' ? 'active_type' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/all_cuff.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openCuffCategories('all')} />
                        <p>ALL</p>
                    </li>
                    <li className={selectedCuffFabric === 'inner' ? 'active_type' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/inner_fabric_cuff.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openCuffCategories('inner')} />
                        <p>INNER</p>
                    </li>
                    <li className={selectedCuffFabric === 'outer' ? 'active_type' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/outer_fabric_cuff.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openCuffCategories('outer')} />
                        <p>OUTER</p>
                    </li>
                </ul> */}
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCuffFabric === 'default' ? 'active_type' : ''}`}
                                onClick={() => openCuffCategories('default')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/by_default_cuff.svg')} />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Default</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCuffFabric === 'all' ? 'active_type' : ''}`}
                                onClick={() => openCuffCategories('all')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/all_cuff.svg')} />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">All</p>
                            </div>
                        </div>

                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCuffFabric === 'inner' ? 'active_type' : ''}`}
                                onClick={() => openCuffCategories('inner')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/inner_fabric_cuff.svg')} />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Inner</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCuffFabric === 'outer' ? 'active_type' : ''}`}
                                onClick={() => openCuffCategories('outer')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/outer_fabric_cuff.svg')} />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Outer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(AccentsCuff)