import { combineReducers } from 'redux';
import login from './login'
import fabric from './fabric'
import signup from './signup'
import forgetPassword from './forgetPassword';
import checkout from './checkout';
import orderList from './order_list'
import measurementAdd from './measurement';
import selectedMeasurement from './selected_measurement'
import address from './address'
import selectedCheckout from './selected_checkout'
import getAdminProducts from './admin_orders'
import manageMeasurement from './manageMeasurement'
import customerDetails from './customerDetails'
import plpList from './plpList'
import getAllTags from './getTags'
import selectedFabrics from './Selected_Fabrics'
import selectedStyle from './Selected_Styles'
import selectedAccents from './Selected_Accents'
import hubspot from './hubspot'
export const reducers = combineReducers({
  LOGIN: login,
  FABRIC: fabric,
  SIGNUP: signup,
  CUSTOMER_ORDERS: customerDetails,
  FORGET_PASSWORD: forgetPassword,
  CHECKOUT: checkout,
  ORDER: orderList,
  MEASUREMENT: measurementAdd,
  SELECTED_MEASUREMENT: selectedMeasurement,
  CUSTOMER_ADDRESS: address,
  SELECTED_CHECK_OUT: selectedCheckout,
  ADMIN_ORDER_ID: getAdminProducts,
  MANAGE_MEASUREMENT: manageMeasurement,
  PLP: plpList,
  GET_TAGS: getAllTags,
  SELECTED_FABRICS: selectedFabrics,
  SELECTED_STYLE: selectedStyle,
  SELECTED_ACCENTS: selectedAccents,
  HUBSPOT: hubspot
});

