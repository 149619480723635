import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.HUB_SPOT_API: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HUB_SPOT_API_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.HUB_SPOT_API_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }

};