import { all, fork } from 'redux-saga/effects';
import login from './login';
import fabric from './fabric'
import signup from './signup';
import customerDetails from './customerDetails'
import forgetPassword from './forgetPassword';
import checkout from './checkout'
import orderlist from './order_list';
import measurement from './measurement';
import address from './address'
import admin_orders from './admin_orders'
import plpList from './plp'
import getTags from './getTags'
import hubspot from './hubspot'
export default function* root() {
  yield all([
    fork(login),
    fork(fabric),
    fork(signup),
    fork(customerDetails),
    fork(forgetPassword),
    fork(checkout),
    fork(orderlist),
    fork(measurement),
    fork(address),
    fork(admin_orders),
    fork(plpList),
    fork(getTags),
    fork(hubspot)
  ]);
}
