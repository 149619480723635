export const CUSTOMER_LIST = (customerAccessToken) =>  {
return `{
    customer(customerAccessToken: "${customerAccessToken}" ) {
      acceptsMarketing
      id
      addresses(first: 200) {
        edges {
          node {
            address1
            address2
            city
            id
            company
            country
            countryCode
            countryCodeV2
            firstName
            id
            lastName
            phone
            name
            province
            zip
          }
        }
      }
      defaultAddress {
        address1
        address2
        city
        company
        country
        firstName
        countryCodeV2
        countryCode
        id
        lastName
        name
        phone
        province
        zip
      }
      displayName
      email
      firstName
      id
      lastName
      phone
      lastIncompleteCheckout {
        completedAt
        createdAt
        id
        totalPrice {
          amount
          currencyCode
        }
        customAttributes {
          key
          value
        }
        appliedGiftCards {
          amountUsedV2 {
            amount
            currencyCode
          }
          balance {
            currencyCode
            amount
          }
          id
          lastCharacters
          presentmentAmountUsed {
            amount
            currencyCode
          }
        }
        email
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        lineItems(first: 200) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              title
              quantity
              variant {
                availableForSale
                id
                title
                sku
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        paymentDue {
          amount
          currencyCode
        }
        shippingAddress {
          address1
          address2
          company
          city
          country
          countryCode
          countryCodeV2
          firstName
          id
          lastName
        }
      }
      orders(first: 10,reverse: true) {
        edges {
          node {
            financialStatus
            shippingAddress {
              address1
              address2
              city
              country
              countryCode
              firstName
              lastName
              id
              name
              phone
              province
              zip
            }
            customerUrl
            edited
            email
            id
            orderNumber
            totalPrice {
              amount
              currencyCode
            }
            fulfillmentStatus
            processedAt
            lineItems(first: 200) {
              edges {
                node {
                  customAttributes {
                    key
                    value
                  }
                  title
                  quantity
                  originalTotalPrice {
                    amount
                  }
                  variant {
                    availableForSale
                    id
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      originalSrc
                      id
                      altText
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
}