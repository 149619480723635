import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class AccentsButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { fields, customizeButton } = this.props
        return (
            <React.Fragment>
                <div className="mt-2">
                    <p className="H-l fs-13 text-lowercase pb-2">Buttons</p>
                    <div className='d-flex flex-wrap gap-3'>
                        <div className={`cursor-pointer text-left ${fields['selectedButton'] === 'white' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => customizeButton('white')} src={images('./images/white_button.png')} className="img-fluid" alt="icons" style={{ width: "60px", height: "60px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${fields['selectedButton'] === 'black' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => customizeButton('black')} src={images('./images/black_button.png')} className="img-fluid" alt="icons" style={{ width: "60px", height: "60px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${fields['selectedButton'] === 'red' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => customizeButton('red')} src={images('./images/red_button.png')} className="img-fluid" alt="icons" style={{ width: "60px", height: "60px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${fields['selectedButton'] === 'blue' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => customizeButton('blue')} src={images('./images/blue_button.png')} className="img-fluid" alt="icons" style={{ width: "60px", height: "60px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(AccentsButtons)