import React from 'react'
import style_advice from '../../asset/new_images/icons/footer_img1.svg'
import shipping from '../../asset/new_images/icons/footer_img2.svg'
import perfectfit from '../../asset/new_images/icons/footer_img3.svg'
import { Link } from 'react-router-dom'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import moment from 'moment'
import { isValidEmail } from '../../util/validation'
import { toast } from 'react-toastify'
import { Constants } from '../../util/Constant'
import Actions from '../../redux/action'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import ButtonDesign from '../button'

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subscriberEmail: undefined,
            successMessage: null,
            error: '',
            isLoading: false,
        }
    }
    subscribe() {
        if (!this.state.subscriberEmail) {
            return this.setState({ error: 'This field cant be empty' })
        }
        if (!isValidEmail(this.state.subscriberEmail)) {
            return this.setState({ error: 'Invalid email' })
        }
        this.setState({ error: '' })
        if (this.state.subscriberEmail) {
            const callback = (res) => {
                this.setState({ isLoading: false });
                if (res?.status === 200) {
                    this.setState({ successMessage: true })
                    toast.success('Thanks for subscribing!', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                } else {
                    this.setState({ error: 'Sorry something went wrong, please try again later' })
                    toast.error('Sorry something went wrong, please try again later', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    setTimeout(() => {
                        this.setState({ error: null })
                    }, 9000);
                }
            }
            let params = {
                fields: [
                    {
                        name: 'email',
                        value: this.state.subscriberEmail
                    }
                ]
            }
            this.setState({ isLoading: true });
            let hubspotData = JSON.stringify(params)

            this.props.hubSpot(hubspotData, Constants.HUBSPOT_API.PRIMARY_FORM, callback)
        }
    }
    handleChange(e) {
        let { value } = e?.target
        this.setState({ subscriberEmail: value })
    }
    toggleOffcanvas = () => {
        document.body.style.overflow = 'hidden';
    };
    toggleOffcanvasClose = () => {
        document.body.style.overflow = 'auto';
    };
    render() {
        const currentyear = moment().year();
        const { isLoading } = this.state;
        return (
            <div className="footer_content">
                <div className='container'>
                    {isLoading && <Loader />}
                    <div className='footer_align footer_border'>
                        <div className='row footer_style_advice_section m-0'>
                            <div className='col-lg-4 px-0 footer_style_advice_section_content'>
                                <div className='d-flex footer_align_mobile_padding'>
                                    <div className='w-10'>
                                        <img src={style_advice} className='shipping_img shipping_material' alt='starfill' />
                                    </div>
                                    <div className='w-90 '>
                                        <p className='H-m fs-16 mb-0 text_nowrap footer_style_wrap'>{CONTENTS.footer.section1.Head1}</p>
                                        <p className='H-l fs-14 mt-3 mb-3 letter-spacing'>{CONTENTS.footer.section1.Content}</p>
                                        <button className="btn border-0 p-0"
                                            type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <p className='H-cb fs-16 text-decoration-underline text-color-white text-lowercase' onClick={() => this.toggleOffcanvas()}>{CONTENTS.footer.section1.askStyleExpert}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 px-0 footer_style_advice_section_content'>
                                <div className='d-flex footer_align_mobile_padding'>
                                    <div className='w-10'>
                                        <img src={shipping} className='shipping_img' alt='starfill' />
                                    </div>
                                    <div className='w-90'>
                                        <p className='H-m fs-16 mb-0'>{CONTENTS.footer.section1.Head2}</p>
                                        <p className='H-l fs-14 mt-3 mb-3 letter-spacing'>{CONTENTS.footer.section1.Content}</p>
                                        {/* <p className='H-cb fs-14 text-decoration-underline text-lowercase'>Learn more</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 px-0 footer_style_advice_section_content'>
                                <div className='d-flex footer_align_mobile_padding'>
                                    <div className='w-10'>
                                        <img src={perfectfit} className='shipping_img shipping_material' alt='starfill' />
                                    </div>
                                    <div className='w-90'>
                                        <p className='H-m fs-16 mb-0'>{CONTENTS.footer.section1.Head3}</p>
                                        <p className='H-l fs-14 mt-3 mb-3 letter-spacing'>{CONTENTS.footer.section1.Content}</p>
                                        {/* <p className='H-cb fs-14 text-decoration-underline text-lowercase'>Learn more</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-44'>
                        <div className='footer_align2 footer_border d-flex flex-column gap-44'>
                            <div className='row footer_newsletter align-items-center m-0'>
                                <div className='col-lg-6 p-0'>
                                    <div className='footer_newsletter_content d-flex flex-column gap-8'>
                                        <p className='H-cb fs-44'>{CONTENTS.footer.section2.newsletter}</p>
                                        <p className='H-l fs-14 letter-spacing'>{CONTENTS.footer.section2.content}</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 p-0'>
                                    <div className="input-group">
                                        <input type="text" className="form-control border-0 newsletter rounded-0"
                                            placeholder="Enter your email" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => this.handleChange(e)} />
                                        <span className="input-group-text newsletter_form_text border-0 f-b rounded-0 cursor-pointer" id="basic-addon1" onClick={() => this.subscribe()}>{CONTENTS.footer.section2.submit}</span>
                                    </div>
                                    <span style={{ color: 'red' }}>{this.state.error}</span>
                                </div>
                            </div>
                        </div>
                        <div className='row footer_akal_gap m-0'>
                            <div className='col-xl-6 d-flex flex-column gap-30 p-0'>
                                <img src={PATH?.img?.FooterAkalLogo} className='footer_logo' alt="footer-logo" />
                                <div className='d-flex gap-24 footer_below_akal'>
                                    <div className='d-flex gap-8 justify-content-lg-start justify-content-center align-items-center'>
                                        <img src={PATH?.img?.FooterCallIcon} className='footer_about_akal_icon' alt="call-icon" />
                                        <p>:</p>
                                        <a className='H-l fs-14 text_nowrap text-white text-decoration-none' href="tel:+66 81-809-1081" target="_blank">{CONTENTS.footer.section3.phone}</a>
                                    </div>
                                    <div className='d-flex gap-8 justify-content-lg-start justify-content-center align-items-center'>
                                        <img src={PATH?.img?.FooterMessageIcon} className='footer_about_akal_icon' alt="message-icon" />
                                        <p>:</p>
                                        <a className='H-l fs-14 text_nowrap text-white text-decoration-none' href="mailto:mikegulati@gmail.com" target="_blank">{CONTENTS.footer.section3.email}</a>
                                    </div>
                                    <div className='d-flex gap-8 justify-content-lg-start justify-content-center align-items-center'>
                                        <img src={PATH?.img?.FooterTimeIcon} className='footer_about_akal_icon' alt="time-icon" />
                                        <p>:</p>
                                        <p className='H-l fs-14 text_nowrap'>{CONTENTS.footer.section3.day}</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center d-lg-none gap-24'>
                                    <img src={PATH?.img?.FooterFacebookIcon} className='footer_facebook_icon' alt="facebook-icon" />
                                    <a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.FooterWhatsappIcon} className='footer_whatsapp_icon' alt="whatsapp-icon" /></a>
                                    <img src={PATH?.img?.FooterTwitterIcon} className='footer_twitter_icon' alt="twitter-icon" />
                                    <img src={PATH?.img?.FooterInstagramIcon} className='footer_instagram_icon' alt="instagram-icon" />
                                </div>
                            </div>
                            <div className="col-xl-6 d-flex justify-content-xl-end footer_reverse p-0">
                                <div className="d-none d-lg-flex flex-column gap-30 border-0 footer_accordion">
                                    <h2 className="" id="flush-headingOne">
                                        <button className="border-0 pdp_accordion_btn footer_subhead H-m cursor-auto" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefooter1" aria-expanded="false" aria-controls="flush-collapseOne">
                                            {CONTENTS.footer.section3.shopBy}
                                        </button>
                                    </h2>
                                    <div className="collapse_show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className='d-flex flex-column gap-11'>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/shirt">{CONTENTS.footer.section3.shirt}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/pant">{CONTENTS.footer.section3.pants}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/suit">{CONTENTS.footer.section3.suit}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/blazer">{CONTENTS.footer.section3.blazer}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/vest">{CONTENTS.footer.section3.vest}</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-none d-lg-flex flex-column gap-30 border-0 footer_accordion">
                                    <h2 className="" id="flush-headingOne">
                                        <button className="border-0 pdp_accordion_btn footer_subhead H-m cursor-auto" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefooter1" aria-expanded="false" aria-controls="flush-collapseOne">
                                            {CONTENTS.footer.section3.company}
                                        </button>
                                    </h2>
                                    <div className="collapse_show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className='d-flex flex-column gap-11'>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/story">{CONTENTS.footer.section3.ourStory}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/corporate">{CONTENTS.footer.section3.cooperateServices}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/return/exchange">{CONTENTS.footer.section3.ReturnsExchange}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/faq">{CONTENTS.footer.section3.faqtext}</Link></p>
                                            <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/blog">{CONTENTS.footer.section3.blogtext}</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-lg-none d-flex flex-column align-item-start w-100'>
                                    <div className="accordion-item border-0 footer_accordion">
                                        <div className='line_border_accordion d-flex flex-column gap-16'>
                                            <h2 className="accordion-header accordion-active" id="flush-headingOne">
                                                <button className="accordion-button collapsed py-lg-0 py-3 px-0 pdp_accordion_btn footer_subhead H-m cursor-auto" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefooter1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    {CONTENTS.footer.section3.shopBy}
                                                </button>
                                            </h2>
                                            <div id="flush-collapsefooter1" className="accordion-collapse collapse collapse_show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className='d-flex flex-column gap-11 pb-16'>
                                                    <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/shirt">{CONTENTS.footer.section3.shirt}</Link></p>
                                                    <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/pant">{CONTENTS.footer.section3.pants}</Link></p>
                                                    <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/suit">{CONTENTS.footer.section3.suit}</Link></p>
                                                    <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/blazer">{CONTENTS.footer.section3.blazer}</Link></p>
                                                    <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/vest">{CONTENTS.footer.section3.vest}</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0 footer_accordion">
                                        <div className='line_border_accordion d-flex flex-column gap-16'>
                                            <h2 className="accordion-header accordion-active" id="flush-headingOne">
                                                <button className="accordion-button collapsed py-lg-0 py-3 px-0 pdp_accordion_btn footer_subhead H-m cursor-auto" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefooter2" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    {CONTENTS.footer.section3.company}
                                                </button>
                                            </h2>
                                            <div id="flush-collapsefooter2" className="accordion-collapse collapse collapse_show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className='d-flex flex-column gap-11 pb-16'>
                                                    <div className='d-flex flex-column gap-11'>
                                                        <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/story">{CONTENTS.footer.section3.ourStory}</Link></p>
                                                        <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/corporate">{CONTENTS.footer.section3.cooperateServices}</Link></p>
                                                        <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/return/exchange">{CONTENTS.footer.section3.ReturnsExchange}</Link></p>
                                                        <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/faq">{CONTENTS.footer.section3.faqtext}</Link></p>
                                                        <p className='pb-lg-0 pb-1 H-l fs-14'><Link className="footer-text" to="/blog">{CONTENTS.footer.section3.blogtext}</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=' d-none d-lg-flex gap-24'>
                                    <a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.FooterFacebookIcon} className='footer_facebook_icon' alt="facebook-icon" /></a>
                                    <a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.FooterWhatsappIcon} className='footer_whatsapp_icon' alt="whatsapp-icon" /></a>
                                    <a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.FooterTwitterIcon} className='footer_twitter_icon' alt="twitter-icon" /></a>
                                    <a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.FooterInstagramIcon} className='footer_instagram_icon' alt="instagram-icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center px-0 w-100 pb-5 m-0'>
                        <p className='col-lg-6 text-lg-start text-center H-l fs-14'>{CONTENTS.footer.section3.copyrightsContent1} <span>{currentyear}</span> {CONTENTS.footer.section3.copyrightsContent2}</p>
                        <div className='col-lg-6 d-flex justify-content-lg-between justify-content-center gap-24 pt-lg-0 pt-3'>
                            <p className='H-l fs-14'><Link className="footer-text" to="/privacy-policy">{CONTENTS.footer.section3.privacyPolicy}</Link></p>
                            <p className=' H-l fs-14'><Link className="footer-text" to="/terms">{CONTENTS.footer.section3.termsCondition}</Link></p>
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div className="footer_popup">
                            <img src={PATH?.img?.ExitPopupIcon} className='footer_exit_popup_img cursor-pointer' alt="exit-popup" data-bs-dismiss="offcanvas" onClick={() => this.toggleOffcanvasClose()} />
                            <div className='d-flex flex-column gap-44'>
                                <div className='d-flex flex-column gap-10'>
                                    <p className='H-cb fs-24 text-center'>{CONTENTS.footer.popup.Head}</p>
                                    <p className='H-l fs-14 text-center'>{CONTENTS.footer.popup.Content}</p>
                                </div>
                                <div>
                                    <div className='row mx-0 footer_popup_list'>
                                        <div className='col-2'>
                                            <img src={PATH?.img?.StoreIcon} alt="" />
                                        </div>
                                        <div className='col-8'>
                                            <div className='d-flex flex-column justify-content-start gap-10'>
                                                <p className='fs-16 H-m'>{CONTENTS.footer.popup.storeHead}</p>
                                                <p className='fs-14 H-l text_grey'>{CONTENTS.footer.popup.storeContent}</p>
                                            </div>
                                        </div>
                                        <div className='col-2 d-flex align-items-center'>
                                            <img src={PATH?.img?.FooterPopupArrowIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className='footer_popup_list'>
                                        <a href='mailto:mikegulati@gmail.com' target='_blank' className='text-decoration-none text-dark'>
                                            <div className='row mx-0'>
                                                <div className='col-2'>
                                                    <img src={PATH?.img?.EmailIcon} alt="" />
                                                </div>
                                                <div className='col-8'>
                                                    <div className='d-flex flex-column justify-content-start gap-10'>
                                                        <p className='fs-16 H-m'>{CONTENTS.footer.popup.emailHead}</p>
                                                        <p className='fs-14 H-l text_grey'>{CONTENTS.footer.popup.emailContent}</p>
                                                    </div>
                                                </div>

                                                <div className='col-2 d-flex align-items-center'>
                                                    <img src={PATH?.img?.FooterPopupArrowIcon} alt="" />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='footer_popup_list'>
                                        <a href='https://wa.me/66818091081' target='_blank' className='text-decoration-none text-dark'>
                                            <div className='row mx-0'>
                                                <div className='col-2'>
                                                    <img src={PATH?.img?.FooterPopupWhatsappIcon} alt="" />
                                                </div>
                                                <div className='col-8'>
                                                    <div className='d-flex flex-column justify-content-start gap-10'>
                                                        <p className='fs-16 H-m'>{CONTENTS.footer.popup.whatsappHead}</p>
                                                        <p className='fs-14 H-l text_grey'>{CONTENTS.footer.popup.whatsappContent}</p>
                                                    </div>
                                                </div>

                                                <div className='col-2 d-flex align-items-center'>
                                                    <img src={PATH?.img?.FooterPopupArrowIcon} alt="" />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='footer_popup_list'>
                                        <a href='tele:+66 81-809-1081' target='_blank' className='text-decoration-none text-dark'>
                                            <div className='row mx-0'>
                                                <div className='col-2'>
                                                    <img src={PATH?.img?.FooterPopupCallIcon} alt="" />
                                                </div>
                                                <div className='col-8'>
                                                    <div className='d-flex flex-column justify-content-start gap-10'>
                                                        <p className='fs-16 H-m'>{CONTENTS.footer.popup.callHead}</p>
                                                        <p className='fs-14 H-l text_grey'>{CONTENTS.footer.popup.callContent}</p>
                                                    </div>
                                                </div>
                                                <div className='col-2 d-flex align-items-center'>
                                                    <img src={PATH?.img?.FooterPopupArrowIcon} alt="" />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {
    hubSpot: Actions.hubSpot
})(Footer));
