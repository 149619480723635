const NAME = 'SELECTED_MEASUREMENT'

export const UPDATE_SELECTED_MEASUREMENT = `${NAME}/UPDATE_SELECTED_MEASUREMENT`;
export const UPDATE_PANT_MEASUREMENT = `${NAME}/UPDATE_PANT_MEASUREMENT`;
export const UPDATE_SUIT_MEASUREMENT = `${NAME}/UPDATE_SUIT_MEASUREMENT`;
export const UPDATE_BLAZER_MEASUREMENT = `${NAME}/UPDATE_BLAZER_MEASUREMENT`;
export const UPDATE_VEST_MEASUREMENT = `${NAME}/UPDATE_VEST_MEASUREMENT`;

export const RESET_SELECTED_MEASUREMENT = `${NAME}/RESET_SELECTED_MEASUREMENT`;


export const getSelectedMeasurement = store => store[NAME]

export const updateSelectedMeasurement = (data) => {
    return {
        type: UPDATE_SELECTED_MEASUREMENT,
        data
    }
}

export const updatePantMeasurement = (data) => {
    return {
        type: UPDATE_PANT_MEASUREMENT,
        data
    }
}

export const updateSuitMeasurement = (data) => {
    return {
        type: UPDATE_SUIT_MEASUREMENT,
        data
    }
}

export const updateBlazerMeasurement = (data) => {
    return {
        type: UPDATE_BLAZER_MEASUREMENT,
        data
    }
}


export const updateVestMeasurement = (data) => {
    return {
        type: UPDATE_VEST_MEASUREMENT,
        data
    }
}

export const resetSelectedMeasurement = (key) => {
    return {
        type: RESET_SELECTED_MEASUREMENT,
        key
    }
}