const HOME = '/'
const SIGNUP = '/signup'
const WELCOME = '/profile'
const CART = '/cart'
const YOUR_ADDRESS_PAGE = '/address'
const FORGET_RESET_PASSWORD = '/reset/:id/:token'
// User Common Routes //


//PLP Routes

const SHIRT = '/shirt'
const SUIT = '/suit'
const PANT = '/pant'
const BLAZER = '/blazer'
const VEST = '/vest'
const SHIRT_DETAILS = '/shirt/details/:id/:variantId'
const PANT_DETAILS = '/pant/details/:id/:variantId'
const BLAZER_DETAILS = '/blazer/details/:id/:variantId'
const SUIT_DETAILS = '/suit/details/:id/:variantId'
const VEST_DETAILS = '/vest/details/:id/:variantId'



const CANVAS_SHIRT = '/canvas/shirt'
const CANVAS_PANT = '/canvas/pant'
const CANVAS_SUIT = '/canvas/suit'
const CANVAS_BLAZER = '/canvas/blazer'
const CANVAS_VESTS = '/canvas/vests'

const CANVAS_SHIRT_EDIT = '/canvas/shirt/:edit'
const CANVAS_PANT_EDIT = '/canvas/pant/:edit'
const CANVAS_SUIT_EDIT = '/canvas/suit/:edit'
const CANVAS_BLAZER_EDIT = '/canvas/blazer/:edit'
const CANVAS_VEST_EDIT = '/canvas/vests/:edit'

const PAYMENT = '/payment'
const ORDERSUCCESS = '/order/:id/:number/completed'
const ORDERSTATUS = '/order/status'
const SHIPPING_ADDRESS = '/shipping/address'

// Orders Routes
const ADMIN_SEARCH = '/tailor'
const GET_ADMIN_ORDERS = '/tailor/order/:orderId/details'
const ADMIN_ORDERS_DETAILS = '/tailor/order/:type/canvas/details'

// Admin Routes

const STANDARD_SHIRT_SIZE = '/measurement/standard/shirt'
const STANDARD_PANT_SIZE = '/measurement/standard/pant'
const STANDARD_SUIT_SIZE = '/measurement/standard/suit'
const STANDARD_BLAZER_SIZE = '/measurement/standard/blazer'
const STANDARD_VESTS_SIZE = '/measurement/standard/vest'

// Standard Measurement Routes

const CUSTOM_SHIRT_MEASUREMENT = '/measurement/tape/shirt'
const CUSTOM_PANT_MEASUREMENT = '/measurement/tape/pant'
const CUSTOM_SUIT_MEASUREMENT = '/measurement/tape/suit'
const CUSTOM_BLAZER_MEASUREMENT = '/measurement/tape/blazer'
const CUSTOM_VESTS_MEASUREMENT = '/measurement/tape/vest'

// Custom Measurement Routes

const CUSTOMER_SHIRT_MEASUREMENT = '/customer/tape/shirt'
const CUSTOMER_PANT_MEASUREMENT = '/customer/tape/pant'
const CUSTOMER_SUIT_MEASUREMENT = '/customer/tape/suit'
const CUSTOMER_BLAZER_MEASUREMENT = '/customer/tape/blazer'
const CUSTOMER_VESTS_MEASUREMENT = '/customer/tape/vest'

// Customer Measurement Routes


const CUSTOM_SHIRT_MEASUREMENT_SUCCESS = '/measurement/tape/shirt/success'
const CUSTOM_PANT_MEASUREMENT_SUCCESS = '/measurement/tape/pant/success'
const CUSTOM_SUIT_MEASUREMENT_SUCCESS = '/measurement/tape/suit/success'
const CUSTOM_BLAZER_MEASUREMENT_SUCCESS = '/measurement/tape/blazer/success'
const CUSTOM_VESTS_MEASUREMENT_SUCCESS = '/measurement/tape/vest/success'

// Custom Measurement Success Routes


const EDIT_SHIRT_MEASUREMENT = '/measurement/tape/shirt/:edit'
const EDIT_PANT_MEASUREMENT = '/measurement/tape/pant/:edit'
const EDIT_SUIT_MEASUREMENT = '/measurement/tape/suit/:edit'
const EDIT_BLAZER_MEASUREMENT = '/measurement/tape/blazer/:edit'
const EDIT_VESTS_MEASUREMENT = '/measurement/tape/vest/:edit'

// Custom Edit Measurement Routes

const EDIT_AUTH_SHIRT_MEASUREMENT = '/measurement/tape/shirt/success/:key/:namespace/:id/:edit'
const EDIT_AUTH_PANT_MEASUREMENT = '/measurement/tape/pant/success/:key/:namespace/:id/:edit'
const EDIT_AUTH_SUIT_MEASUREMENT = '/measurement/tape/suit/success/:key/:namespace/:id/:edit'
const EDIT_AUTH_BLAZER_MEASUREMENT = '/measurement/tape/blazer/success/:key/:namespace/:id/:edit'
const EDIT_AUTH_VESTS_MEASUREMENT = '/measurement/tape/vest/success/:key/:namespace/:id/:edit'

// Edit Checkout Login Routes

const EDIT_CUSTOMER_SHIRT_MEASUREMENT = '/customer/tape/shirt/success/:key/:namespace/:id/:edit'
const EDIT_CUSTOMER_PANT_MEASUREMENT = '/customer/tape/pant/success/:key/:namespace/:id/:edit'
const EDIT_CUSTOMER_SUIT_MEASUREMENT = '/customer/tape/suit/success/:key/:namespace/:id/:edit'
const EDIT_CUSTOMER_BLAZER_MEASUREMENT = '/customer/tape/blazer/success/:key/:namespace/:id/:edit'
const EDIT_CUSTOMER_VESTS_MEASUREMENT = '/customer/tape/vest/success/:key/:namespace/:id/:edit'

// Custom Measurement Edit Success Routes

const OUR_STORY = '/story'
const RETURN_EXCHANGE = '/return/exchange'
const CORPORATE_SERVICE = '/corporate'
const TERMS_AND_CONDITION = '/terms'
const PRIVACY_POLICY = '/privacy-policy'
const FAQ = '/faq'
const BLOG = '/blog'

const LOGIN = '/login'

export default {
    SIGNUP,
    HOME,
    WELCOME,
    CART,
    YOUR_ADDRESS_PAGE,
    FORGET_RESET_PASSWORD,

    // User Common Routes

    SHIRT,
    SUIT,
    PANT,
    BLAZER,
    VEST,
    //PLP

    ORDERSTATUS,
    PAYMENT,
    ORDERSUCCESS,
    SHIPPING_ADDRESS,

    // Order Routes //

    CANVAS_SHIRT,
    CANVAS_PANT,
    CANVAS_SUIT,
    CANVAS_BLAZER,
    CANVAS_VESTS,

    CANVAS_SHIRT_EDIT,
    CANVAS_BLAZER_EDIT,
    CANVAS_SUIT_EDIT,
    CANVAS_PANT_EDIT,
    CANVAS_VEST_EDIT,
    // Canvas Routes

    GET_ADMIN_ORDERS,
    ADMIN_ORDERS_DETAILS,

    //Admin Routes

    // SHIRT,
    // PANT,
    // BLAZER,
    // SUIT,

    SHIRT_DETAILS,
    PANT_DETAILS,
    SUIT_DETAILS,
    BLAZER_DETAILS,
    VEST_DETAILS,

    // Products Routes

    CUSTOMER_SHIRT_MEASUREMENT,
    CUSTOMER_PANT_MEASUREMENT,
    CUSTOMER_SUIT_MEASUREMENT,
    CUSTOMER_BLAZER_MEASUREMENT,

    // Customer Measurement

    STANDARD_SHIRT_SIZE,
    STANDARD_PANT_SIZE,
    STANDARD_SUIT_SIZE,
    STANDARD_BLAZER_SIZE,

    // Standard Measurement

    CUSTOM_SHIRT_MEASUREMENT,
    CUSTOM_PANT_MEASUREMENT,
    CUSTOM_SUIT_MEASUREMENT,
    CUSTOM_BLAZER_MEASUREMENT,

    // Custom Measurement

    CUSTOM_SHIRT_MEASUREMENT_SUCCESS,
    CUSTOM_PANT_MEASUREMENT_SUCCESS,
    CUSTOM_SUIT_MEASUREMENT_SUCCESS,
    CUSTOM_BLAZER_MEASUREMENT_SUCCESS,

    //Custom Measurement Success Routes

    EDIT_SHIRT_MEASUREMENT,
    EDIT_PANT_MEASUREMENT,
    EDIT_SUIT_MEASUREMENT,
    EDIT_BLAZER_MEASUREMENT,

    //Custom Edit Measurement Routes

    EDIT_CUSTOMER_SHIRT_MEASUREMENT,
    EDIT_CUSTOMER_PANT_MEASUREMENT,
    EDIT_CUSTOMER_SUIT_MEASUREMENT,
    EDIT_CUSTOMER_BLAZER_MEASUREMENT,

    //// Custom Measurement Edit Success Routes
    CORPORATE_SERVICE,
    OUR_STORY,
    RETURN_EXCHANGE,
    FAQ,
    BLOG,

    //Footer Routes

    EDIT_AUTH_SHIRT_MEASUREMENT,
    EDIT_AUTH_BLAZER_MEASUREMENT,
    EDIT_AUTH_SUIT_MEASUREMENT,
    EDIT_AUTH_PANT_MEASUREMENT,
    TERMS_AND_CONDITION,
    PRIVACY_POLICY,
    // Auth Edit Measurement Routes


    STANDARD_VESTS_SIZE,
    CUSTOM_VESTS_MEASUREMENT,
    CUSTOMER_VESTS_MEASUREMENT,
    EDIT_VESTS_MEASUREMENT,
    EDIT_AUTH_VESTS_MEASUREMENT,
    EDIT_CUSTOMER_VESTS_MEASUREMENT,
    CUSTOM_VESTS_MEASUREMENT_SUCCESS,
    LOGIN,
    ADMIN_SEARCH
}
