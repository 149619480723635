import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_PANT, S3BASE_URL_SUIT } from '../../config/api_endpoint'
import Loader from '../Loader'
import { PANT_TRANSPARENT, SUIT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { FabricsDetails } from '../SelectedProducts/fabrics_details'
import SuitZoom from '../Zoom/suit'
import { revealSameProductIDS } from '../../util/Constant'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import CustomHeader from '../Header/customHeader'
import { CUSTOMER_ASSOCIATE, LINE_ITEMS_ADD, LINE_ITEMS_UPDATE } from '../../Queries'
import { toast } from 'react-toastify'
import { SUIT_CHECKOUT } from '../ProductCheckout'
import { store } from '../../createStore'

class SuitSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            selectedPantCombination: undefined,
            isShowFront: false,
            isShowFolded: true,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 16,
            back: 9,
            folded: 22,
            isShowCasual: false,
            isFabricModal: false,
            isZoomModal: false,
            pantLoader: 5,
        }
    }
    onloadImageSuccess(isPant) {
        if (isPant) {
            this.onPantLoadImages()
        } else {
            this.onloadImages()
        }
    }
    openZoomModal() {
        this.setState({ isZoomModal: true })
    }
    closeZoomModal() {
        this.setState({ isZoomModal: false })
    }
    onloadImageError(isPant) {
        if (isPant) {
            this.onPantLoadImages()
        } else {
            this.onloadImages()
        }
    }

    closeModal() {
        this.setState({ isFabricModal: false })
    }
    openModal() {
        this.setState({ isFabricModal: true })
    }

    onPantLoadImages() {
        const { pantLoader } = this.state;
        if (pantLoader === 1) {
            this.setState({ isLoading: false, pantLoader: 5 })
            return;
        } else {
            this.setState({ pantLoader: pantLoader - 1 })
        }
    }

    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 16 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 9 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 22 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedPantStyle: { selectedCombination: selectedPantCombination }, isVest } = props;
        const { selectedSuitStyle: { selectedCombination },
            activeChildMenu, selectedPosition, selectedSuitAccents: { selectedAccents },
            selectedVestStyle: { selectedCombination: selectesVestCombination } } = props;
        let { selectedSuitFabric } = props.selectedSuitFabric || {}
        let { productId } = selectedSuitFabric || {}
        let { button, hole, thread, selectedButtonsThreads,
            suitInnerFabric, selectedSuitLining, pocketSquareFabric, selectedPocketSquare
        } = selectedAccents || {}
        const { isVests } = props.selectedVestStyle

        let suitBaseAccentsPath = S3BASE_URL_SUIT + 'accents'

        let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/'
        let basepath = S3BASE_URL_SUIT + '7079274250404/' + detectBrowserBaseUrl() + '/'
        let suitBasePocketPath = suitBaseAccentsPath + '/6756353671332/pocket/'
        let baseVestPath = S3BASE_URL_SUIT + '7079274250404/vests/' + detectBrowserBaseUrl() + '/'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_SUIT + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
            baseVestPath = S3BASE_URL_SUIT + convertAdminProductId(productId) + 'vests/' + detectBrowserBaseUrl() + '/'
        }
        let isSuitVest = ''
        if (isVest && isVests) {
            isSuitVest = true
        } else {
            isSuitVest = false
        }
        if (suitInnerFabric) {
            let parts = suitInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            if (selectedSuitLining === 'custom') {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
            } else if (selectedSuitLining === 'unlined') {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/unlined/'
            } else {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
            }
        }
        if (pocketSquareFabric) {
            let parts = pocketSquareFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            suitBasePocketPath = suitBaseAccentsPath + '/' + shopifyId + '/pocket/'
        }
        let isShowFolded = true
        let isShowBack = false
        let isShowFront = false
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_SUIT + 'modal/front_modal.png',
            frontShirt: S3BASE_URL_SUIT + 'modal/front_shirt.png',
            frontTie: S3BASE_URL_SUIT + 'modal/tie.png',
            backModal: S3BASE_URL_SUIT + 'modal/back_modal.png',
            backShirt: S3BASE_URL_SUIT + 'modal/back_shirt.png',
            frontPant: basepath + 'pant/front_pant.png',
            backSuitFit: basepath + 'fit/back/normal_fit.png',
            frontSuitFit: basepath + 'style/normal/slim/notch/front/single_one_breasted.png',
            foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
            backPocket: basepath + 'pocket/back/double_welted.png',
            frontPocket: basepath + 'transparent/transparent.png',
            foldedPocket: basepath + 'transparent/transparent.png',
            frontBreastPocket: basepath + 'transparent/transparent.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            frontSleeve: basepath + 'sleeve/front/sleeve.png',
            backSleeve: basepath + 'sleeve/back/sleeves_button_0.png',
            foldedSleeve: basepath + 'sleeve/front/sleeve.png',
            backPant: basepath + 'pant/back_pant.png',
            foldedPant: basepath + 'pant/folded_pant.png',
            backStyle: basepath + 'vents/back/normal/ventless.png',
            frontNormalFit: basepath + 'fit/front/normal_fit_ventless.png',
            foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
            foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
            foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
            foldedCollar: basepath + 'collar/folded/other_collar.png',
            suitInner: baseSuitInnerPath + 'suit_interior.png',
            suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
            suitPocketsSquare: selectedPocketSquare === 'without' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBasePocketPath + 'pocket_square.png',
            foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
            frontSuitButtons: suitBaseAccentsPath + '/buttons/front/j8/single_one_button.png',
            backSuitButtons: suitBaseAccentsPath + '/buttons/back/j8/two_button.png',
            backButtonThread: suitBaseAccentsPath + '/threads/back/two_button/button_center/white.png',
            backButtonHole: suitBaseAccentsPath + '/threads/back/two_button/button_hole/white.png',
            frontButtonThread: suitBaseAccentsPath + '/threads/front/single_1_button_thread/button_center/white.png',
            frontButtonHole: suitBaseAccentsPath + '/threads/front/single_1_button_thread/button_hole/white.png',
            foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
            foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
            foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
            frontLapelsHole: suitBaseAccentsPath + 'threads/front/notch_collar_hole/white.png',
            suitVests: basepath + 'vests/notch/single_breasted_six_button.webp',
            suitVestsButtons: suitBaseAccentsPath + '/suit_vests/buttons/bb2/single_3_buttons.png'
        }
        let basePantpath = ''
        if (productId !== 'undefined') {
            let { pantId } = revealSameProductIDS(convertAdminProductId(productId))
            basePantpath = S3BASE_URL_PANT + pantId + '/'
        } else {
            basePantpath = S3BASE_URL_PANT + '6800239886500/'
        }

        let selectedPantCombinationObj = {
            foldedPantFit: basePantpath + 'fit/folded/normal_fit.png',
            foldedCuff: basePantpath + 'cuff/folded/normal_cuff.png',
            foldedSidePocket: basePantpath + 'pocket/folded/rounded.png',
            foldedPocket: basePantpath + 'pocket/folded/double_welted.png'
        }


        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }

        let backButtonThread = ''
        let backButtonHole = ''
        let frontButtonThread = ''
        let frontButtonHole = ''
        let foldedButtonThread = ''
        let foldedButtonHole = ''
        let foldedLapelsHole = ''
        let frontLapelsHole = ''

        switch (selectedButtonsThreads) {
            case 'all':
                backButtonThread = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_thread_url + `${thread}.png`
                backButtonHole = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_holes_url + `${hole}.png`
                frontButtonThread = suitBaseAccentsPath + selectedCombination['style'].front_button_thread_url + `${thread}.png`
                frontButtonHole = suitBaseAccentsPath + selectedCombination['style'].front_button_hole_url + `${hole}.png`
                foldedButtonThread = suitBaseAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
                foldedButtonHole = suitBaseAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                frontLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].front_button_hole_image_url + `${hole}.png`
                break;
            case 'cuffs':
                backButtonThread = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_thread_url + `${thread}.png`
                backButtonHole = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_holes_url + `${hole}.png`
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            case 'lapel':
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                frontLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].front_button_hole_image_url + `${hole}.png`
                break;
            case 'default':
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            default:
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
        }

        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        backSuitFit: basepath + selectedCombination[key].back_image_url,
                        frontNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/front/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT,
                        foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                    }
                    break;
                case 'sleeve_buttons':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSleeve: basepath + selectedCombination[key].front_image_url,
                        backSleeve: basepath + selectedCombination[key].back_image_url,
                        foldedSleeve: basepath + selectedCombination[key].folded_image_url,
                        backSuitButtons: selectedCombination[key].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + '/buttons/back/' + button + '/' + selectedCombination[key].buttons_image_url,
                        backButtonThread: backButtonThread,
                        backButtonHole: backButtonHole
                    }
                    break;
                case 'back_style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        backStyle: selectedCombination['fit'].key === 'slim_fit' ? basepath + selectedCombination[key].back_slim_fit_image_url : basepath + selectedCombination[key].back_normal_fit_image_url
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/front/' + selectedCombination[key].front_image_url,
                        foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination[key].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination[key].button_image_url,
                        frontButtonThread: frontButtonThread,
                        frontButtonHole: frontButtonHole,
                        foldedButtonThread: foldedButtonThread,
                        foldedButtonHole: foldedButtonHole,
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/front/' + selectedCombination['style'].front_image_url,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination['style'].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                        foldedLapelsHole: foldedLapelsHole,
                        frontLapelsHole: frontLapelsHole,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/front/' + selectedCombination['style'].front_image_url,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                    }
                    break;

                default:
                    break;
            }
        })

        let suitVestsButton = ''

        if (isSuitVest) {
            suitVestsButton = selectesVestCombination['style'].key === 'double_four_breasted' || selectesVestCombination['style'].key === 'double_six_breasted' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + '/suit_vests/buttons/' + button + '/' + selectesVestCombination['style'].button_image_url
        } else {
            suitVestsButton = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
        }

        Object.keys(selectesVestCombination).forEach((key) => {
            switch (key) {
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        suitVests: !isSuitVest ? basepath + SUIT_TRANSPARENT : baseVestPath + selectesVestCombination['lapels'].key + '/' + selectesVestCombination[key].vest_image_url,
                        suitVestsButtons: suitVestsButton
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        suitVests: !isSuitVest ? basepath + SUIT_TRANSPARENT : baseVestPath + selectesVestCombination[key].key + '/' + selectesVestCombination['style'].vest_image_url
                    }
                    break;
                default:
                    break;
            }
        })

        Object.keys(selectedPantCombination).forEach((key) => {
            switch (key) {
                case 'cuff':
                    if (selectedPantCombination['fit'].key === 'normal_fit') {
                        selectedPantCombinationObj = {
                            ...selectedPantCombinationObj,
                            foldedCuff: selectedPantCombination[key].key === 'no_cuffs' ? basePantpath + PANT_TRANSPARENT : basePantpath + selectedPantCombination[key].folded_image_url + 'normal_cuff.png',
                        }
                    } else {
                        selectedPantCombinationObj = {
                            ...selectedPantCombinationObj,
                            foldedCuff: selectedPantCombination[key].key === 'no_cuffs' ? basePantpath + PANT_TRANSPARENT : basePantpath + selectedPantCombination[key].folded_image_url + 'slim_cuff.png',
                        }
                    }

                    break;
                case 'pocket':
                    selectedPantCombinationObj = {
                        ...selectedPantCombinationObj,
                        foldedPocket: selectedPantCombination[key].key === 'no_pocket' ? basePantpath + PANT_TRANSPARENT : basePantpath + selectedPantCombination[key].folded_image_url,
                    }
                    break;
                case 'side_pocket':
                    selectedPantCombinationObj = {
                        ...selectedPantCombinationObj,
                        foldedSidePocket: basePantpath + selectedPantCombination[key].folded_image_url,
                    }
                    break;
                case 'fit':
                    selectedPantCombinationObj = {
                        ...selectedPantCombinationObj,
                        foldedPantFit: basePantpath + selectedPantCombination[key].folded_image_url,
                    }
                    break;
                case 'fastening':
                    selectedPantCombinationObj = {
                        ...selectedPantCombinationObj,
                        foldedFastening: basePantpath + selectedPantCombination[key].front_normal_fit_tucked,
                    }
                    break;
                case 'pleats':
                    selectedPantCombinationObj = {
                        ...selectedPantCombinationObj,
                        foldedPleats: selectedPantCombination[key].key === 'no_pleats' ? basePantpath + PANT_TRANSPARENT : basePantpath + selectedPantCombination[key]?.folded_image_url,
                    }
                    break;
                default:
                    break;
            }
        })

        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
            selectedPantCombination: selectedPantCombinationObj,

        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedPosition !== prevProps.selectedPosition) || (this.props.selectedSuitStyle !== prevProps.selectedSuitStyle) || (this.props.selectedSuitFabric !== prevProps.selectedSuitFabric) || (this.props.selectedSuitAccents !== prevProps.selectedSuitAccents)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    showFront() {
        this.props.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.props.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.props.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }

    renderPant() {
        const { isLoading } = this.state
        const { foldedPantFit, foldedCuff, foldedPleats, foldedPocket,
            foldedSidePocket } = this.state.selectedPantCombination || {}
        return (
            <div className="pi-shirt-containers suit_p">
                <div
                    className={`pi_container pi-pant folded-pant w-75 m-auto pi_pant_cuff ${isLoading && 'v-hide'} `}>
                    <img className="custom_shirt folded_fit"
                        src={foldedPantFit}
                        onLoad={() => this.onloadImageSuccess('isPant')}
                        onError={() => this.onloadImageError('isPant')}
                        alt='shirt' />
                    <img className="custom_shirt folded_cuff"
                        src={foldedCuff}
                        onLoad={() => this.onloadImageSuccess('isPant')}
                        onError={() => this.onloadImageError('isPant')}
                        alt='shirt' />
                    <img className="custom_shirt folded_pleats"
                        src={foldedPleats}
                        onLoad={() => this.onloadImageSuccess('isPant')}
                        onError={() => this.onloadImageError('isPant')}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket}
                        onLoad={() => this.onloadImageSuccess('isPant')}
                        onError={() => this.onloadImageError('isPant')}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        src={foldedSidePocket}
                        onLoad={() => this.onloadImageSuccess('isPant')}
                        onError={() => this.onloadImageError('isPant')}
                        alt='shirt' />
                </div>
            </div>
        )
    }
    resetCanvas() {
        this.props.resetStyle()
    }

    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }

    }
    customerAssociate(checkoutId) {
        let { token: { accessToken } } = this.props.token
        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer } = response?.checkoutCustomerAssociateV2 || {}
            if (!customer) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }
    checkoutSuit() {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        const { isBlazer } = this.props
        const { edit } = this.props.match.params
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedSuitFabric } = this.props.selectedSuitFabric || {}
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
        //     productType: Constants.PRODUCTS.SUIT
        // }
        // let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination,
        //     selectedSuitFabric, selectedAccents, Measurements)
        let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination,
            selectedSuitFabric, selectedAccents)

        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedSuitFabric?.id,
                id: selectedSuitFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedSuitFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }

        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }
    onNextStep() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { history: { location: { search } }, isPant,
            isShirt, isSuit, isVest, isBlazer
        } = this.props || {}
        console.log("d", this.props);
        switch (search) {
            case '?fabric':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?style':
                if (isPant)
                    this.props.history.push(`${this.props.location.pathname}?measure`)
                else
                    this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?summary`)
                break;
            case '?summary':
                if (!accessToken) {
                    this.props.history.push("/login")
                } else {
                    if (isSuit) {
                        this.checkoutSuit()
                    }
                }
        }
    }

    render() {
        const isHomePage = this.props.location.pathname === '/';
        const { isScrolled } = this.state;
        const { selectedCombination, selectedPantCombination, isShowFolded, isShowFront, isShowBack, isLoadingStarted, isLoading, isFabricModal, isZoomModal } = this.state;
        const { backModal, backShirt, backSleeve, backSuitFit, backPant, backStyle, frontNormalFit, frontModal, frontSuitFit,
            frontSleeve, frontShirt, frontTie, frontPant, frontBreastPocket, frontPocket, foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, backSuitButtons, frontSuitButtons, backButtonThread, backButtonHole, foldedButtonHole,
            foldedButtonThread, frontButtonHole, frontButtonThread, foldedLapelsHole, frontLapelsHole, suitVests, suitVestsButtons } = selectedCombination || {}
        const { isVests } = this.props.selectedVestStyle
        const { selectedSuitFabric } = this.props.selectedSuitFabric || {}
        let { location } = this.props
        let { search } = location
        return (
            <React.Fragment>
                <div className="order-1 order-sm-1 order-lg-2 verify-casual h-100">
                    {isLoading && <Loader
                        isCanvas={true} />}
                    <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                        </ol>
                        {selectedCombination && (
                            <div className='carousel-inner' data-interval="false">
                                  <p className='canvas-finish H-cb fs-16' onClick={() => this.onNextStep()}>{search === '?summary' ? "Finish" : "Next"}</p>
                                <CustomHeader />
                                <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFolded) &&
                                            <div className={`pi_container pi-suit w-75 m-auto pi_suit_cuff ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedNormalFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedCollar.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_inner_suit"
                                                    src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_collar"
                                                    src={suitInner}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={suitBottomInner}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitVests.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_vests_button"
                                                    src={suitVestsButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedSuitFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_lapels"
                                                    src={foldedLapelsHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitPocketsSquare}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedSleeve.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_hole"
                                                    src={foldedButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_button"
                                                    src={foldedSuitButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_thread"
                                                    src={foldedButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                {this.renderPant()}

                                            </div>

                                        }
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFront) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_suit_front ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt shirt_man"
                                                    src={frontModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_sleeve"
                                                    src={frontShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_tie"
                                                    src={frontTie}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_pant"
                                                    src={frontPant.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fit"
                                                    src={frontNormalFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitVests.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitVestsButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fit"
                                                    src={frontSuitFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt front_lapels"
                                                    src={frontLapelsHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_sleeve"
                                                    src={frontSleeve.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_button"
                                                    src={frontPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitPocketsSquare}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_cuff_fabric"
                                                    src={frontBreastPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_hole"
                                                    src={frontButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_button"
                                                    src={frontSuitButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_thread"
                                                    src={frontButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                            </div>}
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowBack) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_suit_back ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt suit_back"
                                                    src={backModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_sleeve"
                                                    src={backShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_pocket"
                                                    src={backPant.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_pant"
                                                    src={backSleeve.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_fit"
                                                    src={backSuitFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_collar_fabric"
                                                    src={backStyle.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_hole"
                                                    src={backButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt shirt_back_collar_fabric"
                                                    src={backSuitButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_thread"
                                                    src={backButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='canvas_details_icons d-flex flex-column align-items-center gap-12'>
                                    <div onClick={() => this.openZoomModal()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ZoomIn} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.zoom}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.openModal()}>
                                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#FabricDetail">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button>
                                        {/* <button className="btn" type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button> */}
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.fabricDetails}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.resetCanvas()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ResetIcon} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.reset}</p>
                                        {/* <i className="bi bi-arrow-clockwise canvas_icon_color fs-16"></i> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        <span className="carousel-control-prev" role="button" data-slide="prev"
                            onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </span>
                        <span className="carousel-control-next" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </span>
                    </div>
                    <FabricsDetails
                        openFabricDesign={isFabricModal}
                        closeModal={() => this.closeModal()}
                        selectedFabric={selectedSuitFabric}
                    />
                    <SuitZoom
                        selectedCombination={selectedCombination}
                        selectedPantCombination={selectedPantCombination}
                        openModal={isZoomModal}
                        closeModal={() => this.closeZoomModal()}
                        isShowBack={isShowBack}
                        isShowFolded={isShowFolded}
                        isShowFront={isShowFront}
                    />
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        token: Actions.getTokenSelector(store.getState()) || {},
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),

    }
}

export default withRouter(connect(mapStateToProp, {
    resetStyle: Actions.resetStyle,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,
})(SuitSection));
