import React from 'react'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { connect } from 'react-redux'
import { LAST_INCOMPLETE_CHECK_OUT } from '../../Queries'
import { StaticHeader } from '../../config/constant'
import { PATH, CONTENTS, SERVERIMG } from '../../globals/app-constraints'
import ButtonDesign from '../button'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerText: StaticHeader[0],
            isScrolled: false,
            showMenu: false
        };
        this.intervalId = null;
    }

    componentDidMount() {
        let { token: { accessToken } } = this.props.token || {}
        this.ChangeHeaderText();
        if (accessToken) {
            this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        if (this.intervalId) {
            clearInterval(this.intervalId); // Clear the interval
        }
    }

    handleScroll = () => {
        const isScrolled = window.scrollY > 50;
        if (isScrolled !== this.state.isScrolled) {
            this.setState({ isScrolled });
        }
    }

    ChangeHeaderText() {
        let i = 1;
        this.intervalId = setInterval(() => {
            this.setState({
                headerText: StaticHeader[i]
            });
            if ((i + 1) === StaticHeader.length) {
                i = 0;
            } else {
                i++;
            }
        }, 3000);
    }

    _renderCartNotification() {
        const { isScrolled, showMenu } = this.state;
        const isHomePage = this.props.location.pathname === '/';
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (lastIncompleteCheckout) {
            let { lineItems } = lastIncompleteCheckout || {}
            if (lineItems?.edges?.length > 0) {
                return <div className={`cart_header_icon ${(isHomePage && !isScrolled) || (!isScrolled && !isHomePage) || (isScrolled && !isHomePage) ? 'cart-count-default' : ''}  ${isScrolled && isHomePage ? 'cart-count-scroll' : ''} m-0 fs-13 H-cb`}><span>{lineItems?.edges?.length}</span></div>
            }
        } else {
            let { data } = this.props.checkoutDetails || {}
            let { lineItems } = data?.node || {}
            if (lineItems?.edges?.length > 0) {
                return <div className={`cart_header_icon ${(isHomePage && !isScrolled) || (!isScrolled && !isHomePage) || (isScrolled && !isHomePage) ? 'cart-count-default' : ''}  ${isScrolled && isHomePage ? 'cart-count-scroll' : ''} m-0 fs-13 H-cb`}><span>{lineItems?.edges?.length}</span></div>
            }
        }
    }

    navWelcomePage() {
        let { token: { accessToken } } = this.props.token || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else {
            this.props.history.push('/profile')
        }
    }
    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }

    }
    toggleModal = () => {
        document.body.style.overflow = 'hidden';
    };
    toggleModalClose = () => {
        document.body.style.overflow = 'auto';
    };
    render() {
        let { token: { accessToken } } = this.props.token || {}
        const isHomePage = this.props.location.pathname === '/';
        const isCorporate = this.props.location.pathname === '/corporate';
        const isStory = this.props.location.pathname === '/story';
        const isExchange = this.props.location.pathname === '/return/exchange';
        const { isScrolled, showMenu } = this.state;
        return (
            <React.Fragment>
                {this.props.headerOne && <div className='nav_top'>
                    <p className="m-0 text-center H-l fs-12">{this.state.headerText}</p>
                </div>}
                <div className={`w-100 header_nav ${(isHomePage || isCorporate || isStory || isExchange) ? 'z_index position_absolute border-0' : ''}  ${isScrolled ? 'scroll_header position_fixed' : ''} ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'header' : ''} ${(!isHomePage || !isCorporate) && isScrolled ? 'header mobile-header' : ''}`}>
                    <nav className={`container nav justify-content-between align-items-center mobile-menu`}>
                        <div className='d-flex align-items-center gap-30 h-100'>
                            <div onClick={() => this.toggleModal()}>
                                <button className="btn border-0 p-0"
                                    type="button"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    aria-controls="exampleModal"
                                    onClick={() => { this.setState({ showMenu: true }) }}>
                                    <img src={`${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? PATH?.img?.MenuWhite : PATH?.img?.Menu}`} className='header_icons' alt='Menu' />
                                </button>
                            </div>
                            <ul className='d-flex gap-22 list-unstyled m-0'>
                                <li className={`cursor-pointer H-m fs-14 mobile_hidden ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'text_color' : "header-text "}`}
                                    onClick={() => this.props.history.push('/blazer')}>{CONTENTS.header.newArrivals}
                                </li>
                                <li className={`cursor-pointer H-m fs-14 mobile_hidden ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'text_color' : "header-text "}`}
                                    onClick={() => this.props.history.push('/canvas/shirt?fabric')}>{CONTENTS.header.fabrics}
                                </li>
                                <li className={`cursor-pointer H-m fs-14 mobile_hidden ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'text_color' : "header-text "}`}
                                    onClick={() => this.props.history.push('/story')}>{CONTENTS.header.ourStory}
                                </li>
                                {/* <li className={`cursor-pointer H-m fs-14 mobile_hidden ${isHomePage && !isScrolled ? 'text_color' : "header-text "}`}
                                    onClick={() => this.props.history.push('/blazer')}>Collections
                                </li> */}
                            </ul>
                        </div>
                        <img className="cursor-pointer logo_position"
                            src={`${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? PATH?.img?.FooterAkalLogo : PATH?.img?.Logo_black}`} alt='logo' onClick={() => this.props.history.push('/')} />

                        <ul className='d-flex gap-28 list-unstyled m-0'>
                            <div>
                                <li className={`${isScrolled && isHomePage ? 'd-lg-flex d-none' : 'd-none'} ${isHomePage && !isScrolled ? 'd-none' : ''} ${!isHomePage && !isScrolled ? 'd-none' : ''}`}>
                                    <ButtonDesign
                                        btnWidth={'p-0 d-lg-flex button-primary d-none'}
                                        className={'btn-getmore fs-16 H-cb py-12'}
                                        ButtonValue={CONTENTS.header.orderNow}
                                        NoImg
                                        onClick={() => this.props.history.push('/blazer')}
                                        arrowImg={PATH.img.arrowImg}
                                    />
                                </li>
                                <li className={`${isScrolled && isHomePage ? 'd-lg-none d-flex' : ''} ${isHomePage && !isScrolled ? 'd-none' : ''} ${!isHomePage && !isScrolled ? 'd-lg-none d-flex' : ''} ${!isHomePage && isScrolled ? 'd-lg-none d-flex' : ''}`}>
                                    <button className='btn border-0 bg-transparent p-0' onClick={() => this.props.history.push('/blazer')}><img src={PATH.img.OrderNow} className='d-lg-none d-flex' alt='order-now-icon' /></button>
                                </li>
                            </div>
                            {/* <li className='d-flex align-items-center cursor-pointer H-m fs-14'
                            ><img src={`${isHomePage && !isScrolled ? PATH?.img?.SearchWhite : PATH?.img?.Search}`} alt='search' className='mobile_hidden' /><span className={`ps-2 mobile_hidden ${isHomePage && !isScrolled ? 'text_color' : ""}`}>Search</span>
                            </li> */}
                            <li className='d-lg-flex d-none align-items-center cursor-pointer H-m fs-14'
                            ><img src={`${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? PATH?.img?.AccountWhite : PATH?.img?.Account}`} alt='search' className='mobile_hidden' /><span className={`ps-2 mobile_hidden ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'text_color' : ""}`} onClick={() => this.navWelcomePage()}>{CONTENTS.header.Account}</span>
                            </li>
                            <div className='cart_position'>
                                <li className='d-flex align-items-center cursor-pointer H-m fs-14 h-100' onClick={() => this.navCartPage()}><img src={`${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? PATH?.img?.CartWhite : PATH?.img?.Cart}`} alt='search' className='header_cart_img' /><span className={`ps-2 mobile_hidden ${(isHomePage || isCorporate || isStory || isExchange) && !isScrolled ? 'text_color' : ""}`} >{CONTENTS.header.Cart}</span></li>
                                {this._renderCartNotification()}
                            </div>
                        </ul>

                        {/* <div className='d-flex align-items-center h-100'>
                        <div className='cursor-pointer me-1'>
                            <img
                                onClick={() => this.navWelcomePage()}
                                src={Account}
                                className='header_icons account_icon'
                                alt='account-icon' />
                        </div>
                        <div className='cart_position'>
                            <img
                                onClick={() => this.navCartPage()}
                                src={Cart}
                                className='header_icons cursor-pointer'
                                alt='cart-icon' />
                            {this._renderCartNotification()}
                        </div>
                        {
                            !accessToken && <div className='d-lg-flex align-items-lg-center h-100 login_text'>
                                <p className='px-1 cursor-pointer'
                                    onClick={() => this.props.history.push('/login')}>Login</p>
                                <p className='px-1'>|</p>
                                <p className='px-1 cursor-pointer'
                                    onClick={() => this.props.history.push('/signup')}>Sign Up</p>
                            </div>
                        }
                    </div> */}
                        {/* <div className="offcanvas offcanvas-start offcanvas_width header_scroll_hide"
                        tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div>
                            <div className="offcanvas-header justify-content-end pb-0">
                                <a className='measurement_btn_close d-inline-block' href='#' data-bs-dismiss="offcanvas">
                                    <i className="bi bi-x fs-22 text_black"></i>
                                </a>
                            </div>
                            <div className="offcanvas-body p-0">
                                <div className='side_menu'>
                                    <ul className='p-0'>
                                        <li onClick={() => this.props.history.push('/shirt')}>Shirts</li>
                                        <li onClick={() => this.props.history.push('/pant')}>Pants</li>
                                        <li><a className="btn border-0 collapse_content_header"
                                            data-bs-toggle="collapse" href="#collapseExample" role="button"
                                            aria-expanded="false" aria-controls="collapseExample">Suits</a>
                                            <div className="collapse" id="collapseExample">
                                                <div className="card card-body collapse_ul border-0 p-0">
                                                    <ul className='px-0 pb-0 pt-lg-1 pt-2'>
                                                        <li className='pt-lg-4 pt-3 pb-0'
                                                            onClick={() => this.props.history.push('/suit')}>2 piece suits</li>
                                                        <li className='pt-4'
                                                            onClick={() => this.props.history.push('/vest')}>3 piece suits</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li onClick={() => this.props.history.push('/blazer')}>Blazers</li>
                                        <li onClick={() => this.props.history.push('/shirt')}>New Arrivals</li>
                                        <li onClick={() => this.props.history.push('/canvas/shirt?fabric')}>Fabrics</li>
                                        <li onClick={() => this.props.history.push('/story')}>Our Story</li>
                                        <li className='mob_view' onClick={() => this.navWelcomePage()}>Account</li>
                                        <li className='mob_view' onClick={() => this.props.history.push('/login')}>Login</li>
                                        <li className='mob_view' onClick={() => this.props.history.push('/signup')}>Sign Up</li>
                                    </ul>
                                </div>
                                <div className='mt-5 mb-lg-3 mb-5'>
                                    <div className='sidebar_info'>
                                        <p className=''><a className='fs-13 text_grey' href="tel:+66 81-809-1081">We’re available by phone +66 81-809-1081</a></p>
                                        <p className='pt-2'><a className='fs-13 text_grey' href="tel:+66 81-809-1081">Whatsapp +66 81-809-1081</a></p>
                                        <p className='pt-2'><a className='fs-13 text_grey' href="mailto: mikegulati@gmail.com">Email: mikegulati@gmail.com</a></p>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <img src={instagram} className='px-1' alt='instagram' />
                                        <img src={facebook} className='px-1' alt='facebook' />
                                        <img src={twitter} className='px-1' alt='twitter' />
                                    </div>
                                </div>
                                {
                                    !accessToken && <div className='d-lg-none align-items-center h-100 login_text_mob'>
                                        <p className='px-1 cursor-pointer'
                                            onClick={() => this.props.history.push('/login')}>Login</p>
                                        <p className='px-1'>|</p>
                                        <p className='px-1 cursor-pointer'
                                            onClick={() => this.props.history.push('/signup')}>Sign Up</p>
                                    </div>
                                }
 
                            </div>
                        </div>
                    </div> */}
                    </nav >
                </div>
                <div className={`modal fade ${showMenu ? "show d-block" : "d-none"}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog modal-fullscreen modal_fullscreen">
                        <div className="modal-content">
                            <div className='web_popup flex-grow-1'>
                                <div className="d-flex justify-content-between w-100 web_popup_logo">
                                    <div onClick={() => this.toggleModalClose()}>
                                        <div className='cursor-pointer' data-bs-dismiss="modal" onClick={() => this.setState({ showMenu: false })}>
                                            <img src={PATH?.img?.ExitPopupIcon} className='header_exit_popup_img' alt="exit-popup" />
                                        </div>
                                    </div>
                                    <div onClick={() => this.toggleModalClose()}>
                                        <div data-bs-dismiss="modal" onClick={() => this.setState({ showMenu: false })}>
                                            <img src={PATH?.img?.Logo_black} className='header_popup_logo cursor-pointer' alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column justify-content-between header-menu-align flex-grow-1'>
                                    <div className='mobile_popup_hidden'>
                                        <div className='d-flex flex-lg-row flex-column gap-49 header_popup_left w-100'>
                                            <div className='mobile-img-hide'>
                                                <div className='d-flex flex-column gap-24'>
                                                    <img src={SERVERIMG.image.headerPopup} className='header_popup_img' alt="header-popup-img" />
                                                    <div className='d-flex flex-column gap-6'>

                                                        <p className='H-l fs-12 letter-spacing' >{CONTENTS.header.popupHead1}</p>
                                                        <p className='PP_R fs-24'>{CONTENTS.header.popupHead2}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column gap-32 flex-grow-1'>
                                                <div>
                                                    <div onClick={() => this.toggleModalClose()}>
                                                        <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase' onClick={() => {
                                                            this.setState({ showMenu: false })
                                                            this.props.history.push('/blazer')
                                                        }}>{CONTENTS.header.blazers}</p></div>
                                                    <div className='header_popup_menu'>
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button collapsed text-uppercase H-l letter-spacing header_popup_dropdown_menu p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        {CONTENTS.header.suits}
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                                    <div className="card card-body collapse_ul border-0 px-0 pb-0 pt-2">
                                                                        <div onClick={() => this.toggleModalClose()}>
                                                                            <p className='H-l fs-24 header_popup_menu_dropdown text-uppercase'
                                                                                onClick={() => {
                                                                                    this.setState({ showMenu: false })
                                                                                    this.props.history.push('/suit')
                                                                                }}>{CONTENTS.header.twoPieceSuit}</p></div>
                                                                        <div onClick={() => this.toggleModalClose()}>
                                                                            <p className='H-l fs-24  header_popup_menu_dropdown text-uppercase'
                                                                                onClick={() => {
                                                                                    this.setState({ showMenu: false })
                                                                                    this.props.history.push('/vest')
                                                                                }
                                                                                }>{CONTENTS.header.threePieceSuit}</p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => this.toggleModalClose()}>
                                                        <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase' onClick={() => {
                                                            this.setState({ showMenu: false })
                                                            this.props.history.push('/shirt')
                                                        }}>{CONTENTS.header.shirts}</p></div>
                                                    <div onClick={() => this.toggleModalClose()}>
                                                        <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase' onClick={() => {
                                                            this.setState({ showMenu: false })
                                                            this.props.history.push('/pant')
                                                        }}>{CONTENTS.header.pants}</p></div>
                                                    <div onClick={() => this.toggleModalClose()}>
                                                        <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase web_popup_hidden text-uppercase' onClick={() => {
                                                            this.setState({ showMenu: false })
                                                            this.navWelcomePage()
                                                        }}>{CONTENTS.header.Account}</p></div>
                                                    <div onClick={() => this.toggleModalClose()}>
                                                        <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase web_popup_hidden text-uppercase' onClick={() => {
                                                            this.setState({ showMenu: false })
                                                            this.props.history.push('/blazer')
                                                        }}>{CONTENTS.header.newArrivals}</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-lg-row flex-column justify-content-between header_popup_right'>
                                        <div className='d-flex flex-column gap-16'>
                                            <div className='d-flex gap-24'>
                                                <div onClick={() => this.toggleModalClose()}><img src={PATH?.img?.HeaderFacebookIcon} className='header_facebook_icon cursor-pointer' alt="facebook-icon" /></div>
                                                <div onClick={() => this.toggleModalClose()}><a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.HeaderWhatsappIcon} className='header_whatsapp_icon cursor-pointer' alt="whatsapp-icon" /></a></div>
                                                <div onClick={() => this.toggleModalClose()}><img src={PATH?.img?.HeaderTwitterIcon} className='header_twitter_icon cursor-pointer' alt="twitter-icon" /></div>
                                                <div onClick={() => this.toggleModalClose()}><img src={PATH?.img?.HeaderInstagramIcon} className='header_instagram_icon cursor-pointer' alt="instagram-icon" /></div>
                                            </div>
                                            <p className='H-l fs-14'>{CONTENTS.header.phone}<a className='text-dark text-decoration-none' href="tel:+123-456-789" target="_blank">{CONTENTS.header.phoneNumber}</a></p>
                                            <p className='H-l fs-14'><a className='text-dark text-decoration-none' href="mailto:info@example.com" target="_blank">{CONTENTS.header.email}</a></p>
                                        </div>
                                        <div className='d-flex gap-6 copyrights_web_popup align-items-center popup_copyrights '>
                                            <img src={PATH?.img?.CopyrightsIcon} alt="copyrights" />
                                            <p className='H-l fs-12'>{CONTENTS.header.copyrights}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={`modal-backdrop fade show`}></div> */}
            </React.Fragment >
        )
    }
}

const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        checkoutDetails: Actions.getCheckoutDetails(state),
    }
}
export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
})(Header));
