import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getQuery } from '../../service';
export function* getOrderList({ query }) {
  try {
    const response = yield getQuery(query);
    yield put(Actions.getOrderListSuccess(response));
  } catch (error) {
    yield put(Actions.getOrderListFailure(error));
  }
}



export default function* () {
  yield all([
    takeLatest(Actions.GET_ORDER_LIST, getOrderList),
  ]);
}
