import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import CopyMeasurement from './CopyMeasurement'
import Routes from '../../config/routes'
import SubFooter from '../../component/SubFooter'
import { Constants } from '../../util/Constant'
import { DELETE_MEASUREMENT, MEASUREMENT_LIST } from '../../Queries'
import { titleCase } from '../../util/random_number'
import { isFieldEmpty, isValidEmail } from '../../util/validation'

class MeasurementLandingScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            fields: {
                firstName: '',
                lastName: '',
                email: '',
                lastMeasurement: '',
                information: ''
            },
            errors: {},
            measurementMessage: null
        }
    }

    submitHubSpotForms() {
        const { fields } = this.state
        if (this.handleSubmitValidation(["email", "firstName", "lastName", "location"])) {
            const callback = (res) => {
                if (res?.status === 200) {
                    this.setState({ measurementMessage: 'Thanks for reaching out! Please continue to checkout, we will cross-reference your message with your order.' })
                } else {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            }
            let params = {
                fields: [
                    {
                        "name": "email",
                        "value": fields?.email
                    },
                    {
                        "name": "firstname",
                        "value": fields?.firstName
                    },
                    {
                        "name": "lastname",
                        "value": fields?.lastName
                    },
                    {
                        "name": "last_measurment__date_location_",
                        "value": fields?.location
                    },
                    {
                        "name": "message",
                        "value": fields?.information
                    }
                ]
            }

            let hubspotData = JSON.stringify(params)
            this.props.hubSpot(hubspotData, Constants.HUBSPOT_API.MEASUREMEMT_FORM, callback)
        }
    }
    handleChange(e) {
        let { name, value } = e.target
        let { fields } = this.state
        fields[name] = value
        this.setState({ fields }, () => {
            this.handleValidation(name);
        });
    }
    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'firstName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the first name'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'lastName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the last name'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'email':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the email'
                    this.setState({ errors })
                    return false
                } else if (!isValidEmail(fields[field])) {
                    errors[field] = 'Please enter the valid email';
                    this.setState({ errors })
                    return false
                }
                break;
            case 'location':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the last measurement'
                    this.setState({ errors })
                    return false
                }
                break;
            default:
                return null
        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }

    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    componentDidMount() {
        let { customerId } = (this.props.customerId) || {}
        if (customerId) {
            this._getMeasurementDetails(customerId)
        }

    }

    _getMeasurementDetails(customerId) {
        let parts = btoa(customerId);
        let query = MEASUREMENT_LIST(parts)
        this.props.measurementList(query)
    }

    navStandardSize() {
        const { isShirt, isSuit, isPant, isBlazer, isVest } = this.props
        if (isShirt) {
            this.props.history.push(Routes.STANDARD_SHIRT_SIZE)
        } else if (isSuit) {
            this.props.history.push(Routes.STANDARD_SUIT_SIZE)
        } else if (isPant) {
            this.props.history.push(Routes.STANDARD_PANT_SIZE)
        } else if (isBlazer) {
            this.props.history.push(Routes.STANDARD_BLAZER_SIZE)
        } else if (isVest) {
            this.props.history.push(Routes.STANDARD_VESTS_SIZE)
        } else {
            this.props.history.push(Routes.STANDARD_SHIRT_SIZE)
        }
    }

    navTapeScreen() {
        const { isCheckout, match } = this.props
        if (isCheckout) {
            if (match && match.path === Constants.MEASUREMENT_PRODUCT_PATH.SHIRT) {
                this.props.history.push(Routes.CUSTOM_SHIRT_MEASUREMENT)
            } else if (match && match.path === Constants.MEASUREMENT_PRODUCT_PATH.PANT) {
                this.props.history.push(Routes.CUSTOM_PANT_MEASUREMENT)
            } else if (match && match.path === Constants.MEASUREMENT_PRODUCT_PATH.SUIT) {
                this.props.history.push(Routes.CUSTOM_SUIT_MEASUREMENT)
            } else if (match && match.path === Constants.MEASUREMENT_PRODUCT_PATH.BLAZER) {
                this.props.history.push(Routes.CUSTOM_BLAZER_MEASUREMENT)
            } else if (match && match.path === Constants.MEASUREMENT_PRODUCT_PATH.VESTS) {
                this.props.history.push(Routes.CUSTOM_SUIT_MEASUREMENT)
            } else {
                this.props.history.push(Routes.CUSTOM_SHIRT_MEASUREMENT)
            }
            let params = {
                key: '',
                measurementId: '',
                namespace: ''
            }
            this.props.updateMeasurementOptions('create', params)
        } else {
            this.props.history.push(Routes.MEASUREMENT_CUSTOMER_TAPE)
        }
    }

    _renderMeasurementFooter() {
        const { errors, measurementMessage } = this.state
        return (
            <div className="card " style={{ backgroundColor: '#F8F8F8', marginTop: '90px' }}>
                <div className="col-12 text-center my-3 py-5 px-0 px-md-3">
                    {
                        !measurementMessage ? <span className="meas_001">
                            <h2>
                                Are you a legacy Paul’s Fashion customer?
                            </h2>
                            <p>
                                If so, please reach out to us as we may have your measurements on file
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label>
                                        First Name
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="" />
                                        {errors['firstName'] && <span style={{ color: 'red' }}>{errors['firstName']}</span>}
                                    </label>
                                    <label>
                                        Last Name
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="" />
                                        {errors['lastName'] && <span style={{ color: 'red' }}>{errors['lastName']}</span>}
                                    </label>
                                    <label>
                                        Email
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="" />
                                        {errors['email'] && <span style={{ color: 'red' }}>{errors['email']}</span>}
                                    </label>
                                    <label>
                                        Last measurement (Date Location)
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="location"
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="" />
                                        {errors['location'] && <span style={{ color: 'red' }}>{errors['location']}</span>}
                                    </label>
                                </div>
                                <div className="col-lg-6">
                                    <label>
                                        Additional Information
                                        <textarea
                                            className="form-control"
                                            name="information"
                                            onChange={(e) => this.handleChange(e)}
                                            rows="5">
                                        </textarea>
                                    </label>
                                    <button
                                        className="form-control cursor-pointer text-center"
                                        style={{ backgroundColor: '#264350', color: 'white' }}
                                        onClick={() => this.submitHubSpotForms()}
                                    >Submit</button>
                                </div>
                            </div>
                        </span> : <h3>{measurementMessage}</h3>
                    }

                </div>
            </div>
        )
    }

    closeModal() {
        this.setState({ showModal: !this.state.showModal })
    }

    showMeasurementModal() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken) {
            this.setState({ showModal: true })
        } else {
            this.setState({ showModal: false })
        }
    }
    openMeasurementSuccessPage(parseData) {
        const { isShirt, isSuit, isPant, isBlazer, isVest } = this.props
        if (parseData?.value) {
            this.props.updateSelectedMeasurement(parseData?.value)
        } else {
            this.props.updateSelectedMeasurement(parseData)
        }
        if (isShirt) {
            this.props.history.push(Routes.CUSTOM_SHIRT_MEASUREMENT_SUCCESS)
        } else if (isPant) {
            this.props.history.push(Routes.CUSTOM_PANT_MEASUREMENT_SUCCESS)
        } else if (isSuit) {
            this.props.history.push(Routes.CUSTOM_SUIT_MEASUREMENT_SUCCESS)
        } else if (isBlazer) {
            this.props.history.push(Routes.CUSTOM_BLAZER_MEASUREMENT_SUCCESS)
        } else if (isVest) {
            this.props.history.push(Routes.CUSTOM_VESTS_MEASUREMENT_SUCCESS)
        } else {
            this.props.history.push(Routes.CUSTOM_SHIRT_MEASUREMENT_SUCCESS)
        }
    }

    navEditMeasurement(key, namespace, measurementId) {
        if (key.includes(titleCase(Constants.PRODUCTS.SHIRT))) {
            this.props.history.push(
                `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.PANT))) {
            this.props.history.push(
                `/measurement/tape/pant/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.SUIT))) {
            this.props.history.push(
                `/measurement/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.BLAZER))) {
            this.props.history.push(
                `/measurement/tape/blazer/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.VESTS))) {
            this.props.history.push(
                `/measurement/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else {
            this.props.history.push(
                `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        }
    }

    deleteMeasurement(id) {
        let { customerId } = (this.props.customerId) || {}
        const callback = (response) => {
            const { userErrors = {} } = (response && response.privateMetafieldDelete) || {}
            if (userErrors !== null) {
                toast.success(`Measurement Successfully Deleted`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this._getMeasurementDetails(customerId)
                return;
            } else {
                toast.error('Something Went wrong Please Try again later ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        let input = {
            id: id
        }
        this.props.measurementDelete(DELETE_MEASUREMENT, input, callback)
    }
    editMeasurement(key, namespace, id) {
        let measurementId = btoa(id)
        let params = {
            key,
            measurementId,
            namespace
        }
        this.props.updateMeasurementOptions('edit1', params)
        this.navEditMeasurement(key, namespace, measurementId)
    }
    render() {
        const { showModal } = this.state
        const { measurementDetails } = this.props.measurementDetails || {}
        const { isShirt, isSuit, isPant, isBlazer, isVest } = this.props

        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        return (
            <React.Fragment>
                <div className="col-lg-12 shopping_crt pp_0" >
                    <div className="col-12 s_heading">
                        <h2 className="text-center fs-18 H-cb">Choose your measurements</h2>
                        <p className="text-center fs-14 H-l">Experience an array of measurement options from standard sizing to highly customizable solutions</p>
                    </div>

                    <div className="container c-measurements">
                        <div className="row">
                            <ul>
                                <li onClick={() => this.navStandardSize()}>
                                    {/* <h3><img src={require('../../asset/images/suits.png')} className="img-fluid" alt="icons1" /></h3> */}
                                    <div className="c-measure-list">
                                        <h4 className='fs-16 H-l'>Select a standard size</h4>
                                        <p className='fs-14 H-l'>Look through our size chart and pick a size that fits you best.</p>
                                    </div>
                                    <button type="button" className="btn btn-gren fs-14 H-cb">SELECT</button>
                                </li>
                                <li className="active" onClick={() => this.navTapeScreen()}>
                                    <h6 className='fs-16 H-l'>Most Popular</h6>
                                    <h3><img src={require('../../asset/images/tape.png')} className="img-fluid" alt="icons1" /></h3>
                                    <div className="c-measure-list">
                                        <h4 className='fs-16 H-l'>Build a custom profile</h4>
                                        <p className='fs-14 H-l'>Use a tape measure and get help from a friend to measure your body.</p>
                                    </div>
                                    <button type="button" className="btn btn-gren fs-14 H-cb">SELECT</button>
                                </li>
                                <li onClick={() => this.showMeasurementModal()}>
                                    <h3>
                                        <img
                                            // src={require('../../asset/images/timer.svg')}
                                            className="img-fluid"
                                            alt="icons1" />
                                    </h3>
                                    <div
                                        className="c-measure-list">
                                        <h4 className='fs-16 H-l'>Copy from a previous order</h4>
                                        <p className='fs-14 H-l'>Replicate your measurements from a previous order</p>
                                    </div>
                                    <button type="button" disabled={!accessToken} className="btn btn-gren H-cb fs-14">SELECT</button>
                                </li>
                            </ul>
                        </div>
                        {/* {this._renderMeasurementFooter()} */}
                    </div>
                </div>
                <SubFooter />
                <CopyMeasurement
                    openModal={showModal}
                    closeModal={() => this.closeModal()}
                    measurementList={measurementDetails?.customer}
                    deleteMeasurement={(id) => this.deleteMeasurement(id)}
                    editMeasurement={(key, namespace, id) => this.editMeasurement(key, namespace, id)}
                    isShirt={isShirt}
                    isBlazer={isBlazer}
                    isSuit={isSuit}
                    isPant={isPant}
                    isVest={isVest}
                    openMeasurementSuccessPage={(parseData) => this.openMeasurementSuccessPage(parseData)}
                />
            </React.Fragment>
        )
    }
}


const mapStateToProp = (state) => {
    return {
        measurementDetails: Actions.getMeasurement(store.getState()) || {},
        customerId: Actions.getCustomerIdSelector(state),
        token: Actions.getTokenSelector(store.getState()) || {},
    }
}

export default withRouter(connect(mapStateToProp, {
    measurementList: Actions.getMeasurementList,
    measurementDelete: Actions.measurementDelete,
    updateSelectedMeasurement: Actions.updateSelectedMeasurement,
    updateMeasurementOptions: Actions.updateMeasurementOptions,
    hubSpot: Actions.hubSpot
})(MeasurementLandingScreen));
