import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getOrderId, updateOrderUrl, getAdminOrders } from '../../service';
export function* getAdminOrderId({ data, callback }) {
    try {
        const response = yield getOrderId(data);
        callback && callback(response)
        yield put(Actions.getAdminOrderIdSuccess(response));
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getAdminOrderIdFailure(error));
    }
}

export function* updateAdminOrderUrl({ data, query, callback }) {
    try {
        const response = yield updateOrderUrl(data, query);
        callback && callback(response)
        yield put(Actions.updateAdminOrderUrlSuccess(response));
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.updateAdminOrderUrlFailure(error));
    }
}

export function* getAdminOrdersList({ query }) {
    try {
        const response = yield getAdminOrders(query);
        yield put(Actions.getAdminOrderListSuccess(response));
    } catch (error) {
        yield put(Actions.getAdminOrderListFailure(error));
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.GET_ADMIN_ORDER_ID, getAdminOrderId),
        takeLatest(Actions.UPDATE_ORDER_URL_BY_ADMIN, updateAdminOrderUrl),
        takeLatest(Actions.GET_ADMIN_ORDER_LIST, getAdminOrdersList)
    ]);
}
