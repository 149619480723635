export const SHIPPING_ADDRESS = `mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
  checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
    checkout {
      id
      lineItems(first: 10) {
         edges {
           node {
             title
             quantity
             customAttributes {
                         key 
                         value
             }
           }
         }
      }
      shippingAddress {
          firstName
          lastName
          city
          country
          phone
          address1
          province
      }
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
} `