import * as blazer from './blazer'
import * as pant from './pant'
import * as shirt from './shirt'
import * as suit from './suit'
import * as vest from './vest'
export default {
    ...blazer,
    ...pant,
    ...shirt,
    ...suit,
    ...vest
}