import React from 'react'
import { withRouter } from 'react-router-dom'

class ButtonsThreads extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    _renderParentButtonThreads() {
        let { isSuit, isShirt, isVests, isBlazer } = this.props
        if (isSuit || isBlazer || isVests) {
            return this._renderSuitButtonThreads()
        } else if (isShirt) {
            return this._renderShirtButtonThreads()
        }
    }
    _renderSuitButtonThreads() {
        const { selectedAccents, chooseButtonThreadColor } = this.props
        return (
            <div className="mt-2">
                <p className="H-l fs-13 pb-2 text-lowercase">Button Threads</p>
                <div className='d-flex flex-wrap gap-3'>

                    <div
                        className={`cursor-pointer 
              ${selectedAccents && selectedAccents.thread === 'black' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('black', 'thread1')}
                            style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc' }}></div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'white' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('white', 'thread2')}
                            style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}></div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'yellow' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('yellow', 'thread3')}
                            style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'violet' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('violet', 'thread4')}
                            style={{ width: "40px", height: "40px", background: "#7030A0", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'blue' ? 'active_color' : ''}`}>
                        <div className='m-1' onClick={() => chooseButtonThreadColor('blue', 'thread5')}
                            style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'green' ? 'active_color' : ''}`}>
                        <div
                            className='m-1' onClick={() => chooseButtonThreadColor('green', 'thread6')}
                            style={{ width: "40px", height: "40px", background: "#00B050", border: '1px solid #ccc' }}>
                        </div>
                    </div>


                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'red' ? 'active_color' : ''}`}>
                        <div className='m-1' onClick={() => chooseButtonThreadColor('red', 'thread7')}
                            style={{ width: "40px", height: "40px", background: "#FF0000", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'brown' ? 'active_color' : ''}`}>
                        <div
                            className='m-1' onClick={() => chooseButtonThreadColor('brown', 'thread8')}
                            style={{ width: "40px", height: "40px", background: "#C00000", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    _renderShirtButtonThreads() {
        const { selectedAccents, chooseButtonThreadColor } = this.props
        return (
            <div className="mt-2">
                <p className="H-l fs-13 pb-2 text-lowercase">Button Threads</p>
                <div className='d-flex flex-wrap gap-3'>

                    <div
                        className={`cursor-pointer 
          ${selectedAccents && selectedAccents.thread === 'black' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('black', 'thread1')}
                            style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc' }}></div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'white' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('white', 'thread2')}
                            style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}></div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'yellow' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('yellow', 'thread3')}
                            style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'violet' ? 'active_color' : ''}`}>
                        <div className='m-1'
                            onClick={() => chooseButtonThreadColor('violet', 'thread4')}
                            style={{ width: "40px", height: "40px", background: "#7030A0", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'blue' ? 'active_color' : ''}`}>
                        <div className='m-1' onClick={() => chooseButtonThreadColor('blue', 'thread5')}
                            style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'green' ? 'active_color' : ''}`}>
                        <div
                            className='m-1' onClick={() => chooseButtonThreadColor('green', 'thread6')}
                            style={{ width: "40px", height: "40px", background: "#00B050", border: '1px solid #ccc' }}>
                        </div>
                    </div>


                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'red' ? 'active_color' : ''}`}>
                        <div className='m-1' onClick={() => chooseButtonThreadColor('red', 'thread7')}
                            style={{ width: "40px", height: "40px", background: "#FF0000", border: '1px solid #ccc' }}>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'brown' ? 'active_color' : ''}`}>
                        <div
                            className='m-1' onClick={() => chooseButtonThreadColor('brown', 'thread8')}
                            style={{ width: "40px", height: "40px", background: "#C00000", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                </div>
            </div >
            // <ul className="row mx-auto text-left p-0 w-90 my-4 mono pl-0">
            //     <h6 className="col-12 text-left px-0">BUTTON THREADS</h6>
            //     <li className="active">
            //         <div
            //             className={`cursor-pointer 
            //           ${selectedAccents && selectedAccents.thread === 'black' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('black', 'thread1')}
            //             style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc' }}></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'white' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('white', 'thread2')}
            //             style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
            //         ></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'yellow' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('yellow', 'thread3')}
            //             style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'violet' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('violet', 'thread4')}
            //             style={{ width: "40px", height: "40px", background: "#7030A0", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'blue' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('blue', 'thread5')}
            //             style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'green' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('green', 'thread6')}
            //             style={{ width: "40px", height: "40px", background: "#00B050", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'red' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('red', 'thread7')}
            //             style={{ width: "40px", height: "40px", background: "#FF0000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.thread === 'brown' ? 'active_color' : ''}`}
            //             onClick={() => chooseButtonThreadColor('brown', 'thread8')}
            //             style={{ width: "40px", height: "40px", background: "#C00000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            // </ul>
        )
    }
    render() {
        return (
            <React.Fragment>
                {
                    this._renderParentButtonThreads()
                }
            </React.Fragment>
        )
    }
}
export default withRouter(ButtonsThreads)