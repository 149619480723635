import { convertAdminProductId } from './event'
import { PANT_TRANSPARENT, SUIT_TRANSPARENT, TRANSPARENT } from '../config/constant'
import { detectBrowserBaseUrl } from './detect-browser'
export const defaultFabricObject = (obj) => {
    let { collectionByHandle } = obj?.data || {}
    let { edges } = collectionByHandle?.products || {}
    const { node } = (edges && edges[0]) || {}
    let { title, variants, tags, images, id, description } = node || {}
    let { node: products } = (variants && variants.edges && variants.edges[0]) || {}
    let { priceV2 } = products || {}
    let defaultObj = {
        name: title,
        id: products?.id,
        productId: id,
        tags: tags,
        images: images,
        lineItemId: undefined,
        amount: priceV2?.amount ? priceV2?.amount : 69,
        currencyCode: priceV2?.currencyCode ? priceV2?.currencyCode : 'SGD',
        changeFabrics: false,
        description: description,
        selectedColorCode: ''
    }
    return defaultObj
}

export const selectedShirtJSON = (selectedCombination, S3BASE_URL) => {
    const { value } = selectedCombination && selectedCombination[1]
    const { value: selectedAccent } = (selectedCombination && selectedCombination[2]) || {}
    const { value: measurement } = (selectedCombination && selectedCombination[3]) || {}
    let selectedFabrics = JSON.parse(value)
    let selectedAccents = ''
    let selectedButton = 'white'
    if (selectedAccent) {
        selectedAccents = JSON.parse(selectedAccent)
        selectedButton = selectedAccents && selectedAccents.selectedButton
    }
    let { productId } = selectedFabrics || {}
    let basepath = S3BASE_URL + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
    let baseButtonsPath = S3BASE_URL + 'button/'
    let baseCollarPath = ''
    let baseCuffPath = basepath
    let foldedCollarFabric = ''
    let folded_cuff_fabric = ''

    let selectedCombinationDetails = JSON.parse(selectedCombination && selectedCombination[0].value)


    if (selectedAccent) {
        selectedAccents = JSON.parse(selectedAccent)
        let { selectedCollarFabric, collarFabricId,
            selectedCuffFabric, cuffFabricId } = selectedAccents || {}

        if (collarFabricId) {
            baseCollarPath = S3BASE_URL + convertAdminProductId(collarFabricId) + detectBrowserBaseUrl() + '/'
        }
        if (cuffFabricId) {
            baseCuffPath = S3BASE_URL + convertAdminProductId(cuffFabricId) + detectBrowserBaseUrl() + '/'
        }

        switch (selectedCollarFabric) {
            case 'all':
                foldedCollarFabric = baseCollarPath + selectedCombinationDetails['collar'].folded_all_fabric
                break;
            case 'inner':
                foldedCollarFabric = baseCollarPath + selectedCombinationDetails['collar'].folded_inner_fabric
                break;
            case 'outer':
                foldedCollarFabric = baseCollarPath + selectedCombinationDetails['collar'].folded_outer_fabric
                break;
            case 'default':
                foldedCollarFabric = basepath + TRANSPARENT
                break;
            default:
                break;
        }
        switch (selectedCuffFabric) {
            case 'all':
                folded_cuff_fabric = baseCuffPath + selectedCombinationDetails['cuff'].folded_cuff_all_fabric
                break;
            case 'inner':
                folded_cuff_fabric = basepath + TRANSPARENT
                break;
            case 'outer':
                folded_cuff_fabric = baseCuffPath + selectedCombinationDetails['cuff'].folded_cuff_outer_fabric
                break;
            case 'default':
                folded_cuff_fabric = basepath + TRANSPARENT
                break;
            default:
                break;
        }
    }


    let selectedCombinationObj = {
        shirtBackCollar: basepath + 'collar/back_button_collar.webp',
        backCollarOnly: basepath + 'collar/back_kent_collar_only.webp',
        foldedCollarCenterButton: basepath + 'collar/folded_collar_button.png',
        foldedCollarDownButton: basepath + 'collar/no_collar_button_folded_shirt.webp',
        shirtBackPlacket: basepath + 'placket/folded_standard.webp',
        stripeFoldedButton: basepath + 'placket/folded_shirt_stripe_button.png',
        shirtBackPocket: basepath + 'pocket/back_shirt_pocket.webp',
        shirtCuffAll: basepath + 'cuff/1.webp',
    }



    Object.keys(selectedCombinationDetails).forEach((key) => {
        switch (key) {
            case 'collar':
                if (selectedCombinationDetails[key].image_url) {
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        shirtBackCollar: basepath + selectedCombinationDetails[key].folded_image_url,
                        foldedCollarCenterButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombinationDetails[key].folded_color_center_button,
                        foldedCollarDownButton: selectedCombinationDetails[key].key === 'button_down' ? baseButtonsPath + selectedButton + '/folded/' + selectedCombinationDetails[key].folded_collar_down_button : basepath + TRANSPARENT,
                        backCollarOnly: basepath + selectedCombinationDetails[key].back_collar_only,
                        folded_collar_fabric: foldedCollarFabric,
                    }
                }
                break;
            case 'placket':
                if (selectedCombinationDetails[key].image_url) {
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        shirtBackPlacket: basepath + selectedCombinationDetails[key].folded_image_url,
                        stripeFoldedButton: selectedCombinationDetails[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombinationDetails[key].folded_strip_button,
                    }
                }
                break;
            case 'cuff':
                if (selectedCombinationDetails['cuff'].image_url) {
                    if (selectedCombinationDetails['sleeve'].key === 'long') {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            shirtCuffAll: selectedCombinationDetails['sleeve'].key === "short" ? basepath + selectedCombinationDetails['sleeve'].folded_sleeve_image_url : basepath + selectedCombinationDetails[key].image_url,
                            cuffButton: selectedCombinationDetails['sleeve'].key === "short" ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombinationDetails[key].cuff_Button,
                            defaultFoldedShirt: basepath + 'cuff/folded/folded_shirt.webp',
                            foldedCuffFabric: folded_cuff_fabric,
                        }
                    } else {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            defaultFoldedShirt: basepath + 'cuff/folded/folded_shirt.webp',
                            shirtCuffAll: basepath + 'cuff/folded/short_cuff.webp',
                            foldedCuffFabric: baseCuffPath + 'cuff/folded/folded_half_cuff.webp',
                            cuffButton: basepath + TRANSPARENT,
                        }
                    }
                }
                break;
            case 'pocket':
                if (selectedCombinationDetails[key].image_url) {
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        shirtBackPocket: basepath + selectedCombinationDetails[key].folded_image_url,
                        foldedPocketButton: (selectedCombinationDetails[key].key === 'flap_pocket') || (selectedCombinationDetails[key].key === 'two_flap_pocket') ? baseButtonsPath + selectedButton + '/folded/' + selectedCombinationDetails[key].folded_pocket_button : basepath + TRANSPARENT
                    }
                }
                break;
            default:
                break;
        }
    })
    return {
        selectedCombinationObj, selectedCombinationDetails,
        selectedFabrics, selectedAccents, measurement
    }
}

export const defaultPantFabricObject = (obj) => {
    const { collectionByHandle } = obj?.data || {}
    const { edges } = collectionByHandle?.products || {}
    const { node } = (edges && edges[0]) || {}
    const { title, tags, images, id, description, variants } = node || {}
    let { node: products } = (variants && variants.edges && variants.edges[0]) || {}
    let { priceV2 } = products || {}

    const defaultObj = {
        name: title,
        productId: id,
        tags: tags,
        images: images,
        changeFabrics: false,
        description: description,
        id: products?.id,
        amount: priceV2?.amount ? priceV2?.amount : 99,
        currencyCode: priceV2?.currencyCode ? priceV2?.currencyCode : 'SGD',
        selectedColorCode: ''
    }
    return defaultObj
}



export const selectedPantJSON = (selectedCombination, S3BASE_URL) => {
    const { value } = selectedCombination && selectedCombination[1]
    let selectedFabrics = JSON.parse(value)
    let { productId } = selectedFabrics || {}
    let basepath = S3BASE_URL + convertAdminProductId(productId)
    let selectedCombinationDetails = JSON.parse(selectedCombination && selectedCombination[0].value)
    const { value: measurement } = (selectedCombination && selectedCombination[2]) || {}

    let selectedCombinationObj = {
        shirtBackCollar: basepath + 'collar/back_button_collar.webp',
        backCollarOnly: basepath + 'collar/back_kent_collar_only.webp',
        foldedCollarCenterButton: basepath + 'collar/folded_collar_button.png',
        foldedCollarDownButton: basepath + 'collar/no_collar_button_folded_shirt.webp',
        shirtBackPlacket: basepath + 'placket/folded_standard.webp',
        stripeFoldedButton: basepath + 'placket/folded_shirt_stripe_button.png',
        shirtBackPocket: basepath + 'pocket/back_shirt_pocket.webp',
        shirtCuffAll: basepath + 'cuff/1.webp',
    }

    Object.keys(selectedCombinationDetails).forEach((key) => {
        switch (key) {
            case 'cuff':
                if (selectedCombinationDetails['fit'].key === 'normal_fit') {
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedCuff: selectedCombinationDetails[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombinationDetails[key].folded_image_url + 'normal_cuff.png',
                    }
                } else {
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedCuff: selectedCombinationDetails[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombinationDetails[key].folded_image_url + 'slim_cuff.png',
                    }
                }

                break;
            case 'pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPocket: selectedCombinationDetails[key].key === 'no_pocket' ? basepath + PANT_TRANSPARENT : basepath + selectedCombinationDetails[key].folded_image_url,
                }
                break;
            case 'side_pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSidePocket: basepath + selectedCombinationDetails[key].folded_image_url,
                }
                break;
            case 'fit':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPantFit: basepath + selectedCombinationDetails[key].folded_image_url,
                }
                break;
            case 'fastening':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedFastening: basepath + selectedCombinationDetails[key].front_normal_fit_tucked,
                }
                break;
            case 'pleats':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPleats: selectedCombinationDetails[key].key === 'no_pleats' ? basepath + PANT_TRANSPARENT : basepath + selectedCombinationDetails[key].folded_image_url,
                }
                break;
            default:
                break;
        }
    })
    return {
        selectedCombinationObj, selectedCombinationDetails,
        selectedFabrics, measurement
    }
}


export const defaultSuitFabricObject = (obj) => {
    const { collectionByHandle } = obj?.data || {}
    const { edges } = collectionByHandle?.products || {}
    const { node } = (edges && edges[0]) || {}
    const { title, tags, images, id, description, variants } = node || {}
    let { node: products, } = (variants && variants.edges && variants.edges[0]) || {}
    let { priceV2 } = products || {}
    const defaultObj = {
        name: title,
        productId: id,
        tags: tags,
        images: images,
        changeFabrics: false,
        description: description,
        id: products?.id,
        amount: priceV2?.amount ? priceV2?.amount : 379,
        currencyCode: priceV2?.currencyCode ? priceV2?.currencyCode : 'SGD',
        selectedColorCode: ''
    }
    return defaultObj
}


export const defaultVestFabricObject = (obj) => {
    const { collectionByHandle } = obj?.data || {}
    const { edges } = collectionByHandle?.products || {}
    const { node } = (edges && edges[0]) || {}
    const { title, tags, images, id, description, variants } = node || {}
    let { node: products, } = (variants && variants.edges && variants.edges[0]) || {}
    let { priceV2 } = products || {}
    const defaultObj = {
        name: title,
        productId: id,
        tags: tags,
        images: images,
        changeFabrics: false,
        description: description,
        id: products?.id,
        amount: priceV2?.amount ? priceV2?.amount : 379,
        currencyCode: priceV2?.currencyCode ? priceV2?.currencyCode : 'SGD',
    }
    return defaultObj
}


export const selectedSuitJSON = (suitFoldedJson, S3BASE_URL) => {
    let selectedFabrics = JSON.parse(suitFoldedJson && suitFoldedJson[1].value)
    let { productId } = selectedFabrics || {}
    let selectedCombination = JSON.parse(suitFoldedJson && suitFoldedJson[0].value)
    let selectedSuitAccents = JSON.parse(suitFoldedJson && suitFoldedJson[2].value)
    let { value: measurement } = (suitFoldedJson && suitFoldedJson[3]) || {}
    let { button, hole, thread, selectedButtonsThreads,
        suitInnerFabric, selectedSuitLining, pocketSquareFabric, selectedPocketSquare } = selectedSuitAccents || {}

    let suitBaseAccentsPath = S3BASE_URL + 'accents'

    let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/'
    let basepath = S3BASE_URL + '6682322731172/' + detectBrowserBaseUrl() + '/'
    let suitBasePocketPath = suitBaseAccentsPath + '/6756353671332/pocket'


    if (productId !== 'undefined') {
        basepath = S3BASE_URL + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
    }
    if (suitInnerFabric) {
        let parts = suitInnerFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        if (selectedSuitLining === 'custom') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        } else if (selectedSuitLining === 'unlined') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/unlined/'
        } else {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        }
    }
    if (pocketSquareFabric) {
        let parts = pocketSquareFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        suitBasePocketPath = suitBaseAccentsPath + '/' + shopifyId + '/pocket/'
    }
    let selectedCombinationObj = {
        foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
        foldedPocket: basepath + 'transparent/transparent.png',
        foldedBreastPocket: basepath + 'transparent/transparent.png',
        foldedSleeve: basepath + 'sleeve/front/sleeve.png',
        foldedPant: basepath + 'pant/folded_pant.png',
        foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
        foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
        foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
        foldedCollar: basepath + 'collar/folded/other_collar.png',
        suitInner: baseSuitInnerPath + 'suit_interior.png',
        suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
        suitPocketsSquare: selectedPocketSquare === 'without' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBasePocketPath + 'pocket_square.png',
        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
        foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
        foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
        foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
    }

    let foldedButtonThread = ''
    let foldedButtonHole = ''
    let foldedLapelsHole = ''

    switch (selectedButtonsThreads) {
        case 'all':
            foldedButtonThread = suitBaseAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
            foldedButtonHole = suitBaseAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'cuffs':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        case 'lapel':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'default':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        default:
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break
    }

    Object.keys(selectedCombination).forEach((key) => {
        switch (key) {
            case 'pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'breast_pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'fit':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                }
                break;
            case 'style':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                    foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination[key].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                    foldedButtonThread: foldedButtonThread,
                    foldedButtonHole: foldedButtonHole,
                }
                break;
            case 'lapels':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination['style'].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                    foldedLapelsHole: foldedLapelsHole,
                }
                break;
            case 'lapels_width':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                }
                break;
            default:
                break;
        }
    })
    return {
        selectedCombinationObj, selectedFabrics, selectedSuitAccents,
        selectedCombination, measurement
    }
}





export const selectedVestJSON = (suitFoldedJson, S3BASE_URL) => {
    let selectedFabrics = JSON.parse(suitFoldedJson && suitFoldedJson[1].value)
    let { productId } = selectedFabrics || {}

    let selectedCombination = JSON.parse(suitFoldedJson && suitFoldedJson[0].value)

    let vestsFabrics = JSON.parse(suitFoldedJson && suitFoldedJson[2].value)

    let selectedSuitAccents = JSON.parse(suitFoldedJson && suitFoldedJson[3].value)

    let selectedVestAccents = JSON.parse(suitFoldedJson && suitFoldedJson[4].value)

    let selectedVestCombination = JSON.parse(suitFoldedJson && suitFoldedJson[5].value)

    let { value: measurement } = (suitFoldedJson && suitFoldedJson[6]) || {}

    let { suitInnerFabric, selectedSuitLining, pocketSquareFabric,
        selectedPocketSquare, button, hole, thread, selectedButtonsThreads } = selectedSuitAccents || {}

    let suitBaseAccentsPath = S3BASE_URL + 'accents'


    let { isVests } = selectedVestCombination || {}

    let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/'
    let basepath = S3BASE_URL + '7079274250404/' + detectBrowserBaseUrl() + '/'
    let suitBasePocketPath = suitBaseAccentsPath + '/6756353671332/pocket'
    let baseVestPath = S3BASE_URL + '7081696952484/vests/' + detectBrowserBaseUrl() + '/'
    if (productId) {
        basepath = S3BASE_URL + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
        baseVestPath = S3BASE_URL + convertAdminProductId(productId) + 'vests/' + detectBrowserBaseUrl() + '/'
    }
    if (suitInnerFabric) {
        let parts = suitInnerFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        if (selectedSuitLining === 'custom') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        } else if (selectedSuitLining === 'unlined') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/unlined/'
        } else {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        }
    }
    if (pocketSquareFabric) {
        let parts = pocketSquareFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        suitBasePocketPath = suitBaseAccentsPath + '/' + shopifyId + '/pocket/'
    }
    let selectedCombinationObj = {
        foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
        foldedPocket: basepath + 'transparent/transparent.png',
        foldedBreastPocket: basepath + 'transparent/transparent.png',
        foldedSleeve: basepath + 'sleeve/front/sleeve.png',
        foldedPant: basepath + 'pant/folded_pant.png',
        foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
        foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
        foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
        foldedCollar: basepath + 'collar/folded/other_collar.png',
        suitInner: baseSuitInnerPath + 'suit_interior.png',
        suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
        suitPocketsSquare: selectedPocketSquare === 'without' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBasePocketPath + 'pocket_square.png',
        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
        foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
        foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
        foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
    }
    let foldedButtonThread = ''
    let foldedButtonHole = ''
    let foldedLapelsHole = ''

    switch (selectedButtonsThreads) {
        case 'all':
            foldedButtonThread = suitBaseAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
            foldedButtonHole = suitBaseAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'cuffs':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        case 'lapel':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'default':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        default:
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
    }

    Object.keys(selectedCombination).forEach((key) => {
        switch (key) {
            case 'pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'breast_pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'fit':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                }
                break;
            case 'style':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                    foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination[key].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                    foldedButtonThread: foldedButtonThread,
                    foldedButtonHole: foldedButtonHole,
                }
                break;
            case 'lapels':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination['style'].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                    foldedLapelsHole: foldedLapelsHole,
                }
                break;
            case 'lapels_width':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                }
                break;
            default:
                break;
        }
    })
    let suitVestsButton = ''
    if (isVests) {
        suitVestsButton = selectedVestCombination['style'].key === 'double_four_breasted' || selectedVestCombination['style'].key === 'double_six_breasted' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + '/suit_vests/buttons/' + button + '/' + selectedVestCombination['style'].button_image_url
    } else {
        suitVestsButton = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
    }

    Object.keys(selectedVestCombination).forEach((key) => {
        switch (key) {
            case 'style':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    suitVests: baseVestPath + selectedVestCombination['lapels'].key + '/' + selectedVestCombination[key].vest_image_url,
                    suitVestsButtons: suitVestsButton
                }
                break;
            case 'lapels':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    suitVests: baseVestPath + selectedVestCombination[key].key + '/' + selectedVestCombination['style'].vest_image_url
                }
                break;
            default:
                break;
        }
    })
    return {
        selectedCombinationObj, selectedFabrics,
        vestsFabrics, selectedSuitAccents,
        selectedCombination, selectedVestCombination,
        selectedVestAccents, measurement
    }
}


export const defaultBlazerFabrics = (obj) => {
    const { collectionByHandle } = (obj && obj.data) || {}
    const { edges } = (collectionByHandle && collectionByHandle.products) || {}
    const { node } = (edges && edges[0]) || {}
    const { title, tags, images, id, description, variants } = node || {}
    let { node: products } = (variants && variants.edges && variants.edges[0]) || {}
    let { priceV2 } = products || {}
    const defaultObj = {
        name: title,
        productId: id,
        tags: tags,
        images: images,
        changeFabrics: false,
        description: description,
        id: products?.id,
        amount: priceV2?.amount ? priceV2?.amount : 280,
        currencyCode: priceV2?.currencyCode ? priceV2?.currencyCode : 'SGD',
        selectedColorCode: ''
    }
    return defaultObj
}



export const selectedBlazerJSON = (blazerFoldedJSON, S3BASE_URL) => {
    let selectedFabrics = JSON.parse(blazerFoldedJSON && blazerFoldedJSON[1].value)
    let { productId } = selectedFabrics || {}
    let selectedCombination = JSON.parse(blazerFoldedJSON && blazerFoldedJSON[0].value)
    let selectedSuitAccents = JSON.parse(blazerFoldedJSON && blazerFoldedJSON[2].value)
    let { value: measurement } = (blazerFoldedJSON && blazerFoldedJSON[3]) || {}

    let { button, hole, thread, pocketSquareFabric, blazerInnerFabric, selectedBlazerLining,
        selectedPocketSquare, selectedButtonsThreads } = selectedSuitAccents || {}

    let suitBaseAccentsPath = S3BASE_URL + 'accents'

    let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/'
    let suitBasePocketPath = suitBaseAccentsPath + '/6756353671332/pocket'

    let basepath = productId ? S3BASE_URL + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/' :
        S3BASE_URL + '7080752611492/' + detectBrowserBaseUrl() + '/'
    if (blazerInnerFabric) {
        let parts = blazerInnerFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        if (selectedBlazerLining === 'custom') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        } else if (selectedBlazerLining === 'unlined') {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/unlined/'
        } else {
            baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
        }
    }
    if (pocketSquareFabric) {
        let parts = pocketSquareFabric?.split('/');
        let shopifyId = parts[parts.length - 1];
        suitBasePocketPath = suitBaseAccentsPath + '/' + shopifyId + '/pocket/'
    }
    let selectedCombinationObj = {
        foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
        foldedPocket: basepath + 'transparent/transparent.png',
        foldedBreastPocket: basepath + 'transparent/transparent.png',
        foldedSleeve: basepath + 'sleeve/front/sleeve.png',
        foldedPant: basepath + 'pant/folded_pant.png',
        foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
        foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
        foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
        foldedCollar: basepath + 'collar/folded/other_collar.png',
        suitInner: baseSuitInnerPath + 'suit_interior.png',
        suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
        suitPocketsSquare: selectedPocketSquare === 'without' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBasePocketPath + 'pocket_square.png',
        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
        foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
        foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
        foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
    }

    let foldedButtonThread = ''
    let foldedButtonHole = ''
    let foldedLapelsHole = ''

    switch (selectedButtonsThreads) {
        case 'all':
            foldedButtonThread = suitBaseAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
            foldedButtonHole = suitBaseAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'cuffs':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        case 'lapel':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
            break;
        case 'default':
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break;
        default:
            foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
            break
    }

    Object.keys(selectedCombination).forEach((key) => {
        switch (key) {
            case 'pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'breast_pocket':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                }
                break;
            case 'fit':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                }
                break;
            case 'style':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                    foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination[key].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                    foldedButtonThread: foldedButtonThread,
                    foldedButtonHole: foldedButtonHole,
                }
                break;
            case 'lapels':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    suitInner: selectedCombination['style'].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                    foldedLapelsHole: foldedLapelsHole,
                }
                break;
            case 'lapels_width':
                selectedCombinationObj = {
                    ...selectedCombinationObj,
                    foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                    foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                    foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                }
                break;
            default:
                break;
        }
    })
    return {
        selectedCombinationObj, selectedFabrics, selectedSuitAccents,
        selectedCombination, measurement
    }
}