import React from 'react'
import { withRouter } from 'react-router-dom'
import routes from '../../config/routes'
import { connect } from 'react-redux'
import { store } from '../../createStore'
import Actions from '../../redux/action'
import { toast } from 'react-toastify'
import { titleCase } from '../../util/random_number'
import { LINE_ITEMS_ADD, CHECKOUT_CREATE, CUSTOMER_ASSOCIATE } from '../../Queries'
import { BLAZER_CHECKOUT, PANT_CHECKOUT, SHIRT_CHECKOUT, SUIT_CHECKOUT, VEST_CHECKOUT } from '../../component/ProductCheckout'
import { Constants } from '../../util/Constant'
import { PATH } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'

class Sizecomplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuit: false,
            isVests: false,
        }
    }

    componentDidMount() {
        this.props.getRecentCheckOutDetails()
        this.createIntialCheckOutId()
    }
    reset() {
        const { isCheckout } = this.props
        if (isCheckout) {
            this.props.history.push(routes.MEASUREMENT_SIZE)
        } else {
            this.props.history.push(routes.CUSTOMER_MEASUREMENT_SIZE)
        }
    }
    editMeasurement() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
            || {}
        if (accessToken) {
            let { key, namespace, measurementId, measureMentType } = this.props.getMeasurementOptions || {}
            this.navEditMeasurement(key, namespace, measurementId, measureMentType)
        } else {
            this.navEditMeasurement()
        }
    }

    navEditMeasurement(key, namespace, measurementId, measurementType) {
        const { isShirt, isSuit, isPant, isBlazer } = this.props
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken && measurementType === 'edit1') {
            if (key.includes(titleCase(Constants.PRODUCTS.SHIRT))) {
                this.props.history.push(
                    `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
                );
            } else if (key.includes(titleCase(Constants.PRODUCTS.PANT))) {
                this.props.history.push(
                    `/measurement/tape/pant/success/${key}/${namespace}/${measurementId}/:edit`
                );
            } else if (key.includes(titleCase(Constants.PRODUCTS.SUIT))) {
                this.props.history.push(
                    `/measurement/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
                );
            } else if (key.includes(titleCase(Constants.PRODUCTS.BLAZER))) {
                this.props.history.push(
                    `/measurement/tape/blazer/success/${key}/${namespace}/${measurementId}/:edit`
                );
            } else {
                this.props.history.push(
                    `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
                );
            }
        } else {
            if (isShirt) {
                this.props.history.push(routes.EDIT_SHIRT_MEASUREMENT)
            } else if (isPant) {
                this.props.history.push(routes.EDIT_PANT_MEASUREMENT)
            } else if (isSuit) {
                this.props.history.push(routes.EDIT_SUIT_MEASUREMENT)
            } else if (isBlazer) {
                this.props.history.push(routes.EDIT_BLAZER_MEASUREMENT)
            } else {
                this.props.history.push(routes.EDIT_BLAZER_MEASUREMENT)
            }
        }
    }

    customerAssociate(checkoutId) {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer = {} } = response && response?.checkoutCustomerAssociate || {}
            if (customer === null) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }

    shirtCheckout(checkoutId) {
        const { selectedMeasurement } = this.props.getMeasurement
        let { selectedShirtFabric } = this.props.selectedFabric
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const { selectedCombination } = this.props.selectedStyle
        const { shirtAccents } = this.props.selectedAccents
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.SHIRT
        }
        let customAttributes = SHIRT_CHECKOUT(shirtAccents,
            selectedCombination, selectedShirtFabric, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: selectedShirtFabric?.id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }
    createIntialCheckOutId() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (accessToken) {
            if (!lastIncompleteCheckout && !this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        } else {
            if (!this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        }
    }

    blazerCheckout(checkoutId) {
        const { selectedMeasurement } = this.props.getMeasurement
        let { selectedBlazerFabric } = this.props.blazerFabric
        let { selectedAccents } = this.props.blazerAccents
        let { selectedCombination } = this.props.selectedBlazerStyle
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
            productType: Constants.PRODUCTS.SUIT
        }
        let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: selectedBlazerFabric?.id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }

    suitCheckout(checkoutId) {
        const { isBlazer } = this.props
        const { selectedMeasurement } = this.props.getMeasurement
        let { selectedSuitFabric } = this.props.selectedSuitFabric || {}
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle

        let { id } = selectedSuitFabric || {}
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
            productType: Constants.PRODUCTS.SUIT
        }
        let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination,
            selectedSuitFabric, selectedAccents, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }


    vestCheckout(checkoutId) {
        let { selectedSuitFabric } = this.props.selectedSuitFabric
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        const { selectedMeasurement } = this.props.getMeasurement
        let { selectedVestStyle, selectedVestAccents, selectedVestFabric: { selectedVestFabric } } = this.props

        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let { id } = selectedVestFabric || {}
        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.SUIT
        }

        let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
            selectedAccents, selectedVestAccents, selectedVestStyle, Measurements, selectedVestFabric)
        let lineItems = [{
            quantity: 1,
            variantId: id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }

    pantCheckOut(checkoutId) {
        const { selectedMeasurement } = this.props.getMeasurement
        let { selectedPantFabric } = this.props.selectedPantFabric
        const { selectedCombination } = this.props.selectedPantStyle
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.PANT
        }

        let customAttributes = PANT_CHECKOUT(selectedCombination, selectedPantFabric, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: selectedPantFabric?.id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }

    addProductsInCheckout() {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const { isBlazer, isShirt, isPant, isVest, isSuit } = this.props
        let { token = {} } = this.props.token
        let { accessToken } = token || {}


        if (accessToken) {
            if (isSuit) {
                this.suitCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isShirt) {
                this.shirtCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isBlazer) {
                this.blazerCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isVest) {
                this.vestCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isPant) {
                this.pantCheckOut(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else {
                this.shirtCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            }
        } else {
            if (isSuit) {
                this.suitCheckout(this.props.getNewCheckOutId)
            } else if (isShirt) {
                this.shirtCheckout(this.props.getNewCheckOutId)
            } else if (isBlazer) {
                this.blazerCheckout(this.props.getNewCheckOutId)
            } else if (isVest) {
                this.vestCheckout(this.props.getNewCheckOutId)
            } else if (isPant) {
                this.pantCheckOut(this.props.getNewCheckOutId)
            } else {
                this.shirtCheckout(this.props.getNewCheckOutId)
            }
        }
    }


    render() {
        const { isCheckout } = this.props
        const { selectedMeasurement } = this.props.getMeasurement || {}
        return (
            <div className="col-lg-12 shopping_crt">
                <div className="col-12 s_heading">
                    <h2 className="text-center mt-2">Your Measurement is now complete!</h2>
                </div>
                <div className="container c-measurements c-measure-quick c-measure-quickp mm-man">
                    <ul>
                        <li>
                            {
                                Object.keys(selectedMeasurement).map((key) => {
                                    return <p>{titleCase(key)}</p>
                                })
                            }
                        </li>
                        <li>
                            {
                                Object.keys(selectedMeasurement).map((key) => {
                                    return <p>{selectedMeasurement[key]} in</p>
                                })
                            }
                        </li>

                    </ul>
                    <div className="col-12 text-center">
                        <button type="button" className="btn btn-dark mb-3" onClick={() => this.editMeasurement()}>Alter Measurements</button> &nbsp;
                        {isCheckout && (<button type="button" className="btn btn-gren mb-3" onClick={() => this.addProductsInCheckout()}>Add to Bag</button>)}
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        selectedStyle: Actions.getShirtStyle(state),
        selectedAccents: Actions.getShirtAccent(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedFabric: Actions.getShirtFabric(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        getMeasurementOptions: Actions.getMeasurementOptions(state),
        blazerFabric: Actions.getSelectedBlazerFabric(state),
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),

        selectedVestAccents: Actions.getSelectedVestAccent(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedVestFabric: Actions.getVestFabric(state)
    }
}
export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkoutAttributesUpdate: Actions.checkoutAttributesUpdate,
    updateMeasurementOptions: Actions.updateMeasurementOptions,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    // resetSelectedSuitFabric: Actions.resetSelectedSuitFabric,
    checkoutCreate: Actions.checkoutCreate,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate
})(Sizecomplete));