import * as login from './login'
import * as fabric from './fabric'
import * as signUp from './signup'
import * as customerDetails from './customerDetails'
import * as forgetPassword from './forgetPassword'
import * as checkout from './checkout'
import * as orderList from './order_list'
import * as getMeasurement from './measurement'
import * as selectedMeasurement from './selected_measurement'
import * as address from './address'
import * as selectedCheckout from './selected_checkout'
import * as getAdminOrderId from './admin_orders'
import * as manageMeasurement from './manageMeasurement'
import * as plp from './plp'
import * as getTags from './getTags'
import selectedFabrics from './Selected_Fabrics'
import selectedStyles from './Selected_Styles'
import selectedAccents from './Selected_Accents'
import * as hubspot from './hubspot'
export default {
   ...login,
   ...fabric,
   ...signUp,
   ...forgetPassword,
   ...checkout,
   ...orderList,
   ...getMeasurement,
   ...selectedMeasurement,
   ...address,
   ...selectedCheckout,
   ...getAdminOrderId,
   ...manageMeasurement,
   ...customerDetails,
   ...plp,
   ...getTags,
   ...selectedFabrics,
   ...selectedStyles,
   ...selectedAccents,
   ...hubspot
}
