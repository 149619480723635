const NAME = 'GET_TAGS'

export const GET_ALL_TAGS = `${NAME}/GET_ALL_TAGS`;
export const GET_ALL_TAGS_SUCCESS = `${NAME}/GET_ALL_TAGS_SUCCESS`;
export const GET_ALL_TAGS_FAILURE = `${NAME}/GET_ALL_TAGS_FAILURE`;

export const getTags = store => store[NAME]
export const getAllTags = (query, key, isEdit) => {
    return {
        type: GET_ALL_TAGS,
        query,
        key,
        isEdit
    }
}

export const getAllTagsSuccess = (data, key) => {
    return {
        type: GET_ALL_TAGS_SUCCESS,
        data,
        key
    }
}

export const getAllTagsFailure = (error) => {
    return {
        type: GET_ALL_TAGS_FAILURE,
        error
    }
}
