import React from 'react'
import { withRouter } from 'react-router-dom'
import SubFooter from '../../component/SubFooter'
import { PATH, CONTENTS, SERVERIMG } from '../../globals/app-constraints'
import LazyLoad from 'react-lazyload';
import Faqs from '../../component/FAQ';

class Blog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="p-0">
                    <div className='container mt-3 mb-5'>
                        <div className='exchange_contents px-lg-0 px-2 pb-5'>
                            <h1 className='exchange_head mt-lg-4 mt-3 mb-3 text-center fs-24 H-m'>{CONTENTS.blog.head}</h1>
                            <p className='fs-14 H-l text-center'>{CONTENTS.blog.para}</p>
                        </div>
                        <div className='padding_gaps_img_section'>
                            <div className='d-flex gap-6 img_section'>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg1} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content'>{CONTENTS.blog.imgText1}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg2} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content'>{CONTENTS.blog.imgText2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='exchange_contents pb-lg-5 pb-2'>
                            <div className='d-flex flex-column gap-16'>

                                <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.blog.heading1}</p>
                            </div>
                            <p className='fs-14 py-1 H-l'>
                                {CONTENTS.blog.heading1Para1}
                            </p>
                            <p className='fs-14 py-1 H-l'>
                                {CONTENTS.blog.heading1Para2}
                            </p>
                        </div>
                        <div className='padding_gaps_img_section pt-4'>
                            <div className='d-flex gap-6 img_section'>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg3} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content'>{CONTENTS.blog.imgText3}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg4} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content'>{CONTENTS.blog.imgText4}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-6 img_section'>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg5} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content text-lowecase'>{CONTENTS.blog.imgText5}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                                    <div className='d-flex align-items-center justify-content-center blog-img'>
                                        <img src={PATH?.img?.BlogImg6} alt="blog-img" className='w-100' />
                                    </div>
                                    <div className='d-flex flex-column img_section_content gap-15'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div>
                                                <p className='H-l fs-16 max_width_content text-lowecase'>{CONTENTS.blog.imgText6}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='exchange_contents pb-lg-5'>
                            <div className='d-flex flex-column gap-16'>

                                <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.blog.heading2}</p>
                            </div>
                            <p className='fs-14 py-1 H-l'>
                                {CONTENTS.blog.heading2Para1}
                            </p>
                            <p className='fs-14 py-1 H-l'>
                                {CONTENTS.blog.heading2Para2}
                            </p>
                        </div>
                    </div>
                </div>
                <SubFooter />
            </React.Fragment>
        )
    }
}

export default withRouter(Blog)