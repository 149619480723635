import React from 'react'
import { withRouter } from 'react-router-dom'
import Routes from '../../config/routes'
import Actions from '../../redux/action'
import { connect } from 'react-redux'
import { Collection } from '../../config/constant'
import { GET_COLLECTION_LIST } from '../../Queries/collection'
import Loader from '../Loader'
import LazyLoad from 'react-lazyload';

class Vestproduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedColorCode: '',
            sortBy: 'BEST_SELLING',
            tags: [],
            reverse: true,
            navLoader: false
        }
    }
    _renderDefaultVest(title, array, obj) {
        let { selectedColorCode } = this.state
        if (selectedColorCode) {
            let findProducts = array && array.edges.find((j) => j.node && j.node?.image?.altText === selectedColorCode)
            let { image, priceV2, title: variantTitle, id } = (findProducts && findProducts.node) || {}
            let material = variantTitle && variantTitle.split("/")
            const sliced = title?.slice(0, title?.indexOf('in'))
            const titleString = `${sliced}in ${material && material[0]}${material?.length === 4 ? `/${material && material[1]}` : ""}`
            if (findProducts) {
                return (
                    <React.Fragment>
                        <div className='' onClick={() => this.productDetails(obj, id)}>
                            <div className='category_part p-0'>
                                <LazyLoad className="w-100 h-100 object-fit-cover d-flex justify-content-center align-items-center">
                                    <img src={image?.src} alt='category-img' />
                                </LazyLoad>
                            </div>
                        </div>
                        <div className='mt-lg-3 mt-4 px-4 w-100'>
                            <div className='d-flex justify-content-between align-items-start mb-lg-2 mb-3'>
                                <p className='material_name text-lowercase'>{titleString}
                                    {material?.length === 4 ? `/${material && material[1]}` : ''}</p>
                                <p className='currency_amount'>
                                    <span>{priceV2?.currencyCode}</span>
                                    <span className='ms-2'>{priceV2?.amount}</span>
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                )
            } else {
                return this.secondColorObject(title, array, obj)
            }

        } else {
            let { node } = (array && array?.edges[0]) || {}
            let { image, priceV2, title: variantTitle, id } = node || {}
            let material = variantTitle && variantTitle?.split("/")
            const sliced = title?.slice(0, title?.indexOf('in'))
            const titleString = `${sliced}in ${material && material[0]}${material?.length === 4 ? `/${material && material[1]}` : ""}`
            return (
                <React.Fragment>
                    <div className='' onClick={() => this.productDetails(obj, id)}>
                        <div className='category_part p-0'>
                            <LazyLoad className="w-100 h-100 object-fit-cover d-flex justify-content-center align-items-center">
                                <img src={image?.src} alt='category-img' />
                            </LazyLoad>
                        </div>
                    </div>
                    <div className='mt-lg-3 mt-4 px-4 w-100'>
                        <div className='d-flex justify-content-between align-items-start mb-lg-2 mb-3'>
                            <p className='material_name text-lowercase'>{titleString}</p>
                            <p className='currency_amount'>
                                <span>{priceV2?.currencyCode}</span>
                                {/* <img src={currency} alt="euro-currency" /> */}
                                <span className='ms-2'>{priceV2?.amount}</span>
                            </p>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    secondColorObject(title, array, obj) {
        let { node } = (array && array.edges[0]) || {}
        let { image, priceV2, title: variantTitle, id } = node || {}
        let material = variantTitle && variantTitle.split("/")
        const sliced = title?.slice(0, title?.indexOf('in'))
        const titleString = `${sliced}in ${material && material[0]}${material?.length === 4 ? `/${material && material[1]}` : ""}`
        return (
            <React.Fragment>
                <div className='' onClick={() => this.productDetails(obj, id)}>
                    <div className='category_part p-0'>
                        <LazyLoad className="w-100 h-100 object-fit-cover d-flex justify-content-center align-items-center">
                            <img src={image?.src} alt='category-img' />
                        </LazyLoad>
                    </div>
                </div>
                <div className='mt-lg-3 mt-4 px-4 w-100'>
                    <div className='d-flex justify-content-between align-items-start mb-lg-2 mb-3'>
                        <p className='material_name text-lowercase'>{titleString}</p>
                        <p className='currency_amount'>
                            <span>{priceV2?.currencyCode}</span>
                            <span className='ms-2'>{priceV2?.amount}</span>
                        </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    productDetails(obj, variantId) {
        this.setState({ navLoader: true })
        // Add Loader for navigation purpose
        const { selectedProducts } = this.props.getProductsDetails
        let { node } = obj || {}
        const { edges } = node?.variants
        const checkValidPLP = edges?.findIndex((eObj) => eObj?.node?.sku == selectedProducts)
        const colorCode = (edges && edges[0]?.node?.sku) || ''
        if (checkValidPLP === -1) {
            this.props.selectedProducts(colorCode)
        }
        if (!selectedProducts) {
            this.props.selectedProducts(colorCode)
        }
        setTimeout(() => {
            this.setState({ navLoader: false })
            let parts = node?.id?.split('/');
            let id = parts[parts?.length - 1];
            let variant = variantId?.split('/');
            let variantid = variant[variant?.length - 1];
            this.props.history.push(`/vest/details/${id}/${variantid}`)
        }, 1000);
    }
    productRender() {
        this.props.history.push(Routes.BLAZER)
    }
    changeColor(color) {
        this.setState({ selectedColorCode: color })
        this.props.selectedProducts(color)
    }
    mapColor(color) {
        let filtered = color.filter((c) => c.includes('#'))
        return filtered
    }
    updateSelectedFabric(name, productId, tags, description, id, images, amount, currencyCode, selectedColorCode) {
        let fabrics = {
            name,
            productId,
            tags,
            description,
            changeFabrics: true,
            id,
            images,
            amount,
            currencyCode,
            selectedColorCode
        }
        return fabrics
    }
    toTitleCase(string) {
        const str = string;
        const newStr = str?.split(' ')
            .map(w => w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase())
            .join(' ');
        return newStr
    }
    navCanvasVest = async (obj, titleName, variantDetails) => {
        let selectedColorCode = this.state.selectedColorCode
        const { fabricList } = this.props.fabricList || {}
        const { collectionByHandle } = fabricList?.data || {}
        const removed = titleName?.slice(0, titleName?.indexOf('in'))
        if (!selectedColorCode) {
            let { node } = (variantDetails?.edges && variantDetails?.edges[0]) || {}
            selectedColorCode = (node?.image?.altText) || ''
        }
        let findProducts = obj?.variants?.edges?.find((j) => j?.node?.image?.altText === selectedColorCode)
        let { title: variantTitle } = findProducts?.node || {}
        let material = variantTitle?.split('/')
        let finalString = ''
        if (material) {
            // let convertString = `${removed}in ${material && material[0]}/${material && material[1]}`
            let convertString = `${removed}in ${material && material[0]}${material?.length === 4 ? `/${material && material[1]}` : ""}`
            finalString = convertString?.toLowerCase()?.trim()
        } else {
            finalString = titleName?.toLowerCase()
        }
        const revealProduct = await collectionByHandle?.products?.edges?.find((checkFab) => checkFab?.node?.title?.toLowerCase() === finalString)
        let { id, variants } = revealProduct?.node || {}
        let { node } = (variants && variants?.edges && variants?.edges[0]) || {}
        let { id: lineItemId, priceV2 } = node || {}
        const titleCase = this.toTitleCase(finalString)
        let { images, tags, description } = obj
        const selectedProduct = this.updateSelectedFabric(titleCase, id, tags, description, lineItemId, images, priceV2?.amount, priceV2?.currencyCode, selectedColorCode)
        this.props.updateVestFabric(selectedProduct)
        this.props.history.push('/canvas/vests?fabric')
    }
    getCollections() {
        let key = 'vest'
        let query
        let { sortBy, reverse, tags } = this.state
        query = GET_COLLECTION_LIST(Collection.vest, 100, sortBy, reverse, tags)
        this.props.getFabricList(query, key)
    }
    componentDidMount() {
        this.getCollections()
    }
    _renderTripleVest(products) {
        const { node: prodNode } = products?.edges[22] || {}
        const { title, images, variants } = prodNode || {}
        let { node } = (variants && variants?.edges[0]) || {}
        let { priceV2, title: variantTitle } = node || {}
        let material = variantTitle && variantTitle.split("/")
        const value = title?.slice(0, title?.indexOf('in') + 3)
        return (
            <div className='category_content_3_items d-flex m-0 flex-wrap'>
                <div className='category_1_1_3'>
                    <div className='category_part p-0 justify-content-center align-items-end'>
                        <img src={images?.edges[1]?.node?.src} alt='category-img' />
                    </div>
                </div>
                <div className='category_2_1_3'>
                    <div className='category_part p-0'>
                        <LazyLoad className="w-100 h-100 object-fit-cover d-flex justify-content-center align-items-center">
                            <img src={images?.edges[2]?.node?.src} alt='category-img' />
                        </LazyLoad>
                    </div>
                </div>
                <div className='category_2_2_3'>
                    <div className='category_part p-0 justify-content-end align-items-end'>
                        <img src={images?.edges[3]?.node?.src} alt='category-img' />
                    </div>
                </div>
                <div className='mt-lg-3 mt-4 px-4 w-100'>
                    <div className='d-flex justify-content-between align-items-start mb-lg-2 mb-3'>
                        <p className='material_name text-lowercase'>{value} {material && material[0]?.toLowerCase()} {material?.length === 4 ? `/${material && material[1]}` : ''}</p>
                        <p className='currency_amount'>
                            <span>{priceV2?.currencyCode}</span>
                            <span className='ms-2'>{priceV2?.amount}</span>
                        </p>
                    </div>
                    <div className='d-flex mt-2'>

                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { collections } = this.props
        const { products } = collections || {}
        return (
            <div className='mt-lg-3 mt-4 mb-5'>
                {this.state.navLoader && <Loader />}
                <div className='d-flex flex-lg-row flex-column m-0 flex-wrap overall_width'>
                    {
                        products && products?.edges.map((o, i) => {
                            let { title, variants } = o?.node || {}
                            return (
                                <div className='category_content' key={i}>
                                    {this._renderDefaultVest(title, variants, o)}
                                    <div className='d-flex flex-wrap row-gap-lg-3 row-gap-2 px-4 category_align'>
                                        {
                                            variants && variants.edges.map((j, index) => {
                                                let { title, image } = j.node
                                                let color = title && title.split("/")?.find(o => o.includes('#'))?.split('&')[0]
                                                return (
                                                    <div className='color_select select1'
                                                        key={index}
                                                        style={{ background: `${color}` }}
                                                        onMouseOver={() => this.changeColor(image.altText)}
                                                        onClick={() => this.changeColor(image.altText)}
                                                    >
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            )
                        })}
                    {/* {this._renderTripleVest(products)} */}
                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        fabricList: Actions.getFabrics(state),
        getVestFabric: Actions.getVestFabric(state),
        getProductsDetails: Actions.getPLP(state)
    }
}
export default withRouter(connect(mapStateToProps, {
    pdpDetails: Actions.pdpDetails,
    updateVestFabric: Actions.updateVestFabric,
    getFabricList: Actions.getFabricList,
    selectedProducts: Actions.selectedProducts
})(Vestproduct))