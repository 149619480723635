import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Constants } from '../util/Constant'
import Actions from '../redux/action'
import { toast } from 'react-toastify'
import { isValidEmail } from '../util/validation'

class SubFooter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subscriberEmail: undefined,
            successMessage: null,
            error: ''
        }
    }
    subscribe() {
        if (!this.state.subscriberEmail) {
            return this.setState({ error: 'This field cant be empty' })
        }
        if (!isValidEmail(this.state.subscriberEmail)) {
            return this.setState({ error: 'Invalid email' })
        }
        this.setState({ error: '' })
        if (this.state.subscriberEmail) {
            const callback = (res) => {
                if (res?.status === 200) {
                    this.setState({ successMessage: true })
                } else {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            }
            let params = {
                fields: [
                    {
                        name: 'email',
                        value: this.state.subscriberEmail
                    }
                ]
            }

            let hubspotData = JSON.stringify(params)

            this.props.hubSpot(hubspotData, Constants.HUBSPOT_API.PRIMARY_FORM, callback)
        }
    }
    handleChange(e) {
        let { value } = e?.target
        this.setState({ subscriberEmail: value })
    }
    render() {
        const { successMessage } = this.state
        return (
            <div className="col-12 nw_next_purc">
                {/* <div className="nw_next_purc_card">
                    {
                        successMessage ? <p style={{ color: 'white' }}>
                            Thanks for subscribing! Use PF10OFF at checkout for a 10% discount.
                        </p> : <>
                            <h3>Get <span>10% off </span>your next purchase...</h3>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <button
                                    type="button"
                                    className="input-group-text"
                                    id="basic-addon2" onClick={() => this.subscribe()}>
                                    Subscribe
                                </button>
                            </div>
                            <span style={{ color: 'red' }}>{this.state.error}</span>
                            <p>Good things come to those who subscribe.</p>
                        </>
                    }

                </div> */}
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {
    hubSpot: Actions.hubSpot
})(SubFooter));
