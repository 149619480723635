export const HUB_SPOT_API = `HUB_SPOT_API`;
export const HUB_SPOT_API_SUCCESS = `HUB_SPOT_API_SUCCESS`;
export const HUB_SPOT_API_FAILURE = `HUB_SPOT_API_FAILURE`;

export const hubSpot = (params, api, callback) => {
    return {
        type: HUB_SPOT_API,
        params,
        api,
        callback
    }
}

export const hubSpotSuccess = (data, key) => {
    return {
        type: HUB_SPOT_API_SUCCESS,
        data,
        key
    }
}

export const hubSpotFailure = (error) => {
    return {
        type: HUB_SPOT_API_FAILURE,
        error
    }
}
