import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    signupDetails: undefined
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.SIGNUP: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SIGNUP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                signupDetails: action.data.response,
                error: false
            };
        }
        case Actions.SIGNUP_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }

};