const NAME = 'MEASUREMENT'

export const MEASUREMENT_LIST = `${NAME}/MEASUREMENT_LIST`;
export const MEASUREMENT_LIST_SUCCESS = `${NAME}/MEASUREMENT_LIST_SUCCESS`;
export const MEASUREMENT_LIST_FAILURE = `${NAME}/MEASUREMENT_LIST_FAILURE`;

export const MEASUREMENT_ADD = `${NAME}/MEASUREMENT_ADD`;
export const MEASUREMENT_ADD_SUCCESS = `${NAME}/MEASUREMENT_ADD_SUCCESS`;
export const MEASUREMENT_ADD_FAILURE = `${NAME}/MEASUREMENT_ADD_FAILURE`;

export const MEASUREMENT_DELETE = `${NAME}/MEASUREMENT_DELETE`;
export const MEASUREMENT_DELETE_SUCCESS = `${NAME}/MEASUREMENT_DELETE_SUCCESS`;
export const MEASUREMENT_DELETE_FAILURE = `${NAME}/MEASUREMENT_DELETE_FAILURE`;

export const MEASUREMENT_UPDATE = `${NAME}/MEASUREMENT_UPDATE`;
export const MEASUREMENT_UPDATE_SUCCESS = `${NAME}/MEASUREMENT_UPDATE_SUCCESS`;
export const MEASUREMENT_UPDATE_FAILURE = `${NAME}/MEASUREMENT_UPDATE_FAILURE`;

export const MEASUREMENT_DETAILS = `${NAME}/MEASUREMENT_DETAILS`;
export const MEASUREMENT_DETAILS_SUCCESS = `${NAME}/MEASUREMENT_DETAILS_SUCCESS`;
export const MEASUREMENT_DETAILS_FAILURE = `${NAME}/MEASUREMENT_DETAILS_FAILURE`;

export const getMeasurement = store => store[NAME]
export const getMeasurementList = (query) => {
    return {
        type: MEASUREMENT_LIST,
        query
    }
}

export const getMeasurementListSuccess = (data) => {
    return {
        type: MEASUREMENT_LIST_SUCCESS,
        data
    }
}

export const getMeasurementListFailure = (error) => {
    return {
        type: MEASUREMENT_LIST_FAILURE,
        error
    }
}

export const measurementAdd = (query, input, callback) => {
    return {
        type: MEASUREMENT_ADD,
        query,
        input,
        callback
    }
}

export const measurementAddSuccess = (data) => {
    return {
        type: MEASUREMENT_ADD_SUCCESS,
        data
    }
}

export const measurementAddFailure = (error) => {
    return {
        type: MEASUREMENT_ADD_FAILURE,
        error
    }
}

export const measurementDelete = (query, input, callback) => {
    return {
        type: MEASUREMENT_DELETE,
        query,
        input,
        callback
    }
}

export const measurementDeleteSuccess = (data) => {
    return {
        type: MEASUREMENT_DELETE_SUCCESS,
        data
    }
}

export const measurementDeleteFailure = (error) => {
    return {
        type: MEASUREMENT_DELETE_FAILURE,
        error
    }
}

export const measurementupdate = (query, input, callback) => {
    return {
        type: MEASUREMENT_UPDATE,
        query,
        input,
        callback
    }
}

export const measurementUpdateSuccess = (data) => {
    return {
        type: MEASUREMENT_UPDATE_SUCCESS,
        data
    }
}

export const measurementUpdateFailure = (error) => {
    return {
        type: MEASUREMENT_UPDATE_FAILURE,
        error
    }
}

export const measurementDetails = (query, callback) => {
    return {
        type: MEASUREMENT_DETAILS,
        query,
        callback
    }
}

export const measurementDetailsSuccess = (data) => {
    return {
        type: MEASUREMENT_DETAILS_SUCCESS,
        data
    }
}

export const measurementDetailsFailure = (error) => {
    return {
        type: MEASUREMENT_DETAILS_FAILURE,
        error
    }
}