import React from 'react'
import { withRouter } from 'react-router-dom'
import { detectBrowser } from '../../util/detect-browser';

class VestZoom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {

        const { selectedCombination, isShowFolded, isShowFront, isShowBack, openModal, closeModal } = this.props;
        const { backModal, backPant, frontModal, frontPant, frontBreastPocket, frontPocket, foldedPocket,
            foldedBreastPocket, foldedPant, backStyle, foldedStyle, frontStyle, frontShirt, backShirt, frontTie, foldedVestButtons,
            frontVestButtons, frontButtonThread, frontButtonHole, foldedButtonHole, foldedButtonThread, foldedCustomColor, backCustomColor } = selectedCombination || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${openModal ? 'show' : ''}`} tabIndex="-1" style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `0px` : '' }} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content canva_zoom_hgt rounded-0">
                            <div className="modal-header border-0 px-4 py-4">
                                <button type="button" className="btn-close"
                                    onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body zoom-canvas">
                                <div className="">
                                    <div className='carousel-inner' data-interval="false">
                                        <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-vest w-75 m-auto pi_vest_cuff`}>
                                                    <img className="custom_shirt folded_pocket"
                                                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_style"
                                                        src={foldedCustomColor.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedStyle.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedButtonHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedVestButtons}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedButtonThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_breast_pocket"
                                                        src={foldedPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_pant"
                                                        src={foldedPant.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_vest_front`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={frontModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_shirt"
                                                        src={frontShirt}
                                                        alt='shirt' />
                                                    <img className="custom_shirt front_tie"
                                                        src={frontTie}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontPant.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_fit"
                                                        src={frontStyle.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontBreastPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontButtonHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontVestButtons}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontButtonThread}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_vest_back`}>
                                                    <img className="custom_shirt suit_back"
                                                        src={backModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_shirt"
                                                        src={backShirt}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backPant.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backStyle.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={backCustomColor.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(VestZoom)