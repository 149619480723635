import React from 'react'
import { withRouter } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'

class Corporate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="p-0">
                    <div className="main_banner w-100 vh-100 row m-0 position-relative">
                        <div className='overlay-bg'></div>

                        <div className='col-lg-6 p-0 h-100 d-flex align-items-center justify-content-center position-relative'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.corporateServices.bannerLeftImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                            <div className="corporate-align container position-absolute z-2">
                                <h6 className='exchange_banner_head fs-24 H-cb text-uppercase text-white pt-lg-4 pt-0'>{CONTENTS.corporateServices.bannerHead}</h6>
                                <p className='exchange_text fs-14 H-l text-white text-start pt-3 pb-5'>
                                    {CONTENTS.corporateServices.bannerContent}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 p-0 h-100 d-none d-lg-flex align-items-center justify-content-center'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.corporateServices.bannerRightImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                        </div>
                    </div>

                    <div className='blazer_section mx-0 position-relative pb-lg-5 pt-lg-3'>
                        <div className='container pb-lg-5 pb-4'>
                            <div className='exchange_contents pt-lg-5 pt-4'>
                                <div className='d-flex flex-column gap-16'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center">
                                        <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                            <img src={PATH?.img?.PencilCorporate} className='icon_alignment our_story_icon' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.corporateServices.head1}</p>
                                </div>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.corporateServices.head1Para1}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.corporateServices.head1Para2}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.corporateServices.head1Para3}
                                </p>
                            </div>
                            <div className='exchange_contents pt-lg-4 pt-4'>
                                <div className='d-flex flex-column gap-16'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center">
                                        <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                            <img src={PATH?.img?.DataCollectionCorporate} className='icon_alignment our_story_icon' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.corporateServices.head2}</p>
                                </div>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.corporateServices.head2Para1}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.corporateServices.head2Para2}
                                </p>
                                <p className='fs-14 py-3 H-l'><b>{CONTENTS.corporateServices.email} <a className='text-decoration-underline text-dark' href="mailto:corporate@mikegulati@gmail.com">{CONTENTS.corporateServices.emailLink}</a></b></p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='blazer_section mx-0 home_img_section_bg_color_change'>
                        <div className='col-lg-6 p-0 cursor-pointer position-relative d-flex align-items-center justify-content-center cursor-pointer' onClick={() => this.navCustomClothingPage('pant')}>
                            <p className='H-cb fs-16 home_section_names letter-spacing p-4 text-lowercase'>Shop <span className='text-decoration-underline text-lowercase'>Pant</span></p>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={PATH?.img?.BlazerRightImg} className='suit_blazer_img_section w-100' alt="blazer-right-img" />
                            </LazyLoad>
                        </div>
                        <div className='col-lg-6 p-0 d-flex align-items-center justify-content-center home_img_section cursor-pointer' onClick={() => this.navCustomClothingPage('pant')}>
                            <div className='exchange_contents'>
                                <p className='fs-16 py-1 H-l'>
                                    Create a company collection composed of our already available products. Share with us some
                                    information on the way your company operates, and we'd be happy to help you curate a collection
                                    of garments with the right color tones and level of formality to match your business's needs.
                                </p>
                                <p className='fs-16 py-1 H-l'>
                                    This way your employees can individually select the look they prefer. Better yet, with enough
                                    volume, enjoy a substantial discount on all items purchased.
                                </p>
                                <p className='fs-16 py-1 H-l'><b>corporate@mikegulati@gmail.com</b></p>
                            </div>
                        </div>

                    </div> */}
                {/* <div className="container ex_content text-center my-3">
                        <h6 className='exchange_head fs-16 H-m mt-5 mb-4'>fully customizable</h6>
                        <img src={require('../../asset/new_images/pencil.png')} className="return_exchange_icon mb-4" alt="images" />

                        <h6 className='exchange_head fs-24 H-m mt-5 mb-4'>create a collection</h6>
                        <img src={require('../../asset/new_images/data-collection.png')} className="return_exchange_icon mb-4" alt="images" />

                    </div> */}
            </React.Fragment >
        )
    }
}

export default withRouter(Corporate)