const NAME = 'PLP'

export const PLP_LIST = `${NAME}/PLP_LIST`;
export const PLP_LIST_SUCCESS = `${NAME}/PLP_LIST_SUCCESS`;
export const PLP_LIST_FAILURE = `${NAME}/PLP_LIST_FAILURE`;

export const PDP_DETAILS = `${NAME}/PDP_DETAILS`
export const PDP_DETAILS_SUCCESS = `${NAME}/PDP_DETAILS_SUCCESS`
export const PDP_DETAILS_FAILURE = `${NAME}/PDP_DETAILS_FAILURE`


export const GET_HOME_PRODUCTS = 'GET_HOME_PRODUCTS'
export const GET_HOME_PRODUCTS_SUCCESS = 'GET_HOME_PRODUCTS_SUCCESS'
export const GET_HOME_PRODUCTS_FAILURE = 'GET_HOME_PRODUCTS_FAILURE'
export const SELECTED_PRODUCTS = 'GET_SELECTED_PRODUCTS'



export const getPLP = store => store[NAME]
export const getPLPList = (query) => {
    return {
        type: PLP_LIST,
        query
    }
}

export const getPLPListSuccess = (data) => {
    return {
        type: PLP_LIST_SUCCESS,
        data
    }
}

export const getPLPListFailure = (error) => {
    return {
        type: PLP_LIST_FAILURE,
        error
    }
}


export const pdpDetails = (data) => {
    return {
        type: PDP_DETAILS,
        data
    }
}

export const pdpDetailsSuccess = (data) => {
    return {
        type: PDP_DETAILS_SUCCESS,
        data
    }
}

export const pdpDetailsFailure = (data) => {
    return {
        type: PDP_DETAILS_FAILURE,
        data
    }
}

export const getHomeProducts = (query) => {
    return {
        type: GET_HOME_PRODUCTS,
        query
    }
}

export const getHomeProductSuccess = (data) => {
    return {
        type: GET_HOME_PRODUCTS_SUCCESS,
        data
    }
}

export const getHomeProductFailure = (data) => {
    return {
        type: GET_HOME_PRODUCTS_FAILURE,
        data
    }
}

export const selectedProducts = (data) => {
    return {
        type: SELECTED_PRODUCTS,
        data
    }
}