
export const SIGNUP = `/SIGNUP`;
export const SIGNUP_SUCCESS = `/SIGNUP_SUCCESS`;
export const SIGNUP_FAILURE = `/SIGNUP_FAILURE`;

export const signUp = (query, input, callback) => {
    return {
        type: SIGNUP,
        query,
        input,
        callback
    }
}

export const signUpSuccess = (data) => {
    return {
        type: SIGNUP_SUCCESS,
        data
    }
}

export const signUpFailure = (error) => {
    return {
        type: SIGNUP_FAILURE,
        error
    }
}
