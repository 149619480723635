export default {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};


export const convertAdminProductId = (id) => {
  if (id) {
    let parts = id.split('/');
    let number = parts[parts.length - 1]; 
    return `${number}/`
  }

}