import React from 'react';
import { CONTENTS, PATH } from '../../globals/app-constraints';
import AddAddress from '.';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import { toast } from 'react-toastify';
import { CUSTOMER_ADDRESS_DELETE, CUSTOMER_LIST, DEFAULT_ADDRESS, SHIPPING_ADDRESS, SHIPPING_LINE_UPDATE } from '../../Queries';
import { store } from '../../createStore';
import ButtonDesign from '../button';

class ListOfAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddModalOpen: false,
            isEdit: false,
            selectedAddress: null,
            customerAddress: [],
            isCheckout: props.isCheckout || false,
            selectedShippingAddress: null,
            isOffcanvasOpen: false,
        };
    }

    toggleOffcanvas = () => {
        this.setState(prevState => ({
            isOffcanvasOpen: !prevState.isOffcanvasOpen
        }), () => {
            document.body.style.overflow = this.state.isOffcanvasOpen ? 'hidden' : '';
        });
    };

    componentWillUnmount() {
        // Ensure body scroll is reset when component unmounts
        document.body.style.overflow = '';
    }

    toggleAddModal = (address = null, isEdit = false) => {
        this.setState(prevState => ({
            isAddModalOpen: !prevState.isAddModalOpen,
            selectedAddress: address,
            isEdit: isEdit
        }), () => {
            this.getAddressList()
        });
    };

    handleDelete = (id) => {
        const defaultAddress = this.state.customerAddress?.find(address => address?.isDefault);
        if (defaultAddress?.id === id) {
            return toast.warning("The default address cannot be deleted.")
        }
        this.setState(prevState => ({
            customerAddress: prevState.customerAddress.filter(address => address.id !== id)
        }), () => {
            this.onAddressDelete(id)
        });
    };

    handleDefault = (id) => {
        this.setState(prevState => ({
            customerAddress: prevState.customerAddress.map(address =>
                address.id === id
                    ? { ...address, isDefault: true }
                    : { ...address, isDefault: false }
            )
        }), () => {
            this.onDefaultAddress(id);
        });

    };

    handleRadioChange = (address) => {
        this.setState({ selectedShippingAddress: address });
    };

    componentDidMount() {
        this.getAddressList()
    }

    getAddressList() {
        let { data } = this.props.customerList?.customerList || {};
        let { defaultAddress, addresses } = data?.customer || {};
        const updatedAddresses = addresses?.edges?.map((item) => {
            const isDefault = item?.node?.id === defaultAddress?.id;
            return { ...item.node, isDefault };
        });
        console.log("updatedAddresses", updatedAddresses);
        this.setState({ customerAddress: updatedAddresses })
    }

    onDefaultAddress(id) {
        let { token: { accessToken } } = this.props.token || {}
        const callback = (response) => {
            let { customerUserErrors = [] } = response?.customerDefaultAddressUpdate || {}
            if (customerUserErrors?.length === 0) {
                toast.success('Default Address Created Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
                this.props.closeListAddressModal()
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        let input = {
            id: id,
            customerAccessToken: accessToken
        }
        this.props.setDefaultAddress(DEFAULT_ADDRESS, input, callback)
    }

    onAddressDelete(id) {
        let { token: { accessToken } } = this.props.token
        const callback = (response) => {
            let { customerUserErrors = [] } = response?.customerAddressDelete || {}
            if (customerUserErrors?.length === 0) {
                toast.success('Customer Address deleted Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });

                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
                this.props.closeListAddressModal()
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

        let input = {
            id: id,
            customerAccessToken: accessToken
        }
        this.props.addressDelete(CUSTOMER_ADDRESS_DELETE, input, callback)
    }
    hanldePayment(lastIncompleteCheckout) {
        const callback = (response) => {
            let { checkout } = (response && response.checkoutShippingLineUpdate) || {}
            window.open(checkout?.webUrl, '_self')
        }
        let input = {
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
            shippingRateHandle: "Standard"
        }
        this.props.checkoutShippingLineUpdate(SHIPPING_LINE_UPDATE, input, callback)
    }
    handleSubmit(defaultAddress) {
        const { selectedShippingAddress } = this.state
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        if (!defaultAddress) {
            toast.error('No address found', {
                position: toast.TOP_RIGHT
            })
        } else if (selectedShippingAddress) {
            let { firstName, lastName, province, city, address1, phone, country, zip } = selectedShippingAddress || {}
            const callback = (response) => {
                console.log("response", response);

                let { checkout = {} } = (response && response.checkoutShippingAddressUpdateV2) || {}
                if (checkout === null) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.hanldePayment(lastIncompleteCheckout)
                    // this.props.history.push(routes.PAYMENT)
                }
            }
            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            console.log("inputss", input);

            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        } else {
            let { firstName, lastName, province, city, address1, phone, country, zip } = defaultAddress || {}
            const callback = (response) => {
                let { checkout = {}, checkoutUserErrors } = (response && response.checkoutShippingAddressUpdateV2) || {}
                if (checkout === null) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else if (checkoutUserErrors?.length > 0) {
                    toast.error(`${checkoutUserErrors[0].code}`, {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.hanldePayment(lastIncompleteCheckout)
                    // this.props.history.push(routes.PAYMENT)
                }
            }

            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        }
    }
    toggleOffcanvasClose = () => {
        document.body.style.overflow = 'auto';
    };
    render() {
        const { isAddModalOpen, customerAddress, selectedAddress, isEdit, isCheckout } = this.state;
        const { customerList } = this.props.customerList || {}
        const { data } = customerList || {}
        let { addresses = {}, defaultAddress = {} } = (data && data.customer) || {}
        const { isOffcanvasOpen } = this.state;
        const { closeListAddressModal } = this.props;
        return (
            <React.Fragment>
                {isAddModalOpen && (
                    <AddAddress
                        closeAddModal={this.toggleAddModal}
                        fields={selectedAddress}
                        isEdit={isEdit}
                    />
                )}
                {!isAddModalOpen && (
                    <>
                        <div className='offcanvas offcanvas-end measurement_offcanvas show' id="checkoutaddress">
                            <div className='overflow-y-auto overflow-x-hidden h-100 d-flex flex-column'>
                                <div onClick={() => this.toggleOffcanvasClose()}>
                                    <span className='measurement_btn_close px-4 pt-3 pb-2 d-block cursor-pointer' data-bs-dismiss="offcanvas" onClick={closeListAddressModal}>
                                        <img src={PATH?.img?.ExitPopupIcon} alt="" className='address_exit_icon' />
                                    </span>
                                </div>
                                <div className='offcanvas-body px-4 flex-grow-1'>
                                    <p className='fs-16 H-m text-center pb-2'>{CONTENTS.profile.viewAddressPopup.chooseYourAddress}</p>
                                    <p className='fs-14 H-l text-center pb-2'>{CONTENTS.profile.viewAddressPopup.listOfAddresses}</p>
                                    <div className='d-flex flex-column gap-24'>
                                        <div className='d-flex gap-10 justify-content-end cursor-pointer' onClick={() => this.toggleAddModal()}>
                                            <img src={PATH?.img?.AddIcon} alt="" />
                                            <p className='fs-14 H-m text-lowercase'>{CONTENTS.profile.viewAddressPopup.addAddress}</p>
                                        </div>
                                        <div className=''>
                                            {customerAddress?.map((o, index) => (
                                                <div key={index} className='address_popup d-flex align-items-start'>
                                                    {isCheckout && (
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id={`flexRadioDefault${index}`}
                                                                checked={this.state.selectedShippingAddress?.id === o?.id}
                                                                onChange={() => this.handleRadioChange(o)}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className='d-flex flex-column gap-12 ps-2 flex-grow-1 H-l'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <p className='fs-14 H-l'>{o?.name}</p>
                                                            <div className='text-end d-flex gap-10 ps-5'>
                                                                <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.toggleAddModal(o, true)} />
                                                                <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.handleDelete(o?.id)} />
                                                            </div>
                                                        </div>
                                                        <p className='fs-14 H-l'>{CONTENTS.profile.viewAddressPopup.phone}{o?.phone}</p>
                                                        <p className='fs-14 H-l'>{o?.address1},{o?.address2}</p>
                                                        <p className='fs-14 H-1'>{o?.city}-{o?.zip}</p>
                                                        <p className='fs-14 H-1'>{o?.province},{o?.country}</p>
                                                        <a className='fs-14 H-m w-100 PDP_btn text-lowercase text-dark' onClick={() => this.handleDefault(o?.id)} disabled={o?.isDefault}>{o?.isDefault ? 'Default Address' : 'Mark as Default'}</a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {isCheckout && (
                                    <div className='d-flex gap-15 px-4 py-3 justify-content-start'>
                                        <ButtonDesign
                                            btnWidth={'profile_popup_close_btn'}
                                            className={'w-100 fs-16 H-cb py-12'}
                                            NoImg
                                            ButtonValue={CONTENTS.profile.viewAddressPopup.close}
                                            onClick={closeListAddressModal}
                                        />
                                        <ButtonDesign
                                            btnWidth={'profile_popup_proceed_btn button-primary'}
                                            className={'w-100 fs-16 H-cb py-12'}
                                            NoImg
                                            ButtonValue={CONTENTS.profile.viewAddressPopup.proceed}
                                            onClick={() => this.handleSubmit(defaultAddress)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="offcanvas-backdrop fade show"></div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProp = (state) => {
    return {
        customerList: Actions.getCustomer(state),
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
    };
};

export default withRouter(
    connect(mapStateToProp, {
        getCustomerList: Actions.getCustomerList,
        setDefaultAddress: Actions.setDefaultAddress,
        addressDelete: Actions.addressDelete,
        shippingAddressAdd: Actions.shippingAddressAdd,
        checkoutShippingLineUpdate: Actions.checkoutShippingLineUpdate,
    })(ListOfAddress)
);
