import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { isFieldEmpty, isValidName, isValidNumber } from '../../util/validation'
import { toast } from 'react-toastify';
import { CUSTOMER_ADDRESS_DELETE, DEFAULT_ADDRESS, SHIPPING_ADDRESS, CUSTOMER_LIST } from '../../Queries'
import AddAddress from '../AddAddress'
import EditAddress from '../EditAddress'
import routes from '../../config/routes'

class YourAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditModal: false,
            isAddModal: false,
            addressId: '',
            fields: {},
            errors: {},
            selectedAddress: false,
            selectedAddressId: undefined,
            selectedShippingAddress: undefined,
            showDefaultAddress: true
        }
    }
    componentDidMount() {
        let { token = {} } = this.props.token
        let { accessToken } = token
        let query = CUSTOMER_LIST(accessToken)
        this.props.getCustomerList(query)
    }

    onAddressDelete(id) {
        const { selectedAddress } = this.state
        let { token = {} } = this.props.token
        let { accessToken } = token
        const callback = (response) => {
            let { customerUserErrors = [] } = response && response.customerAddressDelete
            if (customerUserErrors[0] === undefined) {
                toast.success('Customer Address deleted Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                if (selectedAddress) {
                    this.setState({ selectedAddress: true, showDefaultAddress: false })
                } else {
                    this.setState({ selectedAddress: false, showDefaultAddress: true })
                }
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

        let input = {
            id: id,
            customerAccessToken: accessToken
        }
        this.props.addressDelete(CUSTOMER_ADDRESS_DELETE, input, callback)
    }
    onDefaultAddress(id) {
        let { token = {} } = this.props.token
        let { accessToken } = token
        const callback = (response) => {
            let { customerUserErrors = [] } = response && response.customerDefaultAddressUpdate
            if (customerUserErrors[0] === undefined) {
                toast.success('Default Address Created Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this.setState({ showDefaultAddress: true, selectedAddress: false })
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        let input = {
            addressId: id,
            customerAccessToken: accessToken
        }
        this.props.setDefaultAddress(DEFAULT_ADDRESS, input, callback)
    }
    editAddressModal(obj, id) {
        let { fields } = this.state
        let { firstName, lastName, address1, country, zip, phone, province, city } = obj
        fields['firstName'] = firstName
        fields['lastName'] = lastName
        fields['address'] = address1
        fields['country'] = country
        fields['zip'] = zip
        fields['phone'] = phone
        fields['city'] = city
        fields['province'] = province
        this.setState({ isEditModal: true, addressId: id, fields })
    }

    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'firstName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the first name'
                    this.setState({ errors })
                    return false
                } else if (fields[field].length > 20) {
                    errors[field] = 'First name allow only 20 Characters'
                    this.setState({ errors })
                    return false
                } else if (!isValidName(fields[field])) {
                    errors[field] = 'Alphabetic characters only allowed'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'lastName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the last name'
                    this.setState({ errors })
                    return false
                } else if (fields[field].length > 20) {
                    errors[field] = 'Last name allow only 20 Characters'
                    this.setState({ errors })
                    return false
                } else if (!isValidName(fields[field])) {
                    errors[field] = 'Alphabetic characters only allowed'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'address':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the address'
                    this.setState({ errors })
                    return false
                }
                break;

            case 'zip':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the zip code'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'phone':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the mobile number'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'province':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the state'
                    this.setState({ errors })
                    return false
                } else if (isValidNumber(fields[field])) {
                    errors[field] = 'Alphabetic characters only allowed'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'country':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please choose the country'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'city':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the city'
                    this.setState({ errors })
                    return false
                } else if (isValidNumber(fields[field])) {
                    errors[field] = 'Alphabetic characters only allowed'
                    this.setState({ errors })
                    return false
                }
                break;
            default:
                return null
        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }
    handleChange(field, e) {
        let { fields } = this.state
        fields[field] = e.target.value
        this.setState({ fields }, () => {
            this.handleValidation(field);
        });
    }

    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    addAddressModal() {
        this.setState({ isAddModal: true })
    }
    closeAddModal() {
        this.setState({ isAddModal: false, fields: {}, errors: {}, selectedAddress: false })
    }
    closeEditModal() {
        this.setState({ isEditModal: false, fields: {}, errors: {} })
    }
    handleSubmit(defaultAddress) {
        const { selectedAddress, selectedShippingAddress } = this.state
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        if (!defaultAddress) {
            toast.error('No address found', {
                position: toast.TOP_RIGHT
            })
        } else if (selectedAddress) {
            let { firstName, lastName, province, city, address1, phone, country, zip } = selectedShippingAddress || {}
            const callback = (response) => {
                let { checkout = {} } = (response && response.checkoutShippingAddressUpdateV2) || {}
                if (checkout === null) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.props.history.push(routes.PAYMENT)
                }
            }
            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        } else {
            let { firstName, lastName, province, city, address1, phone, country, zip } = defaultAddress || {}
            const callback = (response) => {
                let { checkout = {}, checkoutUserErrors } = (response && response.checkoutShippingAddressUpdateV2) || {}
                if (checkout === null) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else if (checkoutUserErrors?.length > 0) {
                    toast.error(`${checkoutUserErrors[0].code}`, {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.props.history.push(routes.PAYMENT)
                }
            }

            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        }
    }
    chooseAddress(id, obj) {
        this.setState({ selectedAddress: true, selectedAddressId: id, selectedShippingAddress: obj, showDefaultAddress: false })
    }
    chooseDefaultAddress(obj) {
        this.setState({ showDefaultAddress: true, selectedShippingAddress: obj, selectedAddress: false })
    }
    render() {
        let { isAddModal, isEditModal, addressId, fields, errors, showDefaultAddress, selectedAddress, selectedAddressId } = this.state
        const { customerList } = this.props.customerList || {}
        const { data } = customerList || {}
        let { addresses = {}, defaultAddress = {} } = (data && data.customer) || {}
        let customerAddress = addresses.edges && addresses.edges.filter(o => o.node && o.node.id !== defaultAddress.id)
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="col-12 s_heading s_addr_001">
                        <h2>Shipping Address</h2>
                    </div>

                    <div className="row y-addrs">
                        <div className="col-lg-4">
                            <div className="card tdt" onClick={() => this.addAddressModal()}>
                                <div className="card-body cursor-pointer" >
                                    <h3 ><b>+</b><br />
                                        Add Address
                                    </h3>
                                </div>

                            </div>
                        </div>
                        {
                            defaultAddress && (
                                <div className="col-lg-4">
                                    <div className={`card cursor-pointer${showDefaultAddress ? 'selected-address default' : ''} `} onClick={() => this.chooseDefaultAddress(defaultAddress)}>
                                        <p className="fs-14 f-m active">Default Address</p>
                                        <div className="card-body">
                                            <h3 className="px-2 mt-2">{defaultAddress && defaultAddress.name}</h3>
                                            <p className="px-2">
                                                {defaultAddress && defaultAddress.address1} <br />
                                                {defaultAddress && defaultAddress.city} <br />
                                                {defaultAddress && defaultAddress.province}<br />
                                                {defaultAddress && defaultAddress.zip} <br />
                                                {defaultAddress && defaultAddress.country} <br />
                                                Phone Number: {defaultAddress && defaultAddress.phone}
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <span className="cursor-pointer" onClick={() => this.editAddressModal(defaultAddress, defaultAddress && defaultAddress.id)}>Edit</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {customerAddress && customerAddress.map((obj, j) => {
                            let { name, address1, city, province, country, phone, zip, id } = obj.node || ''
                            return (
                                <React.Fragment key={j}>
                                    <div className="col-lg-4" key={j}>
                                        <div className={`card cursor-pointer ${selectedAddress && selectedAddressId === id ? 'selected-address default' : ''}`} onClick={() => this.chooseAddress(id, obj.node)}>
                                            <div className="card-body">
                                                <h3 className="px-2 mt-2">{name}</h3>
                                                <p className="px-2"> {address1}  <br />
                                                    {city} <br />
                                                    {province} <br />
                                                    {`${zip}`} <br />
                                                    {country} <br />
                                                    Phone Number: {phone}
                                                </p>
                                            </div>
                                            <div className="card-footer">
                                                <p><span onClick={() => this.editAddressModal(obj.node, id)}>Edit</span>  | <span onClick={() => this.onAddressDelete(id)}> Remove </span> | <span onClick={() => this.onDefaultAddress(id)}> Set as Default</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                    <div className="col-12 text-center py-5">
                        <button type="button" className="btn btn-gren" onClick={() => this.handleSubmit(defaultAddress)}>PROCEED NEXT</button>
                    </div>
                </div>
                <AddAddress
                    isAddModal={isAddModal}
                    closeAddModal={(e) => this.closeAddModal(e)}
                    handleChange={this.handleChange.bind(this)}
                    handleSubmitValidation={(e) => this.handleSubmitValidation(e)}
                    errors={errors}
                    fields={fields}
                />
                <EditAddress
                    isEditModal={isEditModal}
                    addressId={addressId}
                    fields={fields}
                    closeEditModal={(e) => this.closeEditModal(e)}
                    handleChange={this.handleChange.bind(this)}
                    handleSubmitValidation={(e) => this.handleSubmitValidation(e)}
                    errors={errors}
                />
            </React.Fragment >
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    checkoutGiftCardAdd: Actions.checkoutGiftCardAdd,
    shippingAddressAdd: Actions.shippingAddressAdd,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    addressDelete: Actions.addressDelete,
    setDefaultAddress: Actions.setDefaultAddress,
    getCustomerList: Actions.getCustomerList,
})(YourAddress));