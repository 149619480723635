import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/action'
import Loader from '../../component/Loader'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';


class ProductDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            perPage: 100,
            imageCount: 1,
            index: 0,
            sortBy: 'BEST_SELLING',
            reverse: true,
        }
    }

    // navCanvas(product) {
    //     this.props.history.push('/canvas/shirt?fabric')
    // }
    toTitleCase(string) {
        const str = string;
        const newStr = str?.split(' ')
            .map(w => w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase())
            .join(' ');
        return newStr
    }

    navCanvas1 = async (obj, fabricList, selectedProducts) => {
        const { title: titleName, product_type } = obj || {}
        const { collectionByHandle } = fabricList?.fabricList?.data || {}
        const removed = titleName?.slice(0, titleName?.indexOf('in '))
        let findProducts = obj?.variants?.find((j) => j?.sku === selectedProducts)
        let { title: variantTitle } = findProducts || {}
        let material = variantTitle?.split('/')
        let finalString = ''
        if (material) {
            let convertString = `${removed}in ${material && material[0]}${material?.length === 4 ? `/${material && material[1]}` : ""}`
            finalString = convertString?.toLowerCase()?.trim()
        } else {
            finalString = titleName?.toLowerCase()
        }
        const revealProduct = await collectionByHandle?.products?.edges?.find((checkFab) => checkFab?.node?.title?.toLowerCase() === finalString)
        let { id, variants } = revealProduct?.node || {}
        let { node } = (variants && variants?.edges && variants?.edges[0]) || {}
        let { id: lineItemId, priceV2 } = node || {}
        let { images, tags, description } = obj
        const tag = tags?.split(',')
        const titleCase = this.toTitleCase(finalString)
        const selectedProductt = this.updateSelectedFabric(titleCase, id, tag, description, lineItemId, images, priceV2?.amount, priceV2?.currencyCode, selectedProducts)
        switch (product_type?.toLowerCase()) {
            case 'pant':
                await this.props.updateSelectedPantFabric(selectedProductt)
                this.props.history.push('/canvas/pant?fabric')
                break;
            case 'suit':
                await this.props.updateSelectedSuitFabric(selectedProductt)
                this.props.history.push('/canvas/suit?fabric')
                break;
            case 'blazer':
                await this.props.updateSelectedBlazerFabric(selectedProductt)
                this.props.history.push('/canvas/blazer?fabric')
                break;
            case 'vest':
                await this.props.updateVestFabric(selectedProductt)
                this.props.history.push('/canvas/vests?fabric')
                break;
            default:
                await this.props.updateShirtFabric(selectedProductt)
                this.props.history.push('/canvas/shirt?fabric')
                break;
        }
    }
    componentDidMount() {
        const { id } = this.props.match.params
        let parts = id?.split('/');
        let shopifyId = parts[parts.length - 1];
        this.props.pdpDetails(shopifyId)

    }

    updateSelectedFabric(name, productId, tags, description, id, images, amount, currencyCode, selectedColorCode) {
        let fabrics = {
            name,
            productId,
            tags,
            description,
            changeFabrics: true,
            id,
            images,
            amount,
            currencyCode,
            selectedColorCode
        }
        return fabrics
    }

    changeColor(v) {
        this.props.selectedProducts(v?.sku)
    }
    handleChange(e) {
        this.setState({ index: e?.index + 1, imageCount: e?.index + 1 })
    }
    componentWillUnmount() {
        if (this.props.history.location.search !== "?fabric") {
            this.props.selectedProducts('')
        }
    }

    _renderActiveGroupImage(params, selectedProducts) {
        const { images } = params || {}
        const filteredObjs = images?.filter((obj) => obj?.alt?.includes(selectedProducts));
        const { pdpDetails, isLoading } = this.props.getProductsDetails
        const { product } = pdpDetails || {}
        const price = product?.variants?.find((o) => o?.sku == selectedProducts)
        const { title } = product || ''
        const slicedName = title?.slice(0, title?.indexOf('in '))

        return (
            <React.Fragment>
                <div className='row PDP_fabric_img pt-lg-3 m-0'>
                    <div className='col-8 detail_page_img'>
                        <LazyLoad className="w-100 PDP_fabric_img_col">
                            <img src={filteredObjs?.[1]?.src} className='PDP_fabric_col_img' alt="product-detail-page" />
                        </LazyLoad>
                        {/* <p className='fs-24 H-l pt-2 mobile_display'>{slicedName}</p> */}
                    </div>
                    <div className='PDP_container_mobile col-4 PDP_category_images detail_page_img_col d-flex flex-column'>
                        <div className=' mobile_pt-44 d-flex flex-column'>
                            <div className='pdp_swap_img'>
                            </div>
                            {/* {filteredObjs?.map((o) => {
                            return (
                                <img src={o?.src} className='detail_first_img' alt="product-detail-page" />

                            )
                        })} */}
                            {filteredObjs &&
                                <LazyLoad className="w-100">
                                    <img src={filteredObjs[0]?.src} className='detail_first_img ' alt="product-detail-page" />
                                </LazyLoad>
                            }
                            <p className='H-m fs-14 text-start mb-2 PDP_mobile_fabric_p'>{CONTENTS.pdp.fabricDetail}</p>
                            <p className='H-l fs-14 mb-0' dangerouslySetInnerHTML={{ __html: product?.body_html }}></p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { fabricList } = this.props
        const { pdpDetails, selectedProducts, isLoading } = this.props.getProductsDetails
        const { product } = pdpDetails || {}
        const price = product?.variants?.find((o) => o?.sku == selectedProducts)
        const { title } = product || ''
        const slicedName = title?.slice(0, title?.indexOf('in '))
        const { isPant, isShirt, isBlazer, isSuit, isVest } = this.props
        const { location } = this.props;
        const currentType = location?.pathname
        let dressType = ''
        const getCustomTypeData = () => {
            let type = currentType.split("/")[1]
            switch (type) {
                case 'suit':
                    dressType = 'suit'
                    break;
                case 'shirt':
                    dressType = 'shirt'
                    break;
                case 'pant':
                    dressType = 'pant'
                    break;
                case 'vest':
                    dressType = 'vest'
                    break;
                default:
                    dressType = 'blazer'
                    break;
            }
            return dressType
        }
        return (
            <React.Fragment>
                {isLoading && <Loader />}
                <div className='PDP_container flex-grow-1'>
                    <div className='container-lg mx-auto row row_margin_top product_detail_page p-0'>
                        <div className='col-lg-4  PDP_container_mobile PDP_padding_right'>
                            <div className=' pt-44'>
                                {/* <p className='f-m fs-14 pdp_back_text d-lg-block d-none text-end'
                                onClick={() => this.props.history.goBack()}><span className='text-decoration-underline  cursor-pointer'>Back</span></p> */}
                                <div className='d-flex justify-content-between align-items-baseline PDP_header_detail'>
                                    <p className='fs-14 H-l pdp_fabric_name m-0 text-lowercase'>{slicedName}in{" "}{price?.option1}</p>
                                    <p className='currency_amount fs-14 m-0'>
                                        {/* <i className="bi bi-currency-euro fs-17"></i> */}
                                        <span className='fs-14 H-l ps-3'>{price?.currencyCode} {price?.price}</span>
                                    </p>
                                </div>
                                <div className='PDP_hr_line'></div>
                                <div className='mt-3'>
                                    <p className='fs-14 H-l mb-2 text-lowercase'>{CONTENTS.pdp.colorName}<span className='ms-2 H-l fs-14'>{price?.option1}</span></p>
                                    <div className='d-flex flex-wrap gap-y-3'>
                                        {product && product.variants?.map((v, index) => (
                                            <div className='outline_round cursor-pointer' onClick={() => this.changeColor(v)} key={index}>
                                                <div className='color_select select1'
                                                    onMouseOver={() => this.changeColor(v)}
                                                    style={{
                                                        background: `${v.option3}`,
                                                    }}
                                                ></div>
                                            </div>
                                            // <div className="cursor-pointer"
                                            //     style={{
                                            //         background: `${v.option3}`,
                                            //         transform: "translate(-50%, -50%)",
                                            //         width: "16px", height: "16px", marginLeft: "10px",
                                            //         borderRadius: "50px"
                                            //     }}
                                            //     onMouseOver={() => this.changeColor(v)}
                                            //     onClick={() => this.changeColor(v)}
                                            // >
                                            //     <span >
                                            //     </span>
                                            // </div>
                                        ))}
                                        {/* <div className='outline_round'><div className='color_select select1'></div></div>
                                    <div className='outline_round'><div className='color_select select2'></div></div>
                                    <div className='outline_round active'><div className='color_select select3 active'></div></div>
                                    <div className='outline_round'><div className='color_select select1'></div></div>
                                    <div className='outline_round'><div className='color_select select2'></div></div>
                                    <div className='outline_round'><div className='color_select select3 active'></div></div> */}
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-2 mt-lg-3 mt-0 py-lg-0 py-4 w-100 align-items-center'>
                                    {/* <ButtonDesign
                                    btnWidth={'w-100'}
                                    className={'w-100 fs-18 H-cb button-primary py-12'}
                                    NoImg
                                    ButtonValue={'customize'}
                                /> */}
                                    <button className='fs-16 H-cb button-primary py-12 mt-3 w-100 PDP_btn'
                                        type="button" onClick={() => this.navCanvas1(product, fabricList, selectedProducts)}>{CONTENTS.pdp.customize} {getCustomTypeData()}</button>

                                    {/* offcanvas start  */}
                                    {/* <button className='PDP_btn mt-3 w-50 fs-16 H-l'
                                        type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvassize" >Select your Size</button> */}
                                    <div className='offcanvas offcanvas-end measurement_offcanvas' id="offcanvassize">
                                        <div className='overflow-auto h-100'>
                                            <a className='measurement_btn_close mx-4 mt-3 mb-lg-0 mb-2 d-inline-block' href='#' data-bs-dismiss="offcanvas">
                                                <img src={PATH?.img?.ExitPopupIcon} className='PDP_exit_popup_img cursor-pointer' alt="exit-popup" data-bs-dismiss="offcanvas" />
                                                {/* <i className="bi bi-x fs-19 text_black"></i> */}
                                                {/* <i className="bi bi-chevron-left fs-18"></i> */}
                                            </a>
                                            {/* size chart */}
                                            {/* <div className="measure_content_scroll">
                                                <p className='fs-22 H-cb text-center pb-lg-2 pb-4'>Size chart</p>
                                                <div className='buttons_align margin_align mb-3'>
                                                    <button className='btn common_btn measurement_btn size_chart_btn'><span>Regular</span><span className='text_grey'>168 - 186 cm</span></button>
                                                    <button className='btn common_btn measurement_btn size_chart_btn active_btn'><span>Long</span><span className='text_grey'>&#62;168 cm</span></button>
                                                </div>
                                                <div className='size_chart_margin pt-4 pb-3 d-flex justify-content-between'>
                                                    <div>
                                                        <p className='fs-15 H-l mb-1 text-lg-start text-center'>Extra Slim Fit</p>
                                                        <p className='fs-12 text_grey m-0 text-lg-start text-center letter-spacing H-l'>Shirt measurements</p>
                                                    </div>
                                                    <div className='d-flex gap-12'>
                                                        <img src={PATH?.img?.PdpPopupLeftArrowIcon} alt="" className='pdp_icon' />
                                                        <img src={PATH?.img?.PdpPopupRightArrowIcon} alt="" className='pdp_icon' />
                                                    </div>
                                                </div>
                                                <div className='size_chart_margin me-0 mt-1 table_class'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className='buttons_align d-flex align-items-center mt-0'>
                                                                        <span className='H-l fs-14'>cm</span>
                                                                        <div><i className="bi bi-toggle2-off toggle_icon size_chart_toggle"></i></div>
                                                                        <span className='H-l fs-14'>in</span>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <p>37L</p>
                                                                    <p className='H-l fs-13'>S</p>
                                                                    </th>
                                                                <th> <p>38L</p>
                                                                    <p className='H-l fs-13'>M</p></th>
                                                                <th> <p>37L</p>
                                                                <p className='H-l fs-13'>L</p></th>
                                                                <th> <p>39L</p>
                                                                <p className='H-l fs-13'>XL</p></th>
                                                                <th> <p>40L</p>
                                                                <p className='H-l fs-13'>S</p></th>
                                                                <th> <p>37L</p>
                                                                <p className='H-l fs-13'>S</p></th>
                                                                <th> <p>39L</p>
                                                                <p className='H-l fs-13'>S</p></th>
                                                                <th> <p>36L</p>
                                                                <p className='H-l fs-13'>M</p></th>
                                                                <th> <p>37L</p>
                                                                <p className='H-l fs-13'>XXL</p></th>
                                                                <th> <p>39L</p>
                                                                <p className='H-l fs-13'>XS</p></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Chest width</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Waist width</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Shoulder width</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Chest width</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text_grey fs-14 H-l'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Waist width</td>
                                                                <td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                                <td>36.5</td><td>35.4</td>
                                                                <td>38.5</td>
                                                                <td>36.5</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='pt-4 pb-5 px-4'>
                                                    <div className='mx-3 py-4 text-center'>
                                                        <p className='fs-20 H-m mb-1'>We’re here to help</p>
                                                        <p className='fs-13 mb-1 H-l letter-spacing'>Still unsure about your size?</p>
                                                    </div>
                                                    <div className='d-flex flex-column measure_contact_align px-lg-4 px-3 mx-2'>
                                                        <div className='row align-items-baseline measure_contact py-3 px-3'>
                                                            <div className='col-1 p-0'><img src={whatsapp} className='whatsapp_icon' alt='whatsapps' /></div>
                                                            <div className='col-11 pe-2'>
                                                                <p className='fs-16 H-m mb-1'>Whatsapp</p>
                                                                <p className='text_grey fs-12 mb-1 H-l letter-spacing'>Reach us on <br />
                                                                    +66 98 765 2345 for free</p>
                                                            </div>
                                                        </div>
                                                        <div className='row align-items-baseline measure_contact py-3 px-3'>
                                                            <div className='col-1 p-0'><i className="bi bi-envelope fs-20"></i></div>
                                                            <div className='col-11 pe-2'>
                                                                <p className='fs-16 H-m mb-1'>Email</p>
                                                                <p className='text_grey fs-12 mb-1 H-l letter-spacing'>Send us a mail on <span className='text-decoration-underline'>service@paulsfashion.com</span> We will reply within 8 hours</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* Instruction */}
                                            <div className="measure_content_scroll">
                                                <p className='text_grey text-center H-l fs-13 mb-1'>How to measure</p>
                                                <p className='fs-22 H-cb text-center mb-4'>Chest width</p>
                                                <div className='d-flex justify-content-center flex-column measurement_info_align px-4 pb-4 gap-30'>
                                                    <div className='text-center'>
                                                        <div className='measurement_img_align mb-3'>
                                                            <img src={PATH?.img?.MesaurementPopupImg} alt='measure_info' />
                                                        </div>
                                                        <p className='H-m fs-14 mb-2'>Chest</p>
                                                        <p className='text_grey H-l fs-12'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <div className='measurement_img_align mb-3'>
                                                            <img src={PATH?.img?.MesaurementPopupImg} alt='measure_info' />
                                                        </div>
                                                        <p className='H-m fs-14 mb-2'>Chest</p>
                                                        <p className='text_grey H-l fs-12'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <div className='measurement_img_align mb-3'>
                                                            <img src={PATH?.img?.MesaurementPopupImg} alt='measure_info' />
                                                        </div>
                                                        <p className='H-m fs-14 mb-2'>Chest</p>
                                                        <p className='text_grey H-l fs-12'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* select your size 2 */}
                                            {/* <div className="measure_content_scroll">
                                            <p className='fs-18 f-sb text-center m-0 pb-2'>Select your size</p>
                                            <p className='fs-13 text-center'>Pick the size you usually wear.</p>
                                            <div className='buttons_align margin_align'>
                                                <button className='btn common_btn measurement_btn border-0'><span>Regular</span><span className='text_grey'>168 - 186 cm</span></button>
                                                <button className='btn common_btn measurement_btn border-0'><span>Long</span><span className='text_grey'>&#62;168 cm</span></button>
                                            </div>
                                            <div className='d-flex flex-column row-gap-3 px_measure_values'>
                                                <div className='row m-0'>
                                                    <div className='col-2 align-self-center p-0'>
                                                        <p className='fs-15 text_grey m-0'>S</p>
                                                    </div>
                                                    <div className='col-10 d-flex align-self-center p-0 measure_size_align'>
                                                        <div className='col-6'>
                                                            <div className='measure_data active_measure'>
                                                                <p>44</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='measure_data'>
                                                                <p>44</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                            {/* select your size 3 */}
                                            {/* <div className="measure_content_scroll">
                                                <p className='fs-22 H-cb text-center m-0 pb-2'>Select your size</p>
                                                <p className='fs-13 text-center H-l'>Pick the size you usually wear.</p>
                                                <div className='buttons_align margin_align'>
                                                    <button className='btn common_btn measurement_btn border-0'><span>Short</span><span className='text_grey'>&#60; 168 cm</span></button>
                                                    <button className='btn common_btn measurement_btn border-0'><span>Regular</span><span className='text_grey'>168 - 186 cm</span></button>
                                                    <button className='btn common_btn measurement_btn border-0'><span>Long</span><span className='text_grey'>&#62; 168 cm</span></button>
                                                </div>
                                                <div className='d-flex flex-column row-gap-3 px_measure_values'>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>XS</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>46</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data active_measure text-center'>90</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>90</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>S</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>46</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>90</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>90</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>M</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>48</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>94</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>94</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>L</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>50</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>XL</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>50</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>
                                                                <div className='col-3 align-self-center p-0'>
                                                                    <p className='fs-15 m-0 H-l'>XXL</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>50</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <p className='measure_data text-center'>98</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* select your size customize */}
                                            {/* <div className="measure_content_scroll">
                                            <p className='fs-18 f-sb text-center m-0 pb-2'>Size measurements</p>
                                            <p className='fs-13 text-center measurement_para_align mt-1'>If a standard size fits you perfectly, you can skip this step.</p>
                                            <div className='buttons_align d-flex align-items-center justify-content-center'>
                                                <span className='text_grey'>cm</span>
                                                <div><i className="bi bi-toggle2-off toggle_icon"></i></div>

                                                //toggle 2
                                                <div className="form-check form-switch d-inline-block form_switch_measure">
                                                    <input className="form-check-input form_check_measure" type="checkbox" id="flexSwitchCheckDefault" />
                                                </div>

                                                <span className='text_grey'>in</span>
                                            </div>
                                            <div className='d-flex flex-column custom_measurement mt-lg-3 mt-2 measure_scroll_size'>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <p className='col-6 text_grey m-0 cursor-pointer'><i className="bi bi-info-circle-fill me-1 measure_info fs-16"></i>Collar around</p>
                                                    <div className='col-6'>
                                                        <div className='custom_measure'>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-dash-lg"></i></p>
                                                            <p className='fs-15'>21</p>
                                                            <p className='fs-14 cursor-pointer'><i className="bi bi-plus-lg"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                        {/* <div className='d-flex gap-2 px-4 py-3 measure_offcanvas_bottom_btns'> */}
                                        <div className='d-flex gap-15 px-4 py-3 address_popup_btn justify-content-start'>
                                            <ButtonDesign
                                                btnWidth={'pdp_popup_close_btn'}
                                                className={'w-100 fs-14 H-cb py-12'}
                                                NoImg
                                                ButtonValue={'size chart'}
                                            />
                                            <ButtonDesign
                                                btnWidth={'pdp_popup_proceed_btn button-primary'}
                                                className={'w-100 fs-14 H-cb py-12'}
                                                NoImg
                                                ButtonValue={'add to bag'}
                                            />
                                            {/* <button className='btn common_btn secondary_btn w-50'
                                                type="button">Size chart</button>
                                            <button className='btn common_btn primary_btn w-50'
                                                type="button" >Add to bag</button> */}
                                        </div>
                                    </div>
                                    {/* offcanvas end  */}

                                </div>
                                <div className='d-flex flex-column gap-10 mt-5'>
                                    <div className='fs-14 H-l text-lowercase'>
                                        {CONTENTS.pdp.section1.specifications}
                                    </div>
                                    <div className='pb-2'>
                                        <div className='row align-items-center mt-2'>
                                            <p className='fs-14 m-0 d-flex align-items-center col-lg-5 col-5 H-l text-lowercase'><i className="bi bi-paint-bucket fs-17 pe-2"></i><span>{CONTENTS.pdp.section1.color}</span></p>
                                            <p className='fs-14 m-0 H-l col-lg-7 col-7 H-l text-lowercase'>{price?.option1}</p>
                                        </div>
                                        <div className='row align-items-center mt-2'>
                                            <p className='fs-14 m-0 d-flex align-items-center col-lg-5 col-5 H-l text-lowercase'><i className="bi bi-check2-square fs-17 pe-2"></i>{CONTENTS.pdp.section1.productCode}</p>
                                            <p className='fs-14 m-0 H-l col-lg-7 col-7 H-l text-lowercase'>{price?.sku}</p>
                                        </div>
                                        <div className='row align-items-center mt-2'>
                                            <p className='fs-14 H-l m-0 d-flex align-items-center col-lg-5 col-5 text-lowercase'><i className="bi bi-aspect-ratio fs-17 pe-2"></i>{CONTENTS.pdp.section1.material}</p>
                                            <p className='fs-14 H-l m-0 H-l col-lg-7 col-7 text-lowercase'>{price?.option2}</p>
                                        </div>
                                        <div className='row align-items-center mt-2'>
                                            <p className='fs-14 H-l m-0  d-flex align-items-center col-lg-5 col-5 text-lowercase'><i className="bi bi-scissors fs-17 pe-2"></i>{CONTENTS.pdp.section1.cut}</p>
                                            <p className='fs-14 H-l m-0 H-l col-lg-7 col-7 text-lowercase'>{price?.option2}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="accordion accordion-flush mt-lg-4 mt-0 " id="accordionFlushExample">
                                <div className="accordion-item  py-lg-2 py-1">
                                    <h2 className="accordion-header " id="flush-headingOne">
                                        <button className="accordion-button collapsed btn_fs px-0 py-2 PDP_accordion H-l fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            Specifications
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse collapse_show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className='pb-3'>
                                            <div className='row align-items-center mt-2'>
                                                <p className='fs-14 m-0 text_grey d-flex align-items-center col-lg-5 col-5 H-l'><i className="bi bi-paint-bucket fs-17 pe-2"></i><span>Color</span></p>
                                                <p className='fs-14 m-0 f-m col-lg-7 col-7 H-l'>{price?.option1}</p>
                                            </div>
                                            <div className='row align-items-center mt-2'>
                                                <p className='fs-14 m-0 text_grey d-flex align-items-center col-lg-5 col-5 H-l'><i className="bi bi-check2-square fs-17 pe-2"></i>Product Code</p>
                                                <p className='fs-14 m-0 f-m col-lg-7 col-7 H-l'>{price?.sku}</p>
                                            </div>
                                            <div className='row align-items-center mt-2'>
                                                <p className='fs-14 H-l m-0 text_grey d-flex align-items-center col-lg-5 col-5'><i className="bi bi-aspect-ratio fs-17 pe-2"></i>Material</p>
                                                <p className='fs-14 H-l m-0 f-m col-lg-7 col-7'>{price?.option2}</p>
                                            </div>
                                            <div className='row align-items-center mt-2'>
                                                <p className='fs-14 H-l m-0 text_grey d-flex align-items-center col-lg-5 col-5'><i className="bi bi-scissors fs-17 pe-2"></i>Cut</p>
                                                <p className='fs-14 H-l m-0 f-m col-lg-7 col-7'>{price?.option2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item py-lg-2 py-1 d-none">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed btn_fs px-0 PDP_accordion fs-16 H-l" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            Description
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse collapse_show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className='pb-3'>
                                            <p className='text_grey mb-0' dangerouslySetInnerHTML={{ __html: product?.body_html }}></p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className='col-lg-8 PDP_image_part p-0'>
                            {this._renderActiveGroupImage(product, selectedProducts)}
                        </div>
                    </div>
                    <div className='container plp_looks_section d-flex flex-column gap-44'>
                        <p className='text-uppercase H-cb fs-24 text-center pt-lg-3'>{CONTENTS.pdp.section2.Head}</p>
                        <div className="row">
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.FabricIcon} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.pdp.section2.head1}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.pdp.section2.content1}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4 pt-lg-0 pt-5'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.CustomSuit} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.pdp.section2.head2}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.pdp.section2.content2}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4 pt-lg-0 pt-5'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.MeasureIcon} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.pdp.section2.head3}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.pdp.section2.content3}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='row profile_part mt-5 px-3 d-lg-none align-items-center m-0'>
                        <div className='col-11 d-flex align-items-center pe-0'>
                            <img src={profile} width={70} height={70} alt="profile_img" />
                            <div className='ps-3'>
                                <p className='fs-16 f-sb mb-1'>We’re here to help</p>
                                <p className='fs-13 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                        <div className='col-1'><i className="bi bi-chevron-right fs-16"></i></div>
                    </div>
                    <div className='PDP_process_info mt-5'>
                        <p className='fs-17 f-sb text-center py-4'>How to get my lightweight jacket</p>
                        <div className='row m-0 row-gap-3'>
                            <div className='col-lg-4 text-center px-lg-4 px-2'>
                                <img src={fabric} className='PDP_info_icon' alt="fabric-icon" />
                                <p className='fs-14 f-sb pt-4'>Select the fabric</p>
                                <p className='fs-13 text_grey'>This kind of jacket is highly recommended for summer fabrics. Take a look at all of them.</p>
                            </div>
                            <div className='col-lg-4 text-center px-lg-4 px-2'>
                                <img src={blazer} className='PDP_info_icon' alt="fabric-icon" />
                                <p className='fs-14 f-sb pt-4'>Design your Unstructured Blazer</p>
                                <p className='fs-13 text_grey'>You can personalize everything from your jacket, even the details of the unlined stitching. Take a look at all the possibilities.</p>
                            </div>
                            <div className='col-lg-4 text-center px-lg-4 px-2'>
                                <img src={measure_tape} className='PDP_info_icon' alt="fabric-icon" />
                                <p className='fs-14 f-sb pt-4'>Introduce your measurements</p>
                                <p className='fs-13 text_grey'>So you can get the perfect lightweight unlined jacket.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className="container">
                    {isLoading && <Loader />}
                    <div className="gg pdp">
                        <div className='pdp_slider_counter'>
                            <span>{this.state?.imageCount}</span>/<span>{products?.length}</span>
                        </div>

                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">

                            <div className="carousel-inner test" >

                                <div className="carousel-item active">
                                    <Carousel pagination={false} onChange={(e) => this.handleChange(e)}>
                                        {products?.map((o) => (<img src={o?.src} className='img-fluid' alt="" />))}
                                    </Carousel>
                                </div>

                            </div>

                        </div>
                        <div>
                            <p className="text-center pt-3 font-weight-bold">{slicedName}in{" "}{price?.option1}</p>
                            <p className="text-center">{price?.price} SGD</p>
                            <div className="d-flex justify-content-center" >
                                {product && product.variants?.map((v) => (<div className="cursor-pointer"
                                    style={{
                                        background: `${v.option3}`,
                                        transform: "translate(-50%, -50%)",
                                        width: "16px", height: "16px", marginLeft: "10px",
                                        borderRadius: "50px"
                                    }}
                                    onMouseOver={() => this.changeColor(v)}
                                    onClick={() => this.changeColor(v)}
                                >
                                    <span >
                                    </span>
                                </div>))}
                            </div>
                            <button className='w-100 btn-design-now'
                                onClick={() => this.navCanvas1(product, fabricList, selectedProducts)}
                            >Design Now</button>
                        </div>
                    </div>
                </div>

                <div className="container p-sm-5">
                    <div className="pdp-title pt-3">Specifications</div>
                    <div>
                        <div className="pdp-spec mt-4">
                            <div className="row">
                                <div className='col-4'>
                                    <p className='text-left pdp_spc_titile'>
                                        <span>
                                            <img className="pdp_list_icon" src={require('../../asset/images/pdp_colour.png')} alt="" />
                                        </span>
                                        Color
                                    </p>
                                </div>
                                <div className='col-8'>
                                    <p className='text-left'>{price?.option1}</p>
                                </div>
                                <div className='col-4'>
                                    <p className='text-left pdp_spc_titile'>
                                        <span>
                                            <img className="pdp_list_icon" src={require('../../asset/images/pdp_barcode.png')} alt="" />
                                        </span>
                                        Code
                                    </p>
                                </div>
                                <div className='col-8'>
                                    <p className='text-left'>{price?.sku}</p>
                                </div>
                                <div className='col-4'>
                                    <p className='text-left pdp_spc_titile'>
                                        <span>
                                            <img className="pdp_list_icon" src={require('../../asset/images/pdp_textiles.png')} alt="" />
                                        </span>
                                        Material
                                    </p>
                                </div>
                                <div className='col-8'>
                                    <p className='text-left'>{price?.option2}</p>

                                </div>
                            </div>
                            <hr />
                            <div className="description">
                                <p className="d-title"><u>Description</u></p>
                                <p className="d-d" dangerouslySetInnerHTML={{ __html: product?.body_html }}></p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
}


const mapStateToProp = (state) => {
    return {
        fabricList: Actions.getFabrics(state),
        getProductsDetails: Actions.getPLP(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    pdpDetails: Actions.pdpDetails,
    getFabricList: Actions.getFabricList,
    selectedProducts: Actions.selectedProducts,
    updateShirtFabric: Actions.updateShirtFabric,
    updateSelectedBlazerFabric: Actions.updateSelectedBlazerFabric,
    updateSelectedPantFabric: Actions.updateSelectedPantFabric,
    updateSelectedSuitFabric: Actions.updateSelectedSuitFabric,
    updateVestFabric: Actions.updateVestFabric
})(ProductDetails));