import React from 'react'
import { withRouter } from 'react-router-dom'
import SubFooter from '../../component/SubFooter'
import { CONTENTS, SERVERIMG } from '../../globals/app-constraints'
import LazyLoad from 'react-lazyload';
import Faqs from '../../component/FAQ';

class Faqdata extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="p-0">
                    <div className='container mt-3 mb-5'>
                        <div className='exchange_contents px-lg-0 px-2 pb-1 pb-lg-5'>
                            <h1 className='exchange_head mt-lg-4 mt-3 mb-3 text-center fs-24 H-m'>{CONTENTS.faq.head}</h1>
                            <p className='fs-14 H-l text-center pb-4'>{CONTENTS.faq.para}</p>
                            <Faqs />
                        </div>
                    </div>
                </div>
                <SubFooter />
            </React.Fragment>
        )
    }
}

export default withRouter(Faqdata)