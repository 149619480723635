import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../redux/action';
import { ORDER_STATUS } from '../../Queries/orderStatus';
import moment from 'moment';
import { CONTENTS, PATH } from '../../globals/app-constraints';
import ButtonDesign from '../../component/button';

class OrderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailCollapse: false,
            showGeneralCollapse: true,
            showOrderCollapse: false,
            selectedOrder: null
        };
        this.componentRef = undefined;
    }

    _renderOrderDate(date) {
        return moment(date).format('DD-MM-YYYY');
    }

    _renderOrderStatus(finance, fulfil) {
        if (finance === 'PAID' && fulfil === 'UNFULFILLED') {
            return 'Ready for delivery';
        } else if (finance === 'PAID' && fulfil === 'FULFILLED') {
            return 'Track and Trace';
        } else {
            return 'Progressing';
        }
    }

    handleViewOrder = (order) => {
        this.setState({ selectedOrder: order });
        const modifiedUrl = order?.id?.replace(/\?/, '');
        this.props.getOrderList(ORDER_STATUS(order?.id))
    };

    renderOrderList() {
        const orders = this.props.orders?.edges || [];
        console.log("orders", orders);
        return (
            <div>
                <div className='pt-lg-4 pt-2 pb-lg-4 pb-2 m-0 row row-gap-4'>
                    {orders?.length > 0 ? (
                        orders?.map((orderObj, index) => {
                            const order = orderObj?.node;
                            return (
                                <div className="col-lg-4 profile-data-border" key={index}>
                                    <div className="profile_measurement px-3 py-3">
                                        <div className="d-flex justify-content-between">
                                            <p className="fs-16 H-l m-0">{order.name}</p>
                                            <div className='d-flex gap-10'>
                                                <img
                                                    src={PATH?.img?.EyeIcon}
                                                    className="profile-icon cursor-pointer"
                                                    alt="eye-icon"
                                                    onClick={() => this.handleViewOrder(order)}
                                                />
                                            </div>
                                        </div>
                                        <p className="H-l fs-14 m-0 pt-2 text-dark">{CONTENTS.profile.orderHistoryContent.lastUpdated} {order?.lastUpdated}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className="text-center f-m py-3">{CONTENTS.profile.orderHistoryContent.noOrder}</p>
                    )}
                </div>
            </div>
        );
    }

    renderOrderDetails() {
        const { selectedOrder } = this.state;
        if (!selectedOrder) return null;
        console.log("selectedOrder", selectedOrder);
        const { orderList } = this.props.getOrder || {};
        const {
            email,
            shippingAddress = {},
            orderNumber,
            processedAt,
            totalPrice,
            lineItems,
            financialStatus,
            successfulFulfillments,
            fulfillmentStatus,
        } = orderList?.data?.node || {};
        const { firstName, address1, province, city, country, zip, phone } = shippingAddress || {};
        console.log("getOrder", this.props.getOrder);
        return (
            <React.Fragment>
                <ButtonDesign
                    btnWidth={'gap-16 d-flex'}
                    className={'fs-16 H-cb py-12'}
                    ButtonValue={CONTENTS?.profile?.orderHistoryContent?.back}
                    buttonImgPath={PATH?.img?.GetMoreArrow}
                    ButtonarrowDesign={'buttonDesign'}
                    onClick={() => this.setState({ selectedOrder: null })}
                />
                <div className='d-flex align-items-center gap-24'>
                    <p className='H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.order}<span>#{orderNumber}</span></p>
                    <p className='H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.orderDate}<span>{this._renderOrderDate(processedAt)}</span></p>
                </div>
                <div className="row profile-order-status-align">
                    <div className='col-lg-8 profile-order-status ps-0'>
                        <ul className='px-0 py-3' id="progressbar">
                            <li className="step1">
                                <div className='process_state'></div>
                                <p className='H-l fs-13 text-lowercase'>{CONTENTS.profile.orderHistoryContent.Processing}</p>
                            </li>
                            <li className={`${((financialStatus === 'PAID' && fulfillmentStatus === 'UNFULFILLED') || ((financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED'))) ? 'active step2' : 'step2'}`}>
                                <div className='process_state'></div>
                                <p className='H-l fs-13 text-lowercase'>{CONTENTS.profile.orderHistoryContent.readyDelivery}</p>
                            </li>
                            {
                                successfulFulfillments?.length > 0 ? (
                                    <li className={`${(financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED') ? 'active step3' : 'step3'}`}>
                                        {successfulFulfillments?.map((o, index) => {
                                            return o?.trackingInfo.map((k) =>
                                                <span key={index}><a href={k?.url} target="blank" className="H-l fs-13 text-lowercase">Track and Trace</a></span>)
                                        })}
                                    </li>
                                ) : (
                                    <li className="step3">
                                        <div className='process_state notfilled process_not'></div>
                                        <p className="H-l fs-13 text-lowercase">{CONTENTS.profile.orderHistoryContent.trackTrace}</p>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="d-flex flex-column row-gap-3 w-100">
                            {lineItems?.edges?.map((o, i) => {
                                const { variant, quantity, title } = o?.node || {};
                                return (
                                    <div className='ordered_products' key={i}>
                                        <div className='row m-0 w-100'>
                                            <div className='col-6'>
                                                <div className='d-flex gap-2'>
                                                    <div className='ps-2'>
                                                        <p className='H-l fs-14 text-lowercase'>{title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3 pt-lg-0 pt-2'>
                                                <p className='H-l fs-14 text-lg-end'>{CONTENTS.profile.orderHistoryContent.qty} <span className='fs-15 f-m'>{quantity}</span></p>
                                            </div>
                                            <div className='col-3 pt-lg-0 pt-2'>
                                                <p className='H-cb fs-14 text-lg-end'>{quantity * variant?.price?.amount} {totalPrice?.currencyCode}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='col-lg-4 px-0 data-divider'>
                        <div className='d-flex flex-column gap-16'>
                            <div className='row'>
                                <p className='col-4 H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.name}</p>
                                <p className='col-8 H-l fs-14'>{firstName}</p>
                            </div>
                            <div className='row'>
                                <p className='col-4 H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.email}</p>
                                <p className='col-8 H-l fs-14'>{email}</p>
                            </div>
                            <div className='row'>
                                <p className='col-4 H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.phoneNumber}</p>
                                <p className='col-8 H-l fs-14'>{phone}</p>
                            </div>
                            <div className='row'>
                                <p className='col-4 H-l fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.address}</p>
                                <p className='col-8 H-l fs-14'>{address1} {city} {province}, <span className='d-block'>{country}-{zip}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column row-gap-2 pt-4 pb-lg-0 pb-5'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <p className='H-m fs-14 text-lowercase'>{CONTENTS.profile.orderHistoryContent.total}</p>
                        <p className='H-cb fs-24 ps-2'>{totalPrice?.amount} {totalPrice?.currencyCode}</p>
                    </div>
                </div>
                {/* <div className="px-2">
                    <div className='order_status_content order_tracking_content px-lg-3 px-2 py-lg-4 py-2'>
                        <div className='px-lg-0 px-2 py-4'>
                            <div className='row'>
                                <div className='row col-lg-6'>
                                    <div className='col-lg-5 col-6'>
                                        <h6 className='f-m'>Name</h6>
                                        <p className='f-l'>{firstName}</p>
                                    </div>
                                    <div className='col-lg-7 col-6'>
                                        <h6 className='f-m'>Email</h6>
                                        <p className='f-l text-break'>{email}</p>
                                    </div>
                                </div>
                                <div className='row col-lg-6 pt-lg-0 pt-3'>
                                    <div className='col-lg-5 col-6'>
                                        <h6 className='f-m'>Phone Number</h6>
                                        <p className='f-l'>{phone}</p>
                                    </div>

                                    <div className='col-lg-7 col-6'>
                                        <h6 className='f-m'>Address</h6>
                                        <p className='f-l'>{address1} {city} {province},
                                            <span className='d-block'>{country}-{zip}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className='px-0 py-3' id="progressbar">
                            <li className="step1">
                                <div className='process_state'></div>
                                <p>Processing</p>
                            </li>
                            <li className={`${((financialStatus === 'PAID' && fulfillmentStatus === 'UNFULFILLED') || ((financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED'))) ? 'active step2' : 'step2'}`}>
                                <div className='process_state'></div>
                                <p>Ready for Delivery</p>
                            </li>
                            {
                                successfulFulfillments?.length > 0 ? (
                                    <li className={`${(financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED') ? 'active step3' : 'step3'}`}>
                                        {successfulFulfillments?.map((o, index) => {
                                            return o?.trackingInfo.map((k) =>
                                                <span key={index}><a href={k?.url} target="blank">Track and Trace</a></span>)
                                        })}
                                    </li>
                                ) : (
                                    <li className="step3">
                                        <div className='process_state notfilled process_not'></div>
                                        <p>Track and Trace</p>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="d-flex flex-column row-gap-3">
                            {
                                lineItems?.edges?.length > 0 && lineItems?.edges?.map((o, i) => {
                                    let { variant, quantity, title } = o?.node || {};
                                    return (
                                        <div className='ordered_products px-lg-0 px-2 py-lg-3' key={i}>
                                            <div className='row m-0'>
                                                <React.Fragment>
                                                    <div className='col-lg-6'>
                                                        <div className='row'>
                                                            <div className='ps-2 col-12'>
                                                                <p className='f-m mb-2'>{title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 pt-lg-0 pt-2'>
                                                        <p className='f-l text-lg-end'>Qty: <span className='fs-15 f-m'>{quantity}</span></p>
                                                    </div>
                                                    <div className='col-lg-3 pt-lg-0 pt-2'>
                                                        <p className='f-sb text-lg-end'>{quantity * variant?.price?.amount} {totalPrice?.currencyCode}</p>
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='px-lg-0 px-2 py-4'>
                        <div className='d-flex flex-column row-gap-2'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <p className='f-sb fs-18 w-20 text-start'>Total</p>
                                <p className='f-sb fs-18 w-20 text-end'>{totalPrice?.amount}{totalPrice?.currencyCode}</p>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="accordion" id="accordionExample">
                    {successfulFulfillments && successfulFulfillments.map((o, i) => {
                        return (
                            <div className="card" key={i}>
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="/printcollapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => this.setState({ showDetailCollapse: !this.state.showDetailCollapse })}>
                                            Detailed View
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className={`collapse ${this.state.showDetailCollapse ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="/printaccordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><img src={require('../../asset/images/truck.png')} className="img-fluid" alt="icons" /></li>
                                            <li><b>Company</b><br />{o?.trackingCompany}</li>
                                            <li><b>Tracking Number</b><br />{o?.trackingInfo.map((k) => k.number)}</li>
                                            <li><a href={o?.trackingInfo?.map((k) => k.url)} target="blank">{o?.trackingInfo?.map((k) => k?.url)}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div> */}
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.selectedOrder ? this.renderOrderDetails() : this.renderOrderList()}
            </React.Fragment>
        );
    }
}

const mapStateToProp = (state) => {
    return {
        getOrder: Actions.getOrder(state),
    };
};

export default withRouter(connect(mapStateToProp, {
    getOrderList: Actions.getOrderList
})(OrderComponent));
