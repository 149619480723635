import { defaultFabricObject } from '../../../util/defaultFabricObject';
import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedShirtFabric: defaultFabricObject(),
    showFabricList: false,
    products:''
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_SHIRT_FABRIC: {
            let { selectedShirtFabric } = state
            return {
                ...state,
                selectedShirtFabric: {
                    ...selectedShirtFabric,
                    ...action.data
                }
            };
        }
        case Actions.UPDATE_PRODUCT_AMOUNT: {
            return {
                ...state,
                selectedAmount: action.data.amount,
                selectedCurrencyCode: action.data.currencyCode
            };
        } case Actions.SET_DEFAULT_SHIRT_FABRIC: {
            return {
                ...state,
                selectedShirtFabric: defaultFabricObject(action.data),
            };
        } case Actions.ADMIN_UPDATE_SELECTED_FABRIC: {
            return {
                ...state,
                selectedShirtFabric: action.data,
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};