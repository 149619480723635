import React from 'react'
import { withRouter } from 'react-router-dom'
// import routes from '../../config/routes'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_BLAZER } from '../../config/api_endpoint'
// import Loader from '../Loader'
import { SUIT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { SUIT_IMAGES } from '../../util/Canvas_Measurement'
class BlazerSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: false,
            isShowFolded: true,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 14,
            back: 9,
            folded: 16,
            isShowCasual: false,
            selectedSuitCombination: {},
            selectedFabrics: null,
            selectedAccents: null
        }
    }

    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }

    onloadImages() {
        const { isShowFolded, folded } = this.state;
        if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 16 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedBlazerStyle: { selectedCombination }, activeChildMenu, blazerAccents } = props;
        let { selectedBlazerFabric } = props.blazerFabric || {}
        let { button, hole, thread, blazerInnerFabric,
            selectedBlazerLining, pocketSquareFabric, selectedPocketSquare, selectedButtonsThreads } = blazerAccents?.selectedAccents
        let blazerAccentsPath = S3BASE_URL_BLAZER + 'accents'
        let baseBlazerInnerPath = blazerAccentsPath + '/6756353671332/custom/'
        let basepath = S3BASE_URL_BLAZER + '7080752611492/' + detectBrowserBaseUrl() + '/'
        let blazerPocketPath = blazerAccentsPath + '/6756353671332/pocket/'
        if (selectedBlazerFabric?.productId !== 'undefined') {
            basepath = S3BASE_URL_BLAZER + convertAdminProductId(selectedBlazerFabric?.productId) + detectBrowserBaseUrl() + '/'
        }

        if (blazerInnerFabric) {
            let parts = blazerInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            if (selectedBlazerLining === 'custom') {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/custom/'
            } else if (selectedBlazerLining === 'unlined') {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/unlined/'
            } else {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/custom/'
            }
        }
        if (pocketSquareFabric) {
            let parts = pocketSquareFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            blazerPocketPath = blazerAccentsPath + '/' + shopifyId + '/pocket/'
        }
        let selectedCombinationObj = {
            foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            foldedSleeve: basepath + 'sleeve/front/sleeve.png',
            foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
            foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
            foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
            foldedCollar: basepath + 'collar/folded/other_collar.png',
            suitInner: baseBlazerInnerPath + 'suit_interior.png',
            suitBottomInner: baseBlazerInnerPath + 'suit_interior_bottom.png',
            suitPocketsSquare: selectedPocketSquare === 'without' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerPocketPath + 'pocket_square.png',
            foldedSuitButtons: blazerAccentsPath + '/buttons/folded/j8/single_one_button.png',
            foldedButtonThread: blazerAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
            foldedButtonHole: blazerAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
            foldedLapelsHole: blazerAccentsPath + 'threads/folded/notch_collar_hole/white.png',
        }

        let foldedButtonThread = ''
        let foldedButtonHole = ''
        let foldedLapelsHole = ''

        switch (selectedButtonsThreads) {
            case 'all':
                foldedButtonThread = blazerAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
                foldedButtonHole = blazerAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                break;
            case 'cuffs':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            case 'lapel':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                break;
            case 'default':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            default:
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
        }

        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                    }
                    break;
                case 'sleeve_buttons':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSleeve: basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination[key].key === 'mandarin' ? baseBlazerInnerPath + 'mandarin_interior.png' : baseBlazerInnerPath + 'suit_interior.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                        foldedButtonThread: foldedButtonThread,
                        foldedButtonHole: foldedButtonHole,
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination['style'].key === 'mandarin' ? baseBlazerInnerPath + 'mandarin_interior.png' : baseBlazerInnerPath + 'suit_interior.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        foldedLapelsHole: foldedLapelsHole,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            selectedSuitCombination: selectedCombination,
            selectedFabrics: selectedBlazerFabric,
            selectedAccents: blazerAccents?.selectedAccents
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedPosition !== prevProps.selectedPosition) || (this.props.selectedSuitStyle !== prevProps.selectedSuitStyle) || (this.props.selectedSuitFabric !== prevProps.selectedSuitFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    _renderMeasurement(measurements) {
        let selectedMeasurement = JSON.parse(measurements)
        let { productSizeType, key, value: measurementValue } = selectedMeasurement || {}
        return (
            <div className="col-lg-12">
                <h4>Measurements</h4>
                <span>Size :{key}</span> <span>{productSizeType}</span>
                <div className="table-responsive">
                    <table className="table text-center shirt-mm">
                        {SUIT_IMAGES()}
                        <tbody>
                            <tr>
                                <td>{measurementValue?.chest}</td>
                                <td>{measurementValue?.stomach}</td>
                                <td>{measurementValue?.shoulder}</td>
                                <td>{measurementValue?.sleeve}</td>
                                <td>{measurementValue?.shirtLength}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }
    render() {
        const { selectedCombination, selectedSuitCombination,
            selectedFabrics, selectedAccents } = this.state;
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = selectedCombination || {}
        const { style, fit, pocket, sleeve_buttons, back_style, breast_pocket, lapels, lapels_width } = selectedSuitCombination || {}
        const { thread, hole, button, fill, initials, font, liningFabricName, pocketFabricName } = selectedAccents || {}
        const { selectedMeasurement } = this.props.measurements || {}
        return (
            <React.Fragment>
                <div className="row m-0">
                    <div className="col-lg-6 order-1 order-sm-1 order-lg-2 verify-casual h-100">
                        <div className="col-12 text-right mb-4 mbl-shar-ico reset-icon">
                        </div>
                        {/* {isLoading && <Loader
                            isCanvas={true} />} */}

                        <div id="carouselExampleIndicators"
                            className="carousel slide text-center text-center"
                            data-interval="false" data-ride="carousel">
                            {selectedCombination && (
                                <div className='carousel-inner' data-interval="false">
                                    <div className={`carousel-item active`}>
                                        <div className="pi-shirt-containers">
                                            <div className={`pi_container pi-pant w-75 m-auto pi_shirt_cuff`}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedNormalFit.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedCollar.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_inner_suit"
                                                    src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_collar"
                                                    src={suitInner}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={suitBottomInner}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedSuitFit.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_lapels"
                                                    src={foldedLapelsHole}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitPocketsSquare}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedSleeve.replace('.png', detectBrowser())}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_hole"
                                                    src={foldedButtonHole}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_button"
                                                    src={foldedSuitButtons}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_thread"
                                                    src={foldedButtonThread}
                                                    alt='shirt' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2" >
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Styles</h4>
                                <p><b>Fabric : </b>{selectedFabrics?.name}</p>
                                <p>Suit Styles : {style && style.title}</p>
                                <p>Fit : {fit && fit.title}</p>
                                <p>Sleeve Button : {sleeve_buttons && sleeve_buttons.title}</p>
                                <p>Back Style : {back_style && back_style.title}</p>
                                <p>Pocket : {pocket && pocket.title}</p>
                                <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                                <p>Lapels : {lapels && lapels.title}</p>
                                <p>Lapels Width : {lapels_width && pocket.title}</p>
                            </div>
                            <div className="col-sm-6">
                                <h4>Blazer Accents</h4>
                                <p>Button : {button}</p>
                                <p>Hole : {hole}</p>
                                <p>Thread : {thread}</p>
                                {
                                    initials && (
                                        <>
                                            <p>Monogram color : {fill} </p>
                                            <p>Intials: {initials}</p>
                                            <p>Font: {font}</p>
                                        </>
                                    )
                                }
                                {liningFabricName && <p><b>Inner Fabric: {liningFabricName}</b></p>}
                                {pocketFabricName && <p><b>Pocket Fabric: {pocketFabricName}</b></p>}
                            </div>
                        </div>
                        {this._renderMeasurement(selectedMeasurement)}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
        blazerFabric: Actions.getSelectedBlazerFabric(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),
        measurements: Actions.getSelectedMeasurement(state)
    }
}

export default withRouter(connect(mapStateToProp, {
    updateSelectedSuitStyle: Actions.updateSelectedSuitStyle,
})(BlazerSection));
