const NAME = 'SELECTED_STYLE'

export const UPDATE_PANT_STYLE = `${NAME}/UPDATE_PANT_STYLE`;
export const EDIT_SELECTED_PANT_COMBINATION = `${NAME}/EDIT_SELECTED_PANT_COMBINATION`
export const ADMIN_UPDATE_PANT_STYLE = `${NAME}/ADMIN_UPDATE_PANT_STYLE`;


export const getSelectedPantStyle = store => store[NAME].pant

export const updateSelectedPantStyle = (key, data) => {
    return {
        type: UPDATE_PANT_STYLE,
        key,
        data
    }
}

export const editSelectedPantCombination = (data) => {
    return {
        type: EDIT_SELECTED_PANT_COMBINATION,
        data
    }
}

export const adminUpdateSelectedPantCombination = (data) => {
    return {
        type: ADMIN_UPDATE_PANT_STYLE,
        data
    }
}