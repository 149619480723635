import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getQuery } from '../../service';
export function* getFabricList({ query, key, isEdit }) {
  try {
    const response = yield getQuery(query);
    if (!isEdit) {
      if (key === 'shirt') {
        yield put(Actions.setDefaultShirtFabrics(response, key));
      } else if (key === 'pant') {
        yield put(Actions.setDefaultPantFabrics(response, key))
      } else if (key === 'suit') {
        yield put(Actions.setDefaultSuitFabrics(response, key))
      } else if (key === 'blazer') {
        yield put(Actions.setDefaultBlazerFabrics(response, key))
      } else if (key === 'vests') {
        yield put(Actions.setDefaultVestFabric(response, key))
      }
    }
    yield put(Actions.getFabricListSuccess(response));
  } catch (error) {
    yield put(Actions.getFabricListFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest(Actions.GET_FABRIC_LIST, getFabricList),
  ]);
}
