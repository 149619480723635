export const SHIPPING_LINE_UPDATE = `mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
    checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
      checkout {
        id
        webUrl
        lineItems(first: 10) {
           edges {
             node {
               title
               quantity
               customAttributes {
                           key 
                           value
               }
             }
           }
        }
        shippingAddress {
            firstName
            lastName
            city
            country
            province
            zip
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }`