import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { DISOCUNT_CODE, LAST_INCOMPLETE_CHECK_OUT, SHIPPING_LINE_UPDATE, CHECKOUT_CREATE } from '../../Queries'
import { toast } from 'react-toastify';

class Shipping extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            giftCode: '',
            webUrl: '',
            isChecked1: false,
            isChecked2: false,
            errors: ''
        }
    }
    componentDidMount() {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const callback = (response) => {
            let { checkout } = (response && response.checkoutShippingLineUpdate) || {}
            this.setState({ webUrl: checkout?.webUrl })
        }
        let input = {
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
            shippingRateHandle: "Standard"
        }
        this.props.checkoutShippingLineUpdate(SHIPPING_LINE_UPDATE, input, callback)
    }
    placeOrder() {
        this.setState({ errors: true })
    }
    giftCardAdd(e) {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const callback = (response) => {
            let { checkoutUserErrors } = response && response.checkoutDiscountCodeApplyV2
            if (checkoutUserErrors.length > 0) {
                let { code } = checkoutUserErrors[0]
                toast.error(`${code}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.success(`Discount Added Successfully`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                let { token = {} } = this.props.token
                let { accessToken } = token || {}
                this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
            }
        }

        let input = {
            discountCode: this.state.giftCode,
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id
        }
        this.props.checkoutDiscountAdd(DISOCUNT_CODE, input, callback)

    }
    componentWillUnmount() {
        this.props.checkoutCreate(CHECKOUT_CREATE)
    }
    handleGiftCodeChange(e) {
        let { value } = e.target
        this.setState({ giftCode: value })
    }
    navPayment(url) {
        window.open(url, '_self')
    }
    render() {
        let { checkOutList } = this.props
        let { webUrl } = this.state
        let { totalPrice, lineItemsSubtotalPrice, appliedGiftCards = [], availableShippingRates = {} } = checkOutList || {}
        let { amount, currencyCode } = lineItemsSubtotalPrice || {}
        let { shippingRates = {} } = availableShippingRates || {}
        let deliveryRate = (shippingRates && shippingRates.price) || {}
        let giftCard = appliedGiftCards && appliedGiftCards.map(i => i.amountUsed)
        return (
            <div className="col-lg-6 scnd">
                <table>
                    <tbody>
                    </tbody>
                </table>
                {/* <div className="form-group">
                    <label>Gift Code / Discount Code </label>
                    <div className="input-group mb-3 w-75">
                        <input type="text" className="form-control" value={giftCode} onChange={(e) => this.handleGiftCodeChange(e)} />
                        <div className="input-group-append">
                            <button className="btn btn-gren" type="button" id="button-addon2" onClick={() => this.giftCardAdd()}>Add</button>
                        </div>
                    </div>
                </div> */}
                <h4><b>Total</b></h4>
                <table className="shipp-crt border-0">
                    <tbody>
                        <tr>
                            <td>Subtotal</td>
                            <td>{amount} &nbsp; {currencyCode} </td>
                        </tr>
                        <tr>
                            <td>Delivery</td>
                            {
                                deliveryRate === 0 ? (
                                    <td>{deliveryRate} &nbsp; {currencyCode}</td>
                                ) : (
                                    <td>Free</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td>Amount to Pay</td>
                            <td><b>{totalPrice - giftCard} &nbsp;  {currencyCode} </b></td>
                        </tr>
                    </tbody>
                </table>

                <div className="col-12 text-center">
                    <button type="button" className="btn btn-gren" onClick={() => this.navPayment(webUrl)}>
                        <span className="nav-buttonlink">CONTINUE TO CHECKOUT </span>
                    </button>
                </div>
            </div>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        recentCheckout: Actions.checkout(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
    }
}

export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkoutDiscountAdd: Actions.checkoutDiscountAdd,
    checkoutShippingLineUpdate: Actions.checkoutShippingLineUpdate,
    checkoutCreate: Actions.checkoutCreate,
})(Shipping));