const NAME = 'SELECTED_ACCENTS'

export const UPDATE_BLAZER_ACCENTS = `${NAME}/UPDATE_BLAZER_ACCENTS`;
export const REMOVE_BLAZER_MONOGRAM = `${NAME}/REMOVE_BLAZER_MONOGRAM`;
export const EDIT_BLAZER_ACCENTS = `${NAME}/EDIT_BLAZER_ACCENTS`;

export const getSelectedBlazerAccent = store => store[NAME].blazer

export const updateSelectedBlazerAccent = (key, data) => {
    return {
        type: UPDATE_BLAZER_ACCENTS,
        key,
        data
    }
}

export const editSelectedBlazerAccent = (data) => {
    return {
        type: EDIT_BLAZER_ACCENTS,
        data
    }
}

export const removeBlazerMonogram = (data) => {
    return {
        type: REMOVE_BLAZER_MONOGRAM,
        data
    }
}