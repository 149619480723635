import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_SUIT } from '../../config/api_endpoint'
import Loader from '../Loader'
import { SUIT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { Constants } from '../../util/Constant'
import { PANT_IMAGES, SUIT_IMAGES } from '../../util/Canvas_Measurement'
class CanvasSuitSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: false,
            isShowFolded: true,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 16,
            back: 9,
            folded: 18,
            isShowCasual: false,
            selectedSuitCombination: {},
            selectedPosition: 'folded',
            selectedAccents: {},
            selectedSuitFabric: {}
        }
    }
    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }

    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 16 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 9 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 18 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedSuitStyle: { selectedCombination },
            activeChildMenu, selectedSuitAccents: { selectedAccents },
            selectedVestStyle: { selectedCombination: selectesVestCombination } } = props;
        const { selectedPosition } = state
        let { selectedSuitFabric } = props.selectedSuitFabric || {}
        let { productId } = selectedSuitFabric || {}
        let { button, hole, thread, selectedButtonsThreads,
            suitInnerFabric, selectedSuitLining, pocketSquareFabric, selectedPocketSquare
        } = selectedAccents || {}

        let suitBaseAccentsPath = S3BASE_URL_SUIT + 'accents'

        let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/'
        let basepath = S3BASE_URL_SUIT + '7079274250404/' + detectBrowserBaseUrl() + '/'
        let suitBasePocketPath = suitBaseAccentsPath + '/6756353671332/pocket/'
        let baseVestPath = S3BASE_URL_SUIT + '7079274250404/vests/' + detectBrowserBaseUrl() + '/'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_SUIT + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
            baseVestPath = S3BASE_URL_SUIT + convertAdminProductId(productId) + 'vests/' + detectBrowserBaseUrl() + '/'
        }
        if (suitInnerFabric) {
            let parts = suitInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            if (selectedSuitLining === 'custom') {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
            } else if (selectedSuitLining === 'unlined') {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/unlined/'
            } else {
                baseSuitInnerPath = suitBaseAccentsPath + '/' + shopifyId + '/custom/'
            }
        }
        if (pocketSquareFabric) {
            let parts = pocketSquareFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            suitBasePocketPath = suitBaseAccentsPath + '/' + shopifyId + '/pocket/'
        }
        let isShowFolded = true
        let isShowBack = false
        let isShowFront = false
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_SUIT + 'modal/front_modal.png',
            frontShirt: S3BASE_URL_SUIT + 'modal/front_shirt.png',
            frontTie: S3BASE_URL_SUIT + 'modal/tie.png',
            backModal: S3BASE_URL_SUIT + 'modal/back_modal.png',
            backShirt: S3BASE_URL_SUIT + 'modal/back_shirt.png',
            frontPant: basepath + 'pant/front_pant.png',
            backSuitFit: basepath + 'fit/back/normal_fit.png',
            frontSuitFit: basepath + 'style/normal/slim/notch/front/single_one_breasted.png',
            foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
            backPocket: basepath + 'pocket/back/double_welted.png',
            frontPocket: basepath + 'transparent/transparent.png',
            foldedPocket: basepath + 'transparent/transparent.png',
            frontBreastPocket: basepath + 'transparent/transparent.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            frontSleeve: basepath + 'sleeve/front/sleeve.png',
            backSleeve: basepath + 'sleeve/back/sleeves_button_0.png',
            foldedSleeve: basepath + 'sleeve/front/sleeve.png',
            backPant: basepath + 'pant/back_pant.png',
            foldedPant: basepath + 'pant/folded_pant.png',
            backStyle: basepath + 'vents/back/normal/ventless.png',
            frontNormalFit: basepath + 'fit/front/normal_fit_ventless.png',
            foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
            foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
            foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
            foldedCollar: basepath + 'collar/folded/other_collar.png',
            suitVests: basepath + 'vests/notch/single_breasted_six_button.webp',
            suitVestsButtons: suitBaseAccentsPath + '/suit_vests/buttons/bb2/single_3_buttons.png',
            suitInner: baseSuitInnerPath + 'suit_interior.png',
            suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
            suitPocketsSquare: selectedPocketSquare === 'without' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBasePocketPath + 'pocket_square.png',
            foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
            frontSuitButtons: suitBaseAccentsPath + '/buttons/front/j8/single_one_button.png',
            backSuitButtons: suitBaseAccentsPath + '/buttons/back/j8/two_button.png',
            backButtonThread: suitBaseAccentsPath + '/threads/back/two_button/button_center/white.png',
            backButtonHole: suitBaseAccentsPath + '/threads/back/two_button/button_hole/white.png',
            frontButtonThread: suitBaseAccentsPath + '/threads/front/single_1_button_thread/button_center/white.png',
            frontButtonHole: suitBaseAccentsPath + '/threads/front/single_1_button_thread/button_hole/white.png',
            foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
            foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
            foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
            frontLapelsHole: suitBaseAccentsPath + 'threads/front/notch_collar_hole/white.png',

        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }

        let backButtonThread = ''
        let backButtonHole = ''
        let frontButtonThread = ''
        let frontButtonHole = ''
        let foldedButtonThread = ''
        let foldedButtonHole = ''
        let foldedLapelsHole = ''
        let frontLapelsHole = ''

        switch (selectedButtonsThreads) {
            case 'all':
                backButtonThread = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_thread_url + `${thread}.png`
                backButtonHole = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_holes_url + `${hole}.png`
                frontButtonThread = suitBaseAccentsPath + selectedCombination['style'].front_button_thread_url + `${thread}.png`
                frontButtonHole = suitBaseAccentsPath + selectedCombination['style'].front_button_hole_url + `${hole}.png`
                foldedButtonThread = suitBaseAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
                foldedButtonHole = suitBaseAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                frontLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].front_button_hole_image_url + `${hole}.png`
                break;
            case 'cuffs':
                backButtonThread = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_thread_url + `${thread}.png`
                backButtonHole = selectedCombination['sleeve_buttons'].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['sleeve_buttons'].buttons_holes_url + `${hole}.png`
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            case 'lapel':
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                frontLapelsHole = selectedCombination['lapels'].key === 'shawl' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + selectedCombination['lapels'].front_button_hole_image_url + `${hole}.png`
                break;
            case 'default':
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            default:
                backButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                backButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonThread = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                frontLapelsHole = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
                break;
        }


        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        backSuitFit: basepath + selectedCombination[key].back_image_url,
                        frontNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/front/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT,
                        foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                    }
                    break;
                case 'sleeve_buttons':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSleeve: basepath + selectedCombination[key].front_image_url,
                        backSleeve: basepath + selectedCombination[key].back_image_url,
                        foldedSleeve: basepath + selectedCombination[key].folded_image_url,
                        backSuitButtons: selectedCombination[key].key === 'zero' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + '/buttons/back/' + button + '/' + selectedCombination[key].buttons_image_url,
                        backButtonThread: backButtonThread,
                        backButtonHole: backButtonHole
                    }
                    break;
                case 'back_style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        backStyle: selectedCombination['fit'].key === 'slim_fit' ? basepath + selectedCombination[key].back_slim_fit_image_url : basepath + selectedCombination[key].back_normal_fit_image_url
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/front/' + selectedCombination[key].front_image_url,
                        foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination[key].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination[key].button_image_url,
                        frontButtonThread: frontButtonThread,
                        frontButtonHole: frontButtonHole,
                        foldedButtonThread: foldedButtonThread,
                        foldedButtonHole: foldedButtonHole,
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/front/' + selectedCombination['style'].front_image_url,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination['style'].key === 'mandarin' ? baseSuitInnerPath + 'mandarin_interior.png' : baseSuitInnerPath + 'suit_interior.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                        foldedLapelsHole: foldedLapelsHole,
                        frontLapelsHole: frontLapelsHole,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].front_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/front/' + selectedCombination['style'].front_image_url,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontSuitButtons: suitBaseAccentsPath + '/buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                    }
                    break;

                default:
                    break;
            }
        })
        let suitVestsButton = ''

        if (props.suitVest) {
            suitVestsButton = selectesVestCombination['style'].key === 'double_four_breasted' || selectesVestCombination['style'].key === 'double_six_breasted' ? suitBaseAccentsPath + '/' + SUIT_TRANSPARENT : suitBaseAccentsPath + '/suit_vests/buttons/' + button + '/' + selectesVestCombination['style'].button_image_url
        } else {
            suitVestsButton = suitBaseAccentsPath + '/' + SUIT_TRANSPARENT
        }
        Object.keys(selectesVestCombination).forEach((key) => {
            switch (key) {
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        suitVests: props.suitVest ? basepath + SUIT_TRANSPARENT : baseVestPath + selectesVestCombination['lapels'].key + '/' + selectesVestCombination[key].vest_image_url,
                        suitVestsButtons: suitVestsButton
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        suitVests: props.suitVest ? basepath + SUIT_TRANSPARENT : baseVestPath + selectesVestCombination[key].key + '/' + selectesVestCombination['style'].vest_image_url
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
            selectedSuitCombination: selectedCombination,
            selectedSuitFabric,
            selectedAccents
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedPosition !== prevState.selectedPosition) || (this.props.selectedSuitStyle !== prevProps.selectedSuitStyle) || (this.props.selectedSuitFabric !== prevProps.selectedSuitFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    onCanvasPositionChanged(selectedPosition) {
        this.setState({ selectedPosition })
    }
    showFront() {
        this.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }

    _renderSuitStandardMeasurements(measurementValue) {
        const { pantFields, suitFields } = measurementValue || {}
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}

                    <tbody>
                        <tr>
                            <td>{suitFields?.chest}</td>
                            <td>{suitFields?.stomach}</td>
                            <td>{suitFields?.shoulder}</td>
                            <td>{suitFields?.sleeve}</td>
                            <td>{suitFields?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{pantFields?.waist}</td>
                            <td>{pantFields?.hip}</td>
                            <td>{pantFields?.crotch}</td>
                            <td>{pantFields?.thigh}</td>
                            <td>{pantFields?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }

    _renderSuitCustomMeasurement(measurementValue) {
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}

                    <tbody>
                        <tr>
                            <td>{measurementValue?.chest}</td>
                            <td>{measurementValue?.stomach}</td>
                            <td>{measurementValue?.shoulder}</td>
                            <td>{measurementValue?.sleeve}</td>
                            <td>{measurementValue?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{measurementValue?.waist}</td>
                            <td>{measurementValue?.hip}</td>
                            <td>{measurementValue?.crotch}</td>
                            <td>{measurementValue?.thigh}</td>
                            <td>{measurementValue?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }


    _renderMeasurement(measurements) {
        let selectedMeasurement = JSON.parse(measurements)
        return (
            selectedMeasurement?.type === Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT ? this._renderSuitStandardMeasurements(selectedMeasurement?.value) : this._renderSuitCustomMeasurement(selectedMeasurement?.value)
        )

    }
    render() {
        const { selectedCombination, isShowFolded, isShowFront, isShowBack,
            isLoadingStarted, isLoading, selectedSuitCombination, selectedSuitFabric, selectedAccents } = this.state;
        const { backModal, backShirt, backSleeve, backSuitFit, backPant, backStyle, frontNormalFit, frontModal, frontSuitFit,
            frontSleeve, frontShirt, frontTie, frontPant, frontBreastPocket, frontPocket, foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedPant, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, backSuitButtons, frontSuitButtons, backButtonThread, backButtonHole, foldedButtonHole,
            foldedButtonThread, frontButtonHole, frontButtonThread, foldedLapelsHole, frontLapelsHole, suitVests, suitVestsButtons } = selectedCombination || {}

        const { style, fit, pocket, sleeve_buttons, back_style, breast_pocket, lapels, lapels_width } = selectedSuitCombination || {}
        // const { selectedAccents } = this.props.selectedSuitAccents || {}
        let { thread, hole, button, fill, initials, font, liningFabricName, pocketFabricName } = selectedAccents || {}
        const { selectedMeasurement } = this.props.measurements || {}
        return (
            <React.Fragment>
                <div className="row m-0">
                    <div className="col-lg-6 order-1 order-sm-1 order-lg-2 verify-casual h-100">
                        {isLoading && <Loader
                            isCanvas={true} />}
                        <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                                <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                                <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                                <a><i className="bi bi-chevron-right"></i></a>
                            </ol>
                            {selectedCombination && (
                                <div className='carousel-inner' data-interval="false">
                                    <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowFolded) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_suit_cuff ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedNormalFit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={foldedCollar.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_inner_suit"
                                                        src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_collar"
                                                        src={suitInner}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={suitBottomInner}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVests.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_vests_button"
                                                        src={suitVestsButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedSuitFit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_lapels"
                                                        src={foldedLapelsHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitPocketsSquare}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_pleats"
                                                        src={foldedSleeve.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt'
                                                    />
                                                    <img className="custom_shirt folded_pocket"
                                                        src={foldedPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_side_pocket"
                                                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_hole"
                                                        src={foldedButtonHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_button"
                                                        src={foldedSuitButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_thread"
                                                        src={foldedButtonThread}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_pant"
                                                        src={foldedPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowFront) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_suit_front ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={frontModal}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontShirt}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_tie"
                                                        src={frontTie}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontNormalFit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVests.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVestsButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontSuitFit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt front_lapels"
                                                        src={frontLapelsHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontSleeve.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitPocketsSquare}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontBreastPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_hole"
                                                        src={frontButtonHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_button"
                                                        src={frontSuitButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_thread"
                                                        src={frontButtonThread}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                </div>}
                                        </div>
                                    </div>
                                    <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowBack) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_suit_back ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt suit_back"
                                                        src={backModal}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_sleeve"
                                                        src={backShirt}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pant"
                                                        src={backSleeve.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_fit"
                                                        src={backSuitFit.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={backStyle.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_hole"
                                                        src={backButtonHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={backSuitButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_thread"
                                                        src={backButtonThread}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.props.suitVest && <ul className="nw_zoom">
                                            {
                                                <li className="cursor-pointer"
                                                    onClick={() => this.props.showJacket()}>
                                                    <img src={require('../../asset/images/suit/SVG/Show.svg')}
                                                        className="img-fluid" alt="icon" />
                                                    <p>Show Jacket</p>
                                                </li>
                                            }
                                        </ul>
                                    }
                                </div>
                            )}

                            <Link className="carousel-control-prev prev_icon" role="button" data-slide="prev" onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </Link>
                            <Link className="carousel-control-next prev_icon" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2" >
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Styles</h4>

                                <p><b>Fabric : </b>{selectedSuitFabric?.name}</p>
                                <p>Suit Styles : {style && style.title}</p>
                                <p>Fit : {fit && fit.title}</p>
                                <p>Sleeve Button : {sleeve_buttons && sleeve_buttons.title}</p>
                                <p>Back Style : {back_style && back_style.title}</p>
                                <p>Pocket : {pocket && pocket.title}</p>
                                <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                                <p>Lapels : {lapels && lapels.title}</p>
                                <p>Lapels Width : {lapels_width && pocket.title}</p>
                            </div>
                            <div className="col-sm-6">
                                <h4>Suit Accents</h4>
                                <p>Button : {button}</p>
                                <p>Hole : {hole}</p>
                                <p>Thread : {thread}</p>
                                {
                                    initials && (
                                        <>
                                            <p>Monogram color : {fill} </p>
                                            <p>Intials: {initials}</p>
                                            <p>Font: {font}</p>
                                        </>
                                    )
                                }

                                {liningFabricName && <p><b>Inner Fabric: {liningFabricName}</b></p>}
                                {pocketFabricName && <p><b>Pocket Fabric: {pocketFabricName}</b></p>}

                            </div>
                        </div>
                        {this._renderMeasurement(selectedMeasurement)}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        measurements: Actions.getSelectedMeasurement(state)
    }
}

export default withRouter(connect(mapStateToProp, {
})(CanvasSuitSection));
