const NAME = 'SELECTED_FABRICS'
export const UPDATE_VEST_FABRIC = `${NAME}/UPDATE_VEST_FABRIC`;
export const DEFAULT_VEST_FABRIC = `${NAME}/DEFAULT_VEST_FABRIC`;
export const getVestFabric = store => store[NAME].vest

export const updateVestFabric = (data) => {
    return {
        type: UPDATE_VEST_FABRIC,
        data
    }
}

export const setDefaultVestFabric = (data) => {
    return {
        type: DEFAULT_VEST_FABRIC,
        data
    }
}