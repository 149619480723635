import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import CanvasButtons from '../../component/Canvas/canvas_buttons'
import { GET_COLLECTION_LIST } from '../../Queries/collection'
import { Collection } from '../../config/constant'
// import CanvasFilter from '../../component/CanvasFilter'
import { GET_ALL_TAGS } from '../../Queries'
import { Constants, revealSameProductIDS, revealVestId } from '../../util/Constant'
class CanvasFabric extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openMobile: false,
      openStyle: false,
      perPage: 100,
      isFabric: true,
      openFabricDesign: false,
      isShirtFilter: false,
      search: null,
      optionName: 'fabric',
      isSuitVest: false,
      sortBy: 'BEST_SELLING',
      tags: [],
      reverse: true
    }
  }
  handleChange(e) {
    let { fields } = this.state
    const { name, value } = e.target
    fields[name] = value
    this.setState({ fields });
  }
  hamburgerClose() {
    this.setState({ openMobile: false, openStyle: false })
  }

  updateShirtFabric(name, productId, tags, description, id, images, amount, currencyCode) {
    this.setState({ openMobile: false, openStyle: false })
    let fabrics = {
      name,
      productId,
      tags,
      description,
      changeFabrics: true,
      id,
      images,
      amount,
      currencyCode,
      selectedColorCode: null
    }
    this.props.updateShirtFabric(fabrics)
  }
  updatePantFabrics(name, productId, tags, description, id, images, amount, currencyCode) {
    this.setState({ openMobile: false, openStyle: false })
    let fabrics = {
      name,
      productId,
      tags,
      description,
      changeFabrics: true,
      id,
      images,
      amount,
      currencyCode,
      selectedColorCode: null
    }
    this.props.updateSelectedPantFabric(fabrics)
  }

  updateSuitFabric(name, productId, tags, description, id, images, amount, currencyCode) {
    this.setState({ openMobile: false, openStyle: false })
    let parts = productId?.split('/');
    let number = parts[parts.length - 1];
    const { vestId } = revealSameProductIDS(`${number}/`)
    let fabrics = {
      name,
      productId,
      tags,
      description,
      changeFabrics: true,
      id,
      images,
      amount,
      currencyCode,
      selectedColorCode: null
    }
    this.props.updateSelectedSuitFabric(fabrics)
    if (vestId) {
      const convertStoreFrontId = Constants.SHOPIFY_TYPE_URL.productID + vestId
      let params = { productId: convertStoreFrontId, images, amount, name, tags, description }
      this.props.updateVestFabric(params)
    }
  }



  componentDidMount() {
    let { showFabricList } = this.props.selectedFabric
    if (showFabricList) {
      this.setState({ openStyle: true, openMobile: true })
    } else {
      this.setState({ openStyle: false, openMobile: false })
    }
    this.getCollections()
    this.props.getAllTags(GET_ALL_TAGS)
  }


  getCollections() {
    const { perPage, sortBy, tags, reverse, isSuitVest } = this.state
    let { edit } = this.props.match.params
    let { selectedShirtFabric } = this.props.selectedFabric
    const { selectedSuitFabric: { selectedSuitFabric } } = this.props
    const { selectedPantFabric: { selectedPantFabric } } = this.props
    const { blazerFabric: { selectedBlazerFabric } } = this.props
    const { getVestFabric: { selectedVestFabric } } = this.props
    let query = ''
    let key = ''
    let isEdit = ''
    if (edit || selectedShirtFabric?.changeFabrics || selectedSuitFabric?.changeFabrics
      || selectedPantFabric?.changeFabrics || selectedBlazerFabric?.changeFabrics || selectedVestFabric?.changeFabrics
    ) {
      isEdit = true
    }

    const { isPant, isShirt, isBlazer, isSuit, isVest } = this.props
    if (isShirt) {
      query = GET_COLLECTION_LIST(Collection.shirt, perPage, sortBy, reverse, tags)
      key = 'shirt'
    } else if (isPant) {
      query = GET_COLLECTION_LIST(Collection.pant, perPage, sortBy, reverse, tags)
      key = 'pant'
    } else if (isSuit) {
      query = GET_COLLECTION_LIST(Collection.suit, perPage, sortBy, reverse, tags)
      key = 'suit'
    } else if (isBlazer) {
      query = GET_COLLECTION_LIST(Collection.blazer, perPage, sortBy, reverse, tags)
      key = 'blazer'
    } else if (isVest && isSuitVest) {
      query = GET_COLLECTION_LIST(Collection.suit, perPage, sortBy, reverse, tags)
      key = 'suit'
    } else if (isVest && !isSuitVest) {
      query = GET_COLLECTION_LIST(Collection.vest, perPage, sortBy, reverse, tags)
      key = 'vests'
    } else {
      key = 'shirt'
      query = GET_COLLECTION_LIST(Collection.shirt, perPage, sortBy, reverse, tags)
    }
    this.props.getFabricList(query, key, isEdit)
  }


  filterCanvas(params) {
    this.setState({ sortBy: params, isShirtFilter: !this.state.isShirtFilter, reverse: !this.state.reverse }, () => {
      this.getCollections()
    })
  }

  handleTags(e, params) {
    let { checked } = e?.target
    let { tags } = this.state
    if (checked) {
      tags.push(params)
      this.setState({ tags })
    } else {
      let objIndex = tags.findIndex((o => o === params));
      tags.splice(objIndex, 1);
      this.setState({ tags })
    }
  }

  navStyle() {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params
    if (edit) {
      if (isShirt) {
        this.props.history.push(`/canvas/shirt/${edit}?style`)
      } else if (isPant) {
        this.props.history.push(`/canvas/pant/${edit}?style`)
      } else if (isBlazer) {
        this.props.history.push(`/canvas/blazer/${edit}?style`)
      } else if (isSuit) {
        this.props.history.push(`/canvas/suit/${edit}?style`)
      } else if (isVest) {
        this.props.history.push(`/canvas/vests/${edit}?style`)
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?style')
      } else if (isPant) {
        this.props.history.push('/canvas/pant?style')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?style')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?style')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?style')
      }
    }

  }
  navAccent() {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params

    if (edit) {
      if (isShirt) {
        this.props.history.push(`/canvas/shirt/${edit}?accent`)
      } else if (isPant) {
        this.props.history.push(`/canvas/pant/${edit}?accent`)
      } else if (isBlazer) {
        this.props.history.push(`/canvas/blazer/${edit}?accent`)
      } else if (isSuit) {
        this.props.history.push(`/canvas/suit/${edit}?accent`)
      } else if (isVest) {
        this.props.history.push(`/canvas/vests/${edit}?accent`)
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?accent')
      } else if (isPant) {
        this.props.history.push('/canvas/pant?accent')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?accent')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?accent')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?accent')
      }
    }


  }


  navFabric() {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params
    if (edit) {
      if (isShirt) {
        this.props.history.push(`/canvas/shirt/${edit}?fabric`)
      } else if (isPant) {
        this.props.history.push(`/canvas/pant/${edit}?fabric`)
      } else if (isBlazer) {
        this.props.history.push(`/canvas/blazer/${edit}?fabric`)
      } else if (isSuit) {
        this.props.history.push(`/canvas/suit/${edit}?fabric`)
      } else if (isVest) {
        this.props.history.push(`/canvas/vests/${edit}?fabric`)
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?fabric')
      } else if (isPant) {
        this.props.history.push('/canvas/pant?fabric')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?fabric')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?fabric')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?fabric')
      }
    }
  }


  navFabrics() {
    this.setState({ openMobile: true, openStyle: true })
  }
  moveFabrics() {
    this.setState({ openMobile: true, openStyle: true })
  }
  openShirtFilter() {
    this.setState({ isShirtFilter: !this.state.isShirtFilter })
  }

  tagsFilter(products, tags) {
    return products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(tags.toLowerCase()))
  }
  _renderShirtProducts(collections, isLoading) {
    let { products } = collections || {}
    let { search, tags: filterTags } = this.state
    let FilteredItems = []
    if (search) {
      FilteredItems = products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(search.toLowerCase()))
    } else if (filterTags?.length > 0) {
      FilteredItems = products?.edges?.filter((el) => {
        return filterTags.some((f) => {
          return el?.node?.tags?.includes(f);
        });
      });
    } else {
      FilteredItems = products?.edges
    }
    const items = FilteredItems && FilteredItems.map((data, index) => {
      let { title, images, id, variants, tags, availableForSale, description } = data.node || {}
      let { node } = variants && variants.edges && variants.edges[0]
      let { priceV2, id: lineItemId } = node || {}
      let { node: productImage } = images && images.edges[0]
      return availableForSale && (
        <div className='col-lg-6 col-4 px-lg-2 px-1 cursor-pointer' key={index} onClick={() => this.updateShirtFabric(title, id, tags, description,
          lineItemId, images, priceV2?.amount, priceV2?.currencyCode)}>
          <div className='fabric_section' >
            <img src={productImage && productImage.src} title={title} className='fabric_material' alt='fabric1' />
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className='canvas_fabric_name text-nowrap'>
              {
                title && title.length > 16 ? <span title={title}>{`${title.slice(0, 20)}...`}</span> : <span className='text_grey fs-12 H-l'>{title}</span>
              }
            </p>
            <p className='canvas_fabric_dollar H-cb fs-12'>{priceV2?.amount} {priceV2?.currencyCode}</p>
          </div>
        </div>
      )
    })
    return (
      <React.Fragment>
        {items?.length ? <React.Fragment>
          {items}
        </React.Fragment> : !isLoading && <div className='text-center'>
          <img className='mt-5 w-25' src={require('../../asset/images/akal_images/no-fabric-found.svg')} />
          <p className='text-center pt-2'>No fabrics found</p>
        </div>}
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSuitVest !== this.state.isSuitVest) {
      this.getCollections()
    }
  }

  _renderPantProducts(collections, isLoading) {
    const { selectedPantFabric: { selectedPantFabric } } = this.props
    let { products } = collections || {}

    let { search, tags: filterTags } = this.state
    let FilteredItems = []
    if (search) {
      FilteredItems = products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(search.toLowerCase()))
    } else if (filterTags?.length > 0) {
      FilteredItems = products?.edges?.filter((el) => {
        return filterTags.some((f) => {
          return el?.node?.tags?.includes(f);
        });
      });
    } else {
      FilteredItems = products?.edges
    }

    const items = FilteredItems && FilteredItems.map((data, index) => {
      let { title, images, id, variants, tags, availableForSale, description } = data.node || {}
      let { node } = variants && variants.edges && variants.edges[0]
      let { priceV2, id: lineItemId } = node || {}
      let { node: productImage } = images && images.edges[0]
      return availableForSale && (
        <div className='col-lg-6 col-4 px-lg-2 px-1 cursor-pointer' key={index} onClick={() => this.updatePantFabrics(title, id, tags, description,
          lineItemId, images, priceV2?.amount, priceV2?.currencyCode)}>
          <div className='fabric_section' >
            <img src={productImage && productImage.src} title={title} className='fabric_material' alt='fabric1' />
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className='canvas_fabric_name text-nowrap'>
              {
                title && title.length > 16 ? <span title={title}>{`${title.slice(0, 20)}...`}</span> : <span className='text_grey fs-12 H-l'>{title}</span>
              }
            </p>
            <p className='canvas_fabric_dollar fs-12 H-cb'>{priceV2?.amount} {priceV2?.currencyCode}</p>
          </div>
        </div>
      )
    })
    return (
      <React.Fragment>
        {items?.length ? <React.Fragment>
          {items}
        </React.Fragment> : !isLoading && <div className='text-center'>
          <img className='mt-5 w-25' src={require('../../asset/images/akal_images/no-fabric-found.svg')} />
          <p className='text-center pt-2'>No fabrics found</p>
        </div>}
      </React.Fragment>
    )
  }

  _renderSuitProducts(collections, isLoading) {
    const { selectedSuitFabric: { selectedSuitFabric } } = this.props
    let { productId } = selectedSuitFabric || {}
    let { products } = collections || {}
    let { isSuitVest } = this.state
    let { search, tags: filterTags } = this.state
    let FilteredItems = []
    if (search) {
      FilteredItems = products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(search.toLowerCase()))
    } else if (filterTags?.length > 0) {
      FilteredItems = products?.edges?.filter((el) => {
        return filterTags.some((f) => {
          return el?.node?.tags?.includes(f);
        });
      });
    } else {
      FilteredItems = products?.edges
    }

    const items = FilteredItems && FilteredItems.map((data, index) => {
      let { title, images, id, variants, tags, availableForSale, description } = data.node || {}
      let { node } = variants && variants.edges && variants.edges[0]
      let { priceV2, id: lineItemId } = node || {}
      let { node: productImage } = images && images.edges[0]
      return availableForSale && (
        <div className='col-lg-6 col-4 px-lg-2 px-1 cursor-pointer' key={index} onClick={() => this.updateSuitFabric(title, id, tags, description,
          lineItemId, images, priceV2?.amount, priceV2?.currencyCode)}>
          <div className='fabric_section' >
            <img src={productImage && productImage.src} title={title} className='fabric_material' alt='fabric1' />
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className='canvas_fabric_name text-nowrap'>
              {
                title && title.length > 16 ? <span title={title}>{`${title.slice(0, 20)}...`}</span> : <span className='text_grey fs-12 H-l'>{title}</span>
              }
            </p>
            <p className='canvas_fabric_dollar H-cb fs-12'>{priceV2?.amount} {priceV2?.currencyCode}</p>
          </div>
        </div>
      )
    })
    return (
      <React.Fragment>
        {items?.length ? <React.Fragment>
          {items}
        </React.Fragment> : !isLoading && <div className='text-center'>
          <img className='mt-5 w-25' src={require('../../asset/images/akal_images/no-fabric-found.svg')} />
          <p className='text-center pt-2'>No fabrics found</p>
        </div>}
      </React.Fragment>
    )
  }
  updateBlazerFabric(name, productId, tags, description, id, images, amount, currencyCode) {
    this.setState({ openMobile: false, openStyle: false })
    let fabrics = {
      name,
      productId,
      tags,
      description,
      changeFabrics: true,
      id,
      images,
      amount,
      currencyCode,
      selectedColorCode: null
    }
    this.props.updateSelectedBlazerFabric(fabrics)
  }

  updateVestFabric(name, productId, tags, description, id, images, amount, currencyCode) {
    let parts = productId?.split('/');
    let shopifyId = parts[parts.length - 1];
    const { suitId } = revealVestId(shopifyId)

    this.setState({ openMobile: false, openStyle: false })
    let fabrics = {
      name,
      productId,
      tags,
      description,
      changeFabrics: true,
      id,
      images,
      amount,
      currencyCode,
      selectedColorCode: null
    }
    this.props.updateVestFabric(fabrics)
    if (suitId) {
      let params = { productId: suitId, images, amount, name, tags, description }
      this.props.updateSelectedSuitFabric(params)
    }
  }
  _renderBlazerProducts(collections, isLoading) {
    const { blazerFabric: { selectedBlazerFabric } } = this.props
    let { products } = collections || {}
    let { search, tags: filterTags } = this.state
    let FilteredItems = []
    if (search) {
      FilteredItems = products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(search.toLowerCase()))
    } else if (filterTags?.length > 0) {
      FilteredItems = products?.edges?.filter((el) => {
        return filterTags.some((f) => {
          return el?.node?.tags?.includes(f);
        });
      });
    } else {
      FilteredItems = products?.edges
    }

    const items = FilteredItems && FilteredItems.map((data, index) => {
      let { title, images, id, variants, tags, availableForSale, description } = data.node || {}
      let { node } = variants && variants.edges && variants.edges[0]
      let { priceV2, id: lineItemId } = node || {}
      let { node: productImage } = images && images.edges[0]
      return availableForSale && (
        <div className='col-lg-6 col-4 px-lg-2 px-1 cursor-pointer' key={index} onClick={() => this.updateBlazerFabric(title, id, tags, description,
          lineItemId, images, priceV2?.amount, priceV2?.currencyCode)}>
          <div className='fabric_section' >
            <img src={productImage && productImage.src} title={title} className='fabric_material' alt='fabric1' />
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className='canvas_fabric_name text-nowrap'>
              {
                title && title.length > 16 ? <span title={title}>{`${title.slice(0, 20)}...`}</span> : <span className='text_grey fs-12 H-l'>{title}</span>
              }
            </p>
            <p className='canvas_fabric_dollar H-cb fs-12'>{priceV2?.amount} {priceV2?.currencyCode}</p>
          </div>
        </div>
      )
    })
    return (
      <React.Fragment>
        {items?.length ? <React.Fragment>
          {items}
        </React.Fragment> : !isLoading && <div className='text-center'>
          <img className='mt-5 w-25' src={require('../../asset/images/akal_images/no-fabric-found.svg')} />
          <p className='text-center pt-2'>No fabrics found</p>
        </div>}
      </React.Fragment>
    )
  }

  _renderVestProducts(collections, isLoading) {
    const { getVestFabric: { selectedVestFabric } } = this.props
    let { products } = collections || {}

    let { search, tags: filterTags } = this.state
    let FilteredItems = []
    if (search) {
      FilteredItems = products?.edges?.filter((o) => o?.node?.title.toLowerCase()?.includes(search.toLowerCase()))
    } else if (filterTags?.length > 0) {
      FilteredItems = products?.edges?.filter((el) => {
        return filterTags.some((f) => {
          return el?.node?.tags?.includes(f);
        });
      });
    } else {
      FilteredItems = products?.edges
    }

    const items = FilteredItems && FilteredItems.map((data, index) => {
      let { title, images, id, variants, tags, availableForSale, description } = data.node || {}
      let { node } = variants && variants.edges && variants.edges[0]
      let { priceV2, id: lineItemId } = node || {}
      let { node: productImage } = images && images.edges[0]
      return availableForSale && (
        <div className='col-lg-6 col-4 px-lg-2 px-1 cursor-pointer' key={index} onClick={() => this.updateVestFabric(title, id, tags, description,
          lineItemId, images, priceV2?.amount, priceV2?.currencyCode)}>
          <div className='fabric_section' >
            <img src={productImage && productImage.src} title={title} className='fabric_material' alt='fabric1' />
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className='canvas_fabric_name text-nowrap'>
              {
                title && title.length > 16 ? <span title={title}>{`${title.slice(0, 20)}...`}</span> : <span className='text_grey fs-12 H-l'>{title}</span>
              }
            </p>
            <p className='canvas_fabric_dollar H-cb fs-12'>{priceV2?.amount} {priceV2?.currencyCode}</p>
          </div>
        </div>
      )
    })
    return (
      <React.Fragment>
        {items?.length ? <React.Fragment>
          {items}
        </React.Fragment> : !isLoading && <div className='text-center'>
          <img className='mt-5 w-25' src={require('../../asset/images/akal_images/no-fabric-found.svg')} />
          <p className='text-center pt-2'>No fabrics found</p>
        </div>}
      </React.Fragment>
    )
  }


  static getDerivedStateFromProps(props, state) {
    const { isVest } = props;
    const { isVests } = props.selectedVestStyle
    let isSuitVest = ''
    if (isVest && isVests) {
      isSuitVest = true
    } else {
      isSuitVest = false
    }
    return {
      isSuitVest
    }
  }

  _renderPageComponentsFabrics(collections) {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    const { isSuitVest } = this.state
    const { isLoading } = this.props.fabricList
    if (isShirt) {
      return this._renderShirtProducts(collections, isLoading)
    } else if (isPant) {
      return this._renderPantProducts(collections, isLoading)
    } else if (isSuit) {
      return this._renderSuitProducts(collections, isLoading)
    } else if (isVest && isSuitVest) {
      return this._renderSuitProducts(collections, isLoading)
    } else if (isVest && !isSuitVest) {
      return this._renderVestProducts(collections, isLoading)
    } else if (isBlazer) {
      return this._renderBlazerProducts(collections, isLoading)
    } else {
      return this._renderShirtProducts(collections, isLoading)
    }
  }
  searchFabrics(searchValue) {
    this.setState({ search: searchValue })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.searchFabrics(this.props.searchValue);
    }
  }
  _renderProductList() {
    const { isShirt, isPant, isSuit, isBlazer, isVest } = this.props
    const { optionName } = this.state
    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = (fabricList && fabricList.data) || {}
    return (
      <React.Fragment>
        {this._renderPageComponentsFabrics(collectionByHandle)}
        <CanvasButtons
          name="Next"
          handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
          nextStep={() => this.navStyle()}
          optionName={optionName}
          isShirt={isShirt}
          isPant={isPant}
          isSuit={isSuit}
          isBlazer={isBlazer}
          isVest={isVest}
        />
      </React.Fragment>
    )
  }
  handleOnClickOptions(e) {
    let { value } = e.target
    switch (value) {
      case 'fabric':
        this.navFabric()
        break
      case 'style':
        this.navStyle()
        break
      case 'accent':
        this.navAccent()
        break
      default:
        this.navFabric()
    }
  }

  openDesign() {
    this.setState({ openFabricDesign: true })
  }
  closeModal() {
    this.setState({ openFabricDesign: false })
  }



  render() {
    // let { isShirtFilter } = this.state
    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = (fabricList && fabricList.data) || {}
    let { edges } = (collectionByHandle && collectionByHandle.products) || {}
    const { tagsList } = this.props.getTags || {}
    return (
      <React.Fragment>
        <div className='row px-lg-2 m-0 row-gap-4 canvas_style_contents'>
          {/* <CanvasFilter
            isShirtFilter={isShirtFilter}
            openShirtFilter={() => this.openShirtFilter()}
            filterOption={(params) => this.filterCanvas(params)}
            tagsList={tagsList}
            handleTags={(e, params) => this.handleTags(e, params)}
          /> */}
          {this._renderProductList()}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProp = (state) => {
  return {
    fabricList: Actions.getFabrics(state),
    selectedFabric: Actions.getShirtFabric(state),
    selectedPantFabric: Actions.getSelectedPantFabric(state),
    selectedSuitFabric: Actions.getSelectedSuitFabric(state),
    selectedSuitStyle: Actions.getSelectedSuitStyle(state),
    getTags: Actions.getTags(state),
    blazerFabric: Actions.getSelectedBlazerFabric(state),
    getVestFabric: Actions.getVestFabric(state),
    selectedVestStyle: Actions.getSelectedVestStyle(state),
  }
}

export default withRouter(connect(mapStateToProp, {
  getFabricList: Actions.getFabricList,
  updateShirtFabric: Actions.updateShirtFabric,
  updateSelectedPantFabric: Actions.updateSelectedPantFabric,
  updateSelectedSuitFabric: Actions.updateSelectedSuitFabric,
  getAllTags: Actions.getAllTags,
  updateSelectedBlazerFabric: Actions.updateSelectedBlazerFabric,
  updateVestFabric: Actions.updateVestFabric
})(CanvasFabric));