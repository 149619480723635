export const LAST_INCOMPLETE_CHECK_OUT_ID = (accesstoken) => {
    return `{
      customer(customerAccessToken: "${accesstoken}") {
        lastIncompleteCheckout {
            id
            totalPrice {
              amount
              currencyCode
            }
            lineItems(first: 10) {
              edges {
                node {
                  customAttributes {
                    key
                    value
                  }
                  id
                  quantity
                  title
                  variant {
                    id
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      src
                      altText
                    }
                  }
                  unitPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
        }
      }
    }`
  }
  