import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    plpList: {},
    pdpDetails: {},
    homeProducts: {},
    selectedProducts:''
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.PLP_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PLP_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                plpList: action.data,
                error: false
            };
        }
        case Actions.PLP_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.PDP_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PDP_DETAILS_SUCCESS: {
            
            return {
                ...state,
                isLoading: false,
                pdpDetails: action.data,
                // selectedProducts:action.data.product.variants[0].sku
            };
        }
        case Actions.PDP_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.GET_HOME_PRODUCTS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_HOME_PRODUCTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                homeProducts: action.data,
                error: false
            };
        }
        case Actions.GET_HOME_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.SELECTED_PRODUCTS:{
            return{
                ...state,
                selectedProducts:action.data
            }
        }
        default:
            return state;
    }

};