import React from 'react'
import { withRouter } from 'react-router-dom'
import { detectBrowser } from '../../util/detect-browser';

class BlazerZoom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {

        const { selectedCombination, openModal, closeModal } = this.props;
        const { foldedSuitFit, foldedPocket, foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole, foldedButtonThread, foldedLapelsHole } = selectedCombination || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${openModal ? 'show' : ''}`} tabIndex="-1" style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `0px` : '' }} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-lg zoom_full_width" role="document">
                        <div className="modal-content canva_zoom_hgt rounded-0">
                            <div className="modal-header border-0 px-4 py-4">
                                <button type="button" className="btn-close"
                                    onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body zoom-canvas">
                                <div className='carousel-inner' data-interval="false">
                                    <div className={`carousel-item active`}>

                                        <div className="pi-shirt-containers">
                                            <div className={`pi_container w-75 m-auto pi_shirt_cuff `}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedNormalFit.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedCollar.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_inner_suit"
                                                    src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_collar"
                                                    src={suitInner}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={suitBottomInner}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedSuitFit.replace('.png', detectBrowser())}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_lapels"
                                                    src={foldedLapelsHole}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitPocketsSquare}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedSleeve.replace('.png', detectBrowser())}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_hole"
                                                    src={foldedButtonHole}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_button"
                                                    src={foldedSuitButtons}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_thread"
                                                    src={foldedButtonThread}
                                                    alt='shirt' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(BlazerZoom)