import React from 'react'
import { withRouter } from 'react-router-dom'
import { Constants } from '../util/Constant'

class PreviewMeasurement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { showModal, moveMeasurement, closeModal } = this.props
        return (
            <React.Fragment>
                {
                    showModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade show`}
                    tabIndex="-1" id="measure" aria-labelledby="measure"
                    aria-hidden="true" style={{
                        display: showModal ? 'block' : 'none',
                        padding_right: showModal ? ' 17px' : '',
                        fontFamily: Constants.FONT_FAMILY.fontFamily
                    }}
                    aria-modal="true"
                    role={'dialog'} >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h5 className="modal-title" id="measureLabel">Design Confirmation</h5>
                                <div className="m_content mt-4">
                                    <p>Please note that the appearance of the products you see
                                        on our online store may deviate slightly from what
                                        you will see in real life..</p>
                                    <p>These products were shot and rendered in a controlled
                                        environment - with specific lighting, distance, and focus.</p>
                                    <p>If you have any further queries about a specific product,
                                        please feel free to send us a message via the chatbox
                                        below or email us at <b>help@mikegulati@gmail.com</b></p>

                                    <button type="button"
                                        className="btn btn-blue"
                                        onClick={(event) => moveMeasurement(event)} >Next
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(PreviewMeasurement)
