// import Cryptr from 'cryptr';
import CryptoJS from 'crypto-js';

// const cryptr = new Cryptr('myTotalySecretKey');

const Encrypt = (id) => {
    var encryptedString = CryptoJS.AES.encrypt(`${id}`, 'secret key 123').toString();
    let encryptedId = encodeURIComponent(encryptedString)
    return encryptedId
}
export default Encrypt

