
export const FORGET_PASSWORD = `/FORGET_PASSWORD`;
export const FORGET_PASSWORD_SUCCESS = `/FORGET_PASSWORD_SUCCESS`;
export const FORGET_PASSWORD_FAILURE = `/FORGET_PASSWORD_FAILURE`;

export const forgetPassword = (query, email, callback) => {
    return {
        type: FORGET_PASSWORD,
        query,
        email,
        callback
    }
}

export const forgetPasswordSuccess = (data) => {
    return {
        type: FORGET_PASSWORD_SUCCESS,
        data
    }
}

export const forgetPasswordFailure = (error) => {
    return {
        type: FORGET_PASSWORD_FAILURE,
        error
    }
}