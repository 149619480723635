export const SHIRT_CHECKOUT = (selectedAccents, selectedCombination, selectedFabric, Measurements) => {
    let selctedAccent = JSON.stringify(selectedAccents)
    let selectedShirt = JSON.stringify(selectedCombination)
    let selectedFabricsDetails = JSON.stringify(selectedFabric)
    let customAttributesJSON = JSON.stringify(Measurements)

    let CustomAttributes = [{
        key: "SelectedShirt",
        value: selectedShirt
    }, {
        key: "selectedFabric",
        value: selectedFabricsDetails
    }, {
        key: "selectedAccent",
        value: selctedAccent
    }, 
    {
        key: "Measurement",
        value: customAttributesJSON
    }
]

    return CustomAttributes
}


export const PANT_CHECKOUT = (selectedCombination, selectedPantFabric, selectedMeasurement) => {
    let selectedPant = JSON.stringify(selectedCombination)
    let selectedFabricsDetails = JSON.stringify(selectedPantFabric)
    let customAttributesJSON = JSON.stringify(selectedMeasurement)
    let CustomAttributes = [{
        key: "SelectedPant",
        value: selectedPant
    }, {
        key: "selectedFabric",
        value: selectedFabricsDetails
    }, 
    {
        key: "Measurement",
        value: customAttributesJSON
    }
]

    return CustomAttributes
}

export const SUIT_CHECKOUT = (isBlazer, selectedCombination, selectedSuitFabric,
    selectedAccents, selectedMeasurement) => {
    let selectedSuitFabrics = JSON.stringify(selectedSuitFabric)
    let selectedSuitCombination = JSON.stringify(selectedCombination)
    let selctedSuitAccents = JSON.stringify(selectedAccents)
    // let customAttributesJSON = JSON.stringify(selectedMeasurement)
    let CustomAttributes = [{
        key: isBlazer ? "SelectedBlazer" : "SelectedSuit",
        value: selectedSuitCombination
    }, {
        key: "selectedSuitFabric",
        value: selectedSuitFabrics
    }, {
        key: "selectedSuitAccents",
        value: selctedSuitAccents
    },
    //  {
    //     key: "Measurement",
    //     value: customAttributesJSON
    // }
]
    return CustomAttributes
}

export const VEST_CHECKOUT = (selectedSuitFabric, selectedCombination, selectedAccents, selectedVestAccents,
    selectedVestStyle, selectedMeasurement, selectedVestFabric) => {
    let selectedSuitFabrics = JSON.stringify(selectedSuitFabric)
    let selectedVestFabrics = JSON.stringify(selectedVestFabric)
    let selectedSuitCombination = JSON.stringify(selectedCombination)
    let selctedSuitAccents = JSON.stringify(selectedAccents)
    let selectedVestAccent = JSON.stringify(selectedVestAccents?.selectedAccents)
    let selectedVestStyles = JSON.stringify(selectedVestStyle?.selectedCombination)
    // let customAttributesJSON = JSON.stringify(selectedMeasurement)

    let CustomAttributes = [{
        key: "selected3PieceSuit",
        value: selectedSuitCombination
    }, {
        key: "selectedSuitFabric",
        value: selectedSuitFabrics
    }, {
        key: "selectedVestFabric",
        value: selectedVestFabrics
    }, {
        key: "selectedSuitAccents",
        value: selctedSuitAccents
    }, {
        key: "selectedVestAccent",
        value: selectedVestAccent
    }, {
        key: "selectedVestStyles",
        value: selectedVestStyles
    },
    //  {
    //     key: "Measurement",
    //     value: customAttributesJSON
    // }
]

    return CustomAttributes
}


export const BLAZER_CHECKOUT = (selectedCombination, selectedBlazerFabric, selectedAccents, selectedMeasurement) => {

    let selectedBlazerFabrics = JSON.stringify(selectedBlazerFabric)
    let selectedBlazerCombination = JSON.stringify(selectedCombination)
    let selctedBlazerAccents = JSON.stringify(selectedAccents)
    // let customAttributesJSON = JSON.stringify(selectedMeasurement)
    let CustomAttributes = [{
        key: "SelectedBlazer",
        value: selectedBlazerCombination
    }, {
        key: "selectedBlazerFabric",
        value: selectedBlazerFabrics
    }, {
        key: "selectedBlazerAccents",
        value: selctedBlazerAccents
    },
    //  {
    //     key: "Measurement",
    //     value: customAttributesJSON
    // }
]
    return CustomAttributes
}