const NAME = 'SELECTED_ACCENTS'

export const UPDATE_SHIRT_ACCENTS = `${NAME}/UPDATE_SHIRT_ACCENTS`;
export const EDIT_SHIRT_ACCENTS = `${NAME}/EDIT_SHIRT_ACCENTS`
export const REMOVE_SHIRT_MONOGRAM = `${NAME}/REMOVE_SHIRT_MONOGRAM`;
export const UPDATE_SHIRT_TYPE = `${NAME}/UPDATE_SHIRT_TYPE`;

export const getShirtAccent = store => store[NAME].shirt

export const updateShirtAccent = (key, value) => {
    return {
        type: UPDATE_SHIRT_ACCENTS,
        key,
        value
    }
}

export const editShirtAccent = (data) => {
    return {
        type: EDIT_SHIRT_ACCENTS,
        data
    }
}


export const removeShirtMonogram = (data) => {
    return {
        type: REMOVE_SHIRT_MONOGRAM,
        data
    }
}

export const updatedShirtType = (data) => {
    return {
        type: UPDATE_SHIRT_TYPE,
        data
    }
}