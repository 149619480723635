export const GET_CUSTOMER_ORDERS = (perPage, token) => {
    return `{
        customer(customerAccessToken: "${token}") {
          orders(first: ${perPage},reverse: true) {
            edges {
              node {
                id
                totalPrice {
                  amount
                  currencyCode
                }
                financialStatus
                fulfillmentStatus
                processedAt
                orderNumber
                name
              }
            }
          }
        }
      }
      `
}