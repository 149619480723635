import React from 'react'
import { withRouter } from 'react-router-dom'
import CartProduct from '../../component/CartProduct'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { toast } from 'react-toastify';
import {
    CHECKOUT_DETAILS, LAST_INCOMPLETE_CHECK_OUT_ID, CUSTOMER_ASSOCIATE,
    SHIPPING_ADDRESS, SHIPPING_LINE_UPDATE
} from '../../Queries'
import 'react-toastify/dist/ReactToastify.css';
import AddAddress from '../../component/AddAddress'
import { isFieldEmpty, isValidName, isValidNumber } from '../../util/validation'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'
import ListOfAddress from '../../component/AddAddress/listOfAddress'


class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            errors: {},
            giftCode: '',
            page: 1,
            isEdit: false,
            validation: {},
            addressListModal: false,
        }
    }
    componentDidMount() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken) {
            this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT_ID(accessToken))
        } else {
            this.props.checkOutDetails(CHECKOUT_DETAILS(this.props.getNewCheckOutId))
        }
    }
    listAddressModel = () => {
        this.setState(prevState => ({
            addressListModal: !prevState.addressListModal
        }));
    };
    editAddressModal(obj, id) {
        let { fields } = this.state
        fields['firstName'] = obj?.firstName
        fields['lastName'] = obj?.lastName
        fields['address'] = obj?.address1
        fields['city'] = obj?.city
        fields['province'] = obj?.province
        fields['zip'] = obj?.zip
        fields['country'] = obj?.country
        fields['id'] = obj?.id
        fields['phone'] = obj?.phone
        this.setState({ addressModal: true, fields });
    }

    // _renderLoginCartItems() {
    //     let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
    //     return (
    //         <React.Fragment>
    //             <CartProduct checkOutList={lastIncompleteCheckout} />
    //         </React.Fragment>
    //     )
    // }


    nav_checkout_page = (lastIncompleteCheckout) => {
        const callback = (response) => {
            let { checkout } = (response?.checkoutShippingLineUpdate) || {}
            window.open(checkout?.webUrl, '_self')
        }
        let input = {
            checkoutId: lastIncompleteCheckout?.id,
            shippingRateHandle: "Standard"
        }
        this.props.checkoutShippingLineUpdate(SHIPPING_LINE_UPDATE, input, callback)
    }



    handleSubmit(defaultAddress) {
        const { selectedAddress, selectedShippingAddress } = this.state
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        if (!defaultAddress) {
            toast.error('No address found', {
                position: toast.TOP_RIGHT
            })
        } else if (selectedAddress) {
            let { firstName, lastName, province, city, address1, phone, country, zip } = selectedShippingAddress || {}
            const callback = (response) => {
                let { checkout } = (response?.checkoutShippingAddressUpdateV2) || {}
                if (!checkout) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.nav_checkout_page(lastIncompleteCheckout)
                }
            }
            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        } else {
            let { firstName, lastName, province, city, address1, phone, country, zip } = defaultAddress || {}
            const callback = (response) => {
                let { checkout = {}, checkoutUserErrors } = (response && response.checkoutShippingAddressUpdateV2) || {}
                if (checkout === null) {
                    toast.error('Sorry something went wrong,please try again later', {
                        position: toast.TOP_RIGHT
                    })
                } else if (checkoutUserErrors?.length > 0) {
                    toast.error(`${checkoutUserErrors[0].code}`, {
                        position: toast.TOP_RIGHT
                    })
                } else {
                    toast.success('Shipping Address Added Succesfully', {
                        position: toast.TOP_RIGHT
                    })
                    this.nav_checkout_page(lastIncompleteCheckout)
                    // this.props.history.push(routes.PAYMENT)
                }
            }

            let input = {
                checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
                shippingAddress: {
                    address1: address1,
                    city: city,
                    country: country,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    province: province,
                    zip: zip
                }
            }
            this.props.shippingAddressAdd(SHIPPING_ADDRESS, input, callback)
        }
    }
    chooseAddress(id, obj) {
        this.setState({
            selectedAddress: true, selectedAddressId: id,
            selectedShippingAddress: obj, showDefaultAddress: false
        })
    }
    // _renderCartItems() {
    //     let { data } = this.props.checkoutDetails || {}
    //     let { totalPrice, currencyCode, lineItems } = (data && data.node) || {}

    //     return (
    //         <React.Fragment>
    //             <div className="col-lg-12 shopping_crt">
    //                 {/* <ContentHeader Cart={true} /> */}
    //                 {/* <div className="col-12 s_heading">
    //                     <h2 className="text-center mb-5"><b>SHOPPING CART</b> <img src={require('../../asset/images/shopping-cart.png')} className="img-fluid s_001_img" alt="images" /></h2>
    //                 </div> */}
    //                 {
    //                     data && (
    //                         <CartProduct checkOutList={data?.node} />
    //                     )
    //                 }

    //                 {/* {!data && <div className="col-12 pb-5">
    //                     <h3 className="mb-0 text-center">Cart Is Empty.!</h3>
    //                 </div>} */}

    //             </div>
    //         </React.Fragment>
    //     )
    // }

    handleAddressChange(field, e) {
        let { fields } = this.state;
        fields[field] = e.target.value;
        this.setState({ fields }, () => {
            this.handleAddressValidation(field);
        });
    }


    handleAddressValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case "firstName":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the first name";
                    this.setState({ errors });
                    return false;
                } else if (fields[field].length > 20) {
                    errors[field] = "First name allow only 20 Characters";
                    this.setState({ errors });
                    return false;
                } else if (!isValidName(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "lastName":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the last name";
                    this.setState({ errors });
                    return false;
                } else if (fields[field].length > 20) {
                    errors[field] = "Last name allow only 20 Characters";
                    this.setState({ errors });
                    return false;
                } else if (!isValidName(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "address":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the address";
                    this.setState({ errors });
                    return false;
                }
                break;

            case "zip":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the zip code";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "phone":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the mobile number";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "province":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the state";
                    this.setState({ errors });
                    return false;
                } else if (isValidNumber(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "country":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please choose the country";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "city":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the city";
                    this.setState({ errors });
                    return false;
                } else if (isValidNumber(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            default:
                return null;
        }
        errors[field] = "";
        this.setState({ errors });
        return true;
    }


    handleAddressSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleAddressValidation(field)) {
                isFormValid = false;
            }
        });
        return isFormValid;
    }
    handleNext(e) {
        if (this.state.page === 1) {
            this.setState({ page: 2, isEdit: false })
        } else {
            this.handleProceed(e)
        }
    }
    closeAddressModal() {
        this.setState({ addressModal: false, fields: {}, errors: {} });
    }
    addAddressModal() {
        this.setState({ addressModal: true });
    }
    toggleOffcanvas = () => {
        document.body.style.overflow = 'hidden';
    };

    render() {
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { availableShippingRates, lineItems, totalPrice, currencyCode } = lastIncompleteCheckout || {}
        // let { shippingRates: { price } } = availableShippingRates || {}
        let { node } = this.props.checkoutDetails?.data || {}
        const { customerList = {} } = this.props.customerList
        const { data = {} } = customerList
        let { addresses = {}, defaultAddress = {} } = (data && data.customer) || {}
        let customerAddress = addresses?.edges?.filter(o => o?.node?.id !== defaultAddress.id)
        const { addressModal, fields, errors, addressListModal } = this.state

        return (
            <React.Fragment>
                <div className='container flex-grow-1'>
                    <div className='row cart-padding m-0'>
                        {lineItems?.edges?.length === 0 ?
                            <p className='text-center'>
                                No Cart found
                            </p> :
                            <React.Fragment>
                                <div className='d-flex flex-column p-0'>
                                    <p className='text-uppercase fs-24 H-cb'>{CONTENTS.cart.shoppingCart}</p>
                                    <div className='row m-0 cart-align-mobile'>
                                        <div className='col-lg-8 cart-left'>
                                            <CartProduct lineItems={lineItems} />
                                            {/* <div className='d-flex gap-24 cart-product-alignment'>
                                                <div className='cart-img'>
                                                    <img src={PATH?.img?.ProductImg} className='object-fit-contain' alt='product-img' />
                                                </div>
                                                <div className='d-flex flex-column justify-content-between flex-grow-1 cart-gap'>
                                                    <div className='d-flex align-item-center justify-content-between '>
                                                        <p className='H-l fs-18 fabric-text'>Formal French Cuff Dress Shirt</p>
                                                        <div className='d-flex gap-10 ps-4'>
                                                            <img src={PATH?.img?.EditIcon} className='cart-icon' alt='edit-icon' />
                                                            <img src={PATH?.img?.DeleteIcon} className='cart-icon' alt='delete-icon' />
                                                        </div>
                                                    </div>
                                                    <div className='row cart-gap'>
                                                        <div className='col-lg-6'>
                                                            <div className='d-flex align-items-center gap-18'>
                                                                <p className='H-l fs-16'>Quantity:</p>
                                                                <div className='d-flex cart-quantity-border'>
                                                                    <p className='fs-20 H- m-0 cursor-pointer'><i className="bi bi-dash"></i></p>
                                                                    <p className='fs-18 H-cb m-0'>1</p>
                                                                    <p className='fs-20 f-m m-0 cursor-pointer'><i className="bi bi-plus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                                                            <p className='H-l fs-14'>1 x 138 SGD</p>
                                                            <p className='H-l fs-18 subtotal-text'>subtotal: <span>138 SGD</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <CartProduct lineItems={lineItems} /> */}
                                        </div>
                                        <div className='col-lg-4 cart-right'>
                                            <div className='d-flex flex-column gap-49'>
                                                <div className='d-flex flex-column gap-44 cart-price-items'>
                                                    <div>
                                                        <p className='fs-16 H-m text-decoration-underline heading-dotted'>{CONTENTS.cart.priceDetails}</p>
                                                        <p className='fs-14 H-l text_grey pt-2'>{CONTENTS.cart.priceDetailsContent}</p>
                                                    </div>
                                                    <div className='d-flex flex-column gap-18'>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='fs-14 H-l text_grey text-lowercase'>{CONTENTS.cart.totalProduct}</p>
                                                            <p className='fs-14 H-l'>{lineItems && lineItems?.edges.length}</p>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='fs-14 H-l text_grey text-lowercase'>{CONTENTS.cart.shippingCost}</p>
                                                            <p className='fs-14 H-l'>{CONTENTS.cart.free}</p>
                                                        </div>

                                                        {/* <div className='d-flex justify-content-between order_total py-lg-3 py-2'>
                                                            <p className='text_grey'>Shipping Cost</p>
                                                            <p>{price > 0 ? price : 'FREE'}</p>
                                                        </div> */}
                                                        <div className='d-flex flex-column gap-12 pt-2'>
                                                            <div className='d-flex align-items-center gap-44'>
                                                                <p className='fs-14 H-l text-lowercase text-nowrap'>{CONTENTS.cart.orderTotal}</p>
                                                                <div className="cart-divider-line w-100"></div>
                                                            </div>
                                                            <p className='text-end H-cb fs-24'>{lastIncompleteCheckout ? `${totalPrice?.amount} ${totalPrice?.currencyCode}` :
                                                                `${node?.totalPrice?.amount} ${node?.totalPrice?.currencyCode}`}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='cart-checkout-mobile'>
                                                    <div data-bs-toggle="offcanvas" data-bs-target="#checkoutaddress"
                                                        type="button" onClick={() => this.toggleOffcanvas()}>
                                                        <ButtonDesign
                                                            btnWidth={'w-100 p-0 border-0 rounded-0 button-primary'}
                                                            className={'w-100 fs-16 H-cb py-12'}
                                                            NoImg
                                                            ButtonValue={CONTENTS?.cart?.checkout}
                                                            onClick={() => this.listAddressModel()}
                                                        />
                                                    </div>
                                                    {addressListModal && <ListOfAddress closeListAddressModal={this.listAddressModel} isCheckout={true} />}
                                                    {/* <div className='offcanvas offcanvas-end measurement_offcanvas' id="checkoutaddress">
                                                        <div className='overflow-auto h-100'>
                                                            <a className='measurement_btn_close px-4 pt-3 pb-2 d-block'
                                                                href='#' data-bs-dismiss="offcanvas"><img src={PATH?.img?.ExitPopupIcon} alt="" className='address_exit_icon' /></a>
                                                            <p className='fs-22 H-cb text-center pb-2'>Choose your address</p>
                                                            <p className='fs-14 H-l text-center pb-2'>Pick the size you usually wear.</p>
                                                            <div className='offcanvas-body px-4'>
                                                                <div className='d-flex flex-column gap-24 cursor-pointer'>
                                                                    <div className='d-flex gap-10 justify-content-end'>
                                                                        <img src={PATH?.img?.AddIcon} alt="" />
                                                                        <p className='fs-14 H-m'>add address</p>
                                                                    </div>
                                                                    {defaultAddress && <div className='address_popup px-3 py-3 cursor-pointer' >
                                                                        <p className='text-end'>
                                                                            <img src={PATH?.img?.EditIcon} alt="" onClick={() => this.editAddressModal(defaultAddress, 1)} /></p>

                                                                        <div className='d-flex flex-column gap-12'>
                                                                            <p className='fs-14 H-l'>Mercilin</p>
                                                                            <p className='fs-14 H-l'>mercilindivya@gmail.com</p>
                                                                            <p className='fs-14 H-l'>+91 9876543210</p>
                                                                            <p className='fs-14 H-l'>4 Tampines Central 5 Tampines Mall, Singapore
                                                                                <br />
                                                                                529510 Singapore
                                                                                br
                                                                                Phone Number: 67813413</p>

                                                                        </div>
                                                                    </div>}

                                                                    {this.state.page === 1 && <>
                                                                        {customerAddress?.map((o, index) => (
                                                                            <div className='cart_address_bg px-3 py-3 cursor-pointer' key={index}
                                                                                onClick={() => this.chooseAddress(o?.node?.id, o?.node)}>
                                                                                <p className='text-end'>
                                                                                    <i className="bi bi-pencil-square text_grey cursor-pointer"
                                                                                        onClick={() => this.editAddressModal(o?.node, 1)}></i></p>
                                                                                <div className='d-flex flex-column gap-3'>
                                                                                    <div className='row'>
                                                                                        <div className='col-5'>
                                                                                            <p className='text_grey'>Name</p>
                                                                                        </div>
                                                                                        <div className='col-7'>
                                                                                            <p>{o?.node?.name}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-5'>
                                                                                            <p className='text_grey'>Phone Number</p>
                                                                                        </div>
                                                                                        <div className='col-7'>
                                                                                            <p>{o?.node?.phone}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-5'>
                                                                                            <p className='text_grey'>Default Address</p>
                                                                                        </div>
                                                                                        <div className='col-7'>
                                                                                            <p> {o?.node?.address1} <br />
                                                                                                {o?.node?.city} <br />
                                                                                                {o?.node?.province} <br />
                                                                                                {o?.node?.zip} <br />
                                                                                                {o?.node?.country} <br /></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>))
                                                                        }
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.page === 1 && <div className='d-flex gap-15 px-4 py-3 address_popup_btn justify-content-start'>
                                                            <ButtonDesign
                                                                btnWidth={'address_popup_close_btn'}
                                                                className={'w-100 fs-18 H-cb py-12'}
                                                                NoImg
                                                                ButtonValue={'close'}
                                                            />
                                                            <ButtonDesign
                                                                btnWidth={'address_popup_proceed_btn'}
                                                                className={'w-100 fs-18 H-cb button-primary py-12'}
                                                                NoImg
                                                                ButtonValue={'proceed'}
                                                            />
                                                        </div>}
                                                    </div> */}
                                                    <p className='my-lg-4 my-3 text-center cursor-pointer'>
                                                        <span className='fs-14 H-l text-decoration-underline'
                                                            onClick={() => this.props.history.push('/blazer')}>{CONTENTS.cart.continueShopping}</span>
                                                    </p>
                                                    <div className='d-flex align-items-center justify-content-center gap-3 pt-4'>
                                                        <img src={PATH.img.PaymentIcon1} className='w-15' alt='payment-icon' />
                                                        <img src={PATH.img.PaymentIcon2} className='w-15' alt='payment-icon' />
                                                        <img src={PATH.img.PaymentIcon3} className='w-15' alt='payment-icon' />
                                                        <img src={PATH.img.PaymentIcon4} className='w-15' alt='payment-icon' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>}
                        {addressModal && <AddAddress
                            closeAddModal={(e) => this.closeAddressModal(e)}
                            handleChange={this.handleAddressChange.bind(this)}
                            handleSubmitValidation={(e) => this.handleAddressSubmitValidation(e)}
                            errors={errors}
                            fields={fields}
                        />}
                    </div>
                </div>
                {/* <div className='row cart_section m-0 px-lg-0 px-3'>
                    {lineItems?.edges?.length === 0 ? <p className='text-center'>
                        No Cart found
                    </p> : <React.Fragment>
                        <div className='col-lg-8 p-0'>
                            <p className='fs-19 text-center f-sb pt-lg-3 pt-2 pb-lg-4 pb-3 m-0'>Shopping Bag</p>
                            <CartProduct lineItems={lineItems} />

                        </div>
                        <div className='col-lg-4 p-0'>
                            <div className='order_summary'>
                                <div className='order_summary_text pt-lg-5 pt-4 pb-lg-0 pb-4 px-4'>
                                    <p className='fs-14 f-m'>Order Summary</p>
                                    <p className='fs-12 text_grey pt-2'>Excluding taxes and expedited shipping costs.</p>
                                    <div className='mt-lg-4 mt-3'>
                                        <div className='d-flex justify-content-between order_total py-lg-3 py-2'>
                                            <p className='text_grey'>Total items</p>
                                            <p>{lineItems && lineItems?.edges.length}</p>
                                        </div>

                                       
                                        <div className='d-flex justify-content-between order_total py-lg-3 py-2'>
                                            <p className='fs-17 f-m'>Order Total</p>
                                            <p className='fs-17 f-m'>{lastIncompleteCheckout ? `${totalPrice?.amount} ${totalPrice?.currencyCode}` :
                                                `${node?.totalPrice?.amount} ${node?.totalPrice?.currencyCode}`}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='checkout_bottom_section pb-lg-4 pt-3 px-4'>
                                    <button className='btn common_btn primary_btn checkout_btn mt-lg-2 mt-1 w-100'
                                        data-bs-toggle="offcanvas" data-bs-target="#checkoutaddress"
                                        type="button" >Checkout</button>
                                    <div className='offcanvas offcanvas-end measurement_offcanvas' id="checkoutaddress">
                                        <div className='overflow-auto h-100'>
                                            <a className='measurement_btn_close px-4 pt-3 pb-2 d-block'
                                                href='#' data-bs-dismiss="offcanvas"><i className="bi bi-chevron-left fs-18 text_black"></i></a>
                                            <p className='fs-18 f-sb text-center pb-2'>Create contact</p>
                                            <div className='offcanvas-body px-4'>
                                                <div className='d-flex flex-column gap-3'>
                                                    {defaultAddress && <div className='cart_address_bg px-3 py-3 cursor-pointer' >
                                                        <p className='text-end'>
                                                            <i className="bi bi-pencil-square text_grey cursor-pointer"
                                                                onClick={() => this.editAddressModal(defaultAddress, 1)}></i></p>
                                                        <div className='d-flex flex-column gap-3'>
                                                            <div className='row'>
                                                                <div className='col-5'>
                                                                    <p className='text_grey'>Name</p>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <p>{defaultAddress?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-5'>
                                                                    <p className='text_grey'>Phone Number</p>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <p>{defaultAddress?.phone}</p>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-5'>
                                                                    <p className='text_grey'>Default Address</p>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <p> {defaultAddress?.address1} <br />
                                                                        {defaultAddress?.city} <br />
                                                                        {defaultAddress?.province} <br />
                                                                        {defaultAddress?.zip} <br />
                                                                        {defaultAddress?.country} <br /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}

                                                    {this.state.page === 1 && <>
                                                        {customerAddress?.map((o) => (
                                                            <div className='cart_address_bg px-3 py-3 cursor-pointer'
                                                                onClick={() => this.chooseAddress(o?.node?.id, o?.node)}>
                                                                <p className='text-end'>
                                                                    <i className="bi bi-pencil-square text_grey cursor-pointer"
                                                                        onClick={() => this.editAddressModal(o?.node, 1)}></i></p>
                                                                <div className='d-flex flex-column gap-3'>
                                                                    <div className='row'>
                                                                        <div className='col-5'>
                                                                            <p className='text_grey'>Name</p>
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <p>{o?.node?.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-5'>
                                                                            <p className='text_grey'>Phone Number</p>
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <p>{o?.node?.phone}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-5'>
                                                                            <p className='text_grey'>Default Address</p>
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <p> {o?.node?.address1} <br />
                                                                                {o?.node?.city} <br />
                                                                                {o?.node?.province} <br />
                                                                                {o?.node?.zip} <br />
                                                                                {o?.node?.country} <br /></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>))
                                                        }
                                                        <div className='cart_add_contact px-3 py-4 cursor-pointer'
                                                            onClick={() => this.addAddressModal()}>
                                                            <div>
                                                                <p className='cart_add_icon'>+</p>
                                                                <p>Add Address</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.page === 1 && <div className='d-flex gap-2 px-4 py-3 measure_offcanvas_bottom_btns'>
                                            <button className='btn common_btn secondary_btn w-50'
                                                type="button" onClick={() => this.props.history.push("/cart")}>Close</button>
                                            <button className='btn common_btn primary_btn w-50'
                                                type="button" onClick={() => this.handleSubmit(defaultAddress)}>Proceed</button>
                                        </div>}
                                        
                                    </div>
                                    <p className='my-lg-4 my-3 text-center'>
                                        <span className='continue_shopping'
                                            onClick={() => this.props.history.push('/canvas/shirt?fabric')}>Continue Shopping</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}
                    {addressModal && <AddAddress
                        closeAddModal={(e) => this.closeAddressModal(e)}
                        handleChange={this.handleAddressChange.bind(this)}
                        handleSubmitValidation={(e) => this.handleAddressSubmitValidation(e)}
                        errors={errors}
                        fields={fields}
                    />}
                </div> */}
            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        checkoutDetails: Actions.getCheckoutDetails(state),
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    addressCreate: Actions.addressCreate,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkOutDetails: Actions.checkOutDetails,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,
    getCustomerList: Actions.getCustomerList,
    shippingAddressAdd: Actions.shippingAddressAdd,
    checkoutShippingLineUpdate: Actions.checkoutShippingLineUpdate,
})(Cart));