const NAME = 'CUSTOMER_ADDRESS'

export const ADDRESS_CREATE = `${NAME}/ADDRESS_CREATE`;
export const ADDRESS_CREATE_SUCCESS = `${NAME}/ADDRESS_CREATE_SUCCESS`;
export const ADDRESS_CREATE_FAILURE = `${NAME}/ADDRESS_CREATE_FAILURE`

export const ADDRESS_UPDATE = `${NAME}/ADDRESS_UPDATE`;
export const ADDRESS_UPDATE_SUCCESS = `${NAME}/ADDRESS_UPDATE_SUCCESS`;
export const ADDRESS_UPDATE_FAILURE = `${NAME}/ADDRESS_UPDATE_FAILURE`


export const ADDRESS_DELETE = `${NAME}/ADDRESS_DELETE`;
export const ADDRESS_DELETE_SUCCESS = `${NAME}/ADDRESS_DELETE_SUCCESS`;
export const ADDRESS_DELETE_FAILURE = `${NAME}/ADDRESS_DELETE_FAILURE`



export const SET_DEFAULT_ADDRESS = `${NAME}/SET_DEFAULT_ADDRESS`;
export const SET_DEFAULT_ADDRESS_SUCCESS = `${NAME}/SET_DEFAULT_ADDRESS_SUCCESS`;
export const SET_DEFAULT_ADDRESS_FAILURE = `${NAME}/SET_DEFAULT_ADDRESS_FAILURE`


export const address = store => store[NAME]
export const addressCreate = (query, input, callback) => {
    return {
        type: ADDRESS_CREATE,
        query,
        input,
        callback
    }
}

export const addressCreateSuccess = (data) => {
    return {
        type: ADDRESS_CREATE_SUCCESS,
        data
    }
}

export const addressCreateFailure = (error) => {
    return {
        type: ADDRESS_CREATE_FAILURE,
        error
    }
}


export const addresssUpdate = (query, input, callback) => {
    return {
        type: ADDRESS_UPDATE,
        query,
        input,
        callback
    }
}

export const addressUpdateSuccess = (data) => {
    return {
        type: ADDRESS_UPDATE_SUCCESS,
        data
    }
}

export const addressUpdateFailure = (error) => {
    return {
        type: ADDRESS_UPDATE_FAILURE,
        error
    }
}


export const addressDelete = (query, input, callback) => {
    return {
        type: ADDRESS_DELETE,
        query,
        input,
        callback
    }
}

export const addressDeleteSuccess = (data) => {
    return {
        type: ADDRESS_DELETE_SUCCESS,
        data
    }
}

export const addressDeleteFailure = (error) => {
    return {
        type: ADDRESS_DELETE_FAILURE,
        error
    }
}

export const setDefaultAddress = (query, input, callback) => {
    return {
        type: SET_DEFAULT_ADDRESS,
        query,
        input,
        callback
    }
}

export const setDefaultAddressSuccess = (data) => {
    return {
        type: SET_DEFAULT_ADDRESS_SUCCESS,
        data
    }
}

export const setDefaultAddressFailure = (error) => {
    return {
        type: SET_DEFAULT_ADDRESS_FAILURE,
        error
    }
}
