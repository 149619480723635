export const MEASUREMENT_LIST = (customerId) => {
  return `{
    customer(id:"${customerId}") {
      metafields(first: 200,reverse:true){
          edges{
          node{
          id
          namespace
          type
          key
          value
          updatedAt
          }
          }
          }
        }
    }
  `
}