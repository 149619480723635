import React from 'react'
import { withRouter } from "react-router-dom";
import ButtonDesign from '../component/button'


class AdminSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                search: ""
            }
        }
    }
    handleChange(event) {
        let { name, value } = event.target;
        let { fields } = this.state;
        fields[name] = value;
        this.setState({ fields });

    }
    onSearch() {
        let { search } = this.state.fields
        let URL = `${window.location.href}/order/${search}/details`
        if (search) {
            window.open(URL, "_blank")
        }

    }
    render() {
        return (
            <div className='admin_canvas_search d-flex justify-content-center align-items-center gap-20 flex-column'>
                <p className='H-m fs-24 letter-spacing text-white'>Find the Orders#:</p>

                <div className='d-flex justify-content-between align-items-center gap-10 mobile_flex'>
                    <div className="input-group admin_canvas_search_div">
                        <span className="input-group-text bg-transparent border-0 p-0"
                            id="basic-addon1">
                            <i className="bi bi-search fs-19 me-2" ></i></span>
                        <input
                            type="text"
                            name="search"
                            className="form-control admin_canvas_search_input"
                            placeholder="Please enter the order number here"
                            onChange={(e) => this.handleChange(e)}
                            aria-label="Username" aria-describedby="basic-addon1" />
                        <span className='close-search cursor-pointer' onClick={this.toggleSearch}>Close</span>
                    </div>

                    <ButtonDesign
                        btnWidth={'profile_popup_proceed_btn p-0 border-0 rounded-0 button-primary'}
                        className={'w-100 fs-18 H-cb py-12'}
                        NoImg
                        ButtonValue={"search"}
                        onClick={() => this.onSearch()}
                    />
                </div>
            </div>
        )
    }
}
export default withRouter(AdminSearch)