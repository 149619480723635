import React from 'react'
import { withRouter } from 'react-router-dom'
import '../../asset/css/style.css'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import ShirtStyle from '../../config/shirt_style.json'
import PantStyle from '../../config/pant_style.json'
import SuitStyle from '../../config/suit_style.json'
import BlazerStyle from '../../config/blazer_style.json'
import VestStyle from '../../config/vests.json'
import { images } from '../../util/image_render'
import CanvasButtons from '../../component/Canvas/canvas_buttons';
import { FabricsDetails } from '../../component/SelectedProducts/fabrics_details';
import { store } from '../../createStore'
import PreviewMeasurement from '../../component/PreviewMeasurement';
import { ErrorToaster } from '../../util/toast';
import { PANT_CHECKOUT } from '../../component/ProductCheckout';
import { LINE_ITEMS_UPDATE } from '../../Queries';

class CanvasStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            optionName: 'style',
            isSuitVest: false,
            openPreviewModal: false
        }
    }
    navAccent() {
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        let { edit } = this.props.match.params
        if (edit) {
            if (isShirt) {
                this.props.history.push(`/canvas/shirt/${edit}?accent`)
            } else if (isPant) {
                this.props.history.push(`/canvas/pant/${edit}?accent`)
            } else if (isBlazer) {
                this.props.history.push(`/canvas/blazer/${edit}?accent`)
            } else if (isSuit) {
                this.props.history.push(`/canvas/suit/${edit}?accent`)
            } else if (isVest) {
                this.props.history.push(`/canvas/vests/${edit}?accent`)
            }
        } else {
            if (isShirt) {
                this.props.history.push('/canvas/shirt?accent')
            } else if (isPant) {
                this.props.history.push('/canvas/pant?accent')
            } else if (isBlazer) {
                this.props.history.push('/canvas/blazer?accent')
            } else if (isSuit) {
                this.props.history.push('/canvas/suit?accent')
            } else if (isVest) {
                this.props.history.push('/canvas/vests?accent')
            }
        }
    }


    navMeasurement() {
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        let { edit } = this.props.match.params
        if (!edit) {
            if (isShirt) {
                this.props.history.push('/canvas/shirt?measurement')
            } else if (isPant) {
                this.props.history.push('/canvas/pant?measurement')
            } else if (isBlazer) {
                this.props.history.push('/canvas/blazer?measurement')
            } else if (isSuit) {
                this.props.history.push('/canvas/suit?measurement')
            } else if (isVest) {
                this.props.history.push('/canvas/vests?measurement')
            }
        }
    }
    navFabric() {
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        let { edit } = this.props.match.params
        if (edit) {
            if (isShirt) {
                this.props.history.push(`/canvas/shirt/${edit}?fabric`)
            } else if (isPant) {
                this.props.history.push(`/canvas/pant/${edit}?fabric`)
            } else if (isBlazer) {
                this.props.history.push(`/canvas/blazer/${edit}?fabric`)
            } else if (isSuit) {
                this.props.history.push(`/canvas/suit/${edit}?fabric`)
            } else if (isVest) {
                this.props.history.push(`/canvas/vests/${edit}?fabric`)
            }
        } else {
            if (isShirt) {
                this.props.history.push('/canvas/shirt?fabric')
            } else if (isPant) {
                this.props.history.push('/canvas/pant?fabric')
            } else if (isBlazer) {
                this.props.history.push('/canvas/blazer?fabric')
            } else if (isSuit) {
                this.props.history.push('/canvas/suit?fabric')
            } else if (isVest) {
                this.props.history.push('/canvas/vests?fabric')
            }
        }
    }

    renderShirtChildMenu() {
        const { childMenu, activeChildMenu, selectedStyle } = this.props
        const selectedChildMenu = selectedStyle?.selectedCombination[activeChildMenu] || ''
        return (<React.Fragment>
            {
                childMenu.map((menu, i) => {
                    return (
                        <div className='col-lg-3 col-4 px-1' key={i}>
                            <div className={`style_type ${selectedChildMenu.key === menu.key ? 'active_type' : ''}`}
                                onClick={() => this.props.updateSelectedStyle(activeChildMenu, menu)}>
                                <div className='style_img' >
                                    <img src={images('./' + menu.icon)} alt='long-sleeve' />
                                </div>
                                <p className="pt-1 H-l fs-12 text-lowercase"> {menu.title}</p>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>)
    }



    navStyle() {
        this.setState({ openMobile: true, openStyle: true })
    }
    openDesign() {
        this.setState({ openFabricDesign: true })
    }
    closeModal() {
        this.setState({ openFabricDesign: false })
    }


    static getDerivedStateFromProps(props, state) {
        const { isVest } = props;
        const { isVests } = props.selectedVestStyle

        let isSuitVest = ''
        if (isVest && isVests) {
            isSuitVest = true
        } else {
            isSuitVest = false
        }
        return {
            isSuitVest,
        }
    }


    renderPantChildMenu() {
        const { childPantMenu, activePantChildMenu } = this.props
        const { selectedPantStyle } = this.props;
        const selectedChildMenu = selectedPantStyle.selectedCombination[activePantChildMenu] || ''
        return (<React.Fragment>
            {
                childPantMenu.map((menu, i) => {
                    return (
                        <div className='col-lg-3 col-4 px-1' key={i}>
                            <div className={`style_type ${selectedChildMenu.key === menu.key ? 'active_type' : ''}`}
                                onClick={() => this.props.updateSelectedPantStyle(activePantChildMenu, menu)}>
                                <div className='style_img' >
                                    <img src={images('./' + menu.icon)} alt='long-sleeve' />
                                </div>
                                <p className="pt-1 H-l fs-12 text-lowercase"> {menu.title}</p>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>)
    }

    renderBlazerChildMenu() {
        const { childBlazerMenu, activeBlazerMenu } = this.props
        const { selectedBlazerStyle } = this.props;
        const selectedChildMenu = selectedBlazerStyle.selectedCombination[activeBlazerMenu] || ''
        return (<React.Fragment>
            {
                childBlazerMenu.map((menu, i) => {
                    return (
                        <div className='col-lg-3 col-4 px-1' key={i}>
                            <div className={`style_type ${selectedChildMenu.key === menu.key ? 'active_type' : ''}`}
                                onClick={() => this.props.updateBlazerStyle(activeBlazerMenu, menu)}>
                                <div className='style_img' >
                                    <img src={images('./' + menu.icon)} alt='long-sleeve' />
                                </div>
                                <p className="pt-1 H-l fs-12 text-lowercase"> {menu.title}</p>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>)
    }

    renderSuitChildMenu() {
        const { childSuitMenu, activeSuitStyleMenu } = this.props
        const { selectedSuitStyle } = this.props;
        const selectedChildMenu = selectedSuitStyle.selectedCombination[activeSuitStyleMenu] || ''
        return (<React.Fragment>
            {
                childSuitMenu.map((menu, i) => {
                    return (
                        <div className='col-lg-3 col-4 px-1' key={i}>
                            <div className={`style_type ${selectedChildMenu.key === menu.key ? 'active_type' : ''}`}
                                onClick={() => this.props.updateSelectedSuitStyle(activeSuitStyleMenu, menu)}>
                                <div className='style_img' >
                                    <img src={images('./' + menu.icon)} alt='long-sleeve' />
                                </div>
                                <p className="pt-1 H-l fs-12 text-lowercase"> {menu.title}</p>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>)
    }



    _renderChildMenus() {
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        const { isSuitVest } = this.state
        if (isShirt) {
            return this.renderShirtChildMenu()
        } else if (isPant) {
            return this.renderPantChildMenu()
        } else if (isBlazer) {
            return this.renderBlazerChildMenu()
        } else if (isSuit) {
            return this.renderSuitChildMenu()
        } else if (isVest && isSuitVest) {
            return this.renderSuitChildMenu()
        } else if (isVest && !isSuitVest) {
            return this._renderVestsChildMenu()
        }
    }

    _renderVestsChildMenu() {
        const { childVestMenu, activeVestStyleMenu } = this.props
        const { selectedVestStyle } = this.props;
        const selectedChildMenu = selectedVestStyle.selectedCombination[activeVestStyleMenu] || ''
        return (<React.Fragment>
            {
                childVestMenu.map((menu, i) => {
                    return (
                        <div className='col-lg-3 col-4 px-1' key={i}>
                            <div className={`style_type ${selectedChildMenu.key === menu.key ? 'active_type' : ''}`}
                                onClick={() => this.props.updateSelectedVestStyle(activeVestStyleMenu, menu)}>
                                <div className='style_img' >
                                    <img src={images('./' + menu.icon)} alt='long-sleeve' />
                                </div>
                                <p className="pt-1 H-l fs-12 text-lowercase"> {menu.title}</p>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>)
    }
    _renderSubHeaderMenu() {
        const { isShirt, isPant, isSuit, isBlazer } = this.props
        const { activeChildMenu, activePantChildMenu, activeSuitStyleMenu, activeVestStyleMenu, isVests } = this.state
        if (isShirt) {
            return activeChildMenu ? (<span> {activeChildMenu && activeChildMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        } else if (isPant) {
            return activePantChildMenu ? (<span> {activePantChildMenu && activePantChildMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        } else if (isSuit) {
            return activeSuitStyleMenu ? (<span> {activeSuitStyleMenu && activeSuitStyleMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        } else if (isBlazer) {
            return activeSuitStyleMenu ? (<span> {activeSuitStyleMenu && activeSuitStyleMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        } else if (isVests) {
            return activeSuitStyleMenu ? (<span> {activeVestStyleMenu && activeVestStyleMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        } else {
            return activeChildMenu ? (<span> {activeChildMenu && activeChildMenu.toUpperCase()} <b className="float-right"></b></span >) : (<span>Products <b className="float-right"></b></span>)
        }
    }

    checkoutPant() {
        let { edit } = this.props.match.params
        if (edit) {
            const { selectedMeasurement } = this.props.getMeasurement
            const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
            let { selectedPantFabric } = this.props.selectedPantFabric
            const { selectedCombination } = this.props.selectedPantStyle
            let convertMeasurement = JSON.parse(selectedMeasurement)
            let Measurements = {
                value: convertMeasurement?.value,
                type: convertMeasurement?.type,
                productType: convertMeasurement?.productType,
                key: convertMeasurement?.key
            }
            let customAttributes = PANT_CHECKOUT(selectedCombination, selectedPantFabric, Measurements)
            const callback = (response) => {
                let { checkoutLineItemsAdd = {} } = response && response
                if (checkoutLineItemsAdd === null) {
                    ErrorToaster()
                } else {
                    this.props.history.push('/cart')
                }
            }
            let lineItems = [{
                quantity: 1,
                variantId: selectedPantFabric?.id,
                id: selectedPantFabric?.lineItemId,
                customAttributes: customAttributes
            }]
            let input = {
                checkoutId: lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId,
                lineItems
            }
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.navMeasurement()
        }
    }


    handleOnClickOptions(e) {
        let { value } = e.target
        switch (value) {
            case 'fabric':
                this.navFabric()
                break
            case 'style':
                this.navStyle()
                break
            case 'accent':
                this.navAccent()
                break
            default:
                this.navFabric()
        }
    }


    openPreviewModal() {
        this.setState({ openPreviewModal: true })
    }

    closePreviewModal() {
        this.setState({ openPreviewModal: false })
    }
    render() {
        const { openFabricDesign, optionName, openPreviewModal } = this.state
        const { isShirt, isBlazer, isPant, isSuit, isVest } = this.props
        return (
            <React.Fragment>
                <div className='d-flex flex-column row-gap-3 canvas_style_contents'>
                    <div className='canvas_style_section'>
                        {/* <p className='mb-1 f-m'>{this._renderSubHeaderMenu()}</p> */}
                        <p className='mb-1 f-m'></p>
                        <div className='row row-gap-3 m-0'>
                            {this._renderChildMenus()}
                        </div>
                    </div>
                </div>

                {/* {this._renderChildMenus()} */}
                <CanvasButtons
                    name={'Next'}
                    nextStep={() => isPant ? this.checkoutPant() : this.navAccent()}
                    handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
                    optionName={optionName}
                    isShirt={isShirt}
                    isPant={isPant}
                    isSuit={isSuit}
                    isBlazer={isBlazer}
                    isVest={isVest}
                />
                <FabricsDetails
                    openFabricDesign={openFabricDesign}
                    closeModal={() => this.closeModal()}
                // selectedFabric={this.renderSelectedProductFabrics()}
                />
                <PreviewMeasurement
                    showModal={openPreviewModal}
                    moveMeasurement={() => this.navMeasurement()}
                    closeModal={() => this.closePreviewModal()}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedStyle: Actions.getShirtStyle(state),
        selectedFabric: Actions.getShirtFabric(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state)
    }
}

export default withRouter(connect(mapStateToProp, {
    updateSelectedStyle: Actions.updateShirtStyle,
    updateSelectedPantStyle: Actions.updateSelectedPantStyle,
    updateSelectedSuitStyle: Actions.updateSelectedSuitStyle,
    updateSelectedVestStyle: Actions.updateSelectedVestStyle,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    updateBlazerStyle: Actions.updateBlazerStyle
})(CanvasStyle));