import Actions from '../../action'

const initialState = {
    isLoading: false,
    error: false,
    selectedCheckout: undefined
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.CHECKOUT_CREATE_SUCCESS: {
            let { checkout } = action.data.checkoutCreate
            return {
                ...state,
                selectedCheckout: checkout && checkout.id,
                error: false
            };
        }
        case Actions.RESET_CHECK_OUT: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};