import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { CUSTOMER_ADDRESS_UPDATE, CUSTOMER_LIST } from '../../Queries/index'
import { Constants } from '../../util/Constant'
const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
class EditAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            errors: {},
            error: false,
            errorMessage: '',
            giftCode: "",
            countryCode: Constants.COUNTRIES,
            show: 'show',
            showModal: true,
            dialog: 'dialog',
        }
    }

    handleProceed(e) {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { handleSubmitValidation, fields } = this.props
        if (handleSubmitValidation(["firstName", "lastName", "phone", "city", "province", "zip", "address", "country"])) {
            const callback = (response) => {
                let { customerAddress = {}, customerUserErrors } = response && response?.customerAddressUpdate || {}
                if (customerAddress === null) {
                    let error = customerUserErrors[0].message
                    this.setState({ error })
                } else {
                    let { token = {} } = this.props.token
                    let { accessToken } = token
                    let query = CUSTOMER_LIST(accessToken)
                    this.props.getCustomerList(query)
                    // this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
                    this.closeModal('success')
                }
            }
            let input = {
                address: {
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    phone: fields.phone,
                    address1: fields.address,
                    zip: fields.zip,
                    city: fields.city,
                    province: fields.province,
                    country: fields.country
                },
                id: this.props.addressId,
                customerAccessToken: accessToken
            }
            this.props.addresssUpdate(CUSTOMER_ADDRESS_UPDATE, input, callback)
        }
    }
    closeModal(e) {
        const { closeEditModal } = this.props
        if (e) {
            closeEditModal('sucess')
            this.setState({ error: '' })
        } else {
            closeEditModal()
            this.setState({ error: '' })
        }
    }
    renderUI() {
        let { countryCode, error, show, showModal, dialog } = this.state
        const { fields, handleChange, errors } = this.props
        return (
            <React.Fragment>
                {
                    showModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${show} address-modal`} tabIndex="-1" id="measure" aria-labelledby="measure" aria-hidden="true" style={{ display: showModal ? 'block' : 'none', padding_right: showModal ? ' 17px' : '' }} aria-modal="true" role={dialog}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="measureLabel">Edit Address</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-lg-12">
                                    <form id="measure-form">
                                        {
                                            (error) && (<div className="alert alert-danger" role="alert">
                                                {error}
                                            </div>)
                                        }
                                        <div className="row add_content">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" className="form-control" placeholder="" name="firstName" value={fields['firstName'] || ''} onChange={handleChange.bind(this, "firstName")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['firstName']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" className="form-control" placeholder="" name="lastName" value={fields['lastName'] || ''} onChange={handleChange.bind(this, "lastName")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['lastName']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <select className="form-control" name="country" value={fields["country"] || ''}
                                                        onChange={handleChange.bind(this, "country")}
                                                        error={errors["country"]}>
                                                        <option value=''>Choose Country</option>
                                                        {
                                                            Constants.COUNTRIES.map((o, index) => {
                                                                return (
                                                                    <option value={o.addressCode} key={index}>{o.country}</option>
                                                                )
                                                            })
                                                        }
                                                        {/* <option value='India' data-prefix="91">India</option>
                                                        <option value='Thailand' data-prefix="66">Thailand</option>
                                                        <option value="USA" data-prefix="1">USA</option>
                                                        <option value="Singapore" data-prefix="65">Singapore</option> */}
                                                    </select>
                                                    {errors && <small style={{ color: "red" }}>{errors['country']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <input type="text" className="form-control" placeholder="" name="address" value={fields['address'] || ''} onChange={handleChange.bind(this, "address")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['address']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" placeholder="" name="city" value={fields['city'] || ''} onChange={handleChange.bind(this, "city")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['city']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <input type="text" className="form-control" placeholder="" name="province" value={fields['province'] || ''} onChange={handleChange.bind(this, "province")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['province']}</small>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Zipcode</label>
                                                    <input type="text" className="form-control" placeholder="" name="zip" value={fields['zip'] || ''} onChange={handleChange.bind(this, "zip")} />
                                                    {errors && <small style={{ color: "red" }}>{errors['zip']}</small>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className=" form-group ">

                                                    {countryCode.map((i, j) => {
                                                        let { country, countryCode: code } = i
                                                        return (
                                                            fields['country'] === country ? (
                                                                <React.Fragment>
                                                                    <label key={j}>Telephone</label>
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text" >{code}</span>
                                                                        <input type="number" className="form-control" placeholder="" name="phone" value={fields['phone'] || ''} onChange={handleChange.bind(this, "phone")} onKeyDown={blockInvalidChar} />
                                                                    </div>
                                                                </React.Fragment>
                                                            ) : (
                                                                null
                                                            )
                                                        )
                                                    })}

                                                    {(errors && fields['country']) && <small style={{ color: "red" }}>{errors['phone']}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center py-2">
                                            <button type="button" className="btn btn-gren" onClick={(e) => this.handleProceed(e)}>Update</button>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    render() {
        const { isEditModal } = this.props
        return (
            <React.Fragment>
                {
                    isEditModal && (this.renderUI())
                }
            </React.Fragment>
        )
    }

}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    addresssUpdate: Actions.addresssUpdate,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    getCustomerList: Actions.getCustomerList,
})(EditAddress));