import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedAccents: {
        button: 'j8',
        hole: 'black',
        thread: 'black',
        selectedButtonsThreads: 'default',
        fill: "#000000",
        font: "Calibri",
        initials: "",
        selectedBlazerLining: 'default',
        selectedPocketSquare: 'without',
        blazerInnerFabric: 'gid://shopify/Product/6759393001636',
        pocketSquareFabric: 'gid://shopify/Product/6759386448036',
        pocketFabricName: '1000-16',
        liningFabric: '1000-16'
    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_BLAZER_ACCENTS: {
            let { selectedAccents } = state
            return {
                ...state,
                selectedAccents: {
                    ...selectedAccents,
                    [action.key]: action.data
                },
            };
        } case Actions.EDIT_BLAZER_ACCENTS: {
            return {
                ...state,
                selectedAccents: action.data,
            };
        } case Actions.REMOVE_BLAZER_MONOGRAM: {
            let { selectedAccents } = state
            return {
                ...state,
                selectedAccents: {
                    ...selectedAccents,
                    initials: ''
                },
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        } case Actions.RESET_STYLE: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};