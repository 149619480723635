const NAME = 'ORDER'

export const GET_ORDER_LIST = `${NAME}/GET_ORDER_LIST`;
export const GET_ORDER_LIST_SUCCESS = `${NAME}/GET_ORDER_LIST_SUCCESS`;
export const GET_ORDER_LIST_FAILURE = `${NAME}/GET_CUSTOMER_LIST_FAILURE`;


export const getOrder = store => store[NAME]
export const getOrderList = (query) => {
    return {
        type: GET_ORDER_LIST,
        query
    }
}

export const getOrderListSuccess = (data) => {
    return {
        type: GET_ORDER_LIST_SUCCESS,
        data
    }
}

export const getOrderListFailure = (error) => {
    return {
        type: GET_ORDER_LIST_FAILURE,
        error
    }
}

