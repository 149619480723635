import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import {  postQuery } from '../../service';
export function* addressCreate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.addressCreateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    yield put(Actions.addressCreateFailure(error));
    if (callback) {
      callback({ error })
    }
  }
}
export function* addressUpdate({ query, input, callback }) {
    try {
      const response = yield postQuery(query, input);
      yield put(Actions.addressUpdateSuccess(response));
      if (callback) {
        callback(response)
      }
    } catch (error) {
      yield put(Actions.addressUpdateFailure(error));
      if (callback) {
        callback({ error })
      }
    }
  }
  export function* addressDelete({ query, input, callback }) {
    try {
      const response = yield postQuery(query, input);
      yield put(Actions.addressDeleteSuccess(response));
      if (callback) {
        callback(response)
      }
    } catch (error) {
      yield put(Actions.addressDeleteFailure(error));
      if (callback) {
        callback({ error })
      }
    }
  }


  export function* setDefaultAddress({ query, input, callback }) {
    try {
      const response = yield postQuery(query, input);
      yield put(Actions.setDefaultAddressSuccess(response));
      if (callback) {
        callback(response)
      }
    } catch (error) {
      yield put(Actions.setDefaultAddressFailure(error));
      if (callback) {
        callback({ error })
      }
    }
  }

export default function* () {
  yield all([
    takeLatest(Actions.ADDRESS_CREATE, addressCreate),
    takeLatest(Actions.ADDRESS_UPDATE,addressUpdate),
    takeLatest(Actions.ADDRESS_DELETE,addressDelete),
    takeLatest(Actions.SET_DEFAULT_ADDRESS,setDefaultAddress)
  ]);
}
