export const CUSTOMER_ASSOCIATE = `mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
    checkout {
        id
    }
    customer {
        id
    }
      userErrors {
        field
        message
  }
}
  }`