const NAME = 'ADMIN_ORDER_ID'

export const GET_ADMIN_ORDER_ID = `${NAME}/GET_ADMIN_ORDER_ID`;
export const GET_ADMIN_ORDER_ID_SUCCESS = `${NAME}/GET_ADMIN_ORDER_ID_SUCCESS`;
export const GET_ADMIN_ORDER_ID_FAILURE = `${NAME}/GET_ADMIN_ORDER_ID_FAILURE`

export const UPDATE_ORDER_URL_BY_ADMIN = `${NAME}/UPDATE_ORDER_URL_BY_ADMIN`;
export const UPDATE_ORDER_URL_BY_ADMIN_SUCCESS = `${NAME}/UPDATE_ORDER_URL_BY_ADMIN_SUCCESS`;
export const UPDATE_ORDER_URL_BY_ADMIN_FAILURE = `${NAME}/UPDATE_ORDER_URL_BY_ADMIN_FAILURE`

export const GET_ADMIN_ORDER_LIST = `${NAME}/GET_ADMIN_ORDER_LIST`;
export const GET_ADMIN_ORDER_LIST_SUCCESS = `${NAME}/GET_ADMIN_ORDER_LIST_SUCCESS`;
export const GET_ADMIN_ORDER_LIST_FAILURE = `${NAME}/GET_ADMIN_ORDER_LIST_FAILURE`

export const getAdminOrders = store => store[NAME]
export const getAdminOrderId = (data, callback) => {
    return {
        type: GET_ADMIN_ORDER_ID,
        data,
        callback
    }
}

export const getAdminOrderIdSuccess = (data) => {
    return {
        type: GET_ADMIN_ORDER_ID_SUCCESS,
        data
    }
}

export const getAdminOrderIdFailure = (error) => {
    return {
        type: GET_ADMIN_ORDER_ID_FAILURE,
        error
    }
}

export const updateAdminOrderUrl = (data, query, callback) => {
    return {
        type: UPDATE_ORDER_URL_BY_ADMIN,
        data,
        query,
        callback
    }
}

export const updateAdminOrderUrlSuccess = (data) => {
    return {
        type: UPDATE_ORDER_URL_BY_ADMIN_SUCCESS,
        data
    }
}

export const updateAdminOrderUrlFailure = (error) => {
    return {
        type: UPDATE_ORDER_URL_BY_ADMIN_FAILURE,
        error
    }
}

export const getAdminOrderList = (query) => {
    return {
        type: GET_ADMIN_ORDER_LIST,
        query
    }
}

export const getAdminOrderListSuccess = (data) => {
    return {
        type: GET_ADMIN_ORDER_LIST_SUCCESS,
        data
    }
}

export const getAdminOrderListFailure = (error) => {
    return {
        type: GET_ADMIN_ORDER_LIST_FAILURE,
        error
    }
}
