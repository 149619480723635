import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_VEST } from '../../config/api_endpoint'
import Loader from '../Loader'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { FabricsDetails } from '../SelectedProducts/fabrics_details'
import VestZoom from '../Zoom/vest'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import CustomHeader from '../Header/customHeader'
import { CUSTOMER_ASSOCIATE, LINE_ITEMS_ADD, LINE_ITEMS_UPDATE } from '../../Queries'
import { toast } from 'react-toastify'
import { VEST_CHECKOUT } from '../ProductCheckout'
import { store } from '../../createStore'


class SuitSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: false,
            isShowFolded: true,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 10,
            back: 5,
            folded: 8,
            isFabricModal: false,
            isZoomModal: false
        }
    }
    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }
    openZoomModal() {
        this.setState({ isZoomModal: true })
    }
    closeZoomModal() {
        this.setState({ isZoomModal: false })
    }
    closeModal() {
        this.setState({ isFabricModal: false })
    }
    openModal() {
        this.setState({ isFabricModal: true })
    }

    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 10 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 5 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 8 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {

        const { selectedVestStyle: { selectedCombination }, activeChildMenu, selectedPosition, selectedVestAccents: { selectedAccents } } = props;
        let { selectedVestFabric } = props.selectedVestFabric || {}
        let { productId } = selectedVestFabric || {}
        let { button, hole, thread, selectedVestInnerFabric, selectedVestLining } = selectedAccents || {}
        let basepath = S3BASE_URL_VEST + '7081696952484/' + detectBrowserBaseUrl() + '/'
        let vestBaseAccentPath = S3BASE_URL_VEST + 'accents/'
        let transparentPath = S3BASE_URL_VEST + '7081696952484/' + detectBrowserBaseUrl() + '/'
        let vestTransparentPath = S3BASE_URL_VEST + 'transparent/transparent.png'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_VEST + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
            transparentPath = S3BASE_URL_VEST + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
        }
        let baseVestColorPath = ''
        if (selectedVestInnerFabric) {
            let parts = selectedVestInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            baseVestColorPath = vestBaseAccentPath + 'custom/' + shopifyId + '/'
        }

        let isShowFolded = true
        let isShowBack = false
        let isShowFront = false
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_VEST + 'modal/front_modal.png',
            backModal: S3BASE_URL_VEST + 'modal/back_modal.png',
            frontPant: basepath + 'pant/front_pant.png',
            frontPocket: basepath + 'transparent/transparent.png',
            foldedPocket: basepath + 'transparent/transparent.png',
            frontBreastPocket: basepath + 'transparent/transparent.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            backPant: basepath + 'pant/back_pant.png',
            foldedPant: basepath + 'pant/folded_pant.png',
            backStyle: basepath + 'style/back/lining.png',
            frontStyle: basepath + 'style/notch/diagonal/slim/front/single_three_breasted.png',
            foldedStyle: basepath + 'style/notch/diagonal/slim/folded/single_three_breasted.png',
            frontShirt: S3BASE_URL_VEST + 'modal/front_shirt.png',
            backShirt: S3BASE_URL_VEST + 'modal/back_shirt.png',
            frontTie: S3BASE_URL_VEST + 'modal/tie.png',
            foldedVestButtons: vestBaseAccentPath + '/buttons/folded/single_1_button.png',
            frontButtonThread: vestBaseAccentPath + 'threads/front/single_3_button_thread/button_center/white.png',
            frontButtonHole: vestBaseAccentPath + 'threads/front/single_3_button_thread/button_hole/white.png',
            foldedButtonThread: vestBaseAccentPath + 'threads/folded/single_3_button_thread/button_center/white.png',
            foldedButtonHole: vestBaseAccentPath + 'threads/folded/single_3_button_thread/button_hole/white.png',
            foldedCustomColor: selectedVestLining === 'default' ? vestTransparentPath : baseVestColorPath + detectBrowserBaseUrl() + '/folded/custom_color.png',
            backCustomColor: selectedVestLining === 'default' ? vestTransparentPath : baseVestColorPath + detectBrowserBaseUrl() + '/back/custom_color.png'
        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }

        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontPocket: selectedCombination[key].key === 'no_pocket' ? vestTransparentPath : basepath + selectedCombination[key].front_image_url,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? vestTransparentPath : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontBreastPocket: selectedCombination[key].key === 'no' ? vestTransparentPath : basepath + selectedCombination[key].front_image_url,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? vestTransparentPath : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'edge':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination[key].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].folded_image_url + '/' + selectedCombination[key].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination[key].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].folded_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedVestButtons: vestBaseAccentPath + 'buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontVestButtons: vestBaseAccentPath + 'buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontButtonThread: vestBaseAccentPath + selectedCombination['style'].front_button_thread_url + `${thread}.png`,
                        frontButtonHole: vestBaseAccentPath + selectedCombination['style'].front_button_hole_url + `${hole}.png`,
                        foldedButtonThread: vestBaseAccentPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`,
                        foldedButtonHole: vestBaseAccentPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination[key].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination[key].folded_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedPosition !== prevProps.selectedPosition) || (this.props.selectedVestStyle !== prevProps.selectedVestStyle) || (this.props.selectedVestFabric !== prevProps.selectedVestFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    showFront() {
        this.props.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.props.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.props.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }

    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }
    }

    resetCanvas() {
        this.props.resetStyle()
    }
    customerAssociate(checkoutId) {
        let { token: { accessToken } } = this.props.token
        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer } = response?.checkoutCustomerAssociateV2 || {}
            if (!customer) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }
    checkoutVest() {
        const { edit } = this.props.match.params
        let { selectedSuitFabric } = this.props.selectedSuitFabric
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedVestStyle, selectedVestAccents, selectedVestFabric: { selectedVestFabric } } = this.props

        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
        //     productType: Constants.PRODUCTS.SUIT
        // }

        // let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
        //     selectedAccents, selectedVestAccents, selectedVestStyle, Measurements, selectedVestFabric)
        let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
            selectedAccents, selectedVestAccents, selectedVestStyle, '', selectedVestFabric)
        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedVestFabric?.id,
                id: selectedVestFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedVestFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            console.log("response",response);
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
        }
    }
    onNextStep() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { history: { location: { search } }, isPant,
            isShirt, isSuit, isVest, isBlazer
        } = this.props || {}
        switch (search) {
            case '?fabric':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?style':
                if (isPant)
                    this.props.history.push(`${this.props.location.pathname}?measure`)
                else
                    this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?summary`)
                break;
            case '?summary':

                if (!accessToken) {
                    this.props.history.push("/login")
                } else {
                    if (isVest) {
                        this.checkoutVest()
                    }
                }
        }

    }
    render() {
        const isHomePage = this.props.location.pathname === '/';
        const { isScrolled } = this.state;
        const { selectedCombination, isShowFolded, isZoomModal, isShowFront, isShowBack, isFabricModal, isLoadingStarted, isLoading } = this.state;
        const { backModal, backPant, frontModal, frontPant, frontBreastPocket, frontPocket, foldedPocket,
            foldedBreastPocket, foldedPant, backStyle, foldedStyle, frontStyle, frontShirt, backShirt, frontTie, foldedVestButtons,
            frontVestButtons, frontButtonThread, frontButtonHole, foldedButtonHole, foldedButtonThread, foldedCustomColor, backCustomColor } = selectedCombination || {}
        const { selectedVestFabric } = this.props.selectedVestFabric
        const { isVests } = this.props.selectedVestStyle
        let { location } = this.props
        let { search } = location
        return (
            <React.Fragment>
                <div className="order-1 order-sm-1 order-lg-2 verify-casual h-100">
                    {isLoading && <Loader
                        isCanvas={true} />}
                    <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                        </ol>
                        {selectedCombination && (
                            <div className='carousel-inner' data-interval="false">
                                <p className='canvas-finish H-cb fs-16' onClick={() => this.onNextStep()}>{search === '?summary' ? "Finish" : "Next"}</p>
                                <CustomHeader />
                                <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFolded) &&
                                            <div className={`pi_container pi-vest w-75 m-auto pi_vest_cuff  ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_style"
                                                    src={foldedCustomColor.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedStyle.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedVestButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_breast_pocket"
                                                    src={foldedPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_pant"
                                                    src={foldedPant.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFront) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_vest_front  ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt shirt_man"
                                                    src={frontModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_shirt"
                                                    src={frontShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt front_tie"
                                                    src={frontTie}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_pant"
                                                    src={frontPant.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_fit"
                                                    src={frontStyle.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_button"
                                                    src={frontPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_cuff_fabric"
                                                    src={frontBreastPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={frontButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={frontVestButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={frontButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>}
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowBack) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_vest_back  ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt suit_back"
                                                    src={backModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_shirt"
                                                    src={backShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_pocket"
                                                    src={backPant.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_pocket"
                                                    src={backStyle.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={backCustomColor.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='canvas_details_icons d-flex flex-column align-items-center gap-12'>
                                    <div onClick={() => this.openZoomModal()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ZoomIn} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.zoom}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.openModal()}>
                                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#FabricDetail">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button>
                                        {/* <button className="btn" type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button> */}
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.fabricDetails}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.resetCanvas()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ResetIcon} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.reset}</p>
                                        {/* <i className="bi bi-arrow-clockwise canvas_icon_color fs-16"></i> */}
                                    </div>
                                </div>
                                {/* <ul className="nw_zoom">
                                    {
                                        !isVests ? <li className="cursor-pointer" onClick={() => this.props.showJacket()}>
                                            <img src={require('../../asset/images/suit/SVG/Show.svg')}
                                                className="img-fluid" alt="icon" />
                                            <p>Show Jacket</p>
                                        </li> : <li className="cursor-pointer" onClick={() => this.props.showJacket()}>
                                            <img src={require('../../asset/images/suit/SVG/Hide.svg')}
                                                className="img-fluid" alt="icon" />
                                            <p>Hide Jacket</p>
                                        </li>
                                    }

                                    <li className="cursor-pointer" onClick={() => this.openZoomModal()}> <img src={require('../../asset/images/zoom-in.svg')} className="img-fluid" alt="icon" /> <p>Zoom</p> </li>
                                    <li className="cursor-pointer" onClick={() => this.openModal()}> <img src={require('../../asset/images/fabric.svg')} className="img-fluid" alt="icon" /> <p>Fabric</p> </li>
                                    <li className="cursor-pointer" onClick={() => this.resetCanvas()}>
                                        <img src={require('../../asset/images/reset.png')} className="img-fluid" alt="icon" />
                                        <p>Reset</p>
                                    </li>
                                </ul> */}
                            </div >
                        )
                        }
                        <span className="carousel-control-prev" role="button" data-slide="prev"
                            onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </span>
                        <span className="carousel-control-next" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </span>
                    </div>
                    <FabricsDetails
                        openFabricDesign={isFabricModal}
                        closeModal={() => this.closeModal()}
                        selectedFabric={selectedVestFabric}
                    />
                    <VestZoom
                        selectedCombination={selectedCombination}
                        openModal={isZoomModal}
                        closeModal={() => this.closeZoomModal()}
                        isShowBack={isShowBack}
                        isShowFolded={isShowFolded}
                        isShowFront={isShowFront}
                    />
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedVestFabric: Actions.getVestFabric(state),
        selectedVestAccents: Actions.getSelectedVestAccent(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        token: Actions.getTokenSelector(store.getState()) || {},

    }
}

export default withRouter(connect(mapStateToProp, {
    resetStyle: Actions.resetStyle,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    

})(SuitSection));
