const NAME = 'SELECTED_STYLE'
export const UPDATE_SUIT_STYLE = `${NAME}/UPDATE_SUIT_STYLE`;
// export const RESET_SELECTED_SUIT_STYLE = `${NAME}/RESET_SELECTED_SUIT_STYLE`;
export const EDIT_SELECTED_SUIT_COMBINATION = `${NAME}/EDIT_SELECTED_SUIT_COMBINATION`
export const EDIT_THREE_SUITS = `${NAME}/EDIT_THREE_SUITS`;
export const getSelectedSuitStyle = store => store[NAME].suit

export const updateSelectedSuitStyle = (key, data) => {
    return {
        type: UPDATE_SUIT_STYLE,
        key,
        data
    }
}

export const editSelectedSuitCombination = (data) => {
    return {
        type: EDIT_SELECTED_SUIT_COMBINATION,
        data
    }
}


// export const resetSelectedSuitStyle = (key) => {
//     return {
//         type: RESET_SELECTED_SUIT_STYLE,
//         key
//     }
// }


export const editTripleSuit = (key) => {
    return {
        type: EDIT_THREE_SUITS,
        key
    }
}