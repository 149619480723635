import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class VestLining extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedVestsLining, openVestInnerFabrics } = this.props
        return (
            <React.Fragment>
                <div className="">
                    <p className="H-l fs-13 text-lowercase pb-2">Internal Lining</p>
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedVestsLining && selectedVestsLining.selectedVestLining === 'default' ? 'active_type' : ''}`}
                                onClick={() => openVestInnerFabrics('default')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/Default.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Default</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedVestsLining && selectedVestsLining.selectedVestLining === 'custom' ? 'active_type' : ''}`}
                                onClick={() => openVestInnerFabrics('custom')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/CustomColor.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Custom Color</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ul className="row mx-auto p-0 w-90 my-4 custom-collar cuff_desg">
                    <h6 className="col-12 text-left px-0">INTERNAL LINING</h6>
                    <li className={selectedVestsLining && selectedVestsLining.selectedVestLining === 'default' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/Default.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openVestInnerFabrics('default')} />
                        <p>BY DEFAULT</p>
                    </li>
                    <li className={selectedVestsLining && selectedVestsLining.selectedVestLining === 'custom' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/CustomColor.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openVestInnerFabrics('custom')} />
                        <p>CUSTOM COLOR</p>
                    </li>
                </ul> */}
            </React.Fragment>
        )
    }
}

export default withRouter(VestLining)