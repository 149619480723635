import React from 'react'
import { PATH, CONTENTS } from '../../globals/app-constraints'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import 'react-responsive-modal/styles.css';

export const HomeCarousel = () => {
    return (
        <div className='carousel_banner px-2'>
            <Carousel
                autoPlay={true}
                showIndicators={false}
                infiniteLoop={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                interval={3000}
                className='banner_content'
            >
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column gap-6 align-items-start'>
                        <p className='H-cb carousel-head text-uppercase text-center w-100'>{CONTENTS.home.carouselcontent.sliderHead1}</p>
                        <div className='d-flex justify-content-center align-items-center gap-align w-100'>
                            <div className='banner-slider-line-img'></div>
                            <p className='H-l carousel-subtext'><i>{CONTENTS.home.carouselcontent.sliderContent1}</i></p>
                            <div className='banner-slider-line-img'></div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column gap-6 align-items-start'>
                        <p className='H-cb carousel-head text-uppercase text-center w-100'>{CONTENTS.home.carouselcontent.sliderHead2}</p>
                        <div className='d-flex justify-content-center align-items-center gap-align w-100'>
                            <div className='banner-slider-line-img'></div>
                            <p className='H-l carousel-subtext'><i>{CONTENTS.home.carouselcontent.sliderContent2}</i></p>
                            <div className='banner-slider-line-img'></div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column gap-6 align-items-start'>
                        <p className='H-cb carousel-head text-uppercase text-center w-100'>{CONTENTS.home.carouselcontent.sliderHead3}</p>
                        <div className='d-flex justify-content-center align-items-center gap-align w-100'>
                            <div className='banner-slider-line-img'></div>
                            <p className='H-l carousel-subtext'><i>{CONTENTS.home.carouselcontent.sliderContent3}</i></p>
                            <div className='banner-slider-line-img'></div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    )
}