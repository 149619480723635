const NAME = 'CUSTOMER_ORDERS'

export const GET_CUSTOMER_ORDERS = `${NAME}/GET_CUSTOMER_ORDERS`;
export const GET_CUSTOMER_ORDERS_SUCCESS = `${NAME}/GET_CUSTOMER_ORDERS_SUCCESS`;
export const GET_CUSTOMER_ORDERS_FAILURE = `${NAME}/GET_CUSTOMER_ORDERS_FAILURE`;

export const getCustomerOrders = store => store[NAME]

export const customerOrders = (query) => {
    return {
        type: GET_CUSTOMER_ORDERS,
        query
    }
}

export const customerOrdersSuccess = (orders) => {
    return {
        type: GET_CUSTOMER_ORDERS_SUCCESS,
        orders
    }
}

export const customerOrdersFailure = (error) => {
    return {
        type: GET_CUSTOMER_ORDERS_FAILURE,
        error
    }
}
