import { defaultVestFabricObject } from '../../../util/defaultFabricObject';
import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedVestFabric: defaultVestFabricObject()
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_VEST_FABRIC: {
            let { selectedVestFabric } = state
            return {
                ...state,
                selectedVestFabric: {
                    ...selectedVestFabric,
                    ...action.data
                }
            };
        }
        case Actions.DEFAULT_VEST_FABRIC: {
            return {
                ...state,
                selectedVestFabric: defaultVestFabricObject(action.data),
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};