import React, { useState } from 'react'
import './asset/css/custom.css'
import './asset/css/style.css'
import './asset/css/canvas_style.css'
import './asset/css/owl.carousel.min.css'
import './asset/css/owl.theme.default.min.css'
import './asset/css/new_style.css'
// import './asset/css/new_version_style.css'
import './asset/css/akal_style.css'
import Home from './container/Home'
import { Route, Switch } from "react-router-dom"
import Routes from './config/routes'
import renderHocComponent from './component/HocComponent'
import ProductDetails from './container/ProductDetails'
import Cart from './container/Cart'
import Payment from './container/Payment'
import OrderSuccess from './container/OrderSuccess'
import Signup from './container/Signup'
import Welcome from './container/Welcome'
import StandardMeasurement from './container/measurements/StandardMeasurement'
import Sizecomplete from './container/measurements/SizeComplete'
// import OrderStatus from './container/OrderStatus'
// import Tape from './container/measurements/TapeScreen'
import ResetByUrl from './container/ResetByUrl'
import Canvas from './container/Canvas'
import Admin_Products from './container/Admin_Products'
import AdminCanvas from './container/Admin_Canvas'
import YourAddress from './container/YourAddress'
import Story from './container/Footer/Story'
import Exchange from './container/Footer/Exchange'
import Corporate from './container/Footer/Corporate'
import Terms from './container/Footer/terms'
import Privacy from './container/Footer/privacy'
import Faq from './container/Footer/Faq'
import Blog from './container/Footer/Blog'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import PLP from './container/PLP'
import Login from './container/Login'
import { ToastContainer } from 'react-toastify'
import AdminSearch from './container/AdminSearch'

const AuthRoute = props => {
  const { component, path, exact, isShirt, isPant, isSuit,
    isBlazer, removeFooter, isCheckout, headerOne,
    isShowLogin, removeHeader, isCanvas, isVest,
    isAdminCanvas, isMeasurement } = props;
  return (
    <Route
      path={path}
      exact={exact}
      component={renderHocComponent(component, isShirt, isPant, isSuit,
        isBlazer, removeFooter, isCheckout, headerOne, isShowLogin,
        removeHeader, isCanvas, isVest, isAdminCanvas, isMeasurement)}
    />
  );
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: window.innerWidth
    };
  }
  componentDidMount() {
    // Check for iframes and remove them
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      if (iframe.style.position === 'fixed') {
        iframe.parentNode.removeChild(iframe);
      }
    });
  }
  render() {
    const screenWidth = this.state.maxWidth
    return (
      <React.Fragment>
        <ToastContainer />
        <Switch>
          <AuthRoute
            path={Routes.HOME}
            exact component={Home}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.SIGNUP}
            exact component={Signup}
            removeFooter={true}
            removeHeader={true}>
          </AuthRoute>

          <AuthRoute
            path={Routes.WELCOME}
            exact component={Welcome}
            removeFooter={false}
            headerOne={true} >
          </AuthRoute>

          <Route
            path={Routes.FORGET_RESET_PASSWORD}
            exact
            component={ResetByUrl}
          >
          </Route>

          <AuthRoute
            path={Routes.CART}
            exact
            component={Cart}
            removeFooter={screenWidth >= 993 ? false : true}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.YOUR_ADDRESS_PAGE}
            exact
            component={YourAddress}
            removeFooter={true}
            headerOne={true}
          >
          </AuthRoute>


          {/* {User Common Routes} */}

          <AuthRoute
            path={Routes.SHIRT}
            exact
            component={PLP}
            headerOne={true}
            isShirt={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.SUIT}
            exact
            component={PLP}
            headerOne={true}
            isSuit={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.PANT}
            exact
            component={PLP}
            headerOne={true}
            isPant={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.BLAZER}
            exact
            component={PLP}
            headerOne={true}
            isBlazer={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.VEST}
            exact
            component={PLP}
            headerOne={true}
            isVest={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.SHIRT_DETAILS}
            exact
            component={ProductDetails}
            headerOne={true}
            isShirt={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.PANT_DETAILS}
            exact
            component={ProductDetails}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.BLAZER_DETAILS}
            exact
            component={ProductDetails}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.VEST_DETAILS}
            exact
            component={ProductDetails}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.SUIT_DETAILS}
            exact
            component={ProductDetails}
            headerOne={true}
          >
          </AuthRoute>


          {/* <AuthRoute
          path={Routes.PANT}
          exact component={Pant}
          headerOne={true}
        >
        </AuthRoute>

        <AuthRoute
          path={Routes.BLAZER}
          exact
          component={Blazer}
          headerOne={true}
        >
        
        </AuthRoute>

        <AuthRoute
          path={Routes.SUIT}
          exact component={Suit}
          headerOne={true}
        >
        </AuthRoute>

      

        

        <AuthRoute
          path={Routes.SUIT_DETAILS}
          exact
          component={ProductDetails}
          headerOne={true}
        >
        </AuthRoute>

        <AuthRoute
          path={Routes.BLAZER_DETAILS}
          exact
          component={ProductDetails}
          headerOne={true}
        >
        </AuthRoute> */}

          {/* {Products Routes} */}


          <AuthRoute
            path={Routes.CANVAS_SHIRT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isShirt={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_PANT}
            exact component={Canvas}
            removeHeader={screenWidth >= 993 ? true : false}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_SUIT}
            exact component={Canvas}
            removeHeader={screenWidth >= 993 ? true : false}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            headerOne={false}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_BLAZER}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isBlazer={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_VESTS}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isVest={true}
            isCanvas={true}
          >
          </AuthRoute>

          {/* Canvas Routes */}




          <AuthRoute
            path={Routes.CANVAS_SHIRT_EDIT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isShirt={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_PANT_EDIT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isPant={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_SUIT_EDIT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isSuit={true}
            isCanvas={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CANVAS_BLAZER_EDIT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isBlazer={true}
            isCanvas={true}
          >
          </AuthRoute>


          <AuthRoute
            path={Routes.CANVAS_VEST_EDIT}
            exact component={Canvas}
            removeFooter={true}
            removeHeader={screenWidth >= 993 ? true : false}
            isVest={true}
            isCanvas={true}
          >
          </AuthRoute>

          {/* {Canvas Edit Routes} */}



          <AuthRoute
            path={Routes.ORDERSTATUS}
            exact
            component={Welcome}
            headerOne={true}
            removeFooter={true}>
          </AuthRoute>

          <AuthRoute
            path={Routes.PAYMENT}
            exact component={Payment}
            removeFooter={true}
            headerOne={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.SHIPPING_ADDRESS}
            exact
            component={YourAddress}
            removeFooter={true}
            headerOne={true}
            isCheckout={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.ORDERSUCCESS}
            exact
            component={OrderSuccess}
            headerOne={true}
            removeFooter={true}>

          </AuthRoute>

          {/* Orders Routes */}

          <AuthRoute
            path={Routes.GET_ADMIN_ORDERS}
            exact
            component={Admin_Products}
            removeFooter={true}
            removeHeader={true}>
          </AuthRoute>

          <AuthRoute
            path={Routes.ADMIN_ORDERS_DETAILS}
            exact
            component={AdminCanvas}
            removeFooter={true}
            removeHeader={true}
            isAdminCanvas={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.ADMIN_SEARCH}
            exact
            component={AdminSearch}
            removeFooter={true}
            removeHeader={true}
            isAdminCanvas={true}
          >
          </AuthRoute>

          {/* Admin Routes */}


          <AuthRoute
            path={Routes.STANDARD_SHIRT_SIZE}
            exact component={StandardMeasurement}
            removeFooter={true}
            headerOne={true}
            isShirt={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.STANDARD_PANT_SIZE}
            exact component={StandardMeasurement}
            removeFooter={true}
            headerOne={true}
            isPant={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.STANDARD_SUIT_SIZE}
            exact component={StandardMeasurement}
            removeFooter={true}
            headerOne={true}
            isSuit={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.STANDARD_BLAZER_SIZE}
            exact component={StandardMeasurement}
            removeFooter={true}
            headerOne={true}
            isBlazer={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.STANDARD_VESTS_SIZE}
            exact component={StandardMeasurement}
            removeFooter={true}
            headerOne={true}
            isVest={true}
          >
          </AuthRoute>


          {/* Standard Measurement */}

          {/* <AuthRoute
            path={Routes.CUSTOM_SHIRT_MEASUREMENT}
            exact
            component={Tape}
            isCheckout={true}
            removeFooter={true}
            isShirt={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>


          <AuthRoute
            path={Routes.CUSTOM_PANT_MEASUREMENT}
            exact
            component={Tape}
            isCheckout={true}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOM_SUIT_MEASUREMENT}
            exact
            component={Tape}
            isCheckout={true}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOM_BLAZER_MEASUREMENT}
            exact
            component={Tape}
            isCheckout={true}
            removeFooter={true}
            isBlazer={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOM_VESTS_MEASUREMENT}
            exact
            component={Tape}
            isCheckout={true}
            removeFooter={true}
            isVest={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute> */}

          {/* Custom Measurement */}


          {/* <AuthRoute
            path={Routes.CUSTOMER_SHIRT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isShirt={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>


          <AuthRoute
            path={Routes.CUSTOMER_PANT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOMER_SUIT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOMER_BLAZER_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isBlazer={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOMER_VESTS_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isVest={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute> */}


          {/* Customer Measurement */}


          <AuthRoute
            path={Routes.CUSTOM_SHIRT_MEASUREMENT_SUCCESS}
            exact
            component={Sizecomplete}
            isCheckout={true}
            removeFooter={true}
            isCanvas={true}
            isShirt={true}
            isMeasurement={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.CUSTOM_PANT_MEASUREMENT_SUCCESS}
            exact
            component={Sizecomplete}
            isCheckout={true}
            removeFooter={true}
            isCanvas={true}
            isPant={true}
            isMeasurement={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.CUSTOM_SUIT_MEASUREMENT_SUCCESS}
            exact
            component={Sizecomplete}
            isCheckout={true}
            removeFooter={true}
            isCanvas={true}
            isSuit={true}
            isMeasurement={true}
          >
          </AuthRoute>
          <AuthRoute
            path={Routes.CUSTOM_BLAZER_MEASUREMENT_SUCCESS}
            exact
            component={Sizecomplete}
            isCheckout={true}
            removeFooter={true}
            isCanvas={true}
            isBlazer={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CUSTOM_VESTS_MEASUREMENT_SUCCESS}
            exact
            component={Sizecomplete}
            isCheckout={true}
            removeFooter={true}
            isCanvas={true}
            isVest={true}
            isMeasurement={true}
          >
          </AuthRoute>

          {/* Custom Measurement Success Routes */}


          {/* <AuthRoute
            path={Routes.EDIT_CUSTOMER_SHIRT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isShirt={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>


          <AuthRoute
            path={Routes.EDIT_CUSTOMER_PANT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_CUSTOMER_SUIT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_CUSTOMER_BLAZER_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isBlazer={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_CUSTOMER_VESTS_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isVest={true}
            isCanvas={true}
            isMeasurement={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_SHIRT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isShirt={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>


          <AuthRoute
            path={Routes.EDIT_PANT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_SUIT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_BLAZER_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isBlazer={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_VESTS_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isVest={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          {/* No auth edit measurement routes */}
          {/* <AuthRoute
            path={Routes.EDIT_AUTH_SHIRT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isShirt={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_AUTH_PANT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isPant={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_AUTH_SUIT_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isSuit={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_AUTH_BLAZER_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isBlazer={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.EDIT_AUTH_VESTS_MEASUREMENT}
            exact
            component={Tape}
            removeFooter={true}
            isVest={true}
            isCanvas={true}
            isMeasurement={true}
            isCheckout={true}
          >
          </AuthRoute>  */}

          {/* Auth Edit Measurement Routes */}

          <AuthRoute
            path={Routes.OUR_STORY}
            exact component={Story}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.FAQ}
            exact component={Faq}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.BLOG}
            exact component={Blog}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.RETURN_EXCHANGE}
            exact component={Exchange}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.CORPORATE_SERVICE}
            exact component={Corporate}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.TERMS_AND_CONDITION}
            exact component={Terms}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.PRIVACY_POLICY}
            exact component={Privacy}
            headerOne={true}
          >
          </AuthRoute>

          <AuthRoute
            path={Routes.LOGIN}
            exact component={Login}
            removeHeader={true}
            removeFooter={true}
          >
          </AuthRoute>



        </Switch>
      </React.Fragment>

    );
  }
}

export default App;