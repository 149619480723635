import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class AccentsButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { selectedAccentsButton, selectAccentsButton } = this.props
        return (
            <React.Fragment>
                <div className="mt-2">
                    <p className="H-l fs-13 pb-2 text-lowercase">Buttons</p>
                    <div className='d-flex flex-wrap gap-lg-3 gap-2'>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'j8' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('j8')} src={images('./images/suit/suit_button_images/J8.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'j9' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('j9')} src={images('./images/suit/suit_button_images/J9.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'bb2' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('bb2')} src={images('./images/suit/suit_button_images/BB2.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'bb6' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('bb6')} src={images('./images/suit/suit_button_images/BB6.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'bb9' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('bb9')} src={images('./images/suit/suit_button_images/BB9.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'eb13' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('eb13')} src={images('./images/suit/suit_button_images/EB13.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'eb14' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('eb14')} src={images('./images/suit/suit_button_images/EB14.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'fh12' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('fh12')} src={images('./images/suit/suit_button_images/FH12.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'hb4' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('hb4')} src={images('./images/suit/suit_button_images/HB4.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'oc2' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('oc2')} src={images('./images/suit/suit_button_images/OC2.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'oc3' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('oc3')} src={images('./images/suit/suit_button_images/OC3.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'sb1' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('sb1')} src={images('./images/suit/suit_button_images/SB1.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                        <div className={`cursor-pointer text-left ${selectedAccentsButton && selectedAccentsButton.button === 'sb4' ? 'active_button' : ''}`}>
                            <div className=''>
                                <img onClick={() => selectAccentsButton('sb4')} src={images('./images/suit/suit_button_images/SB4.png')} className="img-fluid" alt="icons" style={{ width: "50px", height: "50px", margin: "4px" }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ul className="row mx-auto p-0 w-90 my-4 mono btn_desg" id="btn_desg_vbl">
                    <h6 className="col-12 text-left px-0">BUTTONS</h6>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'j8' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('j8')} src={images('./images/suit/suit_button_images/J8.png')} className="img-fluid" alt="icons" />
                        <p>J8</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'j9' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('j9')} src={images('./images/suit/suit_button_images/J9.png')} className="img-fluid" alt="icons" />
                        <p>J9</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'bb2' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('bb2')} src={images('./images/suit/suit_button_images/BB2.png')} className="img-fluid" alt="icons" />
                        <p>BB2</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'bb6' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('bb6')} src={images('./images/suit/suit_button_images/BB6.png')} className="img-fluid" alt="icons" />
                        <p>BB6</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'bb9' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('bb9')} src={images('./images/suit/suit_button_images/BB9.png')} className="img-fluid" alt="icons" />
                        <p>BB9</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'eb13' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('eb13')} src={images('./images/suit/suit_button_images/EB13.png')} className="img-fluid" alt="icons" />
                        <p>EB13</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'eb14' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('eb14')} src={images('./images/suit/suit_button_images/EB14.png')} className="img-fluid" alt="icons" />
                        <p>EB14</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'fh12' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('fh12')} src={images('./images/suit/suit_button_images/FH12.png')} className="img-fluid" alt="icons" />
                        <p>FH12</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'hb4' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('hb4')} src={images('./images/suit/suit_button_images/HB4.png')} className="img-fluid" alt="icons" />
                        <p>HB4</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'oc2' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('oc2')} src={images('./images/suit/suit_button_images/OC2.png')} className="img-fluid" alt="icons" />
                        <p>OC2</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'oc3' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('oc3')} src={images('./images/suit/suit_button_images/OC3.png')} className="img-fluid" alt="icons" />
                        <p>OC3</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'sb1' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('sb1')} src={images('./images/suit/suit_button_images/SB1.png')} className="img-fluid" alt="icons" />
                        <p>SB1</p>
                    </li>
                    <li className={`col-lg-3 ${selectedAccentsButton && selectedAccentsButton.button === 'sb4' ? 'active' : ''}`}>
                        <img onClick={() => selectAccentsButton('sb4')} src={images('./images/suit/suit_button_images/SB4.png')} className="img-fluid" alt="icons" />
                        <p>SB4</p>
                    </li>
                </ul> */}
            </React.Fragment>
        )
    }
}
export default withRouter(AccentsButtons)