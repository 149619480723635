import React from 'react'
import Suitproduct from '../../component/Product/Suit'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { GET_COLLECTION_LIST } from '../../Queries/collection'
import { Collection } from '../../config/constant'

class Suit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            perPage: 50,
            sortBy: "BEST_SELLING"
        }
    }
    componentDidMount() {
        const { sortBy, perPage } = this.state
        let query = GET_COLLECTION_LIST(Collection.plpSuit, perPage, sortBy, false)
        this.props.getPLPList(query)
    }
    render() {
        const { plpList } = this.props.getPLP || {}
        const { collectionByHandle } = (plpList && plpList.data) || {}
        return (
            <React.Fragment>
                <Suitproduct collections={collectionByHandle} isSuit={true} />
            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        getPLP: Actions.getPLP(state)
    }
}
export default withRouter(connect(mapStateToProp, {
    getPLPList: Actions.getPLPList
})(Suit));