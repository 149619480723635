const NAME = 'SELECTED_STYLE'
export const UPDATE_BLAZER_STYLE = `${NAME}/UPDATE_BLAZER_STYLE`;

export const EDIT_BLAZER_STYLE = `${NAME}/EDIT_BLAZER_STYLE`

export const ADMIN_UPDATE_BLAZER_STYLE = `${NAME}/ADMIN_UPDATE_BLAZER_STYLE`;

export const getSelectedBlazerStyle = store => store[NAME].blazer
export const updateBlazerStyle = (key, data) => {
    return {
        type: UPDATE_BLAZER_STYLE,
        key,
        data
    }
}

export const editBlazerStyle = (data) => {
    return {
        type: EDIT_BLAZER_STYLE,
        data
    }
}


export const adminUpdateBlazerStyle = (data) => {
    return {
        type: ADMIN_UPDATE_BLAZER_STYLE,
        data
    }
}