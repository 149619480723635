import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { RESET_PASSWORD } from '../../Queries'
import { CUSTOMER_LIST } from '../../Queries/customerList'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from '../../createStore'
import { isFieldEmpty } from '../../util/validation'
import Loader from '../../component/Loader'
import ButtonDesign from '../../component/button'

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            isChecked: false,
            errors: {
                password: ''
            },
            showpassword: false
        }
    }


    manageShowPassword() {
        this.setState({ showpassword: !this.state.showpassword })
    }

    _getCustomerDetail() {
        let { token = {} } = this.props.token
        let { accessToken } = token
        let query = CUSTOMER_LIST(accessToken)
        this.props.getCustomerList(query)
    }


    handleChange(e) {
        let { fields } = this.state
        const { name, value } = e.target
        fields[name] = value
        this.setState({ fields });
    }
    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'password':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the Password'
                    this.setState({ errors })
                    return false
                } else if (fields[field].length < 4) {
                    errors[field] = 'Password must be more than 4 Characters'
                    this.setState({ errors })
                    return false
                }
                break;
            default:
                return null
        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }
    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    updateProfile() {
        if (this.handleSubmitValidation(["password"])) {
            const callback = (response) => {
                let { customer, customerUserErrors, customerAccessToken } = response?.customerUpdate || {}
                let errorMessage = customerUserErrors.map(o => o.message)
                if (!customer) {
                    toast.error(`${errorMessage}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.success('Password Successfully Updated ', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.closeResetPasswordModal('success')
                    this._getCustomerDetails(customerAccessToken.accessToken)
                }
            }
            let fields = this.state.fields
            let { token = {} } = this.props.token
            let { accessToken } = token
            let input = {
                customerAccessToken: accessToken,
                customer: {
                    password: fields.password,
                }
            }
            this.props.customerResetPassword(RESET_PASSWORD, input, callback)

        }
    }
    _getCustomerDetails(accessToken) {
        let query = CUSTOMER_LIST(accessToken)
        this.props.getCustomerList(query)
    }
    closeResetPasswordModal(message) {
        let { closeResetPasswordModal } = this.props
        if (message) {
            closeResetPasswordModal(message)
        } else {
            closeResetPasswordModal()
        }
    }
    handleNextKeyUp(e) {
        if (e.key === 'Enter') { this.updateProfile(e) }
    }

    render() {
        let { errors, isLoading, showpassword } = this.state
        return (
            <React.Fragment>
                {isLoading && <Loader />}
                <div className={`modal-backdrop fade show`}></div>
                <div className={`forget_password_modal modal fade show`}
                    id="loginpop"
                    tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-modal="true" >
                    <div className="modal-dialog">
                        <div className="modal-content rounded-0">
                            <span className='measurement_btn_close mx-lg-4 mx-3 mt-lg-3 mt-2 mb-lg-0 d-inline-block text-end cursor-pointer'
                                data-dismiss="modal" onClick={() => this.closeResetPasswordModal()}>
                                <i className="bi bi-x fs-19 text_black"></i>
                            </span>
                            <div className="modal-body pt-0">
                                <h1 className='fs-24 H-m text-center mb-3'>Reset Password</h1>
                                {
                                    (errors['password']) && (<div className="alert alert-danger" role="alert">{errors['password']} </div>)
                                }
                                <div className='d-flex flex-column gap-2 email_password_align'>
                                    <div className='cart_form'>
                                        <label className='form-label login_input_text_color'>Password</label>
                                        <div className='input-group'>
                                            <input
                                                type={showpassword ? 'text' : "password"}
                                                className="form-control login_form_control"
                                                placeholder="Please enter your new password here"
                                                name="password"
                                                autoComplete="off"
                                                onKeyUp={(event) => this.handleNextKeyUp(event)}
                                                onChange={(e) => this.handleChange(e)} />
                                            <span onClick={() => this.manageShowPassword()}
                                                className="input-group-text forgot_password_input_text cursor-pointer"
                                                id="basic-addon1"><i className="bi bi-eye-fill"></i></span>
                                        </div>
                                    </div>
                                    {errors && <small style={{ color: "red" }}>{errors['password']}</small>}
                                </div>
                                <ButtonDesign
                                    btnWidth={'button-primary'}
                                    className={'btn-getmore fs-16 H-cb py-12'}
                                    ButtonValue={'submit'}
                                    NoImg
                                    onClick={(event) => this.updateProfile(event)}
                                    Noarrow
                                />
                                {/* <button
                                    className='btn common_btn primary_btn w-100 login_btn'
                                    type="button" onClick={(event) => this.updateProfile(event)} >Reset Password</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
    }
}

export default withRouter(connect(mapStateToProp, {
    customerResetPassword: Actions.customerResetPassword,
    getCustomerList: Actions.getCustomerList,
})(ResetPassword));