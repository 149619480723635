import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'


class AddressList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { addressList, defaultAddress, editAddress, onDefaultAddress, onAddressDelete } = this.props || {}
        let currentAddressList = addressList?.edges?.filter((aObj) => aObj?.node?.id != defaultAddress?.id)
        return (
            <React.Fragment>
                <div className="col-lg-4 px-2">
                    <div className="profile_measurement px-3 py-3">
                        <p className="text-end m-0 cursor-pointer">
                            <i className="bi bi-pencil-square cursor-pointer text_grey fs-14 p-2"
                                onClick={() => editAddress(defaultAddress)}></i>
                            <i className="bi bi-archive cursor-pointer text_grey fs-14 ml-2"
                                onClick={() => onAddressDelete(defaultAddress?.id)}></i>
                        </p>
                        <p className="m-0">{defaultAddress?.name}</p>
                        <p className="m-0">
                            {defaultAddress?.address1},{defaultAddress?.address2}
                            <span className="d-block">Phone-{defaultAddress?.phone}</span>
                            <span className="d-block">{defaultAddress?.city}-{defaultAddress?.zip}</span>
                            <span>{defaultAddress?.province},{defaultAddress?.country}</span>
                        </p>
                        <p className='fs-14 f-sb mt-2 mb-1'>Default address</p>
                    </div>
                </div>
                {currentAddressList?.map((aObj, index) => {
                    const { address1, address2, city, name, province, zip, country, id, phone } = aObj?.node || {};
                    return (
                        <div className="col-lg-4 px-2" key={index}>
                            <div className="profile_measurement px-3 py-3">
                                <p className="text-end m-0 cursor-pointer">
                                    <i className="bi bi-pencil-square cursor-pointer text_grey fs-14 p-2" onClick={() => editAddress(aObj?.node)}></i>
                                    <i className="bi bi-archive cursor-pointer text_grey fs-14 ml-2" onClick={() => onAddressDelete(aObj?.node?.id)}></i>
                                </p>
                                <p className="m-0">{name}</p>
                                <p className="m-0">
                                    {address1},{address2}
                                    <span className="d-block">Phone-{phone}</span>
                                    <span className="d-block">{city}-{zip}</span>
                                    <span>{province},{country}</span>
                                </p>
                                <p className='fs-14 f-sb mt-2 mb-1 cursor-pointer'
                                    onClick={() => onDefaultAddress(id)}
                                    style={{ textDecoration: 'underline' }}>Make as default</p>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }
}

export default withRouter(AddressList)