const NAME = 'CHECKOUT'

export const CHECKOUT_CREATE = `${NAME}/CHECKOUT_CREATE`;
export const CHECKOUT_CREATE_SUCCESS = `${NAME}/CHECKOUT_CREATE_SUCCESS`;
export const CHECKOUT_CREATE_FAILURE = `${NAME}/CHECKOUT_CREATE_FAILURE`;

export const CHECKOUT_LINE_ITEMS_ADD = `${NAME}/CHECKOUT_LINE_ITEMS_ADD`;
export const CHECKOUT_LINE_ITEMS_ADD_SUCCESS = `${NAME}/CHECKOUT_LINE_ITEMS_ADD_SUCCESS`;
export const CHECKOUT_LINE_ITEMS_ADD_FAILURE = `${NAME}/CHECKOUT_LINE_ITEMS_ADD_FAILURE`;


export const CHECKOUT_LINE_ITEMS_REMOVE = `${NAME}/CHECKOUT_LINE_ITEMS_REMOVE`;
export const CHECKOUT_LINE_ITEMS_REMOVE_SUCCESS = `${NAME}/CHECKOUT_LINE_ITEMS_REMOVE_SUCCESS`;
export const CHECKOUT_LINE_ITEMS_REMOVE_FAILURE = `${NAME}/CHECKOUT_LINE_ITEMS_REMOVE_FAILURE`;


export const CHECKOUT_CUSTOMER_ASSOCIATE = `${NAME}/CHECKOUT_CUSTOMER_ASSOCIATE`;
export const CHECKOUT_CUSTOMER_ASSOCIATE_SUCCESS = `${NAME}/CHECKOUT_CUSTOMER_ASSOCIATE_SUCCESS`;
export const CHECKOUT_CUSTOMER_ASSOCIATE_FAILURE = `${NAME}/CHECKOUT_CUSTOMER_ASSOCIATE_FAILURE`;

export const CHECKOUT_GIFT_CARD_ADD = `${NAME}/CHECKOUT_GIFT_CARD_ADD`;
export const CHECKOUT_GIFT_CARD_ADD_SUCCESS = `${NAME}/CHECKOUT_GIFT_CARD_ADD_SUCCESS`;
export const CHECKOUT_GIFT_CARD_ADD_FAILURE = `${NAME}/CHECKOUT_GIFT_CARD_ADD_FAILURE`;

export const SHIPPING_ADDRESS_ADD = `${NAME}/SHIPPING_ADDRESS_ADD`;
export const SHIPPING_ADDRESS_ADD_SUCCESS = `${NAME}/SHIPPING_ADDRESS_ADD_SUCCESS`;
export const SHIPPING_ADDRESS_ADD_FAILURE = `${NAME}/SHIPPING_ADDRESS_ADD_FAILURE`;

export const GET_RECENT_CHECKOUT = `${NAME}/GET_RECENT_CHECKOUT`;
export const GET_RECENT_CHECKOUT_SUCCESS = `${NAME}/GET_RECENT_CHECKOUT_SUCCESS`;
export const GET_RECENT_CHECKOUT_FAILURE = `${NAME}/GET_INCOMPLETE_CHECKOUT_FAILURE`;


export const CHECKOUT_LINE_ITEMS_UPDATE = `${NAME}/CHECKOUT_LINE_ITEMS_UPDATE`;
export const CHECKOUT_LINE_ITEMS_UPDATE_SUCCESS = `${NAME}/CHECKOUT_LINE_ITEMS_UPDATE_SUCCESS`;
export const CHECKOUT_LINE_ITEMS_UPDATE_FAILURE = `${NAME}/CHECKOUT_LINE_ITEMS_UPDATE_FAILURE`;


export const CHECKOUT_DISCOUNT_ADD = `${NAME}/CHECKOUT_DISCOUNT_ADD`;
export const CHECKOUT_DISCOUNT_ADD_SUCCESS = `${NAME}/CHECKOUT_DISCOUNT_ADD_SUCCESS`;
export const CHECKOUT_DISCOUNT_ADD_FAILURE = `${NAME}/CHECKOUT_DISCOUNT_ADD_FAILURE`;

export const SHIPPING_LINE_UPDATE = `${NAME}/SHIPPING_LINE_UPDATE`;
export const SHIPPING_LINE_UPDATE_SUCCESS = `${NAME}/SHIPPING_LINE_UPDATE_SUCCESS`;
export const SHIPPING_LINE_UPDATE_FAILURE = `${NAME}/SHIPPING_LINE_UPDATE_FAILURE`;


export const CHECKOUT_ATTRIBUTES_UPDATE = `${NAME}/CHECKOUT_ATTRIBUTES_UPDATE`;
export const CHECKOUT_ATTRIBUTES_UPDATE_SUCCESS = `${NAME}/CHECKOUT_ATTRIBUTES_UPDATE_SUCCESS`;
export const CHECKOUT_ATTRIBUTES_UPDATE_FAILURE = `${NAME}/CHECKOUT_ATTRIBUTES_UPDATE_FAILURE`;


export const GET_CHECKOUT_DETAILS = `${NAME}/GET_CHECKOUT_DETAILS`;
export const GET_CHECKOUT_DETAILS_SUCCESS = `${NAME}/GET_CHECKOUT_DETAILS_SUCCESS`;
export const GET_CHECKOUT_DETAILS_FAILURE = `${NAME}/GET_CHECKOUT_DETAILS_FAILURE`;

export const checkout = store => store[NAME]
export const checkoutCreate = (query) => {
    return {
        type: CHECKOUT_CREATE,
        query
    }
}

export const checkoutCreateSuccess = (data) => {
    return {
        type: CHECKOUT_CREATE_SUCCESS,
        data
    }
}

export const checkoutCreateFailure = (error) => {
    return {
        type: CHECKOUT_CREATE_FAILURE,
        error
    }
}

export const checkoutLineItemsAdd = (query, input, callback) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD,
        query,
        input,
        callback
    }
}

export const checkoutLineItemsAddSuccess = (data) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD_SUCCESS,
        data
    }
}

export const checkoutLineItemsAddFailure = (error) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD_FAILURE,
        error
    }
}



export const checkoutShippingLineUpdate = (query, input, callback) => {
    return {
        type: SHIPPING_LINE_UPDATE,
        query,
        input,
        callback
    }
}

export const checkoutShippingLineUpdateSuccess = (data) => {
    return {
        type: SHIPPING_LINE_UPDATE_SUCCESS,
        data
    }
}

export const checkoutShippingLineUpdateFailure = (error) => {
    return {
        type: SHIPPING_LINE_UPDATE_FAILURE,
        error
    }
}

export const checkoutLineItemsUpdate = (query, input, callback) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD,
        query,
        input,
        callback
    }
}

export const checkoutLineItemsUpdateSuccess = (data) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD_SUCCESS,
        data
    }
}

export const checkoutLineItemsUpdateFailure = (error) => {
    return {
        type: CHECKOUT_LINE_ITEMS_ADD_FAILURE,
        error
    }
}

export const checkoutAttributesUpdate = (query, input, callback) => {
    return {
        type: CHECKOUT_ATTRIBUTES_UPDATE,
        query,
        input,
        callback
    }
}

export const checkoutAttributesUpdateSuccess = (data) => {
    return {
        type: CHECKOUT_ATTRIBUTES_UPDATE_SUCCESS,
        data
    }
}

export const checkoutAttributesUpdateFailure = (error) => {
    return {
        type: CHECKOUT_ATTRIBUTES_UPDATE_FAILURE,
        error
    }
}


export const checkoutLineItemsRemove = (query, input, callback) => {
    return {
        type: CHECKOUT_LINE_ITEMS_REMOVE,
        query,
        input,
        callback
    }
}

export const checkoutLineItemsRemoveSuccess = (data) => {
    return {
        type: CHECKOUT_LINE_ITEMS_REMOVE_SUCCESS,
        data
    }
}

export const checkoutLineItemsRemoveFailure = (error) => {
    return {
        type: CHECKOUT_LINE_ITEMS_REMOVE_FAILURE,
        error
    }
}

export const checkoutCustomerAssociate = (query, input, callback) => {
    return {
        type: CHECKOUT_CUSTOMER_ASSOCIATE,
        query,
        input,
        callback
    }
}

export const checkoutCustomerAssociateSuccess = (data) => {
    return {
        type: CHECKOUT_CUSTOMER_ASSOCIATE_SUCCESS,
        data
    }
}

export const checkoutCustomerAssociateFailure = (error) => {
    return {
        type: CHECKOUT_CUSTOMER_ASSOCIATE_FAILURE,
        error
    }
}

export const getRecentCheckOutDetails = (query) => {
    return {
        type: GET_RECENT_CHECKOUT,
        query
    }
}

export const getRecentCheckOutDetailsSuccess = (data) => {
    return {
        type: GET_RECENT_CHECKOUT_SUCCESS,
        data
    }
}

export const getRecentCheckOutDetailsFailure = (error) => {
    return {
        type: GET_RECENT_CHECKOUT_FAILURE,
        error
    }
}


export const checkoutGiftCardAdd = (query, input, callback) => {
    return {
        type: CHECKOUT_GIFT_CARD_ADD,
        query,
        input,
        callback
    }
}

export const checkoutGiftCardAddSuccess = (data) => {
    return {
        type: CHECKOUT_GIFT_CARD_ADD_SUCCESS,
        data
    }
}

export const checkoutGiftCardAddFailure = (error) => {
    return {
        type: CHECKOUT_GIFT_CARD_ADD_FAILURE,
        error
    }
}



export const checkoutDiscountAdd = (query, input, callback) => {
    return {
        type: CHECKOUT_DISCOUNT_ADD,
        query,
        input,
        callback
    }
}

export const checkoutDiscountAddSuccess = (data) => {
    return {
        type: CHECKOUT_DISCOUNT_ADD_SUCCESS,
        data
    }
}

export const checkoutDiscountAddFailure = (error) => {
    return {
        type: CHECKOUT_DISCOUNT_ADD_FAILURE,
        error
    }
}



export const shippingAddressAdd = (query, input, callback) => {
    return {
        type: SHIPPING_ADDRESS_ADD,
        query,
        input,
        callback
    }
}

export const shippingAddressAddSuccess = (data) => {
    return {
        type: SHIPPING_ADDRESS_ADD_SUCCESS,
        data
    }
}

export const shippingAddressAddFailure = (error) => {
    return {
        type: SHIPPING_ADDRESS_ADD_FAILURE,
        error
    }
}



export const checkOutDetails = (query) => {
    return {
        type: GET_CHECKOUT_DETAILS,
        query
    }
}

export const checkOutDetailsSuccess = (data) => {
    return {
        type: GET_CHECKOUT_DETAILS_SUCCESS,
        data
    }
}

export const checkOutDetailsFailure = (error) => {
    return {
        type: GET_CHECKOUT_DETAILS_FAILURE,
        error
    }
}


export const getNewCheckOutId = (state) => {
    return state.CHECKOUT.checkoutId;
}

export const getCheckoutId = (state) => {
    return state.CHECKOUT.recentCheckout;
};


export const getCheckoutDetails = (state) => {
    return state.CHECKOUT.checkoutDetails;
};