import { toast } from 'react-toastify';

export const ErrorToaster = () => {
    return toast.success('Sorry some thing went wrong,Please try again later', {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export const SuccessToaster = () => {
    return toast.success('Success', {
        position: toast.POSITION.TOP_RIGHT,
    });
}