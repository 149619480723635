import { defaultSuitFabricObject } from '../../../util/defaultFabricObject';
import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedSuitFabric: defaultSuitFabricObject()
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_SELECTED_SUIT_FABRIC: {
            let { selectedSuitFabric } = state
            return {
                ...state,
                selectedSuitFabric: {
                    ...selectedSuitFabric,
                    ...action.data
                }
            };
        }
        case Actions.SET_DEFAULT_SUIT_FABRIC: {
            return {
                ...state,
                selectedSuitFabric: defaultSuitFabricObject(action.data),
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};