import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { SHOPIFY_ADMIN_ORDER_URL } from '../../config/constant'
import { GET_ADMIN_ORDER_LIST } from '../../Queries/getAdminOrders'
import { S3BASE_URL, S3BASE_URL_BLAZER, S3BASE_URL_PANT, S3BASE_URL_SUIT } from '../../config/api_endpoint'
import { detectBrowser } from '../../util/detect-browser'
import { selectedPantJSON, selectedShirtJSON, selectedSuitJSON, selectedVestJSON, selectedBlazerJSON } from '../../util/defaultFabricObject'
import { PATH } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'

class Admin_Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        const { orderId } = this.props.match.params
        let adminOrderId = `${SHOPIFY_ADMIN_ORDER_URL}${orderId}`
        let getAdminOrderListQuery = GET_ADMIN_ORDER_LIST(adminOrderId)
        this.props.getAdminOrderList(getAdminOrderListQuery)
    }
    openProducts(type, customAttributes, lineItemId) {
        let url = ''
        if (type === 'shirt') {
            let selectedShirt = selectedShirtJSON(customAttributes, S3BASE_URL)
            let { selectedAccents, selectedFabrics, selectedCombinationDetails, measurement } = selectedShirt || {}
            if (selectedCombinationDetails) {
                this.props.editShirtStyle(selectedCombinationDetails)
            }
            selectedFabrics['editProducts'] = true
            selectedFabrics['lineItemId'] = lineItemId
            this.props.editShirtAccent(selectedAccents)
            this.props.updateShirtFabric(selectedFabrics)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/shirt/canvas/details'
        } else if (type === 'pant') {
            let selectedPant = selectedPantJSON(customAttributes, S3BASE_URL_PANT)
            let { selectedFabrics, selectedCombinationDetails, measurement } = selectedPant || {}
            if (selectedCombinationDetails) {
                this.props.editSelectedPantCombination(selectedCombinationDetails)
            }
            selectedFabrics['editProducts'] = true
            selectedFabrics['lineItemId'] = lineItemId
            this.props.updateSelectedPantFabric(selectedFabrics)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/pant/canvas/details'
        } else if (type === 'suit') {
            let selectedSuit = selectedSuitJSON(customAttributes, S3BASE_URL_SUIT)
            let { selectedSuitAccents, selectedFabrics, selectedCombination, measurement } = selectedSuit || {}
            if (selectedCombination) {
                this.props.updateSelectedSuitStyle(selectedCombination)
            }
            selectedFabrics['lineItemId'] = lineItemId
            this.props.updateSelectedSuitFabric(selectedFabrics)
            this.props.updateSelectedSuitAccent(selectedSuitAccents)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/suit/canvas/details'
        } else if (type === 'blazer') {
            let selectedSuit = selectedBlazerJSON(customAttributes, S3BASE_URL_BLAZER)
            let { selectedSuitAccents, selectedFabrics, measurement, selectedCombination } = selectedSuit || {}
            if (selectedCombination) {
                this.props.editBlazerStyle(selectedCombination)
            }
            selectedFabrics['lineItemId'] = lineItemId
            this.props.updateSelectedBlazerFabric(selectedFabrics)
            this.props.updateSelectedBlazerAccent(selectedSuitAccents)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/blazer/canvas/details'
        } else if ('vest') {
            let selectedSuit = selectedVestJSON(customAttributes, S3BASE_URL)
            let { selectedFabrics,
                vestsFabrics, selectedSuitAccents,
                selectedCombination, selectedVestCombination,
                selectedVestAccents, measurement } = selectedSuit || {}
            if (selectedCombination) {
                this.props.updateSelectedSuitStyle(selectedCombination)
            }
            if (selectedVestCombination) {
                this.props.editSelectedVestCombination(selectedVestCombination)
            }
            vestsFabrics['lineItemId'] = lineItemId
            this.props.updateSelectedSuitFabric(selectedFabrics)
            this.props.updateSelectedSuitAccent(selectedSuitAccents)
            this.props.updateVestFabric(vestsFabrics)
            this.props.editSelectedVestAccent(selectedVestAccents)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/vest/canvas/details'
        } else {
            let selectedShirt = selectedShirtJSON(customAttributes, S3BASE_URL)
            let { selectedAccents, selectedFabrics, selectedCombinationDetails, measurement } = selectedShirt
            if (selectedCombinationDetails) {
                this.props.editSelectedCombination(selectedCombinationDetails)
            }
            selectedFabrics[0]['editProducts'] = true
            selectedFabrics[0]['lineItemId'] = lineItemId
            this.props.editSelectedAccent(selectedAccents)
            this.props.updateSelectedFabric(selectedFabrics)
            this.props.updateSelectedMeasurement(measurement)
            url = '/tailor/order/shirt/canvas/details'
        }
        this.props.history.push(url)
    }
    _renderFoldedCartProducts(customAttributes, S3) {
        let selectedShirt = selectedShirtJSON(customAttributes, S3)
        let { defaultFoldedShirt, shirtBackCollar,
            foldedCollarCenterButton, foldedCollarDownButton,
            shirtBackPlacket, stripeFoldedButton, folded_collar_fabric,
            foldedCuffFabric, shirtCuffAll, cuffButton, shirtBackPocket,
            foldedPocketButton } = selectedShirt && selectedShirt.selectedCombinationObj
        return (
            <div className="col-lg-3 col-4 pi-shirt-containers p-0 h-100">
                {<div className={`pi_container pi_shirt_cuff`}>
                    <img className="custom_shirt shirt_back"
                        src={defaultFoldedShirt}
                        alt='shirt' />
                    <img className="custom_shirt shirt_collar"
                        src={shirtBackCollar.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_collar_fabric"
                        src={folded_collar_fabric && folded_collar_fabric.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_collar_button"
                        src={foldedCollarCenterButton}
                        alt='shirt' />
                    <img className="custom_shirt shirt_collar_down_button"
                        src={foldedCollarDownButton}
                        alt='shirt' />
                    <img className="custom_shirt shirt_placket"
                        src={shirtBackPlacket.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_placket_button"
                        src={stripeFoldedButton}
                        alt='shirt' />
                    <img className="custom_shirt shirt_cuff_all"
                        src={shirtCuffAll.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_cuff_fabric"
                        src={foldedCuffFabric && foldedCuffFabric.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_cuff_button"
                        src={cuffButton}
                        alt='shirt' />
                    <img className="custom_shirt shirt_back_pocket"
                        src={shirtBackPocket.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt shirt_back_pocket_button"
                        src={foldedPocketButton}
                        alt='shirt' />

                </div>
                }
            </div>
        )

    }
    _renderFoldedPantCartProducts(customAttributes, S3) {
        let selectedPant = selectedPantJSON(customAttributes, S3)
        let { foldedPantFit, foldedCuff, foldedPleats, foldedPocket, foldedSidePocket } = selectedPant && selectedPant.selectedCombinationObj
        return (
            <div className="col-lg-6 col-md-6 col-7 col-sm-12 pi-shirt-containers">
                {<div className={`pi_container pi-pant folded-pant w-75 m-auto pi_shirt_cuff`}>
                    <img className="custom_shirt folded_fit"
                        src={foldedPantFit}
                        alt='shirt' />
                    <img className="custom_shirt folded_cuff"
                        src={foldedCuff}
                        alt='shirt' />
                    <img className="custom_shirt folded_pleats"
                        src={foldedPleats}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        src={foldedSidePocket}
                        alt='shirt' />
                </div>
                }
            </div>
        )
    }
    _renderShirtProducts(customAttributes, productId) {
        return (
            <React.Fragment>
                <div className="col-lg-4 admin_canvas_div admin_canvas_col-4_padding">
                    <div className="media position-relative row">
                        {
                            customAttributes && customAttributes.length > 0 && (
                                this._renderFoldedCartProducts(customAttributes, S3BASE_URL)
                            )
                        }
                        <div className="media-body my-auto col-lg-9 col-8 admin_canvas_view_btn d-flex">
                            {/* <p className='fs-12 H-l admin_canvas_fs_mobile admin_canvas_content_padding'>Formal French Cuff Dress Shirt</p> */}
                            {/* <p className='fs-12 H-l admin_canvas_fs_mobile admin_canvas_content_padding'>Lorem Ipsum is simply dummy text of the printing </p> */}
                            <ButtonDesign
                                btnWidth={'d-flex admin_canvas_btn cursor-pointer'}
                                className={'fs-14 H-cb p-0'}
                                ButtonValue={'view products'}
                                NoImg
                                onClick={() => this.openProducts('shirt', customAttributes, productId)}
                                arrowImg={PATH?.img?.GetMoreArrow}
                            />
                            {/* <button
                                type="button"
                                className="btn btn-s-cus"
                                onClick={() => this.openProducts('shirt', customAttributes, productId)}>
                                <span>View Products</span>
                            </button> */}
                        </div>
                    </div>
                </div>


                {/* <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 '>
                    <div className='admin_canvas_product_content py-4 px-4 row'>
                        <div className='col-4 admin_canvas_product_img_section p-0 media position-relative' >
                            {
                                customAttributes && customAttributes.length > 0 && (
                                    this._renderFoldedCartProducts(customAttributes, S3BASE_URL)
                                )
                            }
                        </div>
                        <div className='col-8 media-body' >
                            <button className='btn common_btn secondary_btn mt-3'
                                type="button" onClick={() => this.openProducts('shirt', customAttributes, productId)}>View Products</button>
                        </div>
                    </div>
                </div> */}
            </React.Fragment >
        )
    }
    _renderPantProducts(customAttributes, productId) {
        return (
            <div className="col-lg-6 ">
                <div className="media position-relative row">
                    {
                        customAttributes && customAttributes.length > 0 && (
                            this._renderFoldedPantCartProducts(customAttributes, S3BASE_URL_PANT)
                        )
                    }
                    <div className="media-body my-auto">
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.openProducts('pant', customAttributes, productId)}>
                            <span>View Products</span>
                            <i className="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        )

    }
    _renderFoldedBlazerCartProducts(customAttributes, S3) {
        let combineSuitFabrics = selectedBlazerJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <div className="col-lg-6 col-md-6 col-7 col-sm-12 pi-shirt-containers">
                <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_shirt_cuff`}>
                    <img className="custom_shirt folded_fit"
                        src={foldedNormalFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedCollar.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_inner_suit"
                        src={foldedInnerSuit.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_collar"
                        src={suitInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={suitBottomInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={foldedSuitFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_lapels"
                        src={foldedLapelsHole}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={suitPocketsSquare}
                        alt='shirt' />

                    <img className="custom_shirt folded_pleats"
                        src={foldedSleeve.replace('.png', detectBrowser())}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_hole"
                        src={foldedButtonHole}
                        alt='shirt' />

                    <img className="custom_shirt folded_button"
                        src={foldedSuitButtons}
                        alt='shirt' />

                    <img className="custom_shirt folded_thread"
                        src={foldedButtonThread}
                        alt='shirt' />
                </div>
            </div>
        )
    }
    _renderFoldedSuitCartProducts(customAttributes, S3) {
        let combineSuitFabrics = selectedSuitJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedPant, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <div className="col-lg-6 col-md-6 col-7 col-sm-12 pi-shirt-containers">
                <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_shirt_cuff`}>
                    <img className="custom_shirt folded_fit"
                        src={foldedNormalFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedCollar.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_inner_suit"
                        src={foldedInnerSuit.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_collar"
                        src={suitInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={suitBottomInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={foldedSuitFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_lapels"
                        src={foldedLapelsHole}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={suitPocketsSquare}
                        alt='shirt' />

                    <img className="custom_shirt folded_pleats"
                        src={foldedSleeve.replace('.png', detectBrowser())}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_hole"
                        src={foldedButtonHole}
                        alt='shirt' />

                    <img className="custom_shirt folded_button"
                        src={foldedSuitButtons}
                        alt='shirt' />

                    <img className="custom_shirt folded_thread"
                        src={foldedButtonThread}
                        alt='shirt' />

                    <img className="custom_shirt folded_pant"
                        src={foldedPant.replace('.png', detectBrowser())}
                        alt='shirt' />
                </div>
            </div>
        )
    }
    _renderSuitProducts(customAttributes, productId) {
        return (
            <div className="col-lg-6">
                <div className="media position-relative row">
                    {
                        customAttributes && customAttributes.length > 0 && (
                            this._renderFoldedSuitCartProducts(customAttributes, S3BASE_URL_SUIT)
                        )
                    }
                    <div className="media-body my-auto">
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.openProducts('suit', customAttributes, productId)}>
                            <span>View Products</span>
                            <i className="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    _renderBlazerProducts(customAttributes, productId) {
        return (
            <div className="col-lg-6">
                <div className="media position-relative row">
                    {
                        customAttributes && customAttributes.length > 0 && (
                            this._renderFoldedBlazerCartProducts(customAttributes, S3BASE_URL_BLAZER)
                        )
                    }
                    <div className="media-body my-auto">
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.openProducts('blazer', customAttributes, productId)}>
                            <span>View Products</span>
                            <i className="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }


    _renderFoldedVestCartProducts(customAttributes, S3) {
        let combineSuitFabrics = selectedVestJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedPant, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole, suitVests, suitVestsButtons } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <div className="col-lg-6 col-md-6 col-7 col-sm-12 pi-shirt-containers">
                <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_shirt_cuff`}>
                    <img className="custom_shirt folded_fit"
                        src={foldedNormalFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedCollar.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_inner_suit"
                        src={foldedInnerSuit.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_collar"
                        src={suitInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={suitBottomInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={suitVests.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_vests_button"
                        src={suitVestsButtons}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={foldedSuitFit.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_lapels"
                        src={foldedLapelsHole}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={suitPocketsSquare}
                        alt='shirt' />

                    <img className="custom_shirt folded_pleats"
                        src={foldedSleeve.replace('.png', detectBrowser())}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_hole"
                        src={foldedButtonHole}
                        alt='shirt' />

                    <img className="custom_shirt folded_button"
                        src={foldedSuitButtons}
                        alt='shirt' />

                    <img className="custom_shirt folded_thread"
                        src={foldedButtonThread}
                        alt='shirt' />

                    <img className="custom_shirt folded_pant"
                        src={foldedPant.replace('.png', detectBrowser())}
                        alt='shirt' />
                </div>
            </div>
        )
    }

    _renderVestProducts(customAttributes, productId) {
        return (
            <div className="col-lg-6">
                <div className="media position-relative row">
                    {
                        customAttributes && customAttributes.length > 0 && (
                            this._renderFoldedVestCartProducts(customAttributes, S3BASE_URL_SUIT)
                        )
                    }
                    <div className="media-body my-auto">
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.openProducts('vest', customAttributes, productId)}>
                            <span>View Products</span>
                            <i className="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { adminOrderList } = this.props.adminOrderList || {}
        const { order } = adminOrderList || {}
        return (
            <React.Fragment>
                {/* <div className="admin_canvas_product_list">
                    <h6 className='fs-16 f-m text_muted text-center mt-4 mb-4'>Orders</h6>
                    <div className='d-flex flex-column admin_canvas_product_scroll'>
                        <div className='admin_canvas_product_section py-lg-4 py-3'>
                            <div className='row m-0 px-2 py-4 row-gap-4'>
                                <h6 className='fs-16 f-m text-center'>Shirts</h6>
                                {order && order.lineItems && order.lineItems.edges.map((o) => {
                                    let { customAttributes, productId } = o?.node || {}
                                    if (customAttributes?.length > 0 && customAttributes[0].key === "SelectedShirt") {
                                        return this._renderShirtProducts(customAttributes, productId)

                                    } else if (customAttributes && customAttributes[0].key === "SelectedPant") {
                                        return this._renderPantProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "SelectedSuit") {
                                        return this._renderSuitProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "SelectedBlazer") {
                                        return this._renderBlazerProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "selected3PieceSuit") {
                                        return this._renderVestProducts(customAttributes && customAttributes, productId)
                                    } else {
                                        return <span>No products found</span>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* view products page */}
                <div className="container mx-auto admin_canvas_product_list">
                    <h6 className='fs-24 H-cb mt-4 mb-4 text-uppercase'>Orders</h6>
                    <div className='d-flex flex-column admin_canvas_product_scroll'>
                        <div className=''>
                            <div className='row m-0 py-4 admin_canvas_row_gap'>
                                {/* <div className="d-flex admin_canvas_tab gap-44">
                                    <div
                                        className='H-m fs-15 '>shirts
                                        <p className='active_items'></p>
                                    </div>
                                    <div
                                        className='H-m fs-15'>pants
                                    </div>
                                    <div
                                        className='H-m fs-15'>blazers
                                    </div>
                                    <div
                                        className='H-m fs-15'>suits
                                    </div>
                                </div> */}
                                {order && order.lineItems && order.lineItems.edges.map((o) => {
                                    let { customAttributes, productId } = o?.node || {}
                                    if (customAttributes?.length > 0 && customAttributes[0].key === "SelectedShirt") {
                                        return this._renderShirtProducts(customAttributes, productId)

                                    } else if (customAttributes && customAttributes[0].key === "SelectedPant") {
                                        return this._renderPantProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "SelectedSuit") {
                                        return this._renderSuitProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "SelectedBlazer") {
                                        return this._renderBlazerProducts(customAttributes && customAttributes, productId)
                                    } else if (customAttributes && customAttributes[0].key === "selected3PieceSuit") {
                                        return this._renderVestProducts(customAttributes && customAttributes, productId)
                                    } else {
                                        return <span>No products found</span>
                                    }
                                })}
                            </div>

                           
                        </div>
                    </div>
                </div>

                {/* search page */}

            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        adminOrderList: Actions.getAdminOrders(state)
    }
}

export default withRouter(connect(mapStateToProp, {
    getAdminOrderList: Actions.getAdminOrderList,
    updateShirtAccent: Actions.updateShirtAccent,
    updateShirtStyle: Actions.updateShirtStyle,
    updateShirtFabric: Actions.updateShirtFabric,
    adminUpdateSelectedPantCombination: Actions.adminUpdateSelectedPantCombination,
    updateSelectedSuitAccent: Actions.updateSelectedSuitAccent,
    updateSelectedSuitStyle: Actions.updateSelectedSuitStyle,
    updateSelectedSuitFabric: Actions.updateSelectedSuitFabric,
    editSelectedVestAccent: Actions.editSelectedVestAccent,
    updateSelectedMeasurement: Actions.updateSelectedMeasurement,
    updateSelectedPantStyle: Actions.updateSelectedPantStyle,
    updateSelectedPantFabric: Actions.updateSelectedPantFabric,
    updateBlazerStyle: Actions.updateBlazerStyle,
    updateSelectedBlazerFabric: Actions.updateSelectedBlazerFabric,
    updateSelectedBlazerAccent: Actions.editSelectedBlazerAccent,
    editShirtStyle: Actions.editShirtStyle,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    getCustomerList: Actions.getCustomerList,
    editShirtAccent: Actions.editShirtAccent,
    editSelectedPantCombination: Actions.editSelectedPantCombination,
    editBlazerStyle: Actions.editBlazerStyle,
    editSelectedBlazerAccent: Actions.editSelectedBlazerAccent,
    updateVestFabric: Actions.updateVestFabric,
    editSelectedVestCombination: Actions.updateSelectedVestStyle,
})(Admin_Products));