import React from 'react'
import { withRouter } from 'react-router-dom'
import { CONTENTS } from '../../globals/app-constraints'

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="col-12 p-0" >
                    <div className="container mt-3 mb-5">
                        <div className='exchange_contents px-lg-0 px-2'>
                            <h1 className='exchange_head mt-lg-4 mt-3 mb-3 text-center fs-24 H-m'>{CONTENTS.privacyPolicy.head}</h1>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.para1Part1} <a href="https://worldofakal.com" className='text-decoration-none H-m text-dark'>{CONTENTS.privacyPolicy.akalLink}</a>{CONTENTS.privacyPolicy.para1Part2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.para3}<a href="https://www.privacypolicygenerator.info" className='text-decoration-none H-m text-dark'>{CONTENTS.privacyPolicy.privacyLink}</a>.</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head1}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head1Para}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head2}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head2Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head2Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head2Para3}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head3}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Para}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point4}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point5}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point6}</li>
                                <li className='fs-14 H-l'>{CONTENTS.privacyPolicy.head3Point7}</li>
                            </ul>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head4}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head4Para}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head5}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head5Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head5Para2} <a href="https://www.generateprivacypolicy.com/#cookies" className='text-decoration-none H-m text-dark'>{CONTENTS.privacyPolicy.head5Para2Link}</a></p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head6}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head6Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head6Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head6Para3}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 fs-16 H-m'>{CONTENTS.privacyPolicy.head7}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head7Para1} </p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head7Para2}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head8}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head8Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head8Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head8Para3}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head8Para4}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head8Para5}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 fs-16 H-m'>{CONTENTS.privacyPolicy.head9}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para3}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para4}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para5}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para6}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para7}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head9Para8}</p>
                            <h2 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.privacyPolicy.head10}</h2>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head10Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.privacyPolicy.head10Para2}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(PrivacyPolicy)