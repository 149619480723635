export const shirtMock = {
    overSize: true,
    type: 'shirt',
    customSize: [
        {
            label: 'XS',
            size: 35,
            category:"Small",
            subSize: [{
                label: "Collar around", size: 35.5
            },
            {
                label: "Chest width", size: 49
            },
            {
                label: "Shoulder width", size: 40
            }, {
                label: "Waist width", size: 41
            }, {
                label: "Back length", size: 74
            },
            {
                label: "Sleeve length", size: 62.5
            }
            ]
        }, {
            label: 'XS/S',
            size: 36,
            category:"Small",
            subSize: [{
                label: "Collar around", size: 36.5
            },
            {
                label: "Chest width", size: 49
            },
            {
                label: "Shoulder width", size: 40
            }, {
                label: "Waist width", size: 41
            }, {
                label: "Back length", size: 74
            },
            {
                label: "Sleeve length", size: 62.5
            }
            ]
        }, {
            label: 'S',
            size: 37,
            category:"Small",
            subSize: [{
                label: "Collar around", size: 37.5
            },
            {
                label: "Chest width", size: 52
            },
            {
                label: "Shoulder width", size: 42
            }, {
                label: "Waist width", size: 44
            }, {
                label: "Back length", size: 76
            },
            {
                label: "Sleeve length", size: 63.5
            }
            ]
        }, {
            label: 'S',
            size: 38,
            category:"Small",
            subSize: [{
                label: "Collar around", size: 38.5
            },
            {
                label: "Chest width", size: 52
            },
            {
                label: "Shoulder width", size: 42
            }, {
                label: "Waist width", size: 44
            }, {
                label: "Back length", size: 76
            },
            {
                label: "Sleeve length", size: 63.5
            }
            ]
        }, {
            label: 'M',
            size: 39,
            category:"Medium",
            subSize: [{
                label: "Collar around", size: 39.5
            },
            {
                label: "Chest width", size: 52
            },
            {
                label: "Shoulder width", size: 42
            }, {
                label: "Waist width", size: 44
            }, {
                label: "Back length", size: 76
            },
            {
                label: "Sleeve length", size: 64.5
            },
            ]
        }, {
            label: 'M',
            size: 40,
            category:"Medium",
            subSize: [
                {
                    label: "Collar around", size: 40.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 64.5
                },
            ]
        }, {
            label: 'L',
            size: 41,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 41.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 65.5
                },
            ]
        }, {
            label: 'L',
            size: 42,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 42.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 65.5
                },
            ]
        }, {
            label: 'XL',
            size: 43,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 43.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 66.5
                },
            ]
        }, {
            label: 'XL',
            size: 44,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 44.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 66.5
                },
            ]
        }, {
            label: 'XXL',
            size: 45,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 45.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 67.5
                },
            ]
        }, {
            label: 'XXL',
            size: 46,
            category:"Long",
            subSize: [
                {
                    label: "Collar around", size: 46.5
                },
                {
                    label: "Chest width", size: 52
                },
                {
                    label: "Shoulder width", size: 42
                }, {
                    label: "Waist width", size: 44
                }, {
                    label: "Back length", size: 76
                },
                {
                    label: "Sleeve length", size: 67.5
                },
            ]
        },
    ]
}